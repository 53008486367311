#join-us,
#our-experience,
#certificates,
#platform {
  margin-top: 4rem !important;
}

#platform {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#any-question {
  margin-top: -3rem;
  margin-bottom: 3rem;
}

#home-about-beno {
  margin-top: 6rem !important;
}

/* -================= New Header  for pc =============- */
.header-main-navbar {
  background: #fff;
  transition: 0.5s;
  z-index: 999;
  /* margin-bottom: -170px; */
  height: 90px !important;
  width: 100%;
  padding: 0 !important;
}

.sticky {
  left: 0;
  margin: auto;
  position: fixed !important;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  background: #ffffff !important;
  transition: 0.5s;
  z-index: 99999;
  -webkit-animation: 300ms running fadeInDown;
  animation: 500ms running fadeInUp;
  animation-name: slideInDown;
}

.mobile-menu-sticky {
  position: fixed !important;
  width: 100%;
  transition: 0.5s;
  z-index: 99999;
  animation: 500ms fadeInUp ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-main-navbar a.site-logo {
  padding: 15px 0 !important;
}

.header-main-navbar a.site-logo img {
  width: 12rem;
  height: 60px;
}

.header-main-navbar ul {
  list-style: none;
  height: 66px;
  padding: 0 !important;
  align-items: center;
}

.header-main-navbar ul li {
  padding: 31px 10px 31px 10px !important;
}


.header-main-navbar ul li a {
  color: #232323;
  font-size: 17px;
  font-weight: 700;
  padding: 0 5px;
  margin: 0 0 !important;
}

.header-main-navbar ul li:hover,
.dropdown-services-btn-efft {
  border-bottom: 3px solid #CD4409;
  background-color: antiquewhite;
}

.header-main-navbar .donate-btn-header {
  margin: 0 2rem !important;
}

.donate-btn-header a {
  color: #000 !important;
}

.donate-btn-header {
  position: relative;
}

.donate-btn-header ul {
  padding: unset !important;
  margin: unset !important;
  width: unset !important;
  height: unset !important;
  right: 20px !important;
}

.donate-btn-header ul li {
  padding: unset !important;
}

.donate-btn-header ul li a {
  font-size: 16px !important;
}

.dropdown-btn-main-head a svg,
.industries-dropdown-cont a svg,
.dropdown-services-btn-efft a svg {
  font-size: 12px;
  margin: 2px 0 0 2px !important;
  opacity: 0;
}

.dropdown-btn-main-head:hover a svg,
.industries-dropdown-cont:hover a svg,
.dropdown-services-btn-efft a svg {
  opacity: 1 !important;
}

.mega-section-services,
.mega-section-industries {
  width: 969px;
  height: 130px;
  top: 90px;
  border-radius: 0px 0px 8px 8px;
  border: 1px;
  display: none;
  position: absolute;
  background: #ffffff !important;
  box-shadow: 0px 0px 6px 1px rgb(9 8 8 / 20%);
}

.mega-explore-menu {
  width: 223px;
  height: 170px;
  top: 90px;
  border-radius: 0px 0px 8px 8px;
  border: 1px;
  display: none;
  position: absolute;
  background: #ffffff !important;
  box-shadow: 0px 3px 3px 0px rgb(9 8 8 / 20%);
}

.mega-explore-menu a {
  padding: 15px !important;
  font-size: 17px !important;
}

.mega-explore-menu a svg {
  opacity: 0;
}

.mega-explore-menu a:hover svg {
  opacity: 1;
}

.mega-explore-menu a:hover {
  color: #CD4409;
  border-bottom: 1px solid #CD4409;
}

.mega-section-industries {
  height: 175px !important;
}

.mega-menu-drop-data a {
  color: #212121;
  font-size: 17px;
  font-style: normal;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 5px;
}

.mega-menu-drop-data a svg {
  opacity: 0;
  font-size: 13px;
  margin: 0 0 0 3px !important;
}

.mega-menu-drop-data a:hover svg {
  opacity: 1 !important;
}

.mega-menu-drop-data a:hover {
  color: #CD4409;
  border-bottom: 1px solid #CD4409;
}

/* -================== header for mobile and tablet ================- */
.mobile-menu-container {
  overflow: hidden;
}

.mobile-menu-container .mobile-menu-bar {
  background: #fff;
  padding: 4px 0 0;
  position: relative;
  width: 100%;
  z-index: 999999;
}

.mobile-menu-logo img {
  width: 170px !important;
  height: 60px;
  margin: 10px 10px 10px 15px !important;
}

.mobile-menu-container .meanmenu-reveal {
  width: 22px;
  height: 22px;
  background: #fff;
  border: unset !important;
  padding: 18px;
  cursor: pointer;
  color: #ff4815;
  float: right;
}

.meanmenu-reveal svg {
  width: 25px !important;
  height: 30px !important;
}

.mobile-menu-container .mobile-mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.mobile-menu-container .mobile-mean-nav ul li {
  float: left;
  width: 100%;
  border-top: 1px solid#ddd;
  background: #444;
}

.mobile-mean-nav ul li:hover {
  background: #464040 !important;
  color: #CD4409 !important;
}

.mobile-menu-container .mobile-mean-nav ul li a {
  background: #f8f8f8 none repeat scroll 0 0;
  color: #444;
  display: block;
  float: left;
  font-size: 14px;
  margin: 0;
  padding: 1em 5%;
  text-transform: uppercase;
  width: 90%;
  font-weight: bold;
}

.mobile-menu-container .mobile-mean-nav ul li li a {
  opacity: 0.75;
  padding: 1em 10%;
  text-shadow: none !important;
  visibility: visible;
  width: 80%;
  font-weight: normal;
  text-decoration: none;
  text-transform: capitalize;
  color: #444;
}

.mobile-menu-container .mobile-menu-bar,
.mobile-menu-container .mobile-menu-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}



.heading-ani-effect-industry::after {
  width: 148px !important;
  margin-left: -159px !important;
  margin-top: 10px !important;
  height: 35px !important;
}

.heading-ani-effect-we-serve::after {
  width: 195px !important;
  margin-left: -225px !important;
  margin-top: 20px !important;
}

.heading-ani-effect-tech::after {
  width: 163px !important;
  margin-left: -91px !important;
  margin-top: 13px !important;
}

.heading-ani-effect-tech::after {
  content: none !important;
}

.heading-ani-effect-platforms::after,
.heading-ani-effect-testimonials::after {
  content: none !important;
}

.heading-ani-effect-platforms::after,
.heading-ani-effect-testimonials::after {
  width: 234px !important;
  margin-left: -185px !important;
  margin-top: 17px !important;
}

.sider-ExcellenceITSupport {
  background-blend-mode: overlay;
  background-color: #4f4f4f;
}

.request-call-ctabtn-head {
  background-color: #CD4409;
  padding: 5px 17px !important;
  color: #fff !important;
  border-radius: 30px;
  border: 2px solid #CD4409;
  font-size: 16px !important;
}

.request-call-ctabtn-head:hover {
  background-color: #fff !important;
  border: 2px solid #CD4409;
  color: #CD4409 !important;
}

.request-call-ctabtn-headli {
  border-bottom: unset !important;
  background-color: unset !important;
}

.heading-ani-effect-form-contact::after {
  width: 174px !important;
  margin-left: -173px !important;
  margin-top: 9px !important;
}

/*Hmg Banner*/
.hmg-banner-content,
.home-banner-menu .slick-slide {
  margin: 0 !important;
  padding: 0 !important;
}

.hmg-banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  height: calc(100vh - 90px);
  /* margin: 11rem 0 0 0 !important; */
  /* padding: 0px 0 0 0 !important; */
  display: flex;
  align-items: center;
  transform: scale(1);
  transition: transform 6.5s linear;
  transition-delay: 1.5s;
}

.slick-slide.slick-active .hmg-banner {
  transform: scale(1.05);
  width: 100%;
  overflow: hidden !important;
}

.home-banner-menu .slick-dots {
  margin-bottom: 4rem !important;
}

.home-banner-menu .slick-dots li button {
  color: #fff;
}

.home-banner-menu .slick-dots li.slick-active button:before {
  padding: 10px !important;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #fff;
}

.home-banner-menu .slick-dots li.slick-active .slick-dots li button {
  color: #fff !important;
}

.home-banner-menu .slick-dots li button:before {
  color: #fff;
}

.hmg-banner:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(0 0 0 / 0%);
  width: 100%;
  height: 100vh;
}

.hmg-banner .container {
  position: relative;
  z-index: 9;
}

.hmg-banner p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  margin: 20px 142px 20px 0px;
}

.hmg-banner h2 {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  line-height: 70px;
}

.theame-btn {
  font-size: 17px;
  color: #fff;
  background-color: #CD4409;
  border: 1px solid #CD4409;
  padding: 8px 28px;
  border-radius: 25px;
  transition: all 0.5s;
  font-weight: 600;
}

.theame-btn:hover {
  background-color: #fff;
  border: 1px solid #CD4409;
  color: #CD4409;
}

/* innr-industry start css */
.innr-industry {
  padding: 1rem 0px;
}

.innr-industry .inner-indus h6 {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  color: #14212b;
}

.innr-industry .inner-indus h2 {
  font-size: 45px;
  font-weight: 700;
  line-height: 40px;
  color: #14212b;
  margin-bottom: 30px;
}

.innr-industry .innr-card h5 {
  font-size: 19px;
  font-weight: 600;
  line-height: 28px;
  color: #212121;
}

.innr-industry .innr-card a {
  font-size: 18px;
  font-weight: 500;
  color: #CD4409;
  text-decoration: none;
}

.innr-industry .innr-card a:hover {
  color: #212121;
}

figure {
  /* display: grid; */
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  object-fit: cover;
}

figure>* {
  grid-area: 1/2;
  transition: 0.4s;
}

figure figcaption {
  display: grid;
  align-items: end;
  font-size: 22px;
  font-weight: bold;
  color: #0000;
  padding: 0.75rem;
  background: var(--c, #0009);
  clip-path: inset(0 var(--_i, 100%) 0 0);
  margin: -1px;
}

figure:hover figcaption {
  --_i: 0%;
}

figure:hover img {
  transform: scale(1.2);
}

@supports not (-webkit-mask-clip: text) {
  figure figcaption {
    -webkit-mask: none;
    color: #ffffff;
  }
}

/* ---------------- play button on happens section---------- */
.video-play-button-technologies {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 9%;
  margin-left: 2% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button-technologies:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-technologies 1500ms ease-out infinite;
}

.video-play-button-technologies:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button-technologies:hover:after {
  background-color: darken(#fa183d, 10%);
}

.video-play-button-technologies span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border-technologies {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/* -----------------end play button -------------------- */
div#play-btn-technologies {
  margin-top: 15px;
}

/* innr-industry end css */
#home-industries {
  color: #14212b;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.industries_content {
  color: #14212b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 3rem !important;
}

.partner-logo img {
  width: 130px;
  height: 70px;
  margin-bottom: 20px;
  object-fit: contain;
  cursor: pointer;
}

.indust-wrds h5 {
  color: #14212b;
  font-weight: 700;
  font-size: 20px;
}

.indust-wrds h5:hover {
  color: #CD4409;
}

.indust-wrds p {
  color: #14212b;
  font-weight: 500;
  font-size: 16px;
}

#technologies-logo {
  list-style: none;
  display: flex;
  gap: 10px;
}

/* innr-we-serve start */

section.innr-we-serve {
  margin-top: 2%;
}

.industries-we-serve-eff-left,
.industries-we-serve-eff-right {
  background-image: url(http://localhost:3000/static/media/bg-3.ddf76897bcf698532e31.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  float: left;
  width: 110px;
  margin-top: 21rem;
}

.industries-we-serve-eff-right {
  float: right !important;
  margin-top: 1rem !important;
}

.we-serv-shp img {
  width: 120px;
  height: 180px;
}

.serve-img-1 {
  margin-top: 50px;
}

.serve-img-2 {
  float: right;
}

.innr-we-serve .sec-card {
  padding: 5px;
}

.sec-card-img {
  height: 100%;
  overflow: hidden;
  border-radius: 2px;
}

.sec-card-img img {
  transition: 0.5s ease-in-out;
  margin-bottom: 1rem;
  width: 100%;
  height: 180px;
  border-radius: 2px;
  object-fit: cover;
}

.sec-card:hover {
  background: #fef2ea;
}

.sec-card:hover .sec-card-img img {
  transform: scale(1.1);
}

/* innr-we-serve end */
/* services-section-h start css */
.services-section-h {
  padding: 60px 0px;
}

.certificates {
  height: 220px !important;

}

.services-section-h {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-section-h .services-section-inner .servce-head {
  color: #262626;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 15px;
}

.services-section-h .services-section-inner .servce-head span {
  color: #262626;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.services-section-h .services-section-inner h4 {
  color: #212121;
  font-size: 26px;
  font-weight: 600;
  line-height: 34px;
}

.services-section-h .services-section-inner P {
  color: #262626;
  font-size: 20px;
  font-weight: 400;
}

.services-section-h .services-section-inner a:hover i {
  padding-left: 10px;
  color: #00973a;
  transition: all 0.4s ease-in-out;
}

.subheading p {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 25px;
}

.services-section-inner p {
  line-height: 26px;
}

/* services-section-h end css */
/* inner-Platforms and plus annimation start css */
span.inner-Platforms-cont-right {
  background-repeat: no-repeat;
  background-position: right top;
  float: right;
}

span.inner-Platforms-cont-left {
  background-repeat: no-repeat;
  background-position: right top;
  float: left;
  margin-top: 18rem;
}

span.inner-Platforms-cont-left img,
span.inner-Platforms-cont-right img {
  width: 175px;
}

div.inner-Platforms-cont-circle {
  float: right;
  margin: 0 !important;
  padding: 0 !important;
}

div.inner-Platforms-cont-circle img {
  width: 170px;
}

.innovations-home-beno h2 {
  color: #14212b;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  >span {
    color: #CD4409;
  }
}

.inner-Platforms .inner-plat p {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
  width: 90%;
  margin: auto;
}

.pltfrm {
  text-align: left;
  position: relative;
  margin-left: 60px;
}

.pltfrm img {
  position: absolute;
  right: 0;
  top: 0;
}

.pltfrm a {
  display: inline-block;
  color: #14212b;
  font-size: 18px;
  margin-left: 30px;
  text-decoration: none;
  background-color: #CD4409;
  padding: 6px 25px;
  border: 2px solid #CD4409;
  color: #fff;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.8s;
}

.pltfrm a:hover {
  background-color: unset !important;
  border: 2px solid #CD4409;
  color: #CD4409;
}

.pltfrm .play-btn {
  display: inline-block;
  font-size: 26px;
  background-color: #00973a;
  color: #fff;
  animation: animate-pulse 3s linear infinite;
  border-radius: 50px;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  padding-left: 5px;
  margin: 0;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(74 255 99 / 70%), 0 0 0 0 rgb(74 255 129 / 70%);
  }

  40% {
    box-shadow: 0 0 0 10px rgb(74 255 158 / 52%), 0 0 0 0 rgb(16 213 104 / 76%);
  }

  80% {
    box-shadow: 0 0 0 20px rgb(74 255 131 / 52%),
      0 0 0 30px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
  }
}

/* inner-Platforms and plus annimation end css */
/* Testimonials slider start */

.technologies-heading h2 {
  color: #14212b;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.technologies-heading h2 {
  color: #14212b;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.technologies-content {
  color: #14212b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.inner-Testimonials {
  margin-top: 4rem;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-color: #fcddcb47;
  width: 100%;
  padding: 35px 0px;
  /* margin-top: 35px; */
}

/* Testimonials slider end */
/* inr-started start */


.inr-started .inner-start h2 {
  margin-bottom: 16px;
  font-weight: 600 !important;
  font-size: 28px !important;
  color: #14212B !important;
}

.inr-started .inner-start p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #14212b !important;
}

.inr-started {
  padding: 2rem 0;
}

.inr-started .container {
  position: relative;
  z-index: 99;
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.inr-started input {
  /* border: 1px solid #ececec; */
  background-color: #F9F9F9;
  padding: 8px 20px;
  border: none;
  outline: none;
}

.inr-started input:focus {
  border: 1px solid #CD4409 !important;
  box-shadow: none !important;
}

.inr-started input:hover {
  /* border-bottom: 5px solid #CD4409; */
}

.inr-started textarea:focus {
  border-color: #CD4409 !important;
  box-shadow: none !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ffffff !important;
  font-size: 16px !important;
}

.inr-started textarea {
  /* border: 1px solid #ececec; */
  padding: 8px 20px;
  background-color: #F9F9F9;
  height: 120px;
  border: none;
  outline: none;
}

.inr-started textarea:focus {
  border: 1px solid #CD4409;
}

.inr-started .button-right {
  text-align: start;
}

.contact-form-left {
  box-shadow: 0 0 10px 0 #fff;
  border-radius: 20px;
  background-color: #fff;
  padding: 2rem 2rem 2rem 2rem;

  ul {
    padding-left: 1rem;

    >li {
      color: #CD4409;
      font-size: 16px;
      font-weight: 600;
    }

    >li::marker {
      color: #CD4409;

    }
  }




}

.contact-form-left-main-head {
  position: relative;

  >h4 {
    color: #CD4409;
    font-size: 22px;
    font-weight: 600;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      top: 30px;
      left: 0;
      width: 30%;
      height: 2px;
      background-color: #CD4409;
    }
  }

  >p {
    font-size: 15px;
    color: #14212B;
    margin-bottom: unset;
    line-height: 26px;
  }


  svg {
    color: #CD4409;
  }
}

.contact-form-left-main-head-unique p {
  font-size: 16px;
}

.contact-form-right img {
  width: 380px;
  object-fit: cover;
  border-radius: 50% 50% 10px 10px;
}

.contact-form-left-main-head-unique p a {
  color: #000 !important;
}


/* inr-started start */

/* inner-Testimonials start css */
.testimnl-item {
  position: relative;
  background: #fff;
  padding: 78px 137px 78px 62px;
  border-radius: 125px;
  text-align: right;
  width: 85% !important;
}

.testimnl-item p {
  font-size: 17px;
  color: #14212b;
}

.testimnl-item h5 {
  font-size: 18px;
  font-weight: 600;
  color: #14212b;
  margin-bottom: 0;
  position: relative;
  padding-right: 64px;
}

.testimnl-item h5:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 1px;
  width: 54px;
  background-color: #00973a;
}

.testimnl-img {
  position: absolute;
  right: -107px;
  width: 214px !important;
  height: 214px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px 0px #ccc;
  top: 50%;
  transform: translateY(-50%);
}

/* inner-Testimonials end css */

/* inner-copy-right start */
.inner-copy-right {
  background-color: rgba(0, 150, 67, 0.05);
  padding: 25px 0px 20px 0px;
}

.inner-copy-right .social-media a {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #00792e;
  color: #000000;
  font-size: 15px;
  border-radius: 25px;
  margin-right: 5px;
  transition: 0.4s;
}

.inner-copy-right .social-media a:hover {
  background-color: #00792e;
  color: #ffffff;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/* inner-copy-right end */

.indus-lrn {
  font-weight: 700;
  color: #CD4409;
  font-size: 18px;
  text-decoration: none;
  display: none;
}

.indus-lrn:hover {
  color: #212121;
}

.indus-trends {
  height: 300px;
}

.shape-bg-12 img {
  position: absolute;
  margin-top: 72px;
  z-index: -99999;
  max-width: 100%;
}

.testimnl-para {
  font-size: 24px;
}

#home-contact-textarea {
  resize: none;
}

/*  -------------------------------- lates one section css all pages new -------------------- */
.recent-blogs-slider-once {
  padding: 0px 25px;
}

img.recent-blogs-slider-once-img {
  width: 90%;
  height: 26rem;
  border-radius: 22% 78% 57% 43% / 100% 69% 31% 0%;
  object-fit: cover;
}

h2.recent-blogs-slider-title {
  color: #14212b;
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
}

p.recent-blogs-slider-desc {
  color: #14212b;
  font-size: 16px;
  font-weight: 400;
  padding: 0 5px !important;
  line-height: 26px;
  margin-bottom: 3rem;
}

a.recent-blogs-slider-btn {
  margin-top: 5rem;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background-color: #CD4409;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 33px;
}

a.recent-blogs-slider-btn:hover {
  background-color: #fff;
  color: #CD4409;
  outline: auto;
}

.recent-blogs-slider-date {
  text-align: end;
}

.recent-blogs-slider-date span {
  font-weight: 700;
}

/*  -------------- blogs slider section css all pages new -------------------- */
.dgt-recent-blogs {
  margin-top: 2rem;
}

.single_blog {
  border: 1px solid #ebebeb;
  overflow: hidden;
  transition: 0.5s;
  height: 35rem;
}

.single_blog-certificates {
  overflow: hidden;
  transition: 0.5s;
  height: 6rem;
}

.single_blog_content {
  background: #fff;
  padding: 0 10px !important;
}

.blog_description p {
  font-size: 16px;
  height: 103px;
}

.dgt_blog_meta .meta-date {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  font-size: 15px !important;
}

.single_blog:hover {
  border-color: transparent;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}

.single_blog-certificate:hover {
  border-color: transparent;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
}

.blog_page_title h3 {
  font-size: 18px;
  transition: 0.5s;
}

.blog_page_title a {
  font-weight: 700;
  text-decoration: none;
  color: #000;
}

.blog_page_title a:hover {}

.single_blog_thumb img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.single_blog_thumb_certificates {
  height: 6rem;

  display: flex;
  align-items: center;
  justify-content: center;
}




.single_blog_thumb_certificates img {
  width: 140px;
  object-fit: contain;
  aspect-ratio: 3/2;
  transition: all 0.7s;
  z-index: 99999;
}



.single_blog_thumb_certificates img:hover {

  transform: scale(1.3);
}

.blog_page_button a {
  font-size: 18px;
  transition: 0.5s;
  color: #CD4409;
  text-decoration: none;
  font-weight: 600;
}

.blog_page_button a:hover,
.blog_page_title a:hover,
.dgt_blog_meta a:hover {
  color: #000;
}

.dgt_blog_meta a {
  color: #616161;
  transition: 0.3s;
  position: relative;
  text-decoration: none;
  font-size: 15px !important;
}

.dgt_blog_meta a:before {
  position: absolute;
  right: -6px;
  height: 14px;
  width: 1px;
  background: #666;
  content: "";
  top: 5px;
}

.slick-slide {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-bottom: 10px !important;
}

.slick-prev,
.slick-next {
  background-color: #CD4409 !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #CD4409 !important;
  font-size: 16px !important;
}

/* =*********************  footer css ********************************= */
footer.inners-footer {
  background-color: #4B4B4BE5 !important;
  margin-top: 6%;
}

.footer-top {
  border-bottom-color: rgba(114, 108, 148, 0.2);
  padding-top: 50px;
  padding-bottom: 5px;
}

/* ------- first col ---------- */
.widget.footer-widget {
  background-color: transparent !important;
  padding: 0;
  margin-bottom: 30px;
}

.about_us_widget .footer-logo {
  margin-bottom: 20px;
  display: block;
}

.footer-logo img {
  max-width: 180px;
}

.footer-widget.widget p {
  color: #E8E8E8 !important;
  font-size: 14px;
  padding-bottom: 10px;
  /* border-width: 0 0 2px 0;
  border-style: solid;
  border-image-slice: 0 0 100% 0;
  border-image: linear-gradient(to right, #ff7e5f, #feb47b); */
}

.about_us_widget_para {
  border-bottom: 1px solid #CD4409;

}

.footer-socail-icons {
  margin-top: 1rem;
}

.footer-socail-icons .social_share {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-socail-icons .social_share li {
  display: inline-block;
  margin: 0 5px 0 5px;
}

.footer-socail-icons .social_share li a {
  color: #CD4409;
  width: 40px;
  height: 40px;
  background: #CD4409;
  transition: all 500ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-socail-icons .social_share li:hover a {
  padding-left: 0 !important;
  background-color: #14212B;
  color: #fff;
}


/* -------------- second col and third col  --------- */
#nav_menu-2,
#nav_menu-3 {
  margin-left: 0%;
}

.widget.footer-widget .widget-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding-left: 0;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
}

.widget.footer-widget .widget-title::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60%;
  height: 3px;
  background-image: linear-gradient(to right, #CD4409, #CD4409);

}

.widget.widget_nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget ul li {
  display: block;
  margin: 8px 0 0 0;
  color: #ffffff;
  word-break: break-word;
}

.widget ul li a {
  color: #E8E8E8 !important;
  transition: all 0.3s ease-in;
  border: none;
  text-decoration: none;
  font-size: 14px;
}

.widget.footer-widget ul#menu-services-link li a::after {
  content: "";
  width: 0;
  margin-top: 12px;
  height: 2px;
  float: left;
  background-color: #fff;
  transition: 0.5s all;
}

.widget.footer-widget ul#menu-services-link li a:hover:after {
  width: 15px !important;
  /* margin: 3px 0 0 0 !important; */
  padding: 0 !important;
}

.widget.footer-widget ul#menu-services-link li a:hover {
  padding-left: 2px;
  transition: 0.5s all;
}

svg.footer-lists-icons {
  color: #fff;
  font-size: 12px;
  margin: 0 7px 0 0;
}

/*  ------------ four col ---------- */
.widget .recent_post_item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget .recent_post_item li.single-recent-post-item {
  display: flex;
  /* margin: 1.5rem 0 !important; */
}

.widget .recent_post_item li.single-recent-post-item-unique {
  border-bottom: 2px solid #CD4409;
  padding: 1rem 0;

}

.widget .recent_post_item li.single-recent-post-item .thumb {
  margin-right: 15px;
}

.widget .recent_post_item li.single-recent-post-item .thumb img {
  width: 60px;
  border-radius: 50%;
  height: 60px;
  object-fit: cover;
}

.single-recent-post-item .content p.title {
  line-height: 20px;
  margin: 0 !important;
  padding: 0;

}

.widget .recent_post_item li.single-recent-post-item .content .title a {
  font-size: 14px !important;
  line-height: 10px;
  font-weight: 500;
  padding-left: 0;
}

.widget .recent_post_item li.single-recent-post-item .content .time {
  font-size: 14px;
  font-weight: 500;
}

.widget .recent_post_item li.single-recent-post-item .content .time svg {
  color: #CD4409;
  padding-right: 5px;
}

/* ----- cpoy right footer ----- */
.copy-right-div-footer-beno {
  width: 100%;
  height: 60px;
  background: #CD4409;
}

div#footer-copy-row-beno {
  padding-top: 12px;
}

#footer-sitemap-div-beno-col {
  text-align: start;
  height: 40px;
}

p.copy-right-line-beno {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
}

#footer-sitemap-div-beno {
  text-align: end;
  height: 40px;
}

#footer-sitemap-div-beno a {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  line-height: 42px;
}

/* new section */
#join-us {
  width: 100%;
  margin-bottom: 2rem;
}

.background-large {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: all 3s !important;
}

.background-small {
  width: 50%;
  height: 40vh;
  transition: all 3s;
  object-fit: cover;
}

.home-join-us-left {
  margin: 1rem 0 0 0;
}

.home-join-us-left h3 {
  font-size: 38px;
  color: #14212b;
}

.home-join-us-right {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: -20rem;
}

.home-join-us-right-career {
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
}

.home-join-us-right h5 {
  font-size: 35px;
  font-weight: 800;
  color: #CD4409;
}

.home-join-us-right p {
  font-size: 20px;
  width: 70%;
  line-height: 30px;
  margin-top: 10px;
}

.home-join-us-right-join {
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: space-between;
}

.home-join-us-right-join a {
  color: #000;
  font-size: 16px;
}

.home-join-us-right-join svg {
  background-color: #CD4409;
  font-size: 14px;
  color: #fff;
  padding: 8px 10px;
  cursor: pointer;
}

.home-join-us-right-join svg:hover {
  color: #CD4409;
  background-color: unset !important;
}

.box {
  width: 100vw;
  height: 60vh;
  object-fit: cover;
}

.industries-we-cater-tech-icon ul li img {
  height: 60px !important;
  width: 60px !important;
}

.tech-partners-hometd ul li img {
  height: 50px !important;
  width: 50px !important;
}

.tech-partners-hometd ul {
  margin-top: 20px;
}

.technologies-icons-home-trends ul li img {
  height: 45px !important;
  width: 45px !important;
}

.industries-we-cater-tech-icon ul li {
  padding: 18px;
}

#technologies-logo-row h3 {
  color: #CD4409;
  font-size: 25px;
  font-weight: 700;
  margin-left: 30px;
}

/* .technologies-icons-home-trends ul li {
    padding: 10px 5px;

  } */

.technologies-icons-home-trends ul li {
  padding: 10px 5px;
  /* border: 1px solid #c1c1c1; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  box-shadow: 0 0 10px 2px #eeebeb;
}

.home-offices-map-located {
  display: grid;
  place-items: center;
}

.home-offices-map-located img {
  width: 90%;
  margin-top: 2.5rem;
}

#join-us-career-home {
  position: relative;
}

.home-join-us-right {
  position: relative;
  right: -75px;
  top: 0;
  float: right;
}

/* ----------------------- new version -------------------------- */

.home-about-beno-right {
  margin-left: 2rem;
}

.home-about-beno-right a {
  font-size: 16px;
  background-color: #CD4409;
  color: #fff;
  border-radius: 20px;
  padding: 8px 20px;
}

.home-about-beno-right h3 {
  font-size: 40px;
  line-height: 120%;
  margin-top: 2rem;
  font-weight: 800;
}

.home-about-beno-right p {
  color: #737373;
  font-size: 16px;
  line-height: 26px;
}

.home-about-beno-right-inner {
  border-left: 6px solid #CD4409;
  padding: 1rem 0 1rem 1rem;
  box-shadow: 0px 0px 10px 0px #ece8e8;
  margin-top: 2rem;
}

.home-about-beno-right-inner img {
  object-fit: cover;
  width: 20px;
  height: 20px;
}

.home-about-beno-left {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 436px;
}

.home-about-beno-left-inner-img img {
  position: absolute;
  right: 0;
  top: 0%;
  width: 100px;
  height: 90px;
  object-fit: contain;
}

.home-about-beno-left-inner-box {
  width: 120px;
  height: 140px;
  background-color: #CD4409;
  position: absolute;
  bottom: -13px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >h3 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 800;
  }

  >p {
    text-align: center;
    color: #fff;
    font-size: 16px;
  }
}

.home-about-beno-left-inner-box::before {

  width: 44px;
  height: 20px;
  position: absolute;
  bottom: 0;
  left: -43px;
  content: '';
  background-color: #CD4409;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

.home-about-beno-left-inner-triangle {
  position: relative;
  right: -48%;
  bottom: -41%;
}

.our-experience-main-content {
  background-color: #fef2ea;
  padding: 3rem 4rem 6rem 4rem;
}

.our-experience-circle-container {
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: auto;
}

.our-experience-inner-circle {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 1px #ece8e8;
}

.our-experience-inner-circle h4 {
  font-size: 50px;
  color: #CD4409;
  font-weight: 800;
}

.our-experience-inner-circle p {
  font-size: 16px;
  color: #14212b;
}

.our-experience-main-content h5 {
  font-size: 22px;
  color: #575757;
}

.our-experience-main-content h4 {
  font-size: 40px;
  color: #000000;
  font-weight: 800;
}

.our-experience-main-content p {
  font-size: 16px;
  color: #737373;
  font-weight: 400;
  line-height: 26px;
}

/* ----------------- new industry trends section ------------------- */
.industry-new-trends-content-main {
  height: 350px;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.industry-new-trends-content-main:hover h3 {
  display: none;
}

.industry-new-trends-content-main:hover {
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, .8);
}

.industry-new-trends-content-main h3,
.on-hover-data-indus-trend h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.industry-new-trends-content-main p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.industry-new-trends-content-main a {
  color: #111111;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border-radius: 25px;
  background-color: #fff;
  padding: 7px 14px;
  transition: all 0.3s ease;
  /* Added transition for smooth hover effect */
  width: 9rem;
}

.industry-new-trends-content-main a:hover {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}

.on-hover-data-indus-trend {
  position: absolute;
  /* Position absolutely within the container */
  top: 50%;
  /* Position from top */
  left: 50%;
  /* Position from left */
  transform: translate(-50%, -50%);
  /* Center horizontally and vertically */
  text-align: center;
  /* Center text */
  opacity: 0;
  /* Initially hidden */
  transition: all 0.3s ease;
  /* Smooth transition */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
}

.industry-new-trends-content-main:hover .on-hover-data-indus-trend {
  opacity: 1;
  /* Show on hover */
}

#our-features {
  margin-top: 4rem !important;
}

.our-features-logo {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap;
}

.our-features-logo-img {
  width: 20%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #c1c1c1;

  >img {
    width: 120px;
    object-fit: cover;
  }
}

#certificates {
  padding: unset;
}

/* .inner-Platforms {
    margin-top: 4rem;
  } */

.any-question {
  background-color: #CD4409;
  padding: 1rem 1rem;

}

.any-question-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;

  >p {
    margin-top: 0;
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
  }

  >a {
    background-color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 18px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.7s;
    color: #CD4409;
    transition: 0.5s;
  }

  >a:hover {
    color: #fff !important;
    background-color: #00973a !important;
  }
}

.platform-section-left h5 {
  color: #CD4409;
  font-size: 40px;
  font-weight: 600;
}

.platform-section-left p {
  color: #262626;
  line-height: 26px;
  font-size: 16px;
  width: 80%;
}

.platform-section-left a {
  color: #CD4409;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.platform-section-right {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.platform-tech-marquee-container {
  overflow: hidden;
  display: flex;
  gap: 1rem;
}

.platform-tech-marquee {
  display: flex;
  white-space: nowrap;
  animation: slide 30s linear infinite;
  gap: 1rem;
}

.platform-tech-marquee div.ptm-inr {
  display: flex;
  border: 0.5px solid #D9D9D9;
  border-radius: 10px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}

.platform-tech-marquee img {
  width: 35px;
  object-fit: contain;
}


.platform-tech-marquee h5 {
  font-size: 15px;
  margin-bottom: 0;
}


.platform-tech-marquee p {
  font-size: 13px;
  margin-bottom: 0;
}

.platform-tech-marquee2 {
  display: flex;
  white-space: nowrap;
  animation: slide2 30s linear infinite reverse;
  gap: 1rem;
}

.platform-tech-marquee2 div.ptm-inr {
  display: flex;
  border: 0.5px solid #D9D9D9;
  border-radius: 10px;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.platform-tech-marquee2 img {
  width: 35px;
  object-fit: contain;
}


.platform-tech-marquee2 h5 {
  font-size: 15px;
  margin-bottom: 0;
}


.platform-tech-marquee2 p {
  font-size: 13px;
  margin-bottom: 0;
}

.platform-tech-marquee3 {
  display: flex;
  white-space: nowrap;
  animation: slide 30s linear infinite;
  gap: 4rem;
  padding: 1rem 0;
}

.platform-tech-marquee3 img {
  width: 120px;
  object-fit: contain;
  aspect-ratio: 3/2;
  transition: all 0.7s;
}

.platform-tech-marquee3 img:hover {
  transform: scale(1.3);

}

.home-notification-msg {
  margin-top: 10px;
  text-align: center;
}

.home-notification-msg span {
  text-align: center;
  font-size: 16px;
  color: green;
  font-weight: bold;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }

}

@keyframes slide2 {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }

}

.certificate-slider img {
  width: 500px;
  aspect-ratio: 3/2;
  object-fit: contain;
}



#certificates .slick-prev {
  left: -5px !important;
  z-index: 100 !important;
}

#certificates .slick-next {
  right: -5px !important;
  z-index: 100 !important;
}

.excellence .slick-prev {
  right: -5px !important;
  z-index: 100 !important;
}

.excellence .slick-next {
  right: -5px !important;
  z-index: 100 !important;
}

#certificates .slick-prev:before,
#certificates .slick-next:before {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-size: 18px !important;
  color: #CD4409 !important;
  font-weight: 600;
}

#certificates .slick-prev,
#certificates .slick-next {
  background-color: #fff !important;
  color: #fff !important;
  border-radius: 50% !important;
  border: 2px solid #CD4409 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 14px !important;
  margin-top: -1rem;
}

.our-awards {
  text-align: center;

  >a {
    color: #CD4409;
    border: 2px solid #CD4409;
    padding: 6px 18px;
    border-radius: 20px;
    font-size: 16px;
  }
}

.excellence-slider {
  height: 18rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;

  >h4 {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }

  >a {
    color: #fff;
    background-color: #CD4409;
    padding: 6px 35px;
    border-radius: 20px;
    font-size: 15px;
    transition: all 0.8s;

    &:hover {
      background-color: #fff;
      color: #CD4409;
    }
  }
}

@media only screen and (max-width: 320px) {
  .home-about-beno-left {
    height: 370px !important;
    background-size: contain;
  }

  .home-about-beno-left-inner-img img {
    width: 80px !important;
    height: 75px !important;
    background-size: contain !important;
  }
}

@media (min-width: 321px) and (max-width: 376px) {
  .home-about-beno-left {
    height: 430px !important;
  }


}

@media (min-width: 377px) and (max-width: 426px) {
  .home-about-beno-left {
    height: 480px;
    background-size: contain;
  }

  .home-about-beno-left-inner-box {
    bottom: -11px !important;
  }
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

  #any-question,
  #our-features,
  #certificates {
    margin-top: 2rem !important;
  }

  .our-features-logo-img {
    width: 50% !important;
    height: 150px !important;

    >img {
      width: 80px;
      object-fit: cover;
    }
  }

  .inner-Testimonials {
    margin-top: 2rem !important;
  }

  #join-us,
  #home-about-beno,
  #our-experience,
  #platform {
    margin-top: 2rem !important;
  }

  /* ---------------- blog section all pages -------- */
  img.recent-blogs-slider-once-img {
    width: 100% !important;
    height: 23rem !important;
  }

  .certificates {
    height: 320px !important;
  }

  .blog_description p,
  .single_blog {
    height: unset !important;
  }

  .single_blog-certificates {
    height: unset !important;
  }

  .single_blog_content {
    padding: 0px 12px !important;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .recent-blogs-title-main {
    font-size: 40px !important;
  }

  .page-not-found {
    height: 300px !important;
  }

  .recent-blogs-desc-main {
    margin: 0 0 1rem 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  h2.recent-blogs-slider-title {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-top: 1rem;
    text-align: center;
  }

  p.recent-blogs-slider-desc {
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center;
  }

  .recent-blogs-slider-second .row .col-4 {
    width: 100% !important;
    text-align: center;
  }

  .recent-blogs-slider-date {
    width: 100% !important;
    text-align: center;
    margin-top: 2rem !important;
  }

  .slick-dots {
    width: 96% !important;
    bottom: -5px !important;
  }

  #hmg-banner-card-beno .theame-btn {
    margin-top: 2rem;
  }

  /* ------ end blogs section ----------------- */
  /* ---------- home page heading effects ----------- */
  .heading-ani-effect-business::after {
    width: 186px !important;
    height: 25px !important;
    margin-left: -186px !important;
    margin-top: 26px !important;
  }

  .heading-ani-effect-industry::after {
    width: 147px !important;
    height: 25px !important;
    margin-left: -145px !important;
    margin-top: 35px !important;
  }

  .heading-ani-effect-we-serve::after {
    width: 173px !important;
    height: 25px !important;
    margin-left: -173px !important;
    margin-top: 18px !important;
  }

  .heading-ani-effect-tech::after {
    width: 163px !important;
    height: 25px !important;
    margin-left: -80px !important;
    margin-top: 20px !important;
  }

  .heading-ani-effect-tech {
    content: unset !important;
  }

  .heading-ani-effect-platforms::after,
  .heading-ani-effect-testimonials::after {
    width: 170px !important;
    height: 25px !important;
    margin-left: -170px !important;
    margin-top: 17px !important;
  }

  .heading-ani-effect-form-contact::after {
    width: 154px !important;
    height: 25px !important;
    margin-left: -152px !important;
    margin-top: 19px !important;
  }

  div.inner-Platforms-cont-circle,
  span.inner-Platforms-cont-right,
  span.inner-Platforms-cont-left {
    display: none !important;
  }

  .hmg-banner h6,
  .mrq-sec .mrq-sec-inner marquee,
  .plng-sec .sub-heading h5,
  .plng-sec h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .prprt-slider-sec-slidr .slick-prev {
    left: 0px;
  }

  .prprt-slider-sec-slidr .slick-next {
    right: 0px;
  }

  .connect-sec .slick-list {
    margin: 0 0px;
  }

  /*----------------------- home banner ---------------------------------*/
  .hmg-banner {
    width: 100% !important;
    height: 100% !important;
    min-height: unset !important;
    margin-top: 0 !important;
    padding: 3rem 0 5rem 0 !important;
  }

  .hmg-banner h1 {
    font-size: 42px;
    line-height: 48px;
  }

  .hmg-banner .inrs-hmg {
    text-align: center;
    margin-top: 5% !important;
  }

  .hmg-banner h2 {
    font-size: 30px;
    line-height: 42px !important;
    font-weight: 700;
    color: #fff;
    line-height: 90px;
  }

  .hmg-banner p {
    font-size: 17px !important;
    line-height: 28px !important;
    margin: 2px 2px 2px 2px !important;
  }

  /*---------- home insutry trand section -------------------*/
  .innr-industry {
    padding: 30px 0 15px 0 !important;
  }

  .innr-industry .inner-indus h6 {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .shape-bg-12 img {
    margin-top: 100px !important;
  }

  .innr-industry .inner-indus h2 {
    font-size: 38px !important;
    text-align: center;
    margin: 0 !important;
    position: relative;
  }

  div#industry-card-beno {
    margin: 15px 0 15px 0;
  }

  /*----------------------- home third section css by me ---------------------------------*/
  #home-industries {
    font-size: 38px !important;
    position: relative;
  }

  .industries-we-serve-eff-left,
  .industries-we-serve-eff-right {
    display: none !important;
  }

  .industries_content {
    text-align: justify;
    padding: 4px !important;
    font-size: 18px !important;
    line-height: 25px !important;
    margin-bottom: 1rem !important;
  }

  div#innr-we-serve-card-beno {
    margin: 3px 0 3px 0 !important;
  }

  /*----------------------- home fourth section css by me ---------------------------------*/
  .services-section-h {
    padding: 20px 0px !important;
  }

  .innovations-home-beno h2 {
    font-size: 38px !important;
    position: relative;
  }

  .inr-started .inner-start h2 {
    font-size: 38px !important;
    position: relative;
  }

  /*----------------------- home fifth section css by me ---------------------------------*/
  /* section.inner-Platforms {
      margin-top: 2rem !important;
    } */

  .technologies-heading h2 {
    font-size: 35px !important;
    position: relative;
  }

  div#play-btn-technologies a {
    margin-left: 22% !important;
  }

  .technologies-content {
    font-size: 18px !important;
    line-height: 27px !important;
    text-align: justify !important;
    padding: 4px !important;
  }

  /* .partner-logo img {
      width: 110px !important;
      height: 80px !important;
      margin-bottom: 1px !important;
    } */

  ul#technologies-logo {
    padding: 0 !important;
    text-align: center;
    justify-content: center;
  }

  div#play-btn-technologies,
  #play-btn-text-technologies {
    margin-top: 1px;
    justify-content: center;
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
  }

  .pltfrm {
    text-align: center;
    position: relative;
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .pltfrm a {
    margin-left: 1px !important;
  }

  /*----------------------- home sixth section css by me ---------------------------------*/
  .inr-started {
    /* background-color: #000; */
    overflow: hidden !important;
  }

  .inr-started .inner-start {
    padding-top: 25px !important;
  }

  .inr-started .inner-start p {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    text-align: justify;
    padding: 4px !important;
    margin-bottom: 20px !important;
  }

  .testimnl-item {
    padding: 8px 0 0 0 !important;
    width: 100% !important;
    height: max-content !important;
  }

  .testimnl-img {
    position: unset !important;
    width: 100px !important;
    height: 100px !important;
    padding: 4px !important;
    margin: 1rem 0 0 1rem !important;
    transform: unset !important;
  }

  .testimnl-item p {
    font-size: 17px !important;
    text-align: justify;
    padding: 4px !important;
  }

  .testimnl-item h5 {
    font-size: 20px !important;
    width: 50% !important;
    float: right;
    padding: 0 15px 0 0 !important;
    margin: 2rem 0 0 0 !important;
  }

  .testimnl-item h5::after {
    content: unset !important;
  }

  .innr-card {
    margin-bottom: 10px !important;
  }

  .innr-we-serve .sec-card {
    margin-bottom: 15px !important;
  }

  .services-section-h {
    padding: 30px 0px !important;
  }

  .innovations-home-beno p {
    line-height: 28px !important;
  }

  .inner-Platforms .inner-plat p {
    font-size: 20px;
    line-height: 28px;
  }

  .test-inner .slick-slide {
    margin-top: 10px;
  }

  .test-inner .back-design {
    background-color: #ffffff;
    padding: 8px 10px;
    height: max-content !important;
    border-radius: 25px;
    box-shadow: 0px 0px 8px 0px #c3c3c3;
  }

  /* *************************** footer css **************** */
  footer.inners-footer {
    margin-top: 12% !important;
  }

  #footer-copy-row-beno,
  #footer-sitemap-div-beno-col,
  #footer-sitemap-div-beno {
    padding: 0 0 0 0 !important;
    height: 40px !important;
  }

  div#footer-sitemap-div-beno {
    background: #CD4409 !important;
    text-align: center;
  }

  #nav_menu-2,
  #nav_menu-3 {
    margin-left: 10% !important;
  }

  .widget .recent_post_item li.single-recent-post-item {
    margin: 1.5rem 0 !important;
  }

  .copy-right-div-footer-beno {
    height: 100% !important;
    text-align: center;
  }

  #footer-sitemap-div-beno a {
    font-size: 15px !important;
  }

  #footer-sitemap-div-beno,
  #footer-sitemap-div-beno-col,
  .footer-socail-icons {
    text-align: center !important;
  }

  .footer-socail-icons .social_share {
    text-align: center;
  }

  /* ---------------- new section ------------------- */

  .background-small {
    width: 100% !important;
    height: 300px !important;
  }

  .home-join-us-right {
    margin-top: 1rem !important;
  }

  .home-join-us-left {
    margin: 1rem 0 0 0;
  }

  .home-join-us-left h3 {
    font-size: 30px;
    text-align: center;
  }

  .home-join-us-right h5 {
    font-size: 30px;
  }

  .home-join-us-right p {
    width: 100%;
  }

  /* ---------------------- home v2 ---------------------------- */

  .our-experience-main-content h4 {
    font-size: 22px !important;
  }

  .our-experience-main-content h5 {
    font-size: 18px !important;
  }

  .our-experience-main-content {
    padding: 2rem 1rem !important;
  }

  .our-experience-circle-container {
    transform: unset !important;
    width: 100%;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem !important;
  }



  .home-about-beno-left-inner-img img {

    width: 100px;
    height: 90px;
    background-size: unset !important;
  }

  .home-about-beno-left-inner-triangle {
    display: none !important;
  }

  .home-offices-map-located img {
    height: 180px !important;
    width: 100% !important;
    margin-top: 1rem !important;
    object-fit: contain !important;
  }

  .home-about-beno-right {
    margin-top: 2rem !important;
  }

  .home-about-beno-right {
    margin-left: unset !important;
  }

  .home-about-beno-right h3 {
    font-size: 24px !important;
  }

  .any-question-inner {
    gap: 1rem;

    >p {
      font-size: 16px;
      text-align: center;
    }
  }

  .any-question-inner p br {
    display: block !important;
  }

  .platform-section-left h5 {
    font-size: 35px;
    text-align: center;
  }

  .platform-section-left p {
    text-align: center;
    width: 100%;
  }

  .platform-section-left a {
    width: 80%;
    margin: auto;
  }

  .contact-form-right {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 270px !important;
      margin-top: 1rem;
    }
  }

  .floating-form-msg h3 {
    font-size: 14px !important;
  }

  .floating-form-msg p {
    font-size: 14px !important;
    margin: 0 !important;
  }

  .home-floating-form form {
    gap: 5px !important;
  }

  .home-floating-form {
    gap: 1rem;
    padding: 0.5rem 1rem !important;
  }

  .industry-new-trends-content-main a {
    width: 9rem !important;
  }

}

/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 767px) {

  #platform,
  #certificates,
  #our-features {
    margin-top: 2rem !important;
  }

  .page-not-found {
    height: 300px !important;
  }

  .our-features-logo-img {
    width: 25% !important;
    height: 180px !important;

  }

  .inner-Testimonials {
    margin-top: 2rem !important;
  }

  #join-us,
  #home-about-beno,
  #our-experience {
    margin-top: 2rem !important;
  }

  /* ----------- heading effects style all -------- */
  .heading-ani-effect-business::after {
    width: 221px !important;
    margin-left: -222px !important;
    margin-top: 40px !important;
    height: 30px !important;
  }

  .heading-ani-effect-industry::after {
    width: 153px !important;
    margin-left: -150px !important;
    margin-top: 37px !important;
    height: 25px !important;
  }

  .heading-ani-effect-we-serve::after {
    width: 178px !important;
    margin-left: -176px !important;
    margin-top: 20px !important;
    height: 25px !important;
  }

  .heading-ani-effect-tech::after {
    width: 168px !important;
    margin-left: -79px !important;
    margin-top: 20px !important;
    height: 25px !important;
  }

  .heading-ani-effect-tech::after {
    content: none !important;
  }

  .heading-ani-effect-platforms::after,
  .heading-ani-effect-testimonials::after {
    width: 173px !important;
    margin-left: -172px !important;
    margin-top: 19px !important;
    height: 25px !important;
  }

  .heading-ani-effect-form-contact::after {
    width: 161px !important;
    margin-left: -158px !important;
    margin-top: 20px !important;
    height: 25px !important;
  }

  /* ----- banner -------------- */
  .inrs-hmg {
    margin: 3.5rem 0 !important;
    text-align: center !important;
  }

  .hmg-banner h2 {
    font-size: 40px !important;
    line-height: 45px !important;
  }

  .hmg-banner p {
    font-size: 19px !important;
    margin: unset !important;
    padding: 2rem 0 !important;
  }

  .hmg-banner {
    padding-bottom: unset !important;
    padding-top: unset !important;
    min-height: unset !important;
    margin: 0rem 0 0 0 !important;
    height: 100% !important;
  }

  /* ----------- industry trends section -------- */
  .innr-industry {
    padding: 3rem 0 1rem 0 !important;
  }

  /* -------------- industry serve-------- */
  .industries-we-serve-eff-left,
  .industries-we-serve-eff-right {
    display: none !important;
  }

  .industries_content {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-bottom: 1rem !important;
  }

  .innovations-home-beno h2,
  #home-industries,
  .innr-industry .inner-indus h2 {
    font-size: 40px !important;
  }

  /* ----------- technologies  --------- */
  /* .inner-Platforms {
      margin-top: 2rem !important;
    } */

  .technologies-heading h2 {
    font-size: 40px !important;
  }

  .inner-Platforms .inner-plat p {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  #technologies-logo {
    padding: 0 !important;
    margin: 0 !important;
  }

  #technologies-logo li {
    text-align: center;
  }

  div#play-btn-technologies {
    width: 50%;
    text-align: end;
  }

  div#play-btn-text-technologies {
    width: 50% !important;
    text-align: start;
    margin-top: 3% !important;
  }

  .pltfrm a {
    margin-left: 0 !important;
  }

  .pltfrm {
    margin-left: unset !important;
  }

  /* .partner-logo img {
      margin-bottom: 10px !important;
    } */

  /* --------- Testimonials ---------- */
  .technologies-content {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .testimnl-item p {
    font-size: 16px !important;
    margin: 5px !important;
  }

  .testimnl-item {
    padding: 15px 150px 20px 35px !important;
    text-align: left !important;
    width: 100% !important;
  }

  .testimnl-img {
    right: -13px !important;
    width: 10.2rem !important;
    height: 100% !important;
    padding: 15px !important;
  }

  .testimnl-item h5:after {
    content: unset !important;
  }

  .testimnl-item h5 {
    font-size: 20px !important;
    text-align: right !important;
    padding-right: unset !important;
  }

  /* ----- contact section home -------- */
  .inr-started .inner-start {
    padding-top: 10px !important;
  }

  .inr-started .inner-start h2 {
    margin-bottom: 10px !important;
    font-size: 40px !important;
  }

  .inr-started .inner-start p {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-bottom: 40px !important;
  }

  /* ---------------- blog section all pages -------- */
  .recent-blogs-slider-once {
    width: 97% !important;
  }

  img.recent-blogs-slider-once-img {
    height: 29rem !important;
  }

  .blog_description p,
  .single_blog {
    height: unset !important;
  }

  .single_blog-certificates {
    height: unset !important;
  }

  .recent-blogs-slider-second {
    margin: 2rem 0px;
    width: 100% !important;
  }

  .recent-blogs-desc-main {
    text-align: center !important;
    line-height: 26px !important;
  }

  h2.recent-blogs-slider-title {
    font-size: 24px !important;
    line-height: 34px !important;
  }

  p.recent-blogs-slider-desc {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .recent-blogs-slider-second .row .col-4 {
    width: 37% !important;
  }

  .recent-blogs-slider-date {
    width: 63% !important;
  }

  /* ------ end blogs section ----------------- */

  div.inner-Platforms-cont-circle,
  span.inner-Platforms-cont-right,
  span.inner-Platforms-cont-left {
    display: none !important;
  }

  div#industry-card-beno {
    margin: 15px 0 15px 0 !important;
  }

  div#innr-we-serve-card-beno {
    margin: 10px 0 10px 0 !important;
  }

  .services-section-h {
    padding: 30px 0px !important;
  }

  .hmg-banner h1 {
    font-size: 48px;
    line-height: 48px;
  }

  .ceo-sec,
  .advantage-sec,
  .testimn-sec {
    padding-top: 50px;
  }

  .sub-heading h2,
  .plng-sec .sub-heading h2 {
    font-size: 28px;
  }

  .ceo-sec-tp-lst {
    column-count: 1;
  }

  .ceo-sec-tp-lst li {
    margin-bottom: 10px;
  }

  .ceo-sec-tp-lst-scnd li {
    width: 50%;
    margin-top: 20px;
  }

  .ceo-sec-tp-lst-scnd li h3 {
    font-size: 48px;
    line-height: 52px;
  }

  .modrn-structure {
    margin-top: 50px;
  }

  .layout-sections {
    padding-bottom: 50px;
  }

  .modrn-structure,
  .exceptional-comfort-sec,
  .enquirifrm-sec {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .modrn-structure .contnt h2 {
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
  }

  .advantage-sec-innr {
    margin-top: 20px;
  }

  .advantage-sec-innr h4 {
    font-size: 18px;
  }

  .advantage-sec-innr p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .resort-sec,
  .trnsfr-sec,
  .plng-sec {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .connect-sec,
  .aryavartha-cms-content-sec,
  .amenities-tabs-sec {
    padding-top: 50px;
  }

  .plng-sec {
    margin-top: 50px;
  }

  .building-sec h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .testimn-sec .sub-heading {
    margin-bottom: 0px;
  }

  .testimn-sec-innr {
    margin-top: 20px;
  }

  .testimn-sec ul li:nth-child(2) .testimn-sec-innr {
    margin-top: 20px;
  }

  .layout-sections {
    padding-top: 50px;
  }

  .inner-Testimonials {
    margin-top: 0 !important;
  }

  .back-design {
    margin: 10px -8px 10px -8px;
  }

  .inr-started {
    overflow: hidden;
  }

  /* **************************** footer css ****************** */
  div#footer-fourth-col,
  div#footer-sitemap-div-beno,
  div#footer-sitemap-div-beno-col {
    width: 100%;
  }

  div#footer-first-col {
    width: 90% !important;
  }


  #footer-sitemap-div-beno-col,
  #footer-sitemap-div-beno {
    text-align: center;
  }

  .widget .recent_post_item li.single-recent-post-item {
    margin: 1.5rem 0 !important;
  }

  .footer-socail-icons {
    width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: center;
  }

  .copy-right-div-footer-beno {
    width: 100%;
    height: 100% !important;
  }

  /* ---------------- new section ------------------- */

  .background-small {
    width: 100% !important;
    height: 300px !important;
  }

  .home-join-us-right {
    margin-top: 1rem !important;
    margin-left: 3rem !important;
  }

  .home-join-us-left {
    margin: 1rem 0 0 0;
  }

  .home-join-us-left h3 {
    font-size: 30px;
    text-align: center;
  }

  .home-join-us-right h5 {
    font-size: 30px;
  }

  .home-join-us-right p {
    width: 100%;
  }

  #join-us {
    min-height: 120vh !important;
  }

  /* ---------------------- home v2 ---------------------------- */

  .our-experience-main-content h4 {
    font-size: 22px !important;
  }

  .our-experience-main-content h5 {
    font-size: 18px !important;
  }

  .our-experience-main-content {
    padding: 4rem 1rem 8rem 1rem !important;
  }

  .our-experience-circle-container {
    transform: translateY(-33%);
    width: 100%;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem !important;
  }

  .home-about-beno-right {
    margin-left: unset !important;
  }

  .home-about-beno-right h3 {
    font-size: 24px !important;
  }

  .any-question-inner {
    gap: 1rem;
  }

  .any-question-inner p br {
    display: block !important;
  }

  .contact-form-right {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 350px !important;
    }
  }

  .industry-new-trends-content-main a {
    width: 10rem !important;
  }
}



/* Tablets iPads (Portrait) */
@media (min-width: 768px) and (max-width: 991px) {

  #platform,
  #certificates,
  #our-features {
    margin-top: 3rem !important;
  }

  .page-not-found {
    height: 70vh !important;
  }

  .our-features-logo-img {
    width: 33.3% !important;
    height: 180px !important;

  }

  .inner-Testimonials {
    margin-top: 3rem !important;
  }

  #join-us,
  #home-about-beno,
  #our-experience {
    margin-top: 3rem !important;
  }

  h2.recent-blogs-slider-title {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  /* ---------- banner -------- */
  .hmg-banner {
    min-height: unset !important;
    padding-top: 4rem !important;
    height: 100% !important;
    margin: 0rem 0 0 0 !important;
    padding-bottom: 4rem !important;
  }

  .inrs-hmg {
    text-align: center;
    margin-top: 1% !important;
  }

  .theame-btn {
    /* margin-top: 2rem !important; */
  }

  /* ------------ testimonials-------- */
  .testimnl-item {
    padding: 15px 154px 15px 40px !important;
    text-align: left !important;
    width: 100% !important;
  }

  .testimnl-img {
    right: -7px !important;
    width: 159px !important;
    height: 100% !important;
    padding: 10px !important;
  }

  .testimnl-item h5 {
    font-size: 20px !important;
    text-align: end;
    padding-right: unset !important;
  }

  .testimnl-item h5::after {
    content: unset !important;
  }

  /* ---------------- blog section all pages -------- */
  .recent-blogs-slider-once {
    width: 40% !important;
    padding: 0px !important;
  }

  img.recent-blogs-slider-once-img {
    width: 100% !important;
    height: 27rem !important;
  }

  .recent-blogs-slider-second {
    /* margin: 2rem 0px; */
    width: 60% !important;
  }

  .recent-blogs-slider-second .row .col-4 {
    width: 42% !important;
  }

  .recent-blogs-slider-date {
    width: 58% !important;
  }

  p.recent-blogs-slider-desc {
    margin-bottom: 1rem !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .blog_page_title h3 {
    height: 55px !important;
  }

  .blog_description p {
    height: 95px !important;
  }

  /* ------ end blogs section ----------------- */

  div.inner-Platforms-cont-circle,
  span.inner-Platforms-cont-right,
  span.inner-Platforms-cont-left {
    display: none !important;
  }

  div#hmg-banner-card-beno {
    width: 100% !important;
  }

  div#industry-card-beno {
    margin-bottom: 3% !important;
  }

  .hmg-banner p {
    margin: 0px 0px 0px 0px !important;
  }

  div#innr-we-serve-card-beno {
    margin-top: 1rem;
  }

  .inr-started {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .hmg-banner h6,
  .hmg-banner p,
  .mrq-sec .mrq-sec-inner marquee,
  .plng-sec .sub-heading h5,
  .plng-sec h4 {
    font-size: 22px !important;
  }

  .innr-industry {
    padding: 3rem 0px 0 0 !important;
  }

  .industries_content {
    font-size: 18px !important;
    margin-bottom: 1rem !important;
  }

  .services-section-h {
    padding: 20px 0px !important;
  }

  .hmg-banner h1 {
    font-size: 66px;
    line-height: 92px;
  }

  .inner-Platforms .inner-plat p {
    font-size: 18px !important;
  }

  /* .partner-logo img {
      width: 100% !important;
      height: 100% !important;
    } */

  #play-btn-text-technologies {
    padding-left: 2.5rem !important;
  }

  .inr-started .inner-start p {
    font-size: 18px !important;
  }

  .industries-we-serve-eff-left,
  .industries-we-serve-eff-right {
    display: none !important;
  }

  .technologies-content {
    font-size: 18px !important;
  }

  .inr-started .inner-start {
    padding-top: 20px !important;
  }

  .sub-heading h2,
  .plng-sec .sub-heading h2 {
    font-size: 32px;
  }

  .sub-heading h2 br,
  .plng-sec .sub-heading h2 br {
    display: none;
  }

  .ceo-sec-tp-lst {
    column-count: 2;
  }

  .ceo-sec-tp-lst-scnd li h3 {
    font-size: 52px;
    line-height: 62px;
    margin-bottom: 10px;
  }

  .ceo-sec-tp-lst-scnd li p {
    font-size: 16px;
    margin-bottom: 0;
    color: #ed3237;
  }

  .modrn-structure {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .building-sec img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: top;
  }

  .prprt-slider-sec-slidr-innr img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .prprt-slider-sec-slidr .slick-prev:after,
  .prprt-slider-sec-slidr .slick-next:after {
    font-size: 46px;
  }

  .th-ftr-inr .social-lnks {
    justify-content: flex-start;
    margin-top: 25px;
  }

  .th-ftr {
    padding-top: 10px;
  }

  .banner-inner-page {
    height: auto;
    min-height: 350px;
  }

  .layout-sections .tab-pane {
    margin-top: 20px;
  }

  .layout-sections {
    padding-top: 65px;
  }

  .layout-sections:after {
    width: 100%;
    height: calc(100% - 0px);
  }

  .layout-sections .brochure-text-section h5 {
    margin-bottom: 15px;
    margin-top: 35px;
  }

  .btn-red {
    padding: 15px 10px;
    min-width: 165px;
  }

  /* ************************ footer css ******************** */

  div#footer-first-col,
  #footer-fourth-col {
    width: 100% !important;
  }

  .footer-socail-icons {
    width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    text-align: center;
  }

  .copy-right-div-footer-beno {
    width: 100%;
    height: 100% !important;
  }

  .widget .recent_post_item li.single-recent-post-item {
    margin: 1.5rem 0 !important;
  }

  div#footer-sitemap-div-beno,
  div#footer-sitemap-div-beno-col {
    width: 50% !important;
  }

  /* ---------------- new section ------------------- */

  .background-small {
    width: 60% !important;
    height: 300px !important;
  }

  .home-join-us-right {
    margin-top: -15rem !important;
    margin-left: 3rem !important;
  }

  .home-join-us-left {
    margin: 1rem 0 0 0;
  }

  .home-join-us-left h3 {
    font-size: 30px;
    text-align: center;
  }

  .home-join-us-right h5 {
    font-size: 30px;
  }

  .home-join-us-right p {
    width: 100%;
  }

  #join-us {
    min-height: 80vh !important;
  }

  /* ---------------------- home v2 ---------------------------- */

  .our-experience-main-content h4 {
    font-size: 22px !important;
  }

  .our-experience-main-content h5 {
    font-size: 18px !important;
  }

  .our-experience-main-content {
    padding: 4rem 2rem 8rem 2rem !important;
  }

  .our-experience-circle-container {
    transform: translateY(-33%);
    width: 100%;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem !important;
  }

  /*---------            home v2 ---------------------*/
  .home-about-beno-left {
    height: 480px;
    background-size: contain;
  }

  .home-about-beno-left-inner-img img {
    right: 0rem;
    top: 6rem;
    width: 60px;
    height: 60px;
  }

  .home-about-beno-left-inner-box {
    bottom: 87px;
  }

  .home-about-beno-right-inner {
    width: 28rem;
  }

  .home-about-beno-right p {
    line-height: 22px;
  }

  .home-about-beno-right-inner p {
    font-size: 14px;
  }

  .home-about-beno-right h3 {
    font-size: 35px;
  }

  .home-about-beno-right-inner {
    margin-top: 1rem;
  }

  .home-about-beno-right a {
    font-size: 12px;
    padding: 6px 12px;
  }

  .any-question-inner {
    gap: 4rem;
  }

  .any-question-inner p br {
    display: block !important;
  }

  .contact-form-right {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 320px !important;
    }
  }

  .contact-form-left {
    padding: 1rem 1rem 1rem 1rem;
  }

  .inr-started .inner-start h2 {
    font-size: 24px !important;
  }

  .inr-started .inner-start p {
    font-size: 16px !important;
  }

  .inr-started textarea {
    height: 100px;
  }

  .inr-started {
    margin-top: 2rem;
  }

  .industry-new-trends-content-main a {
    width: 9rem !important;
  }
}

/* Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1200px) {

  /* -================= New Header =============- */
  .mega-section-services,
  .mega-section-industries {
    right: 3% !important;
  }

  /* ----- banner ---- */
  .hmg-banner {
    padding-bottom: unset !important;
  }

  .hmg-banner h2 {
    font-size: 55px !important;
  }

  .hmg-banner p {
    font-size: 20px !important;
    margin: 20px 95px 20px 0px !important;
  }

  /* -------- section industry trands------ */
  .innr-industry .innr-card h5 {
    font-size: 20px !important;
  }

  /* ------ technologies ----------- */
  /* .partner-logo img {
      width: 100% !important;
      height: 100% !important;
    } */

  div.inner-Platforms-cont-circle img {
    width: 130px !important;
  }

  /* ---------------- blog section all pages -------- */
  .recent-blogs-slider-once {
    width: 40% !important;
    padding: 0px !important;
  }

  .blog_description p {
    height: 100px !important;
  }

  .blog_page_title h3 {
    height: 57px !important;
  }

  img.recent-blogs-slider-once-img {
    width: 100% !important;
    height: 27rem !important;
  }

  .recent-blogs-slider-second {
    width: 60% !important;
  }

  /* shadow effect  */
  span.inner-Platforms-cont-left img,
  span.inner-Platforms-cont-right img {
    width: 100px !important;
  }

  .heading-ani-effect-business::after {
    width: 258px !important;
    margin-left: -257px !important;
    margin-top: 35px !important;
  }

  .industries-we-serve-eff-left,
  .industries-we-serve-eff-right {
    height: 165px !important;
    width: 100px !important;
  }

  /* ***************************** footer css **************** */
  .footer-socail-icons .social_share li {
    margin: 5px 5px 5px 5px !important;
  }

  .home-about-beno-left {
    height: 376px;
  }

  .home-about-beno-left-inner-img img {
    right: -10px;
    top: -10px;
  }

  .contact-form-right {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 430px !important;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1441px) {

  /* -================= New Header =============- */
  .mega-section-services,
  .mega-section-industries {
    right: 10% !important;
  }

  .background-small {
    width: 50% !important;
    height: 400px !important;
  }
}

@media (min-width: 420px) and (max-width: 480px) {
  .slick-dots {
    bottom: -2px !important;
  }
}

.floating-contact-btn {
  position: fixed;
  top: 50%;
  right: -300px;
  transform: translateY(-50%);
  width: 300px;
  height: auto;
  background: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, .5);
  box-sizing: border-box;
  transition: .3s;
}

.floating-contact-btn-comes {

  right: 0px;
  z-index: 999999999999 !important;

}

.floating-contact-btn a {
  position: absolute;
  height: 48px;
  width: 182px;
  box-shadow: -2px -1px 8px rgb(58 56 52 / 28%);
  background-image: linear-gradient(to left, rgb(51, 129, 255), rgb(151, 227, 255));
  top: 40%;
  font-weight: 600;
  left: -113px;
  border-radius: 3px 3px 0 0;
  transform: rotate(270deg);
  cursor: pointer;
  color: #fff;
  line-height: 45px;
  font-size: 18px;
  text-align: center;
}

.home-floating-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-top: 4px solid #CD4409;
}

.floating-form-msg {
  width: 100%;
  /* background-color: #CD4409; */
}

.floating-form-msg h3 {
  font-size: 17px;
  font-weight: 600;
}

.floating-form-msg p {
  font-size: 15px;
}

.home-floating-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-floating-form form input {
  padding: 6px 40px 8px 6px;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #c1c1c1;
}

.home-floating-form form input:focus {
  border: 1px solid #CD4409;
}

.floating-form-msg-textarea input {
  padding: 6px 6px 40px 6px !important;
}

.home-floating-form form input::placeholder {
  color: #000000;
  font-size: 14px;
}

.home-floating-form form button {
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  background-color: #CD4409;
}

/* page-not-found */

.page-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    color: #111111;
    font-size: 8vw;
    font-weight: 800;
    letter-spacing: 10px;

    span {
      color: #CD4409;
    }
  }

  p {
    color: #111111;
    font-size: 25px;
    font-weight: 600;
  }

  a {
    color: #ffffff;
    background: #CD4409;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 5px;
  }
}



/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */
/* about beno  */



#about-about,
#about-our-journey,
.about-counter {
  padding-top: 4rem !important;
}

/* ------------- banner css ------------- */
.about-banner-main {
  width: 100%;
  height: 500px;
  max-height: 500px;
  /* margin-top: 10.5rem; */
  background-size: cover;
  background-size: cover;
  /* padding: 4rem 0; */
  background-position: top;
}

.about-banner-heading {
  font-size: 62px;
  text-transform: none;
  font-weight: 800;
  color: #fff;
  line-height: 1.1;
}

.about-banner-para {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
  margin-top: 1.5rem;
}

.about-banner-para span {
  font-weight: 700;
}

.about-head-main-title span::after {
  content: unset;
}

.about-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.about-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.about-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

.about-head-main-para h5 {
  font-size: 18px;
  font-weight: 800;
}

#about-about-dev-img {
  margin-top: 1rem;
}

.about-about-introimg img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.about-about-triangle-down::after {
  width: 12% !important;
}

.about-about-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.about-sec-meter {
  margin-top: 2rem;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.about-sec-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #c1c1c1;
}

.about-sec-line {
  width: 100%;
  height: 3px;
  background-color: #c1c1c1;
  position: relative;

}

.about-sec-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about-sec-btn button {
  background: none;
  border: none;
  color: #d1d1d1;
}

.about-sec-inner-box {
  width: 150px;
  height: 20px;
  background-color: #CD4409;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
}

.about-leading {
  background-image: linear-gradient(30deg, #CD4409, #e20d0d);
  padding: 4rem 5rem 0 0;
  text-align: right;

}

.about-leading-title {
  text-align: right;
  color: #fff !important;
  font-size: 55px !important;
}

.about-leading-meet-btn {
  background: transparent;
  letter-spacing: 3px;
  font-weight: 600;
  padding: 15px 50px 15px 25px;
  border: 2px solid #fff;
  border-right: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.about-leading-meet-btn:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 30%;
  background: #fff;
}

.about-leading-meet-btn:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 30%;
  background: #fff;
}

.about-leading-meet-btn span {
  display: block;
  width: 60px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 86%;
  margin-top: -7px;
  background: url(https://cache.techmahindra.com/static/img/link-arw.png) no-repeat right;
  -webkit-transition: all .4s ease-in;
  -moz-transition: all .4s ease-in;
  -o-transition: all .4s ease-in;
  transition: all .4s ease-in;
}

.about-leading-meet-btn:hover span {
  width: 80px !important;
}

.about-socail {
  display: flex;
  gap: 10px;
}

.about-socail div {
  width: 40px;
  height: 40px;
  background-color: #CD4409;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.7s;

  &:hover {
    background-color: #262626;
  }
}

.about-socail a {
  font-size: 20px;
  color: #fff;
  height: 27px;
}

.about-leading-maim-img img {
  object-fit: contain !important;
}

.about-leading-founder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-leading-founder h3 {
  font-weight: 700;
}

/* --------------- journey slider ---------------- */

.years {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.years span {
  color: #d1d1d1;
  cursor: pointer;
}

.active_slider {
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  transition: all .9s;

}

.active_slider::before {
  content: "";
  width: 80px;
  height: 20px;
  border-radius: 20px;
  background-color: #CD4409;
  position: relative;
  top: -100%;
  left: 50%;
}

.circle-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #d1d1d1;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #d1d1d1;
}

.about-counter-content h3 {
  font-weight: 700;
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  background-image: linear-gradient(to right bottom, #e62222, #ef6d35, #ef6d35, #e62222, #ef6d35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.about-counter-content p {
  color: #262626;
  text-align: center;
  font-size: 16px;
  padding: 0 12px;
  font-weight: 700;
}

/* about-single-slider */

.about-single-slider {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem 0;
}

#about-our-journey .slick-slide {
  padding: unset !important;
  margin: unset !important;
}


.about-single-slider-content-1-parent {
  background: linear-gradient(to right, rgba(255, 165, 0, 0) 30%, #F1742D 30%);
  /* width: 700px; */
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 0% 50%);
  padding: 1.5rem 0;
  height: 350px;
}

.about-single-slider-content-1 {
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 0 10px 0 #F1742D33;
  width: 88%;
  height: 100%;

  >h2 {
    color: #14212B;
    font-size: 35px;
    font-weight: 700;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 150%;
      height: 3px;
      background-color: #CD4409;
    }
  }

  >h4 {
    color: #14212B;
    font-size: 25px;
    font-weight: 600;
    margin-top: 1rem;

  }

  >p {
    color: #14212B;
    font-size: 16px;
    margin-top: 1rem;
  }

}

.about-single-slider-content-2-parent {
  background: linear-gradient(to right, rgba(255, 165, 0, 0) 30%, #F1742D 30%);
  /* width: 200px; */
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 60% 0, 100% 50%, 60% 100%, 0 100%, 0% 50%);
  padding: 1.5rem 0;
  height: 350px;
}

.about-single-slider-content-2 {
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 0 10px 0 #F1742D33;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  >h3 {
    transform: rotate(270deg);
    letter-spacing: 5px;
    font-size: 30px;
    font-weight: 700;
  }
}


#about-our-journey .slick-prev {
  left: 40px !important;
  z-index: 100 !important;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
}

#about-our-journey .slick-next {
  right: 60px !important;
  z-index: 100 !important;
  position: absolute;
  top: 50%;
  transform: translate(-50%);
}


#about-our-journey .slick-prev:before,
#about-our-journey .slick-next:before {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
  font-size: 18px !important;
  color: #CD4409 !important;
  font-weight: 600;
}

#about-our-journey .slick-prev,
#about-our-journey .slick-next {
  background-color: #fff !important;
  color: #fff !important;
  border-radius: 50% !important;
  border: 2px solid #CD4409 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 14px !important;
  margin-top: -1rem;
}

@media only screen and (max-width: 480px) {

  #about-about,
  .about-counter,

  #about-our-journey {
    padding-top: 2rem !important;
  }

  /* ----------- banners css ------------ */
  .about-banner-main {
    margin-top: unset !important;
    height: 250px !important;
    padding: 3rem 0 !important;
  }

  /* ------------------- about beno about sec -------------------- */

  .about-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  .about-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  /* ------------------- about beno about sec -------------------- */

  .about-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center !important;
  }

  .about-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .about-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #about-about-dev-img {
    margin-top: 2rem !important;
  }

  .about-leading {
    padding: 2rem 5rem 2rem 0 !important;
  }

  .active_slider::before {
    width: 60px;
  }

  .about-single-slider-content-2-parent {
    /* display: none !important; */
    height: 200px;
    width: unset !important;
  }

  .about-single-slider {
    padding: 1rem 0 !important;
  }

  .about-single-slider-content-1-parent {
    height: unset !important;
  }

  .about-single-slider-content-1 h4 {
    font-size: 17px !important;
  }

  .about-single-slider-content-1 h2 {
    font-size: 25px !important;
  }

  .about-single-slider-content-1 p {
    font-size: 15px !important;
  }

  .about-head-main-des {
    text-align: center !important;
  }

  #about-our-journey .slick-prev {
    left: 15px !important;
  }

  #about-our-journey .slick-next {
    right: 0px !important;
    z-index: 0 !important;
  }

  .small-slider-about {
    margin-top: 1rem;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  #about-about,
  .about-counter,

  #about-our-journey {
    padding-top: 2rem !important;
  }

  /* ----------- banners css ------------ */
  .about-banner-main {
    margin-top: 0px !important;
    height: 300px !important;
  }

  .about-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  .about-banner-para {
    text-align: center;
  }

  /* -------------------- about beno about sec ------------------------ */

  .about-head-main-title {
    font-size: 30px !important;
    text-align: center !important;
  }

  .about-head-main-title span {
    font-size: 30px !important;
    text-align: start !important;
  }

  .about-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: start !important;
  }

  #about-about-dev-img {
    margin-top: 2rem !important;
  }

  .about-single-slider-content-2-parent {
    /* display: none !important; */
    height: 200px;
    width: unset !important;
  }

  .about-single-slider {
    padding: 1rem 0 !important;
  }

  .about-single-slider-content-1-parent {
    height: unset !important;
  }


  .about-single-slider-content-1 h4 {
    font-size: 18px !important;
  }

  .about-single-slider-content-1 h2 {
    font-size: 25px !important;
  }

  .about-single-slider-content-1 p {
    font-size: 16px !important;

  }

  .about-head-main-des {
    text-align: center !important;
  }

  #about-our-journey .slick-prev {
    left: 15px !important;
  }

  #about-our-journey .slick-next {
    right: 30px !important;
  }

  .small-slider-about {
    margin-top: 1rem;
  }

}

@media (min-width: 768px) and (max-width: 991px) {

  #about-about,
  .about-counter,

  #about-our-journey {
    padding-top: 3rem !important;
  }

  /* ----------- banners css ------------ */

  .about-banner-main {
    margin-top: 0px !important;
    height: 300px !important;
  }

  .about-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  .about-banner-para {
    text-align: center;
  }

  /* -------------------------- about beno about sec ------------------------ */

  .about-head-main-title,
  .about-head-main-title span {
    font-size: 35px !important;
  }

  .about-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  .about-leading-right {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
  }

  .about-single-slider-content-1-parent {
    height: 500px !important;
  }

  .about-single-slider-content-2-parent {
    height: 500px !important;
  }

  .about-single-slider-content-1>h2 {
    color: #14212B;
    font-size: 25px;
    font-weight: 700;
    position: relative;
    display: inline-block;
  }

  .about-single-slider-content-1>h4 {
    color: #14212B;
    font-size: 20px;
    font-weight: 600;
    margin-top: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* ----------- banners css ------------ */

  .about-banner-heading {
    font-size: 50px;
  }

  /* -------------------------- about beno about sec ------------------------ */


  .about-head-main-title {
    font-size: 40px !important;
  }

  .about-single-slider-content-1-parent {
    height: 420px !important;
  }

  .about-single-slider-content-2-parent {
    height: 420px !important;
  }
}