/* --================= IT Support Main page css  ===================- */
/* all section tabs padding */
#itsupport-expertise,
#itsupport-services,
#itsupport-approach,
#itsupport-solution,
#itsupport-benefits,
#itsopport-intro,
#itsopport-network-counter,
#itsopport-network-business-areas,
#itsupport-what-think-first,
#itsupport-network-services,
#itsupport-tailored-solution {
  padding-top: 4rem;
}

#indus-managed-it-effect::after {
  margin-left: -360px !important;
  width: 208px !important;
}

/* ----------- Main page banners css ------------ */
.itsupport-banner-main {
  background-blend-mode: overlay;
  width: 100%;
  height: 450px;
  max-height: 450px;
  /* margin-top: 10.5rem; */
  background-color: #727272;
  background-size: cover;
  /* padding: 5rem 0; */
  transition: all 3s;
  display: flex;
  align-items: center;
}

.itsupport-banner-main:hover {
  transform: scale(1.03);
}

.itsupport-banner-heading {
  font-size: 58px;
  text-transform: none;
  font-weight: 800;
  color: #fff;
  line-height: 1.1;
}

.itsupport-banner-para {
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

#play-button-itsupport {
  display: flex;
  align-items: center;
}

div#play-button-itsupport-banner {
  position: relative;
}

.managed-it-bnr-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 63%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.managed-it-bnr-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.managed-it-bnr-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.managed-it-bnr-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.itsupport-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff;
}

.itsupport-banner-know-how a svg {
  padding: 4px 5px 0 5px;
}

.itsupport-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}

/*--------------- itsupport banner button css-------------- */

/*----------------  page indexes section -------------------*/
section.nag-page-index-itsupport {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 99;
}

section.nag-page-index-itsupport.sticky-itsupport {
  position: fixed;
  top: 90px !important;
}

.nav-link-index-itsupport.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-itsupport ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-index-itsupport ul li {
  display: inline-flex;
}

.navbar-index-itsupport ul li:hover,
.navbar-index-itsupport ul li a:hover {
  background-color: #ffffff;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-index-itsupport ul li a {
  color: #fff;
  font-size: 18px;
  padding: 7px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.it-support-inner-banner-bg-btn {
  margin-top: 3.5rem;
}

.it-support-inner-banner-bg-btn {
  margin-top: 3.5rem;
}

.it-support-inner-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.it-support-inner-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.it-support-inner-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.it-support-inner-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* --------- it support intro section  ---------------------- */
.itsopport-intro-para-know-how {
  margin-top: 2rem;
}

.itsopport-intro-para-know-how a {
  background: #CD4409;
  padding: 10px 25px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.itsopport-intro-para-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

#itsopport-intro-img-col {
  margin-top: 1rem;
}

.itsopport-intro-img-col-under {
  width: 100%;
  height: 299px;
  object-fit: cover;
}

.itsopport-intro-img-triangle-down::after {
  width: 12% !important;
}

.itsopport-intro-img-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.itsopport-intro-img-txt-down {
  margin-top: -2.5rem !important;
}

.itsopport-intro-img-front-txt {
  width: 95.5%;
  height: max-content;
  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-left: 26px;
  z-index: 1;
  position: relative;
}

.itsopport-intro-img-front-txt h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.itsopport-intro-img-front-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 7.9rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.itsopport-intro-img-front-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.itsopport-intro-img-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* ------------ it industries section ------------ */
#itsupport-services {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
  background-position: center;
}

.card.itsupport-industries-crd {
  margin-top: 1rem;
  border: 2px solid #eedfd7;
  text-align: center;
}

.card.itsupport-industries-crd a {
  text-decoration: none !important;
}

.itsupport-industries-crd-imgs {
  overflow: hidden;
  margin: 5px;
}

.itsupport-industries-crd-imgs:hover img {
  transform: scale(1.1);
}

.card.itsupport-industries-crd:hover {
  border: 2px solid #CD4409;
}

.card.itsupport-industries-crd .card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.itsupport-industries-crd img {
  width: 100%;
  height: 250px;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  object-fit: cover;
  border-radius: 5px;
}

.itsupport-industries-crd h3 {
  color: #14212b;
  margin-top: 1rem;
  font-size: 20px;
  padding: 0 8px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    color: #CD4409;
  }
}

.itsupport-industries-crd p {
  color: #696969;
  text-align: center;
  padding: 0 8px !important;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.itsupport-industries-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.itsupport-industries-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

/* ------------- Our Approach Towards Solution -------- */
#itsupport-approach {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

.approach-bg-head span {
  display: block;
}

.itsupport-approach-cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itsupport-approach-enforcement-cards {
  border: 2px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem 5px;
  margin: 10px;
  width: 23.2%;
}

.itsupport-approach-enforcement-cards:hover {
  border: 2px solid #CD4409;
}

.itsupport-approach-enforcement-cards img {
  width: 80px;
  height: 85px;
  margin-top: 3rem;
  object-fit: contain;
}

.itsupport-approach-enforcement-cards h2 {
  margin-top: 2.5rem;
  color: #14212b;
  font-size: 22px;
  font-weight: 600;
}

/* ----------- Managed IT Support Services ------------ */

.itsupport-accordion-body-cont {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

button.accordion-button {
  box-shadow: none !important;
}

/* ----------- scalable growth and transformation. ------------ */

.itsupport-scalable-growth-content ul {
  margin-top: 2rem;
  margin-left: 2rem;
}

.itsupport-scalable-growth-content ul li {
  color: #262626;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}

span.itsupport-shadow-img-segments-right {
  float: right;
  margin-top: 50px;
}

span.itsupport-shadow-img-segments-right img {
  margin: -95px 0 0 0;
  width: 99px;
  height: 100% !important;
}

span.itsupport-shadow-img-segments-left img {
  margin: 0px 0 95px 0;
  width: 99px;
  height: 100% !important;
}

span.itsupport-shadow-img-segments-left {
  float: left;
  margin-top: 400px;
}

/* -================== all pages comman css code ==============- */
/* ----------- all it support sub pages banners css ------------ */
.itsupport-sub-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #e7f0eb9c;
  background-position: center center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10.5rem;
}

.itsupport-sub-banner-infra {
  background-blend-mode: unset !important;
  background-color: unset !important;
}

.itsupport-sub-banner-Heading {
  color: #14212b;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
}

.itsupport-sub-banner-para {
  color: #000;
  font-size: 17px;
  line-height: 32px;
}

.itsupport-sub-banner_btn,
.itsupport-sub-banner-num-btn {
  margin: 3% 0 0 0%;
}

.itsupport-sub-banner_btn a {
  background: #CD4409;
  padding: 12px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 33px;
  color: #fff;
}

.itsupport-sub-banner_btn a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}



.itsupport-sub-banner-num-btn a {
  background: #ffffff;
  padding: 12px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 33px;
  color: #CD4409;
  border: 2px solid #CD4409;
}

/* ------------- play button all pages------------- */
.itsupport-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 15rem;
}


.itsupport-sub-video-play-button-under {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 9%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.itsupport-sub-video-play-button-under:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.itsupport-sub-video-play-button-under:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.itsupport-sub-video-play-button-under span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

p.itsupport-sub-under-learn {
  color: #14212b;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

/* -------- all it support sub pages main heading and sub heading css --------- */
.itsopport-intro-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;

}

.itsopport-intro-section-heading-infra {
  font-size: 40px !important;
}

.itsopport-intro-section-heading2,
#managed-it-solutions-main-head {
  border-left: 4px solid #CD4409;
  padding: 0 1rem;
}

.itsopport-intro-section-heading span {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.smart-solution-head-bg span {
  display: block;
}

.itsopport-sup-services-section-heading {
  color: #14212b;
  font-size: 42px;
  font-weight: 500;
  line-height: normal;
}

.itsopport-sup-services-section-heading span {
  color: #CD4409;
  font-size: 42px;
  font-weight: 700;
  margin-right: 5px;
  line-height: normal;
}

.itsopport-intro-head h6 {
  color: #262626;
  font-size: 15px;
  font-weight: 600;
  line-height: 42px;
}

.itsopport-intro-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin-top: 10px;
}

.itsopport-intro-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

/* -========================== Network infa secrive page ==================- */
.network-infra-service-line::after {
  width: 11.2rem !important;
}

/* ------------- words from us section --------- */
#app-trans-words-from-us {
  margin-top: 3rem;
}

#itsopport-network-counter-row {
  margin-top: 2rem;
}

span.app-sub-head-span {
  display: unset !important;
}

.itsopport-network-counter-cards {
  margin: 10px;
  width: 48%;
  border: 2px dashed #CD4409;
  border-top: unset;
  padding: 20px 0;
  text-align: center;
  height: max-content;
}

.itsopport-network-counter-cards h2 {
  color: #CD4409;
  font-size: 60px;
  font-weight: 800;
}

.itsopport-network-counter-cards p {
  color: #14212b;
  font-size: 22px;
  font-weight: 600;
  padding: 10px;
}

/* -------- service area ----------- */
#itsopport-network-business-areas {
  /* margin: 0rem 0 10rem 0 !important; */
}

.itsopport-inner-area-customer {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.itsopport-inner-area-customer:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #CD4409;
}

.itsopport-inner-area-customer:hover .itsopport-small-box-down {
  margin-left: 22rem;
}

.itsopport-inner-area-customer h5 {
  margin-right: 20px;
  color: #14212b;
  font-size: 30px;
  font-weight: 600;
}

.itsopport-inner-area-customer p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.itsopport-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.itsopport-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.itsopport-inner-area-customer .itsopport-circle .itsopport-cir-inr {
  color: #696969 !important;
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
}

.itsopport-inner-area-customer .itsopport-circle .itsopport-cir-inr span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

/* ---------- it support service success stories first ---------------- */
section#itsupport-what-think-first {
  /* margin-top: -5rem !important; */
}


.itsupport-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

/* ---------- what think second part ----- */
section#itsupport-what-think-seocnd {
  margin-top: 5rem !important;
}

img.itsupport-success-stories-multi-img {
  width: 100%;
  margin-bottom: 1rem;
  height: 13rem;
}

#itsupport-success-stories-multi-data h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: inline-block;

}

#itsupport-success-stories-multi-data h6::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

#itsupport-success-stories-multi-data h5 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#itsupport-success-stories-multi-data p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

/* -================ security service page ==============- */
/* -------- beno suppot section --------- */
.mis-wcbeno-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mis-mss-cards {
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 31%;
  padding-left: 15px;
  padding-bottom: 15px;
}

.mis-mss-cards:hover {
  border: 2px solid #CD4409;
}

.mis-mss-cards img {
  width: 90px;
  height: 80px;
  margin-top: 2rem;
  object-fit: contain;
}

.mis-mss-cards h2 {
  margin-top: 1rem;
  color: #14212b;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.mis-mss-cards p {
  color: #14212b;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

/* ----------  security service it support------------- */
/* section#itsupport-tailored-solution {
  margin: -5rem 0px 10rem 0;
} */

.tailored-solution-para-first p {
  color: #333;
  font-size: 18px;
  line-height: normal;
}

.tailored-solution-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tailored-solution-para-second p {
  color: #CD4409;
  font-size: 28px;
  font-weight: 800;
  border: 1px solid #d1d1d1;
  text-align: center;
  border-left: 5px solid #CD4409;
  padding: 15px 5px;
}

.tailored-solution-iconn {
  text-align: end;
}

.tailored-solution-iconn p svg {
  width: 28px !important;
  fill: #CD4409 !important;
}

/* -===================== managed it page ================- */
.managetit-service-line::after {
  width: 8.5rem !important;
}

.mis-service-cover-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mis-serv-covr-cards {
  border: 1px solid #d1d1d1;
  display: flex;
  flex-direction: column;
  height: 11rem;
  margin: 10px;
  width: 31%;
  padding-left: 15px;
  padding-bottom: 15px;
  position: relative;
}

.mis-serv-covr-cards img {
  width: 90px;
  height: 80px;
  margin-top: 2rem;
}

.mis-serv-covr-cards h2 {
  margin-top: 1rem;
  color: #14212b;
  font-size: 24px;
  font-weight: 700;
}

.mis-serv-covr-cards::after {
  content: "";
  position: absolute;
  top: 1px;
  height: 4px;
  width: 100%;
  left: 0;
  background-color: #CD4409;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0);
}

.mis-serv-covr-cards:hover::after {
  transform: scaleX(1);
}

.workforce-cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mis-serv-covr-cards:hover {
  border: unset;
  padding-left: 15px;
  padding-bottom: 15px;
}

.mis-serv-covr-cards:hover.mis-serv-covr-cards h2 {
  color: #CD4409;
}

/* ---------- it support service section ------------ */
.itsopport-services-sec {
  margin: 4rem 0 10rem 0;
}

#itsopport-serv-smart {
  border: 2px solid #CD4409;
  background: #fff;
  padding: 10px 10px 0px 10px;
  margin: 2% 2% 0 0;
  position: relative;
  width: 48%;
}

div#itsopport-serv-smart-desc {
  margin: 0;
  padding: 0;
}

#itsopport-serv-smart-img img {
  width: 100%;
  height: 10.5rem;
  object-fit: cover;
  margin-bottom: 10px;
}

#itsopport-serv-smart-desc h4 {
  color: #212121;
  font-size: 26px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#itsopport-serv-smart-desc p {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

.itsopport-serv-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
  display: none;
}

.itsopport-serv-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

#itsopport-serv-smart:hover .itsopport-serv-small-box-down {
  display: block;
}

/* ------------ industries sction -------------- */
.managed-indus-item-row {
  margin-top: 2rem;
}

.managed-indus-item-box {
  margin-bottom: 2rem;
}

.managed-indus-item-box.hidden-segments,
.hidden-wedo_box {
  display: none;
}

.managed-indus-item-box.visible-segments,
.visible-wedo_box {
  display: block;
}

.managed-indus-item-card {
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  border: unset !important;
  height: 350px;
  text-align: center;
}

.managed-indus-item-card figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

figure.managed-indus-item-effect-steve img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  object-fit: cover;
}

figure.managed-indus-item-effect-steve h2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #212121;
  line-height: normal;
  text-transform: capitalize;
  word-spacing: -0.15em;
  background: #ffffff;
}

figure.managed-indus-item-effect-steve p {
  padding: 0.5em;
  opacity: 0;
  font-size: 15px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  line-height: 21px;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 10px 11px !important;
  margin: 0;
  height: 100% !important;
}

figure.managed-indus-item-effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.managed-indus-item-effect-steve:hover p {
  opacity: 1;
}

figure .managed-indus-item-intro {
  position: absolute;
  bottom: -1px;
  width: 90%;
  height: 40%;
  margin-left: 5% !important;
}

.btn-div-manage-it-main {
  margin-top: 2rem;
}

.load-more-manage-it-btn {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.load-more-manage-it-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* -------------------------services section ------------------------ */
.itsupport-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.itsupport-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.itsupport-head-main-title-span {
  margin-left: 10px;
}

#app-datamig-itsupport-about-effect span::after {
  content: unset !important;
}

.itsupport-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

#itsupport-service-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#itsupport-service-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#itsupport-service-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#itsupport-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#itsupport-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#itsupport-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#itsupport-what-think-head span {
  display: block !important;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {

  /* --================= Managed It support Main page css  ===================- */
  /* heading background */
  .itsupport-banner-heading span::after {
    width: 113px;
    height: 36px;
    margin-left: -111px;
    margin-top: -3px;
  }

  .it-support-head-bg span::after,
  .flexibility-head-bg::after,
  .smart-solution-head-bg span::after,
  .approach-bg-head span::after,
  .enable-heading-bg::after,
  span.itsupport-shadow-img-segments-left img,
  span.itsupport-shadow-img-segments-right img {
    display: none;
  }

  #indus-managed-it-effect::after {
    margin-left: -259px !important;
    width: 167px !important;
    margin-top: -3px !important;
  }

  #itsupport-services,
  #itsupport-approach {
    background-image: none;
  }

  /* all section tabs padding */
  #itsupport-expertise,
  #itsupport-services,
  #itsupport-approach,
  #itsupport-solution,
  #itsupport-benefits,
  #itsopport-intro,
  #itsopport-network-counter,
  #itsopport-network-business-areas,
  #itsupport-what-think-first,
  #itsupport-network-services,
  #itsupport-tailored-solution {
    padding-top: 2rem !important;
  }

  /* ----------- Main page banners css ------------ */
  .itsupport-banner-main {
    margin-top: unset !important;
    height: unset !important;
    max-height: unset !important;
    padding: 3rem 0 !important;
  }

  .itsupport-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  /* -------play button-------- */
  .itsupport-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .itsopport-intro-img-front-txt h4 {
    font-size: 22px !important;
  }

  .managed-it-bnr-btn-main {
    margin-top: 20% !important;
  }

  .itsupport-banner-know-how {
    text-align: center;
  }

  .it-support-inner-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .it-support-inner-banner-bg-btn {
    margin-top: 2rem !important;
  }

  .itsupport-sub-banner-Heading {

    font-size: 40px;

  }

  /*----------------  page indexes section -------------------*/
  .navbar-index-itsupport ul li a {
    font-size: 16px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-itsupport {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-itsupport li {
    margin: 5px !important;
  }

  /* --------- it support intro section  ---------------------- */

  .itsopport-intro-img-col-under {
    margin-top: 1px !important;
  }

  .itsopport-intro-img-front-txt {
    width: unset !important;
  }

  .itsopport-intro-img-front-txt h4 {
    font-size: 20px !important;
  }

  .itsopport-network-counter-cards p {
    font-size: 18px !important;
  }

  .itsopport-intro-para-know-how {
    justify-content: center;
    display: flex;
  }

  /* ------------- Our Approach Towards Solution -------- */
  .heading-left-line-als {
    border-left: 5px solid #CD4409;
    padding-left: 1rem;
  }

  .itsupport-approach-enforcement-cards {
    width: 90% !important;
  }

  .itsopport-sup-services-section-heading,
  .itsopport-sup-services-section-heading span {
    font-size: 30px !important;
  }

  .itsupport-accordion-body-cont {
    font-size: 17px !important;
    line-height: 26px !important;
  }

  .itsupport-scalable-growth-content ul li {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  .itsupport-scalable-growth-content ul {
    margin-left: 0rem !important;
  }

  /* -========== all page comman css code =====- */
  /* -------- all  sub pages main heading and sub heading  css ----- */
  .itsopport-intro-section-heading {
    font-size: 32px !important;
    position: relative;
  }

  .itsopport-intro-section-heading span {
    font-size: 32px !important;
    display: inline;
    margin-left: 5px;
  }

  .itsopport-intro-section-sub-heading {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  /* ------------- all sup pages banner css------- */


  .itsupport-sub-banner {
    margin-top: unset !important;
    padding: 3rem 0 !important;
    text-align: center;
    height: 100% !important;
  }

  .itsupport-sub-banner-Heading {
    font-size: 40px !important;
    line-height: 45px !important;
  }

  .itsupport-sub-banner-para {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .itsupport-sub-banner-num-btn {
    margin: 2rem 0 3rem 0 !important;
  }

  .itsupport-sub-banner-para {
    font-size: 17px;
  }

  /* ---------------- network service area section -------------- */
  section#customer-business-areas {
    margin-top: 1rem !important;
  }

  .itsopport-inner-area-customer h5 {
    font-size: 25px !important;
    margin-right: 10px !important;
    text-align: center;
  }

  .itsopport-inner-area-customer p {
    margin-right: 10px !important;
    text-align: center;
  }

  .itsopport-inner-area-customer {
    padding: 20px 0px 0px 20px !important;
  }

  .itsopport-small-box-down {
    position: unset !important;
    float: right !important;
  }

  .it-support-head-bg br {
    display: none;
  }

  /* --------------- counter section  [------------*/
  .itsopport-network-counter-cards {
    width: 93% !important;
    padding: 5px 0 !important;
  }

  /* ---------- application service success stories first ---------------- */
  .itsupport-sub-under-img-right {
    margin-top: 0px !important;
  }

  .itsupport-sub-video-play-button-under {
    margin-top: 4rem !important;
  }

  #itsupport-success-stories-multi-data h5 {
    font-size: 22px !important;
  }

  #itsupport-success-stories-multi-data p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .itsopport-network-counter-cards h2 {
    font-size: 50px !important;
  }

  /* -================ security service page ==============- */
  /* -------- beno suppot section --------- */
  .mis-mss-cards {
    width: 90% !important;
    height: max-content;
    text-align: center !important;
    display: flex;
    align-items: center !important;
  }

  /* ---- Tailored Solution ------------ */
  .tailored-solution-iconn {
    width: 10% !important;
  }

  .tailored-solution-para-first {
    width: 90% !important;
  }

  /* section#itsupport-tailored-solution {
    margin: 1rem 0px 7rem 0 !important;
  } */

  /* .tailored-solution-row {
    margin-bottom: 2rem;
  } */

  /* -===================== managed it page ================- */
  .mis-serv-covr-cards {
    width: 90% !important;
    height: 10rem;
  }

  /* ---------- it support service section ------------ */
  #itsopport-serv-smart {
    width: 94% !important;
    margin: 2% 2% !important;
    padding: 10px 0px 0px 10px !important;
    height: max-content !important;
  }

  div#itsopport-serv-smart-desc {
    padding: 0 12px !important;
  }

  #itsopport-serv-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
    height: 12rem !important;
  }

  #itsopport-serv-smart-desc p {
    margin-right: 5px !important;
    margin-bottom: 10px;
    line-height: 28px !important;
    font-size: 18px !important;
  }

  #itsopport-network-business-areas {
    /* margin: 2rem 0 1rem 0 !important; */
  }

  .tailored-solution-para-second p {
    font-size: 22px !important;
  }

  section#itsupport-what-think-seocnd {
    margin-top: 1rem !important;
  }

  .itsopport-services-sec {
    margin: 2rem 0 7rem 0 !important;
  }

  /* ------------ industries sction -------------- */
  .managed-indus-item-box {
    margin-bottom: 1rem !important;
  }

  .managed-indus-item-card {
    height: 300px !important;
  }

  figure.managed-indus-item-effect-steve img {
    height: 100% !important;
  }

  figure.managed-indus-item-effect-steve p {
    font-size: 15px !important;
    height: 100% !important;
    line-height: 19px;
  }

  figure .managed-indus-item-intro {
    height: 50%;
  }

  .btn-div-manage-it-main {
    margin-top: 0rem !important;
  }

  /* ---------------------services section ----------------------- */
  .itsupport-head-main-title span::after {
    content: unset !important;
  }

  .itsupport-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center !important;
    display: unset !important;
  }

  .itsupport-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .itsupport-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #itsupport-service-area-cont {
    height: unset !important;
  }

  #itsupport-what-think-head span {
    display: unset !important;
    text-align: center !important;
  }
}

/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 767px) {

  /* --================= Managed It support Main page css  ===================- */
  /* all section tabs padding */
  #itsupport-expertise,
  #itsupport-services,
  #itsupport-approach,
  #itsupport-solution,
  #itsupport-benefits,
  #itsopport-intro,
  #itsopport-network-counter,
  #itsopport-network-business-areas,
  #itsupport-what-think-first,
  #itsupport-network-services,
  #itsupport-tailored-solution {
    padding-top: 2rem !important;
  }

  /* all heading background */
  .it-support-head-bg span::after {
    width: 165px !important;
    margin-left: -223px !important;
    margin-top: 1px !important;
  }

  .itsupport-banner-heading span::after {
    width: 183px !important;
    height: 43px !important;
    margin-left: -184px !important;
    margin-top: 13px !important;
  }

  .it-support-head-bg span::after,
  .flexibility-head-bg::after,
  .smart-solution-head-bg span::after,
  .approach-bg-head span::after,
  .enable-heading-bg::after,
  span.itsupport-shadow-img-segments-left img,
  span.itsupport-shadow-img-segments-right img {
    display: none;
  }

  #itsupport-services,
  #itsupport-approach {
    background-image: none;
  }

  /* ----------- Main page banners css ------------ */
  .itsupport-banner-main {
    margin-top: 0px !important;
    height: 100% !important;
  }

  .itsupport-banner-para {
    text-align: center;
  }

  div#play-button-itsupport {
    margin: 2rem 0 0 0 !important;
  }

  #play-button-itsupport-banner,
  .itsupport-know-how-btn {
    width: 50% !important;
  }

  .managed-it-bnr-btn-main {
    margin-top: 35% !important;
    margin-left: 50% !important;
  }

  .itsupport-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  .itsopport-intro-img-front-txt h4 {
    font-size: 22px !important;
  }

  .it-support-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .navbar-index-itsupport ul li a {
    padding: 7px 25px !important;
  }

  .navbar-index-itsupport ul {
    padding: 0 !important;
  }

  .itsupport-sub-banner-para {
    font-size: 17px;
  }

  #itsopport-intro-img-col,
  .itsupport-sub-under-img-right {
    margin-top: 0rem !important;
  }

  .itsopport-network-counter-cards h2 {
    font-size: 43px !important;
  }

  .itsupport-head-main-title span {
    display: unset !important;
  }

  /* --------- index pages css ------- */
  .nav-link-index-itsupport {
    font-size: 27px;
    padding: 3px 13px 3px 13px;
  }

  .itsopport-intro-para-know-how {
    display: flex !important;
    justify-content: center !important;
  }

  .itsupport-sub-banner-Heading {

    font-size: 40px;

  }

  /* --------- it support intro section  ---------------------- */

  .itsopport-intro-img-front-txt {
    width: 95% !important;
  }

  .itsopport-intro-know-how-btn {
    margin-bottom: 1rem !important;
  }

  /* ------------- Our Approach Towards Solution -------- */
  .itsupport-approach-enforcement-cards {
    width: 45% !important;
  }

  /* ---------- solution section ------- */

  .itsopport-sup-services-section-heading,
  .itsopport-sup-services-section-heading span {
    font-size: 32px !important;
  }

  .itsupport-accordion-body-cont {
    line-height: 32px !important;
  }

  .itsupport-scalable-growth-content ul li {
    font-size: 20px !important;
    line-height: 36px !important;
  }

  /* -================== all pages comman css code ==============- */
  /* -------- all it support sub pages main heading and sub heading css --------- */
  .itsopport-intro-section-heading {
    font-size: 35px !important;
  }

  .itsopport-intro-section-heading span {
    font-size: 35px !important;
  }

  .itsopport-intro-section-sub-heading {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  /* ------------- all sup pages banner css------- */
  .itsupport-sub-banner {
    margin-top: unset !important;
    padding: 3rem 0 !important;
    text-align: center;
    height: 100% !important;
  }

  .itsupport-sub-banner-Heading {
    font-size: 40px !important;
    line-height: 50px !important;
  }

  .itsupport-sub-banner-para {
    font-size: 19px !important;
    line-height: 30px !important;
  }

  .itsupport-sub-banner-num-btn {
    margin: 2rem 0 3rem 0 !important;
  }

  /* ---------------- network service area section -------------- */
  #business-areas-cont-itsopport {
    width: 100% !important;
  }

  .itsopport-inner-area-customer h5 {
    margin-right: 15px !important;
    font-size: 23px !important;
    text-align: center;
  }

  .itsopport-inner-area-customer p {
    margin-right: 15px !important;
    text-align: center;
  }

  /* ---------- application service success stories first ---------------- */
  /* section#itsupport-what-think-first {
    margin-top: 0rem !important;
  } */

  .itsupport-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  div#itsupport-success-stories-multi,
  div#itsupport-success-stories-multi-data {
    width: 100% !important;
  }

  #itsupport-success-stories-multi-data h5 {
    font-size: 22px !important;
    margin-top: 0rem !important;
  }

  #itsupport-success-stories-multi-data p {
    line-height: 30px !important;
  }

  /* ----============== network infra sevice page ===========- */
  .itsopport-network-counter-cards {
    width: 45% !important;
    padding: unset !important;
    height: 12rem !important;
  }

  .itsopport-network-counter-cards p {
    font-size: 20px !important;
  }

  #itsopport-network-business-areas {
    margin: 2rem 0 3rem 0 !important;
  }

  section#itsupport-what-think-seocnd {
    margin-top: 1rem !important;
  }

  /* -================ security service page ==============- */
  /* -------- beno suppot section --------- */
  /* section#itsupport-tailored-solution {
    margin: 0rem 0px 3rem 0 !important;
  } */

  .mis-mss-cards {
    width: 46% !important;
  }

  /* ---- Tailored Solution ------------ */
  .tailored-solution-iconn {
    width: 10% !important;
  }

  .tailored-solution-para-first {
    width: 90% !important;
  }

  .tailored-solution-para-second {
    width: 90% !important;
    margin-left: 10% !important;
  }

  .tailored-solution-row {
    margin-bottom: 2rem;
  }

  /* -===================== managed it page ================- */
  .mis-serv-covr-cards {
    width: 45.5% !important;
    height: max-content !important;
  }

  .mis-serv-covr-cards h2 {
    font-size: 21px !important;
  }

  /* ---------- it support service section ------------ */
  .itsupport-scalable-growth-content ul {
    margin-top: 1rem !important;
    margin-left: 1rem !important;
  }

  #itsopport-serv-smart {
    width: 96% !important;
    margin-bottom: 1rem !important;
    padding: 10px 0px 0px 10px !important;
    height: max-content !important;
    margin: 2% 1% !important;
  }

  div#itsopport-serv-smart-desc {
    padding: 0 12px !important;
  }

  #itsopport-serv-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
    height: 15rem !important;
  }

  #itsopport-serv-smart-desc p {
    margin-right: 10px !important;
    line-height: 28px !important;
    font-size: 18px !important;
    margin-bottom: 15px !important;
  }

  .itsopport-services-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  /* ------------ industries sction -------------- */
  .btn-div-manage-it-main {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  /* ----------------------services section ----------------------- */

  .itsupport-head-main-title span::after {
    width: 119px !important;
    margin-left: -195px !important;
    margin-top: 4px !important;
    height: 29px !important;
  }

  .itsupport-head-main-title {
    font-size: 30px !important;
    text-align: center !important;
    display: unset !important;
  }

  .itsupport-head-main-title span {
    font-size: 30px !important;
  }

  .itsupport-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #itsupport-service-area-cont {
    height: unset !important;
  }

  #itsupport-service-area-cont {
    height: unset !important;
  }

  #itsupport-what-think-head span {
    display: unset !important;
    text-align: center !important;
  }
}

/* Tablets iPads (Portrait) */
@media (min-width: 768px) and (max-width: 991px) {

  /* --================= Managed It support Main page css  ===================- */
  /* all section tabs padding */
  #itsupport-expertise,
  #itsupport-services,
  #itsupport-approach,
  #itsupport-solution,
  #itsupport-benefits,
  #itsopport-intro,
  #itsopport-network-counter,
  #itsopport-network-business-areas,
  #itsupport-what-think-first,
  #itsupport-network-services,
  #itsupport-tailored-solution {
    padding-top: 3rem;
  }

  span.itsupport-shadow-img-segments-left img,
  span.itsupport-shadow-img-segments-right img {
    display: none;
  }

  /* all heading background */
  .itsupport-banner-heading span::after {
    width: 221px !important;
    margin-left: -218px !important;
    margin-top: 18px !important;
  }

  .it-support-head-bg span::after {
    width: 149px !important;
    margin-left: -225px !important;
    margin-top: -2px !important;
  }

  .smart-solution-head-bg span::after {
    width: 170px !important;
    margin-left: -209px !important;
    margin-top: 0px !important;
  }

  .flexibility-head-bg::after {
    width: 214px !important;
    margin-left: -258px !important;
    margin-top: 4px !important;
  }

  .enable-heading-bg::after {
    width: 198px !important;
    margin-left: -196px !important;
    margin-top: 4px !important;
  }

  /* ----------- Main page banners css ------------ */
  .itsupport-banner-main {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset;
  }

  .itsupport-banner-para {
    text-align: center;
  }

  .itsupport-know-how-btn {
    width: 70% !important;
  }

  .itsupport-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-itsupport {
    margin-top: 2rem;
  }

  #play-button-itsupport-banner,
  .itsupport-know-how-btn {
    width: 50% !important;
  }

  .managed-it-bnr-btn-main {
    margin-top: 25% !important;
    margin-left: 50% !important;
  }

  .it-support-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
  }

  /* --------- it support intro section ---------- */
  .itsopport-intro-img-front-txt {
    width: unset !important;
  }

  .itsopport-intro-img-front-txt h4 {
    font-size: 22px !important;
  }

  .itsupport-scalable-growth-content ul li {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  /* -================  all pages comman code css =============- */
  /* ------------- all  sub pages main heading and sub heading css --------- */
  .itsopport-intro-section-heading {
    font-size: 37px !important;
    line-height: 45px !important;
  }

  .itsopport-intro-section-heading span {
    font-size: 37px !important;
  }

  .itsopport-intro-section-sub-heading {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .itsopport-network-counter-cards p {
    font-size: 20px !important;
  }

  .itsopport-network-counter-cards h2 {
    font-size: 50px !important;
  }

  /* ------------- all sup pages banner css------- */
  .itsupport-sub-banner {
    margin-top: unset !important;
    padding-top: 1rem !important;
    height: unset !important;
    padding-bottom: 3rem;
  }

  .itsupport-sub-banner-Heading {
    font-size: 45px !important;
    line-height: 50px !important;
  }

  .itsupport-sub-banner-para {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .itsupport-sub-banner_btn,
  .itsupport-sub-banner-num-btn {
    width: 47% !important;
    margin-bottom: 3rem !important;
  }

  /* ------------- Our Approach Towards Solution -------- */
  .approach-bg-head span::after {
    width: 127px !important;
    margin-left: -133px !important;
    margin-top: 0px !important;
  }

  .itsupport-approach-enforcement-cards {
    width: 40% !important;
  }

  .itsopport-intro-img-txt-down {
    margin-bottom: 3.2rem !important;
  }

  #itsopport-network-business-areas {
    margin: 4rem 0 3rem 0 !important;
  }

  /* ---------------- network service area section -------------- */
  section#customer-business-areas {
    margin-top: 2rem !important;
  }

  div#business-areas-cont-itsopport {
    width: 50%;
  }

  .itsopport-inner-area-customer h5 {
    font-size: 23px !important;
  }

  .navbar-index-itsupport ul li a {
    padding: 7px 25px !important;
  }

  .navbar-index-itsupport ul {
    padding: 0 !important;
  }

  /* ---------- application service success stories  ---------------- */
  /* section#itsupport-what-think-first {
    margin-top: 2rem !important;
  } */

  #itsupport-success-stories-multi-data h5 {
    font-size: 22px !important;
  }

  #itsupport-success-stories-multi-data p {
    line-height: 26px !important;
    font-size: 16px !important;
  }

  section#app-success-stories-seocnd {
    margin-top: 2rem !important;
  }

  section#itsupport-what-think-seocnd {
    margin-top: 1rem !important;
  }

  /* -================ security service page ==============- */
  /* -------- beno suppot section --------- */
  .mis-mss-cards {
    width: 47% !important;
    height: 20rem !important;
    display: flex;
    align-items: center !important;
    text-align: center !important;
  }

  .itsupport-approach-solution {
    margin: 2rem 0 2rem 0 !important;
  }

  /* ---- Tailored Solution ------------ */
  .tailored-solution-iconn {
    width: 10% !important;
  }

  .tailored-solution-para-first {
    width: 90% !important;
  }

  .tailored-solution-para-second {
    width: 90% !important;
    margin-left: 10% !important;
  }

  .tailored-solution-row {
    margin-bottom: 2rem;
  }

  /* section#itsupport-tailored-solution {
    margin: 0 0px 3rem 0 !important;
  } */

  /* -===================== managed it page ================- */
  .mis-serv-covr-cards {
    width: 30% !important;
    height: 13rem !important;
  }

  /* ---------- it support service section ------------ */
  div#itsopport-serv-smart-desc,
  div#itsopport-serv-smart-img {
    width: 100% !important;
  }

  div#itsopport-serv-smart-desc {
    padding: 0 12px !important;
  }

  #itsopport-serv-smart {
    padding: 10px 0px 0px 10px !important;
    height: max-content !important;
    margin: 2% 1% !important;
    width: 47% !important;
  }

  #itsopport-serv-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
  }

  #itsopport-serv-smart-desc p {
    margin-right: 7px !important;
    line-height: 25px !important;
    font-size: 16px !important;
  }


  .itsopport-network-counter-cards {
    width: 47% !important;
    height: 12rem !important;
  }

  .itsopport-services-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  .itsopport-sup-services-section-heading br {
    display: none;
  }

  /* ------------ industries sction -------------- */
  .managed-indus-item-box {
    width: 50% !important;
  }

  figure.managed-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ----------------------services section ---------------------- */

  .itsupport-head-main-title span::after {
    width: 143px !important;
    margin-left: -227px !important;
    margin-top: 6px !important;
    height: 32px !important;
  }

  .itsupport-head-main-title,
  .itsupport-head-main-title span {
    font-size: 35px !important;
  }

  .itsupport-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #itsupport-service-area-cont-img {
    width: 100% !important;
  }

  .itsupport-service-area-cont-desc {
    width: 100% !important;
  }

  #itsupport-service-area-cont,
  #itsupport-service-area-cont-desc {
    width: 100% !important;
  }

  #itsupport-service-area-cont {
    height: unset !important;
  }
}

/* Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1200px) {

  /* --================= Managed It support Main page css  ===================- */
  /* all heading background  */
  .itsupport-banner-heading span::after {
    width: 246px !important;
    margin-left: -241px !important;
    margin-top: 6px !important;
  }

  .it-support-head-bg span::after {
    width: 218px !important;
    margin-left: -267px !important;
    margin-top: 11px !important;
  }

  /* ----------- Main page banners css ------------ */

  .itsupport-banner-heading {
    font-size: 50px;
  }

  /* ------------- Our Approach Towards Solution -------- */
  .itsupport-approach-enforcement-cards {
    width: 30% !important;
  }

  .itsopport-network-counter-cards {
    width: 47% !important;
    padding: unset !important;
    height: 10rem;
  }

  #itsopport-network-business-areas {
    margin: 4rem 0 4rem 0 !important;
  }

  /* -================  all pages comman code css =============- */
  /* ------------- all  sub pages main heading and sub heading css ------- */
  .itsopport-intro-section-heading {
    font-size: 45px !important;
  }

  .itsopport-intro-section-heading span {
    margin-left: 10px !important;
    font-size: 45px !important;
  }

  .smart-solution-head-bg span::after {
    margin-left: -256px !important;
  }

  .approach-bg-head span::after {
    width: 166px !important;
    margin-left: -163px !important;
    margin-top: 8px !important;
  }

  /* ------- all pages banner ------------- */
  .itsupport-sub-banner_btn,
  .itsupport-sub-banner-num-btn {
    width: 35% !important;
    margin-bottom: 3rem !important;
  }

  .itsopport-intro-img-txt-down {
    margin-bottom: 3rem !important;
  }

  /* section#itsupport-tailored-solution {
    margin: 0rem 0px 1rem 0 !important;
  } */

  .itsopport-intro-img-front-txt h4 {
    font-size: 22px !important;
  }

  .itsopport-intro-img-front-txt {
    width: 94.5% !important;
  }

  /* ---------------- network service area section -------------- */
  .itsopport-inner-area-customer h5 {
    font-size: 23px !important;
  }

  /* ----------- what we think section ------------------ */
  section#itsupport-what-think-first {
    margin-top: 1rem !important;
  }

  #itsupport-success-stories-multi-data h5 {
    font-size: 27px !important;
  }

  section#itsupport-what-think-seocnd {
    margin-top: 2rem !important;
  }

  /* -================ security service page ==============- */
  /* -------- beno suppot section --------- */
  .mis-mss-cards {
    width: 31% !important;
  }

  /* -===================== managed it page ================- */
  .mis-serv-covr-cards {
    width: 30% !important;
    height: 10.5rem !important;
  }

  /*------------- it support service section --------------------*/
  div#itsopport-serv-smart-desc,
  div#itsopport-serv-smart-img {
    width: 100% !important;
  }

  div#itsopport-serv-smart-desc {
    padding: 0 12px !important;
  }

  #itsopport-serv-smart {
    padding: 10px 0px 0px 10px !important;
    height: 23.1rem !important;
  }

  #itsopport-serv-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
  }

  #itsopport-serv-smart-desc p {
    margin-right: 10px !important;
  }

  .itsopport-services-sec {
    margin: 4rem 0 4rem 0 !important;
  }

  .itsopport-sup-services-section-heading br {
    display: none;
  }

  /* ------------ industries sction -------------- */
  figure.managed-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* --------------------- services section ------------------- */

  .itsupport-head-main-title span::after {
    margin-left: -290px !important;
    margin-top: 9px !important;
  }

  .itsupport-head-main-title {
    font-size: 40px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {

  /* --================= Managed It support Main page css  ===================- */
  /* ----------- Main page banners css ------------ */
  .itsupport-sub-banner_btn {
    width: 30% !important;
  }

  .itsupport-banner-heading {
    /* margin-top: 2rem !important; */
  }

  .itsupport-sub-banner-num-btn {
    width: 70% !important;
  }

  /* ---------------- network service area section -------------- */
  .itsopport-inner-area-customer {
    padding: 15px 0px 0px 15px !important;
  }

  .itsopport-network-counter-cards {
    height: 11.5rem !important;
  }

  /* #itsopport-network-business-areas {
    margin: 2rem 0 5rem 0 !important;
  } */

  /* ------------ industries sction -------------- */
  figure.managed-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }
}

#expertise,
#services,
#rpo-approach,
#why-us {
  padding-top: 4rem !important;
}


/* ------- banner css -------------- */
section#rpo-service-banner-sec {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  margin-top: 10.6rem;
}

.rpo-banner-title {
  font-size: 65px;
  font-weight: 800;
  color: #14212b;
  margin-top: 50px;
  position: relative;
  line-height: 1.1;
}

.rpo-serv-banner-desc {
  color: #14212b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

div#rpo-serv-banner-play-btn-main {
  margin-top: 8%;
  margin-left: 4%;
  width: 11%;
}

.rpo-serv-banner-work-btn {
  margin: 11% 0 0 10%;
}

.rpo-serv-happens-box-play-btn {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 25%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.rpo-serv-happens-box-play-btn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.rpo-serv-happens-box-play-btn:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.rpo-serv-happens-box-play-btn span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border-heppens {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.rpo-serv-banner-work-btn a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff;
}

.rpo-serv-banner-work-btn a svg {
  padding: 4px 5px 0 5px;
}

.rpo-serv-banner-work-btn a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 3px solid #CD4409;
}


/* ------ index section rpo -------- */
section.rpo-serv-page-index {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 90px;
  width: 100%;
  z-index: 9;
}

.sticky-rpo {
  position: fixed;
  top: 0;
}

.rpo-serv-indx-item.active {
  background-color: white;
  color: #CD4409;
}

.rpo-serv-indexing-nav ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.rpo-serv-indexing-nav ul li {
  display: inline-flex;
}

.rpo-serv-indexing-nav ul li:hover,
.rpo-serv-indexing-nav ul li a:hover {
  background-color: #ffffff;
  color: #CD4409;
}

.rpo-serv-indexing-nav ul li a {
  color: #fff;
  font-size: 17px;
  padding: 10px 50px 10px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* ------------------------- rpo expertise ------------------------- */


.rpo-serv-expertise-title-unique::after {
  margin-left: -340px !important;
}

.rpo-serv-expertise {
  width: 100%;
}

.rpo-serv-expertise-pre-haad {
  color: #14212b;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 0 !important;
}

#rpo-serv-expertise-cont-fluid {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
}

.rpo-serv-expertise-title {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.rpo-serv-expertise-desc {
  color: #14212b;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

#rpo-serv-expertise-card-row {
  margin: 5rem 0 3rem 0;
}

.rpo-serv-expertise-service_box {
  margin: 10px;
  padding: 12px 0 15px 10px;
  width: 31%;
  clip-path: polygon(0 17%, 100% 0%, 100% 82%, 0% 100%);
  background-color: #fef2ea !important;
}

div.rpo-serv-expertise-service_box h6 {
  color: #14212b;
  font-size: 24px;
  margin-top: 60px;
  font-style: normal;
  margin-left: 15px !important;
  font-weight: 700;
  line-height: normal;
}

div.rpo-serv-expertise-service_box p {
  color: #262626 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 20px;
  margin-left: 15px !important;
  line-height: normal;
}

.rpo-serv-expertise-service_box img {
  margin-bottom: 13px !important;
  margin-left: 15px !important;
}

.rpo-serv-expertise-small {
  width: 70px;
  height: 50px;
  margin-top: -12px;
  margin-right: -0.2px;
  background: #CD4409;
  float: right;
  opacity: 0;
  clip-path: polygon(0 30%, 100% 0%, 100% 60%, 0% 90%);
  border-radius: 2px;
}

.rpo-serv-expertise-service_box:hover:after {
  content: "";
  height: 340px;
  width: 3px;
  margin-top: -17rem;
  float: right;
  background: #CD4409;
}

.rpo-serv-expertise-service_box:hover .rpo-serv-expertise-small {
  opacity: 1;
}

/* --------------- rpo services ---------------------------- */
.rpo-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
  text-align: center;
}

.rpo-sub-section-heading span {
  color: #14212b !important;
  display: block;
  font-size: 45px;
  font-weight: 700;
}

.rpo-serv-our-segments-desc {
  color: #14212b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

span.shadow-img-segments-right {
  float: right;
  margin-top: 50px;
}

span.shadow-img-segments-left {
  float: left;
  margin-top: 400px;
}

.shadow-img-segments-right img,
.shadow-img-segments-left img {
  width: 99px;
  height: 100% !important;
}

.rpo-serv-what_wedo-innr {
  position: relative;
  width: 100% !important;
  border: none !important;
  overflow: hidden;
}

.rpo-serv-what_wedo-innr a {
  text-decoration: none;
}

.rpo-serv-what_wedo-innr img {
  width: 100%;
  height: 250px;
  border: 5px solid #fef2ea;
  object-fit: cover;
  transition: 0.5s;
}

.rpo-serv-what_wedo-innr img:hover {
  border: 5px solid #CD4409;
}

h5#rpo-serv-wedo-card-title {
  color: #14212b;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
}

h5#rpo-serv-wedo-card-title:hover {
  color: #CD4409;
}

p#rpo-serv-wedo-card-desc {
  color: #14212b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 26px;
}

/* ---------------- approach ------------------------------ */

#rpo-approach {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

.rpo-approach-cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rpo-approach-enforcement-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 10px;
  padding: 60px;
  width: 23%;
  height: 250px;
  border: 1px solid #d1d1d1;
  background: #fffbf9;
  align-items: center;
}

.rpo-approach-enforcement-cards::before,
.rpo-approach-enforcement-cards::after {
  position: absolute;
  border-style: solid;
  border-color: #CD4409;
  border-width: 1px;
  height: 80px;
  width: 80px;
  content: "";
}

.rpo-approach-enforcement-cards::before {
  top: -5px;
  left: -5px;
  border-width: 5px 0 0 5px;
}

.rpo-approach-enforcement-cards::after {
  bottom: -5px;
  right: -5px;
  border-width: 0 5px 5px 0;
}

.rpo-approach-enforcement-cards h1 {
  font-size: 50px;
  font-weight: 700;
}


.rpo-approach-enforcement-cards img {
  width: 80px;
  height: 85px;
  margin-top: 3rem;
  object-fit: contain;
}

.rpo-approach-enforcement-cards h2 {
  color: #14212b;
  font-size: 20px;
  font-weight: 600;
}

.rpo-heading {
  font-size: 45px;
  font-weight: 700;
  position: relative;
  color: #CD4409;
}

.rpo-heading span {
  color: #14212b !important;
}

/* ---------------------------- rpo why us ------------------------------- */

.rpo-serv-our-segments-heading::after {
  margin-left: -398px !important;
}

.rpo-serv-our-segments-heading-unique::after {
  margin-left: -730px !important;
}

.rpo-sub-section-heading-unique::after {
  margin-left: -240px !important;
}

.rpo-serv-happens-sec {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
  background-position: center;
}

.rpo-serv-our-segments-heading {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.rpo-serv-our-segments-desc {
  color: #14212b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

#rpo-serv-happens-row-main-second {
  margin: 2rem 0;
}

.rpo-serv-happens-box-back-img {
  width: 26.5rem;
  height: 24rem;
  border: 12px solid #fef2ea;
  padding: 26%;
  background-size: cover;
}

.rpo-serv-happens-box-left-col-second {
  padding-left: 6rem;
}

h2.rpo-serv-happens-box-head {
  color: #14212b;
  margin-top: 25px;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
}

.rpo-serv-happens-box-head span {
  color: #CD4409;
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
}

p.rpo-serv-happens-box-desc {
  color: #14212b;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

p.rpo-serv-happens-box-desc ul {
  margin-top: 2rem;
  font-weight: 700;
}

#rpo-serv-happens-row-main {
  margin-top: 3rem;
}

.rpo-serv-happens-box-left-col {
  padding-right: 6rem;
}

.rpo-serv-happens-box-right-img {
  width: 26.5rem;
  height: 24rem;
  object-fit: cover;
  border: 12px solid #fef2ea;
  background-size: cover;
}

.rpo-serv-happens-box-learn-more {
  margin: 2rem 0 0 1rem;
}

.rpo-serv-happens-box-learn-more a {
  color: #fff;
  background-color: #CD4409;
  padding: 10px 16px;
  border-radius: 22px;
}

.rto-serv-expertise-socail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  float: right;
  width: 60px;
  /* border: 1px solid red; */
  margin-top: 19rem;
}

.rto-serv-expertise-socail div {
  width: 40px;
  height: 40px;
  background-color: #14212b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rto-serv-expertise-socail a {
  font-size: 20px;
  color: #fff;
}


@media only screen and (max-width: 480px) {

  #expertise,
  #services,
  #rpo-approach,
  #why-us {
    padding-top: 2rem !important;
  }

  #services {
    margin-top: 4rem;
  }

  .rpo-sub-section-heading-unique::after {
    content: unset !important;
  }

  .rpo-serv-our-segments-heading-unique::after {
    content: unset !important;
  }

  .rpo-serv-expertise-title-unique::after {
    content: unset !important;
  }


  /* ------- banner css -------------- */
  section#rpo-service-banner-sec {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .rpo-banner-title {
    font-size: 40px !important;
    text-align: center;
  }

  .rpo-serv-banner-desc {
    font-size: 18px !important;
    margin-top: 1.5rem !important;
    text-align: center;
    line-height: 26px !important;
  }

  div#rpo-serv-banner-play-btn-main,
  .rpo-serv-banner-know-how {
    margin-top: 1rem !important;
    margin-left: unset !important;
    text-align: center !important;
    width: 100% !important;
  }

  .rpo-serv-happens-box-play-btn {
    margin-top: 60% !important;
  }

  .rpo-serv-banner-work-btn {
    margin: 0 0 3rem 0 !important;
  }

  /* ------ index section rpo -------- */
  .rpo-serv-indexing-nav ul {
    padding: 0 !important;
  }

  .rpo-serv-indexing-nav ul li a {
    padding: 8px 7px 8px 7px !important;
  }

  /* ----------------- rpo expertice ---------------------*/
  .rpo-serv-expertise-title::after {
    width: 168px !important;
    margin-top: 8px !important;
    margin-left: -250px !important;
  }

  .rpo-serv-expertise-title {
    font-size: 40px !important;
  }

  #rpo-serv-expertise-card-row {
    margin: 3rem 0 -3rem 0 !important;
  }

  .rpo-serv-expertise-desc {
    line-height: 24px !important;
    margin: 0 !important;
  }

  .rpo-serv-what_wedo_box {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }

  #rpo-serv-expertise-cont-fluid {
    background-image: unset !important;
  }

  .rpo-serv-expertise-pre-haad {
    font-size: 17px !important;
  }

  .rpo-serv-expertise-service_box {
    width: 100% !important;
    height: max-content !important;
    margin: 0 0 -25px 0 !important;
  }

  .rpo-serv-expertise-service_box:hover:after {
    height: 303px !important;
    margin-top: -17rem !important;
  }

  /* ----------------------- rpo services ------------------------ */

  .rpo-sub-section-heading {
    font-size: 30px !important;
    text-align: center !important;
  }

  .rpo-sub-section-heading span {
    font-size: 30px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  p#rpo-serv-wedo-card-desc {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  /* --------------------------------- rpo approach -------------------------------------- */
  .rpo-approach-enforcement-cards {
    width: 92% !important;
  }

  .rpo-heading {
    font-size: 30px !important;
    text-align: center;
    position: relative;

  }

  /* --------------------------rpo why us ----------------------------------- */

  .rpo-serv-happens-sec {
    background-image: unset !important;
  }

  .rpo-serv-our-segments-heading::after {
    margin-left: -300px !important;
    width: 143px !important;
    margin-top: 3px !important;
  }

  .rpo-serv-our-segments-heading {
    font-size: 37px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #rpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  .rpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 23rem !important;
  }

  .rpo-serv-happens-box-left-col-second {
    padding-left: 10px !important;
  }

  .rpo-serv-happens-box-head span {
    font-size: 22px !important;
  }

  h2.rpo-serv-happens-box-head {
    text-align: center !important;
    margin-top: 24px !important;
    font-size: 21px !important;
  }

  p.rpo-serv-happens-box-desc,
  .rpo-serv-happens-box-desc ul li {
    line-height: 24px !important;
  }

  p.rpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  #rpo-serv-happens-row-main {
    margin-top: 0rem !important;
  }

  .rpo-serv-happens-box-left-col {
    padding-right: 10px !important;
  }

  .rpo-serv-happens-box-right-img,
  .rpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 23rem !important;
  }

  .rto-serv-expertise-socail {
    display: none;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  #expertise,
  #services,
  #rpo-approach,
  #why-us {
    padding-top: 2rem !important;
  }

  /* ------- banner css -------------- */
  section#rpo-service-banner-sec {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .rpo-banner-title {
    font-size: 50px !important;
    text-align: center;
  }

  .rpo-serv-banner-desc {
    font-size: 18px !important;
    margin-top: 1.5rem !important;
    text-align: center;
    line-height: 32px !important;
  }

  div#rpo-serv-banner-play-btn-main,
  .rpo-serv-banner-know-how {
    margin-top: 0 !important;
    margin-left: unset !important;
    text-align: center !important;
    width: 100% !important;
  }

  .rpo-serv-happens-box-play-btn {
    margin-top: 25% !important;
  }

  .rpo-serv-banner-work-btn {
    margin: 0 0 3rem 0 !important;
  }

  /* ------ index section rpo -------- */
  .rpo-serv-indexing-nav ul li a {
    font-size: 18px !important;
    padding: 10px 25px 10px 24px !important;
  }

  /* ----------------------- rpo expertise ------------------------ */

  .rpo-serv-expertise-title::after {
    width: 170px !important;
    margin-left: -244px !important;
    margin-top: 5px !important;
  }

  .rpo-serv-expertise-title {
    font-size: 40px !important;
  }

  .rpo-serv-expertise-desc {
    line-height: 26px !important;
    margin: 0 !important;
  }

  #rpo-serv-expertise-card-row {
    margin: 4rem 0 1rem 0 !important;
  }

  #rpo-serv-expertise-cont-fluid {
    background: unset !important;
  }

  .rpo-serv-expertise-pre-haad {
    font-size: 17px !important;
  }

  .rpo-serv-expertise-service_box {
    width: 100% !important;
    height: max-content !important;
    margin: -48px 0 20px 0 !important;
  }

  /* --------------------- rpo services --------------------- */

  .rpo-sub-section-heading {
    font-size: 48px !important;
  }

  .rpo-sub-section-heading span {
    font-size: 37px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  /* ------------------------- apo why us -------------------------------------- */

  .rpo-serv-our-segments-heading::after {
    margin-left: -320px !important;
    width: 149px !important;
    margin-top: 4px !important;
  }

  .rpo-serv-our-segments-heading {
    font-size: 40px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  #rpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  .rpo-serv-happens-box-right-img,
  .rpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 25rem !important;
  }

  .rpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  .rpo-serv-happens-box-head span {
    font-size: 36px !important;
    font-size: 24px !important;
  }

  h2.rpo-serv-happens-box-head {
    text-align: center !important;
    font-size: 22px !important;
  }

  p.rpo-serv-happens-box-desc,
  .rpo-serv-happens-box-desc ul li {
    line-height: 28px !important;
  }

  p.rpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  #rpo-serv-happens-row-main {
    margin-top: 1rem !important;
  }

  .rpo-serv-happens-box-left-col {
    padding-right: 20px !important;
  }

  .rto-serv-expertise-socail {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  #expertise,
  #rpo-approach,
  #services,
  #why-us {
    padding-top: 3rem !important;
  }

  /* ------- banner css -------------- */
  section#rpo-service-banner-sec {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .rpo-banner-title {
    margin: 4rem 5rem 0 5rem !important;
    text-align: center;
  }

  .rpo-serv-banner-desc {
    margin-top: 1.5rem !important;
    text-align: center;
  }

  .rpo-serv-happens-box-play-btn {
    margin-top: 5rem !important;
  }

  #rpo-serv-banner-play-row {
    width: 100% !important;
    text-align: center;
    margin: 0rem 0 2rem 0 !important;
  }

  #rpo-serv-banner-play-btn-main,
  .rpo-serv-banner-know-how {
    width: 35% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #rpo-serv-banner-play-btn-main {
    margin: 0 0rem 0px 6rem !important;
  }

  .rpo-serv-banner-know-how {
    margin: 0 6rem 0 0 !important;
  }

  .rpo-serv-banner-work-btn {
    margin: unset !important;
  }

  /* ------ index section rpo -------- */
  .rpo-serv-indexing-nav ul li a {
    padding: 10px 25px 10px 25px !important;
  }

  /*  -------------------------------rpo expertise ------------------------------ */
  .rpo-serv-expertise-service_box:hover:after {
    height: 318px !important;
    margin-top: -17rem !important;
  }

  #rpo-serv-expertise-cont-fluid {
    background: unset !important;
  }

  .rpo-serv-expertise-service_box {
    width: 47% !important;
    margin: 0 10px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  p#rpo-serv-wedo-card-desc {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  /* ------------------- rpo appoach ------------------------------- */

  .rpo-approach-enforcement-cards {
    width: 46% !important;
  }

  .rpo-heading {
    font-size: 39px !important;
    text-align: center;
  }

  .rpo-heading {
    font-size: 39px !important;
    text-align: center;
  }

  /* ------------------------ rpo why us -------------------------- */

  .rpo-serv-our-segments-heading::after {
    margin-left: -326px !important;
    margin-top: 3px !important;
    width: 155px !important;
  }

  .rpo-serv-our-segments-heading {
    font-size: 40px !important;
  }

  .rpo-serv-our-segments-desc {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  #rpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  .rpo-serv-happens-box-right-img,
  .rpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 27rem !important;
  }

  .rpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  .rpo-serv-happens-box-head span {
    font-size: 24px !important;
  }

  h2.rpo-serv-happens-box-head {
    font-size: 22px !important;

  }

  p.rpo-serv-happens-box-desc,
  .rpo-serv-happens-box-desc ul li {
    line-height: 25px !important;
  }

  p.rpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .rpo-serv-happens-box-left-col {
    padding-right: 10px !important;
  }

  .rto-serv-expertise-socail {
    margin-top: 22rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {

  /* ------- banner css -------------- */
  .rpo-serv-banner-content {
    width: 70% !important;
  }

  .rpo-banner-title {
    font-size: 60px !important;
    margin-top: 7rem !important;
  }

  .rpo-serv-happens-box-play-btn {
    margin-top: 75% !important;
  }

  #rpo-serv-banner-play-btn-main,
  .rpo-serv-banner-know-how {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #rpo-serv-banner-play-btn-main {
    width: 30% !important;
  }

  .rpo-serv-banner-know-how {
    width: 65% !important;
  }

  .rpo-serv-banner-desc {
    margin-top: 2rem !important;
  }

  /* ---------------------rpo expertise -------------------------------- */
  .rpo-serv-expertise-service_box:hover:after {
    height: 298px !important;
    margin-top: -16rem !important;
  }

  /* --------------------- rpo services ----------------------- */

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  h5#rpo-serv-wedo-card-title {
    font-size: 20px !important;
  }

  /* -------------------------- rpo approach --------------------------- */

  .rpo-approach-enforcement-cards {
    width: 80% !important;
  }

  /* ------------------------------- apo why us ------------------------------ */

  #rpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  .rpo-serv-happens-box-right-img,
  .rpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 26rem !important;
  }

  .rpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  p.rpo-serv-happens-box-desc,
  .rpo-serv-happens-box-desc ul li {
    line-height: 32px !important;
  }

  p.rpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .rpo-serv-happens-box-left-col {
    padding-right: 20px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  /* -------------------------- rpo why us ----------------------------- */
  .rpo-serv-happens-box-right-img,
  .rpo-serv-happens-box-back-img {
    width: 100% !important;
  }

  p.rpo-serv-happens-box-desc,
  .rpo-serv-happens-box-desc ul li {
    line-height: 32px !important;
  }

  p.rpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .rpo-serv-happens-box-left-col {
    padding-right: 2rem !important;
  }
}

@media (min-width: 1600px) {

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }
}