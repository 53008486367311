/* --------------margin all sections------------ */
#ai-business,
#dgt-expertise,
#dgt-services,
#dgt-what-we-do,
#our-blog,
#customer-what-we-do,
#sales-what-we-do,
#dgt-network-services,
#dgt-op-what-we-do,
#dgt-op-expertise,
#dgt-op-operationally-agile,
#dgt-op-business-areas,
#dc-what-we-do,
#customer-business-areas,
#dgt-dc-product-solutions,
#dc-what-we-think,
#maximum-efficiency,
#customer-what-we-think,
#workforce-enablement,
#customer-experience,
#dgt-security-service-sec {
  padding-top: 4rem;
}

/* --================= Digital Transformation Main page css  ===================- */
/* ----------- Digital Transformation Main page banners css ------------ */
.digital-transformation-banner {
  width: 100%;
  height: 450px;
  max-height: 450px;
  /* margin-top: 10.5rem; */
  background-size: cover;
  /* padding: 5rem 0; */
  transition: all 3s;
  display: flex;
  align-items: center;
}

.digital-transformation-banner:hover {
  transform: scale(1.03);
}

p.dgt-banner-heading {
  font-size: 65px;
  text-transform: none;
  font-weight: 800;
  color: #000;
  line-height: 1.1;
}

.dgt-banner-para {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}


/*--------------- dgt banner button css-------------- */
div#play-button-dgt {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  margin-top: 4rem;
}

#play-button-dgt-banner {
  position: relative;
}

.dgt-bannerplay-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  padding: 18px 20px 18px 28px;
  position: absolute;
}

.dgt-bannerplay-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.dgt-bannerplay-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.dgt-bannerplay-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}


.dgt-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.dgt-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}


/* .dgt-banner-know-how a:before {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background: #fff;
  transform: skew(50deg);
  transition-duration: .9s;
  z-index: -1;
} */

.dgt-banner-know-how a:hover {
  color: #CD4409;
}

.dgt-banner-know-how a:hover:before {
  width: 180%;
}

.dgt-inner-banner-bg-btn {
  margin-top: 3.5rem;
}

.dgt-inner-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.dgt-inner-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.dgt-inner-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.dgt-inner-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/*----------------  page indexes section -------------------*/
section.nag-page-index-dgt {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 99;
}

section.nag-page-index-dgt.sticky-dgt {
  position: fixed;
  top: 89px !important;
}

.nav-link-index-dgt.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-dgt ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-index-dgt ul li {
  display: inline-flex;
}

.navbar-index-dgt ul li:hover,
.navbar-index-dgt ul li a:hover {
  background-color: #ffffff;
  color: #212121;
}

.navbar-index-dgt ul li a {
  color: #FFF;
  font-size: 18px;
  padding: 8px 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* --------- whats possible section ---------------------- */


.dgt-para-know-how {
  margin-top: 2rem;
}

.dgt-para-know-how a {
  background: #CD4409;
  padding: 10px 30px;
  font-size: 18px;
  text-decoration: none;
  transition: .5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.dgt-para-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

.under-img-right-dgt {
  width: 100%;
  height: 299px;
  object-fit: cover;
}

.dgt-triangle-down::after {
  width: 12% !important;
}

.dgt-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.dgt-ai-front-txt {
  width: 95.5%;
  height: max-content;
  padding: 5px 18px;
  margin-top: -41px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-left: 26px;
}

.dgt-ai-front-txt h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  position: relative;
  display: inline-block;
}

.dgt-ai-front-txt h6::after {
  content: '';
  position: absolute;
  width: 100%;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
  top: 100%;
  left: 0;
}

.dgt-ai-front-txt h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.dgt-ai-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* --------- dgt Our Expertise section ---------------------- */
.inr-Digital-Our-Expertise {
  background-repeat: no-repeat;
  background-size: cover;
}

.inner-dgt-section-heading h6 {
  color: #262626;
  font-size: 19px;
  font-weight: 400;
  line-height: 20px;
}

.inner-dgt-section-heading h2 {
  color: #14212B;
  font-size: 45px;
  font-weight: 700;
}

.inner-dgt-section-heading p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}

.Digital-expert-service {
  box-shadow: 0px 0px 4px 0px #b5b5b5;
  padding: 40px 0 40px 10px;
  margin-bottom: 16px;
}

.Digital-expert-service img {
  height: 90px;
  width: 90px;
  margin-bottom: 1rem;
}

.Digital-expert-service h5 {
  color: #14212B;
  font-size: 22px;
  font-weight: 600;
  padding: 0px 10px 0px 0px;
}

.Digital-expert-service p {
  color: #696969;
  font-size: 16px;
  font-weight: 400;
  height: 100px;
  padding: 0px 10px 0px 0px;
}

.dgt-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #FEF2EA;
  display: none;
}

.dgt-small-box svg {
  padding: 10px;
  color: #CD4409;
  font-size: 20px;
  rotate: -135deg;
  transition: 0.5s;
}

.Digital-expert-service:hover .dgt-small-box {
  display: block;
}

.Digital-expert-service.top-customer {
  width: 100px;
  height: 100px;
}

/* --------- dgt Our Segments ----------------------  */

.segments_box_dgt {
  margin: 0 0 20px 0 !important;
}

span.dgt-seg-dotted-img-right {
  float: right;
  margin-top: 50px;
}

span.dgt-seg-dotted-img-left {
  float: left;
  margin-top: 400px;
}

.dgt-seg-dotted-img-right img,
.dgt-seg-dotted-img-left img {
  width: 97px;
  height: 100% !important;
}

/*-- cards css --- */
.dgt-img-segment {
  display: block;
  margin: 0 auto;
  border: unset;
  width: 100%;
  height: 350px;
  padding-left: 0;
  font-size: 0;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

.dgt-img-segment figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  background: #3085a3;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10); */
}

.dgt-img-segment figure .dgt-hov-img-text::before,
.dgt-img-segment figure .dgt-hov-img-text::after {
  pointer-events: none;
}

figure.dgt-seg-imgs {
  /* z-index: auto; */
  background: #000;
}

figure.dgt-seg-imgs:before {
  /* box-shadow: 0 3px 30px rgba(0, 0, 0, 0.8); */
  opacity: 0;
}

figure.dgt-seg-imgs .dgt-hov-img-text {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 1.25em;
  backface-visibility: hidden;
  width: 90%;
  height: 40%;
  bottom: 0;
  margin-left: 5% !important;
}

figure.dgt-seg-imgs img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  position: relative;
  display: block;
  opacity: 0.8;
  height: 240px;
  object-fit: cover;
}

figure.dgt-seg-imgs h2,
figure.dgt-seg-imgs p {
  background: #ffffff;
  color: #2d434e;
}

figure.dgt-seg-imgs h2 {
  position: absolute;
  padding: 20px 0;
  bottom: 0px;
  width: 100%;
  word-spacing: -0.15em;
  font-weight: 600;
  text-transform: uppercase;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-style: normal;
  line-height: normal;
  font-size: 18px;
  margin: 0 0 0 0;
  color: #14212B;
}

figure.dgt-seg-imgs p {
  padding: 0.5em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  color: #FFF;
  font-style: normal;
  line-height: 22px;
  font-size: 15px;
  font-weight: 200;
  position: absolute;
  bottom: 0;
  margin: 0;
  height: 100%;
}

figure.dgt-seg-imgs:hover:before {
  opacity: 1;
}

figure.dgt-seg-imgs:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.dgt-seg-imgs:hover h2:before {
  opacity: 0;
}

figure.dgt-seg-imgs:hover p {
  opacity: 1;
}

.dgt-img-segment figure,
.dgt-hov-img-text,
img {
  z-index: 0;
}

figure:hover h2 {
  background: #f1f1f1;
  transition: transform 0.35s;
  transform: translateY(-2.5em);
}

button.load-more-btn-dgt {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  margin-top: 1.5rem;
  line-height: normal;
  border: unset;
}

button.load-more-btn-dgt:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid;
}

/* {--------------- dgt what we do section ----------------} */
span#dgt-serv-wedo-content1,
span#dgt-serv-wedo-content2,
span#dgt-serv-wedo-content3,
span#dgt-serv-wedo-content4 {
  display: none;
}

.blog_page_button button {
  font-size: 18px;
  transition: 0.5s;
  color: #CD4409;
  text-decoration: none;
  font-weight: 600;
  background: unset;
  border: unset;
  transition: .5s;
}

.blog_page_button button:hover {
  color: #000;
  transition: .5s;
}

.dgt-we-do-img-sec {
  position: relative;
  width: 100% !important;
  border: none;
  overflow: hidden;
}

img.dgt-what-we-do-card-image {
  width: 100%;
  height: 250px;
  border: 5px solid #fef2ea;
  object-fit: cover;
  transition: 0.5s;
}

img.dgt-what-we-do-card-image:hover {
  border: 5px solid #CD4409;
  transition: 0.5s;
}

.what_wedo_box_dgt {
  margin-bottom: 1rem;
}

h5#dgt-what-we-do-card-head {
  color: #14212B;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: .5s;

}

h5#dgt-what-we-do-card-head:hover {
  color: #CD4409;
  transition: .5s;
}


.dgt-we-do-img-sec a {
  text-decoration: none;
}

p#dgt-what-we-do-card-para {
  color: #14212B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 6px 0 !important;
}


/* --==================== all sub pages commen section  css ==========================- */
/* ----------- all DGT sub pages banners css ------------ */
.dgt-sub-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10.5rem;
}

.dgt-sub-banner-Heading {
  color: #14212b;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
}

.dgt-sub-banner-para {
  color: #000;
  font-size: 18px;
  line-height: 32px;
}

.dgt-sub-banner-Heading-unique {
  color: #fff !important;
}

.dgt-sub-banner-para-unique {
  color: #fff !important;
}

.dgt-sub-banner_btn,
.dgt-sub-banner-num-btn {
  margin: 3% 0 0 0%;
}

.dgt-sub-banner_btn a {
  background: #CD4409;
  padding: 12px 30px;
  font-size: 23px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.dgt-sub-banner_btn a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

.dgt-sub-banner-num-btn a {
  background: #ffffff;
  padding: 11px 25px;
  font-size: 22px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #CD4409;
  border: 2px solid #CD4409;
}

/* ----------------- all DGT sub pages main heading and sub heading  css --------- */
.dgt-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.dgt-sub-section-heading span {
  color: #14212B !important;
  display: unset !important;
  font-size: 45px;
  font-weight: 700;
}

.dgt-sub-section-heading-main,
.dgt-sub-section-heading-main span {
  font-size: 40px !important;
}

.dgt-sub-section-sub-heading {
  color: #14212B !important;
  font-size: 17px;
  line-height: 28px;
}

.dgt-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

/* ------------------------- DGT service what-we-do section -------------------- */


.dgt-sub-under-img-right {
  width: 100%;
  padding: 8%;
  background-size: cover;
  background-position: center;
  margin-top: 2rem;
}

.dgt-sub-video-play-button-under {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 7rem;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.dgt-sub-video-play-button-under:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.dgt-sub-video-play-button-under:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.dgt-sub-video-play-button-under span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

p.dgt-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.dgt-sub-front-txt {
  width: 95.9%;
  height: max-content;
  padding: 12px 18px 2px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  float: right;
  margin-bottom: 0.8rem;
}

.dgt-sub-front-txt h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.dgt-sub-front-txt h6::after {
  content: '';
  display: block;
  position: relative;
  width: 9.5rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.dgt-sub-front-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.dgt-sub-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.dgt-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  position: absolute;
}

/* -======================= Customer Experience ===========================- */
/* --------- areas business  section --------------------- */
/* section#dgt-op-business-areas {
  margin-top: 3rem;
} */

.dgt-inner-area-customer {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.dgt-inner-area-customer:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 3px solid #CD4409;
}

.dgt-inner-area-customer:hover .dgt-small-box-down {
  margin-left: 22rem;
}

.dgt-inner-area-customer h5 {
  margin-right: 20px;
  color: #14212B;
  font-size: 30px;
  font-weight: 600;
}

.dgt-inner-area-customer p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.dgt-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.dgt-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.dgt-inner-area-customer .dgt-circle .dgt-cir-inr {
  color: #696969 !important;
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
}

.dgt-inner-area-customer .dgt-circle .dgt-cir-inr span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

/* --------- Customer Experience  section --------------------- */


.customer-experience-pre-head {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}

.customer-experience-head-div {
  border-left: 5px solid #CD4409;
  padding: 0px 3rem;
  width: 80%;
}

.dgt-sub-section-heading-spec {
  color: #14212B;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dgt-sub-section-heading-spec span {
  color: #CD4409;
}

/* ------ accordition code -----*/
.customer-experience-accordion-main {
  margin-top: 3rem;
}

.accordion-button::after {
  background-image: none;
  content: "\002B";
  font-size: 25px !important;
  font-weight: bold !important;
  position: absolute;
  right: 1rem;
  width: 28px;
  line-height: 28px !important;
  height: 28px;
  background: #000;
  border-radius: 30px;
  margin: 0px 0px !important;
  padding: 0px 6px 1px 7px !important;
  color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(0deg);
  content: "\2212";
}

.accordion-item {
  background-color: #fff;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.customer-experience-accordion-quest,
.accordion-button {
  color: #14212B;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  background-color: #fff !important;
  line-height: 40px;
}

.customer-experience-accordion-quest span {
  color: #CD4409;
  font-size: 25px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.dgt-col-first-img-tabs {
  width: 100%;
  height: 398px;
  background-size: cover;
}

.dgt-content-img-btn-custo {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  margin-top: 34%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.dgt-content-img-btn-custo:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.dgt-content-img-btn-custo span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

.dgt-content-img-btn-custo:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

p.dgt-img-fifth-btn {
  color: #14212B;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.dgt-tabs-accor-content p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.dgt-tabs-accor-content ul li {
  color: #262626;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

.dgt-tabs-accor-content a {
  background: #CD4409;
  padding: 9px 15px;
  margin: 20px 15px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.dgt-tabs-accor-content a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

/* ------- what why section ------ */


.customer-maximum-efficiency-head::after {
  margin-left: -8.9rem !important;
  width: 8.9rem !important;
}

.customer-maximum-efficiency-head-unique::after {
  margin-left: -9.3rem !important;
  width: 9.3rem !important;
}

/* -=========================== Digital operation =================- */
.dgt-op-we-do-subhead::after {
  width: 8.3rem !important;
}

/* ---- Operationally Agile  ----------- */
.dgo-agile-para p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.dgo-agile-para ul li {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.dgo-centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dgo-centered-div img {
  max-width: 70%;
}

.dgt-tabs-accor-content {
  margin-top: 2rem;
}

.dgt-tabs-accor-content a {
  color: #fff !important;
  /* padding: 10px 12px !important; */
  font-size: 17px !important;
  padding: 8px 20px !important;
}

.dgt-tabs-accor-content a:hover {
  color: #CD4409 !important;
  background-color: #fff !important;
  outline: auto !important;
}

div#operationally-agile-main {
  margin-top: 2rem;
}

/* -============================= Digital Consulting =================- */
.dc-what-do-pre::after {
  width: 10.3rem;
}

.dc-what-do-pre-unique::after {
  width: 9.6rem !important;
}

.dc-startup-dri {
  max-width: 100% !important;
  width: 100% !important;
  height: 24rem !important;
  object-fit: cover;

}


.dc-pre-head-do::after {
  width: 8.9rem !important;
  margin-left: -8.8rem !important;
}

/* -============================= Digital workforce  =================- */


.dgo-workforce-para {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 1rem;
}

.dgo-enforcement-cards {
  border: 1px solid #D1D1D1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 15.5rem;
  margin: 10px;
  width: 23.2%;
}

.dgo-enforcement-cards img {
  width: 90px;
  height: 85px;
  margin-top: 3rem;
  object-fit: contain;
}

.dgo-enforcement-cards h2 {
  margin-top: 2.5rem;
  color: #14212B;
  font-size: 24px;
  font-weight: 500;
}

.workforce-cards-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dgt-sub-section-heading {
  color: #CD4409 !important;
  font-size: 58px;
  font-weight: 700;
  position: relative;
}

.dgt-sub-section-heading span {
  color: #14212b !important;
  display: block;
  font-size: 45px;
  font-weight: 700;
}

.dgt-sub-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
}

.dgt-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

.dgt-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  margin-top: 3rem;
}

.dgt-sub-video-play-button-under {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 9%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.dgt-sub-video-play-button-under:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.dgt-sub-video-play-button-under:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.dgt-sub-video-play-button-under span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

p.dgt-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.dgt-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
  position: relative;
}

#dgt-sub-triangle-down-row-main {
  margin-left: 12px;
}

.dgt-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.dgt-sub-section-heading span {
  color: #14212b !important;
  display: block;
  font-size: 45px;
  font-weight: 700;
}

.dgt-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

.dgt-sub-under-img-right {
  width: 100%;
  background-size: cover;
  height: 290px;
}

.dgt-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.dgt-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.dgt-head-main-title-span {
  margin-left: 10px;
}

#app-datamig-dgt-about-effect span::after {
  content: unset !important;
}

.dgt-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

#dgt-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#dgt-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#dgt-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#dgt-service-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#dgt-service-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#dgt-service-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#dgt-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#dgt-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#dgt-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.dgt-what-we-think2 {
  margin-top: 1rem;
}

/* Mobile Devices */
@media only screen and (max-width : 480px) {

  /* --------------margin-top all sections------------ */
  #ai-business,
  #dgt-expertise,
  #sales-what-we-do,
  #dgt-network-services,
  .dgt-our-segments,
  .dgt-what-we-do,
  .dgt-recent-blogs,
  #dgt-op-expertise,
  #dgt-op-what-we-do,
  #dgt-op-operationally-agile,
  #dc-what-we-do,
  #customer-business-areas,
  #dgt-dc-product-solutions,
  #dc-what-we-think,
  #maximum-efficiency,
  #customer-what-we-think,
  #dgt-security-service-sec,
  #workforce-enablement,
  #customer-experience {
    padding-top: 2rem !important;
  }

  /* all heading background */
  .dgt-banner-head-bg::after {

    width: 160px !important;
    margin-left: -158px !important;
    margin-top: 1px !important;
  }

  .dgt-sub-section-heading span::after {
    width: 168px !important;
    margin-left: -279px !important;
    margin-top: -7px !important;
  }

  .dgt-our-exper-head-bg h2::after {
    width: 151px !important;
    margin-left: -201px !important;
    margin-top: -2px !important;
  }

  .dgt-our-segment-head-bg h2::after {
    width: 177px !important;
    margin-left: -266px !important;
    margin-top: -2px !important;
  }

  .dgt-what-we-do-head-bg h2::after {
    width: 153px !important;
    margin-left: -179px !important;
    margin-top: -1px !important;
  }



  /* --================= Digital Transformation Main page css  ===================- */
  /* ----------- Digital Transformation Main page banners css ------------ */
  .digital-transformation-banner {
    margin-top: unset !important;
    padding: 3rem 0 !important;
    height: unset !important;
    max-height: unset !important;
  }

  p.dgt-banner-heading {
    font-size: 39px !important;
    text-align: center !important;
    position: relative !important;
  }


  .first-div-banner-dgt {
    display: none;
  }

  /* -------play button-------- */
  .dgt-banner-para {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .dgt-banner-know-how {
    text-align: center;
  }

  .dgt-bannerplay-btn-main {
    position: unset !important;
    margin-left: 50%;
  }

  div#play-button-dgt {
    margin-left: 0rem !important;
  }

  /*----------------  page indexes section -------------------*/
  .navbar-index-dgt ul li a {
    font-size: 17px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-dgt {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-dgt li {
    margin: 5px !important;
  }

  /* -----------our experties---------- */

  .under-img-right-dgt {
    margin-top: 1rem !important;
  }

  .dgt-triangle-down {
    margin-top: 0.9rem !important;
  }

  .dgt-ai-front-txt {
    width: unset !important;
  }

  .inner-dgt-section-heading h2 {
    font-size: 33px !important;
    position: relative;
  }

  .inner-dgt-section-heading p {
    line-height: 25px !important;
    font-size: 16px !important;
  }

  .inr-Digital-Our-Expertise {
    background-image: unset !important;
  }

  .Digital-expert-service {
    padding: 25px 0 25px 10px !important;
  }

  .dgt-small-box {
    right: 8.6% !important;
    margin-top: -15px !important;
  }

  .Digital-expert-service p {
    line-height: 27px !important;
  }

  .Digital-expert-service p {
    height: unset !important;
  }

  /* ----- our segments --------- */
  .dgt-seg-dotted-img-right img,
  .dgt-seg-dotted-img-left img {
    display: none;
  }

  figure.dgt-seg-imgs p {
    height: 100% !important;
  }

  figure.dgt-seg-imgs p {
    line-height: 20px !important;
    font-size: 14px !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  section.dgt-sub-banner {
    margin-top: 0px !important;
  }

  h1.dgt-sub-banner-Heading {
    text-align: center !important;
    font-size: 40px !important;
    line-height: 1 !important;
  }

  p.dgt-sub-banner-para {
    font-size: 17px !important;
    line-height: 28px !important;
    text-align: center !important;
  }

  .dgt-sub-banner_btn,
  .dgt-sub-banner-num-btn {
    text-align: center;
    margin-top: 2rem !important;
  }

  .dgt-para-know-how {
    text-align: center;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  .dgt-sub-section-heading {
    font-size: 40px !important;
    text-align: center;
    position: relative;
  }

  .dgt-sub-section-heading span {
    font-size: 30px !important;
  }

  .dgt-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 25px !important;
    text-align: center;
  }

  /* ------------- all pages what-we-do section --------------- */

  .dgt-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 91.5%;
  }

  .dgt-sub-front-txt h4 {
    font-size: 20px !important;
  }

  /* ------------- all pages business area section --------------- */

  /* --================= customer Experience css  ===================- */
  .dgt-inner-area-customer h5 {
    font-size: 25px !important;
    margin-right: 10px !important;
    text-align: center;
  }

  .dgt-inner-area-customer p {
    margin-right: 10px !important;
    text-align: center;
  }

  .dgt-inner-area-customer {
    padding: 20px 0px 0px 20px !important;
  }

  .dgt-small-box-down {
    position: unset !important;
    float: right !important;
  }

  .customer-experience-pre-head {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .dgt-sub-section-heading-spec {
    font-size: 30px !important;
  }

  .customer-experience-head-div {
    height: unset !important;
    padding: 0px 2% !important;
    width: 100% !important;
  }

  .customer-experience-accordion-quest,
  .accordion-button {
    font-size: 18px !important;
    line-height: 23px !important;
  }

  .customer-experience-accordion-main {
    margin-top: 2rem !important;
  }

  .accordion-flush .accordion-item .accordion-button,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    padding: 13px 5px !important;
  }

  .accordion-button::after {
    font-size: 18px !important;
    right: 0.2rem !important;
    width: 20px !important;
    line-height: 22px !important;
    height: 20px !important;
    padding: 0px 3px 1px 5px !important;
  }


  .dgt-col-first-img-tabs {
    height: 235px !important;
  }

  .dgt-tabs-accor-content p {
    margin-top: 1rem !important;
    line-height: 30px !important;
  }

  .dgt-tabs-accor-content ul li {
    font-size: 18px !important;
    line-height: 29px !important;
  }

  .dgt-tabs-accor-content a {
    padding: 10px 13px !important;
  }

  /* -============== digital operation page ===============- */
  .dgo-centered-div {
    margin-top: 1rem !important;
  }

  /* -============================= Digital Consulting =================- */
  .dgo-agile-para {
    margin-top: 1rem !important;
  }

  .dgo-agile-para p {
    line-height: 29px !important;
  }

  .dc-startup-dri {
    height: 100% !important;
  }

  /* -============================= Digital workflow =================- */
  .dgo-enforcement-cards {
    width: 90% !important;
  }

  .dgt-inner-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  /* .dgt-inner-banner-bg-btn {
      margin-top: 2rem !important;
  } */

  .dgt-inner-banner-bg-btn {
    margin-top: 0rem !important;
    height: unset !important;
    /* padding-bottom: 5rem !important; */
  }

  .dgt-sub-section-heading {
    font-size: 30px !important;
  }

  .dgt-sub-section-heading span {
    font-size: 30px !important;
  }

  .dgt-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .dgt-sub-under-img-right {
    margin-top: 0px !important;
  }

  .dgt-sub-video-play-button-under {
    margin-top: 4rem !important;
  }

  .dgt-head-main-title span::after {
    content: unset !important;
  }

  .dgt-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center !important;
    /* display: unset !important; */
  }

  .dgt-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .dgt-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #dgt-service-area-cont {
    height: unset !important;
  }

  .dgt-sub-section-heading-spec {
    font-size: 30px !important;
  }

  .dgt-sub-section-heading-spec {
    font-size: 30px !important;
  }

  .dgo-workforce-para {
    font-size: 17px;
    line-height: 30px;
  }

  div#operationally-agile-main {
    margin-top: unset !important;
  }

  .customer-experience-head-div {
    padding: 0px 0 0 1rem !important;
  }
}

/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 767px) {

  /* --================= Digital Transformation Main page css  ===================- */
  #ai-business,
  #dgt-op-operationally-agile,
  #dgt-expertise,
  #dgt-services,
  #dgt-what-we-do,
  #our-blog,
  #dgt-network-services,
  #dgt-op-what-we-do,
  #dgt-op-expertise,
  #dgt-op-business-areas,
  #dgt-op-business-areas,
  #dc-what-we-do,
  #dgt-security-service-sec,
  #customer-business-areas,
  #dgt-dc-product-solutions,
  #dc-what-we-think,
  #maximum-efficiency,
  #customer-what-we-think,
  #workforce-enablement,
  #customer-experience {
    padding-top: 2rem !important;
  }

  .dgt-sub-section-heading span::after {
    width: 238px !important;
    margin-left: -391px !important;
    margin-top: 5px !important;
  }

  /* -------------- dgt banner css ------------------ */
  .digital-transformation-banner {
    margin-top: 0px !important;
    padding: 4rem 0 !important;
    height: unset !important;
    max-height: unset !important;
  }

  .dgt-banner-para {
    text-align: center;
  }

  p.dgt-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  div#play-button-dgt {
    margin-left: unset !important;
  }

  #play-button-dgt-banner {
    width: 50% !important;
  }

  .dgt-know-how-btn {
    width: 50% !important;
  }

  .dgt-bannerplay-btn-main {
    margin-left: 40% !important;
  }

  .dgt-banner-know-how {
    margin: 1rem 0 0 0 !important;
  }

  .navbar-index-dgt ul {
    padding: 0 !important;
  }

  /* --------- digital transformation index pages css -------------- */
  .nav-link-index-dgt {
    font-size: 27px;
    padding: 8px 15px !important;
  }

  /* --------- ai business ---------- */
  /* .dgt-whats-possible {
      margin: 2rem 0 !important;
  } */
  .dgt-know-how-btn {
    margin-bottom: 1rem !important;
  }

  .customer-experience-head-div {
    padding: 0px 0 0 1rem !important;
  }

  /*---------- our experties---------- */
  .inner-dgt-section-heading p {
    line-height: 26px !important;
    font-size: 16px !important;
  }

  div#dgt-expert-col-th,
  .segments_box_dgt {
    width: 86% !important;
    margin: 5px 7% !important;
  }

  .inner-Transformation-expert p {
    font-size: 18px;
    line-height: 24px !important;
    margin-bottom: 30px;
  }

  .dgt-small-box {
    position: unset !important;
    float: right !important;
    width: 40px;
    height: 40px;
    background: #FEF2EA;
    display: none;
  }

  .Digital-expert-service p {
    height: 70px !important;
  }

  /* ------- our segments ----------- */
  .dgt-seg-dotted-img-right img,
  .dgt-seg-dotted-img-left img {
    display: none;
  }

  /* {--------------- dgt what we do section ----------------} */
  p#dgt-what-we-do-card-para {
    line-height: 20px !important;
  }

  /* .card.dgt-we-do-img-sec {
  height: 26rem !important;
} */
  /* .what_wedo_box_dgt{
  width: 50% !important;
} */
  .Digital-expert-service .top-customer {
    margin-right: 25px;
  }

  /* {--------------- dgt our sucess story section ----------------} */

  .slick-dots {
    width: 96% !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .dgt-sub-banner {
    margin-top: 0rem !important;
    background-position: center;
  }

  .dgt-sub-banner-Heading {
    font-size: 45px !important;
    line-height: 50px !important;
  }

  .dgt-sub-banner-para {
    font-size: 23px !important;
    line-height: 32px !important;
  }

  .dgt-sub-banner_btn {
    width: 45% !important;
    margin-top: 3rem !important;
  }

  .dgt-sub-banner-num-btn {
    width: 50% !important;
    margin-top: 3rem !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  .dgt-sub-section-heading {
    font-size: 45px !important;
  }

  .dgt-sub-section-heading span {
    font-size: 42px !important;
  }

  .dgt-sub-section-sub-heading {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  /* ------------------- your business ------------- */
  #business-areas-cont {
    width: 100% !important;
  }

  .dgt-inner-area-customer h5 {
    margin-right: 15px !important;
    font-size: 24px !important;
    text-align: center;
  }

  .dgt-inner-area-customer p {
    margin-right: 15px !important;
    text-align: center;
  }

  .dgt-sub-section-heading-spec {
    font-size: 40px !important;
  }

  .dgt-tabs-accor-content p {
    line-height: 30px !important;
  }

  div#dgt-col-first-tabs,
  #dgt-col-second-tabs {
    width: 100%;
  }

  .dgt-col-first-img-tabs {
    height: 300px !important;
  }

  .dgt-content-img-btn-custo {
    margin-top: 26% !important;
  }

  /* ------------- all pages what-we-do section --------------- */
  .dgt-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 95%;
  }

  /* --================= customer Experience css  ===================- */
  .customer-experience-head-div {
    height: unset !important;
    width: unset !important;
  }

  .dgt-tabs-accor-content {
    margin-top: 1rem !important;
  }

  .dgt-tabs-accor-content ul li {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  /* -============================= Digital workflow =================- */
  .dgo-enforcement-cards {
    width: 29.5% !important;
  }

  .dgt-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .dgt-sub-section-heading {
    font-size: 48px !important;
  }

  .dgt-sub-section-heading span {
    font-size: 37px !important;
  }

  .dgt-sub-section-sub-heading {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .dgt-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  .dgt-head-main-title span::after {
    width: 119px !important;
    margin-left: -195px !important;
    margin-top: 4px !important;
    height: 29px !important;
  }

  .dgt-head-main-title {
    font-size: 30px !important;
    text-align: center !important;
    display: unset !important;
  }

  .dgt-head-main-title span {
    font-size: 30px !important;
  }

  .dgt-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #dgt-service-area-cont {
    height: unset !important;
  }

  #dgt-service-area-cont {
    height: unset !important;
  }
}

/* Tablets iPads (Portrait) */
@media (min-width: 768px) and (max-width: 991px) {

  /* --================= Digital Transformation Main page css  ===================- */
  /* -------------- dgt banner css ------------------ */
  .digital-transformation-banner {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset !important;
  }

  #sales-what-we-do,
  #dgt-op-what-we-do,
  #dgt-op-expertise,
  #dgt-security-service-sec,
  #dgt-op-operationally-agile,
  #dgt-op-business-areas,
  #dc-what-we-do,
  #customer-business-areas,
  #dgt-dc-product-solutions,
  #dc-what-we-think,
  #maximum-efficiency,
  #customer-what-we-think,
  #workforce-enablement,
  #customer-experience,
  #dgt-network-services {
    padding-top: 3rem !important;
  }

  .dgt-banner-para {
    text-align: center;
  }

  p.dgt-banner-heading {
    text-align: center !important;
    position: relative;
  }

  div#play-button-dgt {
    margin-left: unset !important;
  }

  .dgt-bannerplay-btn-main {
    margin-left: 50% !important;
  }

  /* ----------------page indexing -----------*/
  .navbar-index-dgt ul li a {
    padding: 8px 10px 10px 8px !important;
  }

  /* ----------------all heading background img--------- */
  .dgt-sub-section-heading span::after {
    width: 196px !important;
    margin-left: -324px !important;
    margin-top: -3px !important;
  }

  /* --------- ai business ---------- */
  .dgt-sub-section-heading span {
    font-size: 35px !important;
    position: relative;
  }

  .dgt-sub-section-heading {
    font-size: 40px !important;
  }

  .Digital-expert-service p {
    height: 170px !important;
  }

  .dgt-sub-section-sub-heading {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .inner-dgt-section-heading p {
    line-height: 30px !important;
  }

  .dgt-ai-front-txt {
    width: unset !important;
  }

  /* --------- our expertise  ---------- */
  /* .Digital-expert-service h5 {
      font-size: 20px !important;
      margin: 25px 10px 15px 0px !important;
  } */
  .Digital-expert-service .top-customer {
    margin-right: 10px;
  }

  .Digital-expert-service h5 {
    height: 50px !important;
  }

  .Digital-expert-service p {
    font-size: 17px !important;
    margin: 0px 10px 0 0 !important;
  }

  .Digital-expert-service {
    padding: 15px 0px 40px 10px !important;
  }

  div#dgt-expert-col-th {
    padding: 0 7px !important;
  }

  /* --------- our segments ---------- */
  span.dgt-seg-dotted-img-right,
  span.dgt-seg-dotted-img-left {
    display: none !important;
  }

  figure.dgt-seg-imgs p {
    font-size: 13px !important;
    line-height: 20px !important;
  }

  .what_wedo_box_dgt.hidden-wedo_box {
    display: block !important;
  }

  #row_what_wedo_dgt .dgt-load-btn {
    display: none !important;
  }

  .segments_box_dgt {
    padding: 5px !important;
  }

  figure.dgt-seg-imgs h2 {
    font-size: 20px !important;
  }

  /* ---------- what we do  ----------- */
  .dgt-we-do-img-sec {
    height: 27rem !important;
  }

  .what_wedo_box_dgt {
    padding: 5px !important;
    text-align: center;
    width: 50% !important;
    text-align: left;
  }

  p#dgt-what-we-do-card-para {
    font-size: 17px !important;
    line-height: 27px !important;
  }


  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */

  .dgt-sub-banner {
    margin-top: 0 !important;
    width: unset !important;
    padding-bottom: 3rem !important;
  }

  .dgt-sub-banner-Heading {
    margin-top: 2rem;
    font-size: 50px !important;
    line-height: 55px !important;
  }

  .dgt-sub-banner-para {
    font-size: 22px !important;
    line-height: 32px !important;
    margin-top: 1rem !important;
  }

  .dgt-sub-banner_btn {
    width: 45% !important;
    margin-top: 3rem !important;
  }

  .dgt-sub-banner-num-btn {
    width: 55% !important;
    margin-top: 3rem !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  #dgt-sub-service-areas-cont-first::after {
    height: 9rem !important;
  }

  #dgt-sub-service-areas-cont-first::before {
    width: 9rem !important;
  }

  div#dgt-sub-service-areas-cont-first-desc,
  div#dgt-sub-service-areas-cont-first-img {
    width: 100% !important;
  }

  #dgt-sub-service-areas-cont-first {
    height: max-content !important;
  }

  /* ------------- all sub pages what we do section css ---------------- */
  .dgt-sub-front-txt {
    width: 96.5% !important;
    float: unset !important;
    margin-left: 1.5rem !important;
  }

  /* --==================== customer page  css ==========================- */
  /* ---------- business areas ----------------------- */

  div#business-areas-cont {
    width: 50%;
  }

  .dgt-tabs-accor-content {
    margin-top: 0px !important;
  }

  .dgt-tabs-accor-content p {
    line-height: 28px !important;
  }

  .dgt-inner-area-customer h5 {
    font-size: 23px !important;
  }

  .customer-experience-head-div {
    height: unset !important;
    width: 100% !important;
  }

  .dgt-sub-section-heading-spec {
    font-size: 40px !important;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .dgt-tabs-accor-content ul li {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .dgt-content-img-btn-custo {
    margin-top: 50% !important;
  }

  .slick-dots {
    width: 96% !important;
  }

  /* -============================= Digital Consulting =================- */
  .dc-startup-dri {
    height: 29rem !important;
  }

  /* -============================= Digital workflow =================- */
  .dgo-enforcement-cards {
    width: 30% !important;
  }

  .dgt-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .dgt-head-main-title span::after {
    width: 143px !important;
    margin-left: -227px !important;
    margin-top: 6px !important;
    height: 32px !important;
  }

  .dgt-head-main-title,
  .dgt-head-main-title span {
    font-size: 40px !important;
  }

  .dgt-head-main-para {
    font-size: 17px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #dgt-service-area-cont-img {
    width: 100% !important;
  }

  .dgt-service-area-cont-desc {
    width: 100% !important;
  }

  #dgt-service-area-cont-desc {
    width: 100% !important;
  }

  #dgt-service-area-cont-img {
    width: 100% !important;
  }

  .dgt-service-area-cont-desc {
    width: 100% !important;
  }

  #dgt-service-area-cont,
  #dgt-service-area-cont-desc {
    width: 100% !important;
  }

  #dgt-service-area-cont {
    height: unset !important;
  }

  .dgt-what-we-think2-para {
    font-size: 17px !important;
  }

  .dgt-what-we-think2-sub-heading {
    font-size: 20px !important;
  }
}

/* Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1200px) {

  /* --================= Digital Transformation Main page css  ===================- */
  .navbar-index-dgt ul li a {
    padding: 10px 25px !important;
  }

  .dgt-ai-front-txt {
    width: 94% !important;
  }

  p.dgt-banner-heading {
    font-size: 60px !important;
  }

  /* dgt all heading background */
  .dgt-banner-head-bg::after {
    margin-left: -213px !important;
    margin-top: -46px !important;
  }

  .dgt-sub-section-heading span::after {
    margin-top: 11px !important;
  }

  .Digital-expert-service h5 {
    height: 50px !important;
  }

  /* -------------- dgt banner css ------------------ */
  .col-lg-6.col-md-12.col-sm-12.dgt-banner-front {
    margin-top: 2rem !important;
  }

  .digital-transformation-banner {
    padding: 3rem 0 !important;
  }


  .first-div-banner-dgt {
    padding-right: 15rem !important;
  }

  /* -------------- our expertise css ------------------ */
  .Digital-expert-service {
    padding: 44px 0px 40px 20px !important;
  }

  .Digital-expert-service .top-customer {
    margin-right: 20px;
  }

  /* -------------- our segments css ------------------ */
  span.dgt-seg-dotted-img-right,
  span.dgt-seg-dotted-img-left {
    display: none !important;
  }

  figure.dgt-seg-imgs p {
    font-size: 14px !important;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .dgt-sub-banner_btn {
    width: 40% !important;
    margin-top: 3rem !important;
  }

  .dgt-sub-banner-num-btn {
    width: 60% !important;
    margin-top: 3rem !important;
  }

  .dgt-sub-banner-Heading {
    font-size: 46px !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */

  #dgt-sub-service-areas-cont-first::before {
    width: 9rem !important;
  }

  div#dgt-sub-service-areas-cont-first-desc,
  div#dgt-sub-service-areas-cont-first-img {
    width: 100% !important;
  }

  #dgt-sub-service-areas-cont-first {
    height: max-content !important;
  }

  /* -=================== customer page css =======================- */
  .dgt-sub-front-txt {
    width: 94.5% !important;
  }

  /* --- business areas ------------------ */
  section#customer-what-we-do {
    margin-top: 2rem !important;
  }

  .dgt-content-img-btn-custo {
    margin-top: 50% !important;
  }

  .dgt-inner-area-customer h5 {
    font-size: 23px !important;
  }

  .customer-experience-head-div {
    height: unset !important;
    width: 100% !important;
  }

  .dgt-sub-section-heading-spec {
    font-size: 40px !important;
  }

  /* -============================= Digital Consulting =================- */
  .dc-startup-dri {
    height: 28rem !important;
  }

  /* -============================= Digital workflow =================- */
  .dgo-enforcement-cards {
    width: 30% !important;
  }

  .dgt-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  .dgt-head-main-title span::after {
    margin-left: -290px !important;
    margin-top: 9px !important;
  }

  .dgt-head-main-title {
    font-size: 40px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .dgt-sub-banner_btn {
    width: 30% !important;
  }

  .dgt-sub-banner-num-btn {
    width: 70% !important;
  }

  /* ---- what we do ----- */
  .dgt-sub-front-txt {
    width: 94.5% !important;
  }

  /* ----business area --- */
  .dgt-inner-area-customer {
    padding: 15px 0px 0px 15px !important;
  }

  figure.dgt-seg-imgs p {
    font-size: 14px !important;
  }

  /* ----customer experience  --- */
  .customer-experience-head-div {
    width: 90% !important;
  }

  .dgt-content-img-btn-custo {
    margin-top: 35% !important;
  }

  .dgt-sub-triangle-down {
    border-left: 28px solid transparent !important;
  }
}

@media only screen and (min-width : 1400px) {
  .dgt-wwd-dot-left {
    margin-left: 0px;
  }
}