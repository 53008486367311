/* --================= application service Main page css  ===================- */
/* ------- all service page heading effect ------------ */


#application-expertise-head-efft span::after {
  content: unset !important;
}

#app-srvice-head-bg span::after {
  content: none !important;
}

#devops-app-serve-notch-head span::after {
  content: unset !important;
}

#app-srvice-head-bg::after {
  content: none !important;
}

.application-sub-section-heading span::after {
  margin-left: -417px !important;
  width: 191px !important;
  margin-top: 10px !important;
}

.application-sub-section-heading-aptrans span::after {
  content: none !important;
}

.application-services-sub-section-heading-apstrans span::after,
.application-sub-section-heading-spec-devops::after {
  content: none !important;
}

.application-sub-section-heading-spec-devops span {
  margin-left: 10px !important;
}

#smart-platforms-app span::after {
  margin-left: -359px !important;
  width: 202px !important;
  margin-top: 10px !important;
}

.application-sub-section-heading-spec::after {
  margin-left: -268px !important;
  width: 197px !important;
  margin-top: 17px !important;
}

#application-expertise,
#application-industries,
#application-sevices,
#application-blog,
#sales-what-we-do,
#application-services-what-think-seocnd,
#application-services-network-services,
.why-choose-web-app,
.how-we-develop-sec,
.app-dev-about-beno {
  padding-top: 4rem !important;
}

/* ----------- application service Main page banners css ------------ */
.application-service-banner {
  width: 100%;
  height: 450px;
  /* max-height: 500px; */
  /* margin-top: 10rem; */
  background-size: cover;
  /* padding: 5rem 0; */
  transition: all 3s;
  display: flex;
  align-items: center;
}

.application-service-banner:hover {
  transform: scale(1.03);
}

p.application-banner-heading {
  font-size: 60px;
  text-transform: none;
  font-weight: 800;
  color: #000000;
  line-height: 1.1;
}

.application-banner-heading-main {
  color: #000 !important;
}

.application-banner-para-main {
  color: #000 !important;
}

.application-banner-para {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  word-wrap: break-word;
}

/*--------------- application banner button css-------------- */
#play-button-application {
  margin-top: 4rem;
  display: flex;
  align-items: center;
}

#play-button-application-banner {
  position: relative;
}

.app-serv-bnr-play-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  padding: 18px 20px 18px 28px;
  position: absolute;
  margin-left: 35%;
}

.app-serv-bnr-play-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.app-serv-bnr-play-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.app-serv-bnr-play-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.application-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.application-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}

.app-service-inner-banner-bg-btn {
  margin-top: 3.5rem;
}

.app-service-inner-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.app-service-inner-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.app-service-inner-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.app-service-inner-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/*----------------  page indexes section -------------------*/
section.nag-page-index-application {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 9;
}

section.nag-page-index-application.sticky-application {
  position: fixed;
  top: 90px !important;
}

.nav-link-index-application.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-application ul {
  margin-bottom: 0 !important;
  text-align: center;
  padding: 0 !important;
}

.navbar-index-application ul li {
  display: inline-flex;
}

.navbar-index-application ul li:hover,
.navbar-index-application ul li a:hover {
  background-color: #ffffff;
  color: #212121;
}

.navbar-index-application ul li a {
  color: #fff;
  font-size: 18px;
  padding: 8px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* --------- application service section ---------------------- */
.application-ds-expertise {
  background-image: url(../../../../public/assets/img/bpo/under-back.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.application-para-know-how {
  margin-top: 2rem;
}

.application-para-know-how a {
  background: #CD4409;
  padding: 10px 30px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.application-para-know-how a i {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.application-para-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

#application-service-inno-sec {
  margin-top: 1rem;
}

.under-img-right-application {
  width: 100%;
  height: 299px;
  object-fit: cover;
}

.application-triangle-down::after {
  width: 12% !important;
}

.application-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

.application-sr-front-txt {
  width: 95.5%;
  height: max-content;
  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-left: 26px;
  z-index: 1;
  position: relative;
}

.application-sr-front-txt h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.application-sr-front-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 8.6rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.application-sr-front-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.application-sr-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* ---------- application Industries section ------------ */
.hidden-indApp {
  display: none;
}

.visible-indApp {
  display: block;
}

.application-industries-sec {
  background-image: url("../../../../public/assets/img/shape-12-1.png");
  background-repeat: no-repeat;
}

#application-industries-smart {
  border: 2px solid #CD4409;
  background: #fff;
  padding: 10px 10px 0px 10px;
  margin: 2% 2% 0 0;
  position: relative;
  width: 48%;
}

div#application-industries-smart-desc {
  margin: 0;
  padding: 0;
}

#application-industries-smart-desc a {
  text-decoration: none;
}

#application-industries-smart-img img {
  width: 100%;
  height: 13.5rem;
  object-fit: cover;
  margin-bottom: 10px;
}

#application-industries-smart-desc h4 {
  color: #212121;
  font-size: 24px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    color: #CD4409;
  }
}

#application-industries-smart-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 10px 0 0;
}

.application-small-box-down {
  width: 40px;
  height: 40px;
  background: #CD4409;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.application-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

#application-industries-smart:hover .application-small-box-down {
  display: block;
}

/* ---------- smart service section ------------ */
.application-smart-sevices {
  background-image: url(../../../../public/assets/img/bpo/under-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.app-smart-best {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 0px;
  width: 48%;
  margin: 10px;
  position: relative;
}

.app-smart-best .app-smart-best-errow {
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 15px 0 0 0;
  background: #fef2ea;
  position: absolute;
  right: 0;
  bottom: 0;
}

.app-smart-best:hover .app-smart-best-errow {
  background: #CD4409;
}

.app-smart-best:hover .app-smart-best-errow svg {
  color: #fff;
}

.app-smart-best .app-smart-best-errow svg {
  padding: 16px;
  color: #CD4409;
  font-size: 18px !important;
  rotate: 45deg;
}

.app-smart-best-head {
  padding: 20px 20px 30px 30px;
}

.app-smart-best-head a {
  text-decoration: none;
}

.app-smart-best-head h2 {
  color: #CD4409 !important;
  font-size: 42px !important;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #d1d1d1;
}

.app-smart-best-head span {
  color: #14212b !important;
  font-size: 26px !important;
  font-weight: 600 !important;

}

.app-smart-best-head p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  padding-top: 15px;
  line-height: 28px;
}

/* --==================== all sub pages commen section  css ==========================- */
/* ----------- all application sub pages banners css ------------ */
.ads-sub-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 450px;
  display: flex;
  align-items: center;
  align-items: center;
  /* margin-top: 10.5rem; */
}

.ads-sub-banner-mobile-app {
  background-blend-mode: unset !important;

}

.ads-sub-banner-aps-transfer,
.ads-sub-banner-aps-life {
  background-blend-mode: unset !important;
}

.ads-sub-banner-Heading-mobile-app,
.ads-sub-banner-Heading-aps-transfer,
.ads-sub-banner-Heading-aps-life,
.ads-sub-banner-Heading-devops {
  font-size: 55px !important;
}

.ads-sub-banner-web {
  background-blend-mode: unset !important;
  background-position: 0 35% !important;
}

.ads-sub-banner-quality {
  background-blend-mode: unset !important;
  background-position: top !important;

}

.ads-sub-banner-unique,
.ads-sub-banner-devops {
  background-blend-mode: unset !important;
  background-color: unset !important;
}

.ads-sub-banner-Heading {
  color: #14212b;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
}

.ads-sub-banner-Heading-unique {
  text-shadow: 2px 2px 2px #000;
}

.ads-sub-banner-para-unique {
  /* font-size: 22px !important; */
  /* text-shadow: 5px 5px 5px #000000ca; */
  background-color: #00000049;
}

.ads-sub-banner-para {
  color: #000;
  font-size: 18px;
  line-height: 32px;
}

.ads-sub-banner_btn,
.ads-sub-banner-num-btn {
  margin: 3% 0 0 0%;
}

.ads-sub-banner_btn a {
  background: #CD4409;
  padding: 12px 30px;
  font-size: 23px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.ads-sub-banner_btn a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

.ads-sub-banner-num-btn a {
  background: #ffffff;
  padding: 11px 25px;
  font-size: 22px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #CD4409;
  border: 2px solid #CD4409;
}

button.load-more-indus-applic-btn {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  margin-top: 1.5rem;
  line-height: normal;
  border: unset;
}

button.load-more-indus-applic-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid;
}

/* ----------------- all application services sub pages main heading and sub heading  css --------- */
.application-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.application-sub-section-heading span {
  color: #14212b !important;
  /* display: block; */
  font-size: 45px;
  font-weight: 700;
}

.application-sub-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 26px;
}

.application-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

.application-cat-pre-head {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  line-height: 42px;
}

.appser-head-div {
  height: 137px;
  border-left: 5px solid #CD4409;
  padding: 0px 3rem;
  width: 80%;
}

.application-sub-section-heading-spec {
  color: #14212b;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.application-sub-section-heading-spec span {
  color: #CD4409;
}

.application-sub-section-heading-spec .appl-serv-sp {
  display: inline !important;
}

/* -=================================== Design Strategy ======================- */
.design-strategy-appw::after {
  width: 11.4rem !important;
}

.appser-inner-area-customer {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.appser-inner-area-customer:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #CD4409;
}

.appser-inner-area-customer:hover .appser-small-box-down {
  margin-left: 22rem;
}

.appser-inner-area-customer h5 {
  margin-right: 20px;
  color: #14212b;
  font-size: 30px;
  font-weight: 600;
}

.appser-inner-area-customer p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.appser-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.appser-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.appser-inner-area-customer .appser-circle .appser-cir-inr {
  color: #696969 !important;
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
}

.appser-inner-area-customer .appser-circle .appser-cir-inr span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

/* ---------------- Successful Journey section ------------ */
.application-ds-successful-journey {
  margin-top: 4rem;
}

.application-ds-successful-journey .app-juour-item {
  border: #fff !important;
  box-shadow: unset !important;
}

.application-dssjour {
  margin-top: 3rem;
}

.application-ds-successful-journey .accordion-button::after {
  display: none !important;
}

.application-ds-successful-journey .application-dssjour-img img {
  width: 100%;
  height: 370px;
}

.application-ds-successful-journey #app-jour-btn button {
  font-size: 26px;
  padding-left: 0px;
  color: #000;
  box-shadow: none !important;
}

.application-ds-successful-journey #app-jour-btn button span {
  color: #CD4409;
  font-weight: 900;
}

.application-ds-successful-journey .accordion-body p {
  font-size: 18px;
  font-weight: 400 !important;
  color: #14212b;
}

/* -------------- what we think sectio ------------ */
section#application-what-think-first {
  margin-top: -5rem !important;
}

section#application-what-think-seocnd {
  margin-top: 5rem !important;
}

.appser-ds-think-pre::after {
  width: 10.7rem;
  margin-left: -10.7rem;
}

.appser-ds-think-pre-unique::after {
  width: 8.5rem !important;
  margin-left: -8.5rem !important;
}

/* -====================== Agile Transformation =================- */
.agile-trans-appw::after {
  width: 8.7rem !important;
}

/* ------- Corporate Adaptability ---------- */
#appser-head-agile span {
  display: inline-block !important;
  margin-right: 1rem !important;
}

div#agile-corporate-main {
  margin-top: 3rem;
}

.agile-corporate-para h3 {
  color: #14212b;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 40px;
}

.agile-corporate-para p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.agile-corporate-para ul li {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.agile-corporate-content-btn {
  margin-top: 2rem !important;
}

.agile-corporate-content-btn a {
  background: #CD4409;
  padding: 7px 16px;
  margin: 20px 15px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.agile-corporate-content-btn a:hover {
  color: #CD4409 !important;
  background-color: #fff !important;
  outline: auto !important;
}

.agile-corporate-img-div img {
  max-width: 100%;
  height: 20rem;
  margin-top: 4rem;
}

#application-agile-first {
  margin-top: 3rem;
}

.appser-agile-trans-pre::after {
  width: 8.3rem !important;
  margin-left: -8.2rem !important;
}

/* -======================== Application Transformation ================- */
.application-trans-appw::after {
  width: 11.2rem !important;
}

/* -------- business area in AT-------- */
img.app-trans-top-customer {
  margin-right: 2rem;
}

.application-trans-areaser {
  box-shadow: 0px 0px 4px 0px #b5b5b5;
  padding: 44px 0px 40px 30px;
  margin-bottom: 16px;
}

.application-trans-areaser h5 {
  color: #14212b;
  font-size: 25px;
  font-weight: 600;
  margin: 25px 30px 15px 0px;
}

.application-trans-areaser p {
  color: #696969;
  font-size: 18px;
  font-weight: 400;
  margin: 0px 30px 0 0;
}

.application-trans-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #fef2ea;
  display: none;
}

.application-trans-small-box svg {
  padding: 10px;
  color: #CD4409;
  font-size: 20px;
  rotate: -135deg;
  transition: 0.5s;
}

.application-trans-areaser:hover .application-trans-small-box {
  display: block;
}

.application-trans-areaser.app-trans-top-customer {
  width: 100px;
  height: 100px;
}

/* ------------- words from us section --------- */
#app-trans-words-from-us {
  margin-top: 4rem !important;
}

#app-trans-words-from-row {
  margin-top: 2rem;
}

span.app-sub-head-span {
  display: unset !important;
}

.apt-words-from-us-cards {
  margin: 10px;
  width: 48%;
  border: 2px dashed #CD4409;
  border-top: unset;
  padding: 20px 0;
  text-align: center;
  height: max-content;
}

.apt-words-from-us-cards h2 {
  color: #CD4409;
  font-size: 55px;
  font-weight: 800;
}

.apt-words-from-us-cards p {
  color: #14212b;
  font-size: 21px;
  padding: 10px;
}

/* -------------- aplication transformation page what we think ----------------- */
#aps-transformation {
  margin: 4rem 0px;
}

#aps-trans-row {
  margin-top: 2rem;
}

.aps-trans-triangle-down {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-top: 38px solid #14212b;
  position: absolute;
  top: 15.9rem;
  right: 0;
}

.aps-trans-card {
  border: unset;
}

.aps-trans-under-head {
  color: #CD4409;
  font-size: 21px;
  font-weight: 700;
}

.aps-trans-under-para {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.aps-trans-under-txt {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;

}

.aps-trans-under-txt::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #CD4409;
  position: absolute;
  top: 100%;
  left: 0;
}

img.aps-trans-think-card-img {
  height: 15.9rem;
  object-fit: cover;
}

/*  -========================= Quality Engineering page =======================- */
.quality-engineering-appw::after {
  width: 8.2rem !important;
}

/* ----- Our Approach Towards Quality Engineering Process section ---- */
.aps-right-arrow img {
  max-width: 7rem;
}

#aps-quality-eng-process {
  margin-top: 4rem;
}

#aps-quality-eng-process-row {
  margin-top: 2rem;
}

.apo-modernization-num h2 {
  color: #CD4409;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81%;
  margin: 0;
  text-align: left;
}

.apo-modernization-num-para h2 {
  color: #14212b;
  font-size: 26px;
  font-weight: 700;
}

.apo-modernization-num-para p {
  text-align: left;
  color: #14212b;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
}

#aps-quality-eng-process {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* ------------- what we think --------- */
#quality-enng-what-think-first {
  margin-top: 4rem;
}

#aps-transformation-quality-enng {
  margin: 4rem 0 0 0;
}

/* -======================= DevOps page ===================- */
.devops-appw::after {
  width: 7.1rem !important;
}

/* -======================= application lifecycle page ===================- */
.lifecycle-appw::after,
#lifecycle-app-think-cat::after {
  width: 8.7rem !important;
}

.application-services-What-we-do {
  background-size: 100%;
  margin: 3rem 0;
  background-blend-mode: overlay;
  background-color: #ffffff42;
}

.application-services-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.application-services-sub-section-heading-quality {
  font-size: 40px !important;
}

.application-services-sub-section-heading span {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.application-services-sub-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
}

.application-services-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

.application-services-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 16rem;
}



.application-services-sub-video-play-button-under {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 9%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.application-services-sub-video-play-button-under:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.application-services-sub-video-play-button-under:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.application-services-sub-video-play-button-under span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

.application-services-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

#application-services-sub-triangle-down-row-main {
  margin-left: 12px;
}

.application-services-sub-front-txt {
  position: relative;
  z-index: 1;
  width: 94.6%;
  height: max-content;
  padding: 12px 18px 2px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  float: right;
  margin-bottom: 0.8rem;
}

.application-services-sub-front-txt h6 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.application-services-sub-front-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 12.8rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.application-services-sub-front-txt-unique h6::after {
  width: 9rem !important;
}

.application-services-sub-front-txt-bottom h6::after {
  width: 9rem !important;
}

.application-services-sub-front-txt-bottom-second h6::after {
  width: 11.6rem !important;
}

.application-services-sub-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.application-services-sub-front-txt h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

p.application-services-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

img.application-services-success-stories-multi-img {
  width: 100%;
  margin-bottom: 1rem;
  height: 13rem;
}

#application-services-success-stories-multi-data h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: inline-block;
}

#application-services-success-stories-multi-data h6::after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  right: 0;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.app-development-serve-software::after {
  width: 12rem !important;
}

.web-development-serve-software::after {
  width: 12.1rem !important;
}

#application-services-success-stories-multi-data h5 {
  color: #000;
  font-size: 25px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#application-services-success-stories-multi-data p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.application-services-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.application-services-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  /* display: block; */
}

.application-services-head-main-title-span {
  margin-left: 10px;
}

#app-datamig-application-services-about-effect span::after {
  content: unset !important;
}

.application-services-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

#application-services-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#application-services-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#application-services-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#application-services-service-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#application-services-service-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#application-services-service-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#application-services-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#application-services-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#application-services-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

/* ------------- why choose section web app development page ---------- */
.why-choose-web-devlopment-app-content-fst p {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0em;
  color: #333333;
}

.why-choose-web-devlopment-app-content-snd ul {
  list-style: none;
}

.why-choose-web-devlopment-app-content-snd ul li {
  margin-top: 6px;
  font-size: 17px;
  font-weight: 400;
  color: #333333;
}

.why-choose-web-devlopment-app-content-snd ul li::before {
  content: "✓";
  background-color: #CD4409;
  padding: 1px 6px;
  margin-right: 10px;
  font-weight: bold;
  border-radius: 25px;
  color: #fff;
  font-size: 10px;
}

.why-choose-web-app h6 {
  margin-top: 2rem;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.why-choose-web-app h6 span {
  color: #CD4409 !important;
}

/* -------------- how to develop ------------------ */
.develop-web-app-how {
  margin-top: 3rem;
}

.develop-web-app-how h3 {
  color: #CD4409;
  font-weight: 700;
  font-size: 30px;
}

.develop-web-app-how h2 {
  font-weight: 700;
  font-size: 22px;
}

.develop-web-app-how p {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0em;
}

.develop-web-app-how button {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  background-color: unset;
  border: 1px solid #d4d4d4;
  border-radius: 25px;
  padding: 6px 12px;
  margin: 0 5px;
}

.develop-web-app-how .wap-data-number,
.develop-web-app-how .wap-data-title,
.develop-web-app-how .wap-data-desc {
  padding: 2rem 0;
}

.develop-web-app-how .wap-data-title,
.develop-web-app-how .wap-data-desc {
  padding: 2rem 0;
  border-bottom: 2px solid #d4d4d4;
}

/* ---------- custom web app -------------- */
.custom-web-app-how {
  margin-top: 3rem;
}

.custom-web-app-how h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #14212b;
}

.custom-web-app-how p {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  color: #333333;
}

.custom-webapp-content {
  position: relative;
  padding: 5px;
}

.custom-webapp-content::before {
  width: 2px;
  height: 87%;
  content: "";
  position: absolute;
  right: 0;
  background-color: #D4D4D4;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}

.custom-webapp-content::after {
  width: 93%;
  height: 2px;
  left: 0;
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #D4D4D4;
}

/* ---------------- mobile app dev about section ------------ */
.app-dev-about-beno-row {
  margin-top: 3rem;
}

.app-dev-about-beno-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.app-dev-about-beno-content {
  border: 1px solid #d1d1d1;
  padding: 25px 10px;
  text-align: center;
  margin-top: 1.5rem;
}

.app-dev-about-beno-content h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  color: #CD4409;
}

.app-dev-about-beno-content p {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: #14212B;
}

.app-dev-about-beno h6 {
  margin-top: 2rem;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.app-dev-about-beno h6 span {
  color: #CD4409 !important;
}

/* Mobile Devices */
@media only screen and (max-width: 480px) {
  /* --================= application service Main page css  ===================- */

  #application-expertise,
  #application-industries,
  #application-sevices,
  #application-blog,
  #sales-what-we-do,
  #application-services-network-services,
  #application-services-what-think-seocnd,
  .why-choose-web-app,
  .how-we-develop-sec,
  .app-dev-about-beno {
    padding-top: 2rem !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }

  .application-sub-section-heading span::after {
    margin-left: -285px !important;
    width: 125px !important;
    margin-top: -6px !important;
  }

  #smart-platforms-app span::after {
    margin-left: -245px !important;
    width: 105px !important;
  }

  .application-sub-section-heading-spec span::after {
    margin-left: -255px !important;
    width: 135px !important;
    margin-top: 1px !important;
  }

  /* ----------- application service Main page banners css ------------ */
  .application-service-banner {
    margin-top: unset !important;
    height: unset !important;
    max-height: unset !important;
    padding: 3rem 0 !important;
  }

  p.application-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  /* -------play button-------- */
  .application-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .app-serv-bnr-play-btn-main {
    position: unset !important;
    margin-left: 50% !important;
    margin-top: 2rem !important;
  }

  .application-know-how-btn {
    text-align: center !important;
  }

  #play-button-application {
    position: unset !important;
    display: unset !important;
  }

  /*----------------  page indexes section -------------------*/
  .navbar-index-application ul li a {
    font-size: 16px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-application {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-application li {
    margin: 5px !important;
  }

  /*----------------  application service section -------------------*/

  .under-img-right-application {
    margin-top: 1rem !important;
  }

  .application-triangle-down {
    margin-top: 1rem !important;
  }

  .application-sr-front-txt {
    width: unset !important;
    margin-top: 1rem !important;
  }

  .application-sr-front-txt h4 {
    font-size: 24px !important;
  }

  /*------------- application Industries --------------------*/
  #application-industries-smart {
    width: 94% !important;
    margin: 2% 2% !important;
    padding: 10px 0px 0px 10px !important;
    height: max-content !important;
  }

  div#application-industries-smart-desc {
    padding: 0 12px !important;
  }

  #application-industries-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
    height: 12rem !important;
  }

  #application-industries-smart-desc p {
    margin-right: 5px !important;
    margin-bottom: 10px;
    line-height: 24px !important;
    font-size: 16px !important;
  }

  /* --------- smart sevices section ---------- */

  .app-smart-best {
    width: 93% !important;
    margin: 3% !important;
  }

  .app-smart-best h2 {
    font-size: 25px !important;
    padding: 10px 0px;
  }

  .app-smart-best span {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .app-smart-best .app-smart-best-errow {
    width: 45px !important;
    height: 45px !important;
  }

  .app-smart-best .app-smart-best-errow svg {
    padding: 13px !important;
    font-size: 20px !important;
  }

  .app-smart-best .app-smart-best-head {
    padding: 10px 15px;
  }

  .app-smart-best p {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  /* --==================== all application sub pages commen section  css ==========================- */
  /* ----------------- banner section css application sub pages---------------- */
  section.ads-sub-banner {
    margin-top: 0px !important;
    height: unset !important;
    padding-bottom: 3rem !important;
  }

  div#agile-banner-sec {
    margin-top: 7% !important;
  }

  .ads-sub-banner-Heading {
    text-align: center !important;
    font-size: 40px !important;
    line-height: 52px !important;
  }

  p.ads-sub-banner-para {
    font-size: 17px !important;
    line-height: 30px !important;
    text-align: center !important;
  }

  .ads-sub-banner_btn,
  .ads-sub-banner-num-btn {
    text-align: center;
    margin: 1rem 0px 1rem 0px !important;
  }

  .ads-sub-banner-num-btn {
    padding-bottom: 2rem !important;
  }

  /* ------------- all application sub pages main heading and sub heading  css ---------------- */
  .application-sub-section-heading {
    font-size: 30px !important;
    position: relative;
  }

  .application-sub-section-heading span {
    font-size: 30px !important;
    display: inline-block !important;
  }

  .application-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .application-sub-section-heading-spec {
    font-size: 30px;
    position: relative;
    line-height: 37px;
  }

  /* -======================= all application pages business area section ================- */
  section#customer-business-areas {
    margin-top: 1rem !important;
  }

  .appser-inner-area-customer h5 {
    font-size: 25px !important;
    margin-right: 10px !important;
    text-align: center;
  }

  .appser-inner-area-customer p {
    margin-right: 10px !important;
    text-align: center;
  }

  .appser-inner-area-customer {
    padding: 20px 0px 0px 20px !important;
  }

  .appser-small-box-down {
    position: unset !important;
    float: right !important;
  }

  /* ---------------- Successful Journey section ------------ */
  /* .application-dssjour-img{
        display: none;
    } */
  .application-dssjour {
    margin-top: 1rem;
  }

  .application-ds-successful-journey {
    margin-top: 2rem !important;
  }

  .application-ds-successful-journey .application-dssjour .accordion-body p {
    font-size: 18px !important;
  }

  .application-ds-successful-journey #app-jour-btn button {
    font-size: 20px !important;
    padding: 5px 0 !important;
  }

  .appser-head-div {
    height: unset !important;
    padding: 0px 10px !important;
    width: 100% !important;
  }

  /* ------------ Corporate Adaptability --------------- */
  .agile-corporate-para {
    margin-top: unset !important;
  }

  .agile-corporate-para h3 {
    font-size: 26px !important;
    line-height: 35px !important;
  }

  .agile-corporate-para p,
  .agile-corporate-para ul li {
    font-size: 18px !important;
    line-height: 29px !important;
  }

  .agile-corporate-img-div {
    margin-top: 1rem !important;
  }

  .agile-corporate-img-div img {
    max-width: 100% !important;
    height: 18rem !important;
    object-fit: cover;
    margin-top: unset !important;
  }

  /* -======================== Application Transformation ================- */
  /* -------- business area in AT-------- */
  .application-trans-areaser {
    margin: 0 15px 15px 15px;
    width: unset !important;
    padding: 15px 0px 25px 15px !important;
    height: max-content;
  }

  .application-trans-small-box {
    right: 8.6% !important;
    margin-top: -15px !important;
  }

  .application-trans-areaser p {
    line-height: 27px !important;
  }

  /* ------------- words from us section --------- */
  .apt-words-from-us-cards {
    width: 93.7% !important;
  }

  .apt-words-from-us-cards p {
    font-size: 26px !important;
    padding: 0 5px;
  }

  /* ------------- what we think section --------- */
  #aps-transformation {
    margin: 2rem 0px 0 0 !important;
  }

  #aps-trans-row {
    margin-top: 0rem !important;
  }

  img.aps-trans-think-card-img {
    margin-top: 1rem;
  }

  .aps-trans-triangle-down {
    top: 16.9rem !important;
  }

  /* ----- Our Approach Towards Quality Engineering Process section ---- */
  #quality-enng-what-think-first {
    margin-top: 1rem !important;
  }

  #aps-transformation-quality-enng {
    margin: 2rem 0 0 0 !important;
  }

  #aps-quality-eng-process {
    margin-top: 2rem;
  }

  .aps-right-arrow {
    display: none;
  }

  .apo-modernization-num h2 {
    font-size: 36px !important;
    float: left;
    height: 3.5rem !important;
  }

  .apo-modernization-num-para h2 {
    font-size: 22px !important;
  }

  .apo-modernization-num-para p {
    line-height: 21px !important;
  }

  .application-services-sub-section-heading {
    font-size: 30px !important;
    text-align: center !important;
  }

  .application-services-sub-section-heading span {
    font-size: 30px !important;
  }

  .application-services-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
  }

  .application-services-sub-under-img-right {
    margin-top: 0px !important;
  }

  .application-services-sub-video-play-button-under {
    margin-top: 4rem !important;
  }

  .application-services-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 91.5%;
  }

  .application-services-sub-front-txt h4 {
    font-size: 24px !important;
  }

  .application-services-sub-front-txt p {
    line-height: 20px !important;
  }

  #application-services-success-stories-multi-data h5 {
    font-size: 25px !important;
  }

  #application-services-success-stories-multi-data p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .application-services-head-main-title span::after {
    content: unset !important;
  }

  .application-services-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center !important;
    /* display: unset !important; */
  }

  .application-services-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .application-services-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #application-services-service-area-cont {
    height: unset !important;
  }

  .app-service-inner-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .app-service-inner-banner-bg-btn {
    margin-top: 2rem !important;
  }

  .why-choose-web-devlopment-app-content-fst p {
    font-size: 16px !important;
    text-align: center !important;
  }

  .why-choose-web-devlopment-app-content-snd ul li {
    margin-top: 10px !important;
    font-size: 16px !important;
  }

  .why-choose-web-devlopment-app-content-snd ul {
    padding-left: unset !important;
  }

  .why-choose-web-app h6 {
    margin-top: 1rem !important;
  }

  .develop-web-app-how .wap-data-number,
  .develop-web-app-how .wap-data-title,
  .develop-web-app-how .wap-data-desc {
    padding: 1rem 0 0 0 !important;
  }

  .develop-web-app-how {
    margin-top: 0rem !important;
    padding: 1rem !important;
  }

  .develop-web-app-how button {
    padding: 6px 9px !important;
    margin: 8px 5px !important;
    font-size: 14px !important;
  }

  .custom-web-app-how p {
    font-size: 16px !important;
  }

  .custom-web-app-how {
    margin-top: 1rem !important;
  }

  #aps-transformation .card-body {
    height: 190px !important;
  }
}

/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 767px) {

  /* --================= application service Main page css  ===================- */
  #application-expertise,
  #application-industries,
  #application-sevices,
  #application-blog,
  #application-services-network-services,
  #application-services-what-think-seocnd,
  #sales-what-we-do,
  .why-choose-web-app,
  .how-we-develop-sec,
  .app-dev-about-beno {
    padding-top: 2rem !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }

  .application-sub-section-heading span::after {
    margin-left: -399px !important;
    width: 182px !important;
    margin-top: 6px !important;
  }

  #smart-platforms-app span::after {
    margin-left: -332px !important;
    margin-top: 6px !important;
  }

  .application-sub-section-heading-spec span::after {
    margin-left: -275px !important;
    width: 198px !important;
    margin-top: 2px !important;
  }

  .navbar-index-application ul li a {
    padding: 10px 40px !important;
  }

  /* -------------- application service banner css ------------------ */
  .application-service-banner {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset !important;
    padding: 4rem 0 !important;
  }

  #play-button-application-banner,
  .application-know-how-btn {
    width: 50% !important;
  }

  .app-serv-bnr-play-btn-main {
    margin-left: 50% !important;
  }

  p.application-banner-heading {
    text-align: center !important;
    font-size: 40px !important;
  }

  /* --------- digital transformation index pages css -------------- */
  .nav-link-index-application {
    font-size: 27px;
    padding: 3px 13px 3px 13px;
  }

  /* --------- application service section ---------- */
  .application-sr-front-txt {
    width: 95% !important;
  }

  /*------------- application Industries --------------------*/
  #application-industries-smart {
    width: 96% !important;
    margin-bottom: 1rem !important;
    padding: 10px 0px 0px 10px !important;
    height: max-content !important;
    margin: 2% 1% !important;
  }

  div#application-industries-smart-desc {
    padding: 0 12px !important;
  }

  #application-industries-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
    height: 15rem !important;
  }

  #application-industries-smart-desc p {
    margin-right: 10px !important;
    line-height: 28px !important;
    font-size: 18px !important;
    margin-bottom: 15px !important;
  }

  /* --------- smart sevices section ---------- */
  .application-smart-sevices {
    margin: 0rem 0px !important;
  }

  .app-smart-best {
    width: 93.5% !important;
    margin: 3% !important;
  }

  .app-smart-best-head h2 {
    font-size: 40px !important;
    align-items: unset !important;
  }

  .app-smart-best-head span {
    font-size: 29px !important;
  }

  .app-smart-best p {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  /* -------------------- successful journey ------------- */
  .application-dssjour {
    margin-top: 1rem !important;
  }

  .appser-head-div {
    height: unset !important;
    padding: 0px 1rem !important;
    width: 99% !important;
  }

  .application-ds-successful-journey #app-jour-btn button {
    font-size: 25px !important;
    padding: 5px 0 !important;
  }

  .application-ds-successful-journey .application-dssjour-img img {
    height: 22rem !important;
    object-fit: contain !important;
  }

  .application-ds-successful-journey .app-juour-item {
    padding: 0 !important;
    margin: 10px 0 !important;
  }

  /* ----------- what we think section ------------------ */
  section#application-what-think-first {
    margin-top: 0rem !important;
  }

  /* --==================== all application  sub pages commen section  css ==========================- */
  /* ----------------- banner section css application sub pages---------------- */
  .ads-sub-banner {
    margin-top: 0rem !important;
    background-position: center;
  }

  .ads-sub-banner-Heading {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .ads-sub-banner-para {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  .ads-sub-banner_btn {
    width: 45% !important;
    margin-top: 3rem !important;
  }

  .ads-sub-banner-num-btn {
    width: 50% !important;
    margin-top: 3rem !important;
  }

  /* ------------- all application sub pages main heading and sub heading  css ---------------- */
  .application-sub-section-heading {
    font-size: 45px !important;
    text-align: center;
  }

  .application-sub-section-heading span {
    font-size: 42px !important;
  }

  .application-sub-section-heading-spec {
    font-size: 40px !important;
  }

  .application-sub-section-sub-heading {
    font-size: 17px !important;
    line-height: 26px !important;
    text-align: center;
  }

  /* -======================= all application pages business area section ================- */
  #business-areas-cont-appser {
    width: 100% !important;
  }

  .appser-inner-area-customer h5 {
    margin-right: 15px !important;
    font-size: 23px !important;
    text-align: center;
  }

  .appser-inner-area-customer p {
    margin-right: 15px !important;
    text-align: center;
  }

  /* ------------ Corporate Adaptability --------------- */
  .application-ds-successful-journey,
  div#agile-corporate-main {
    margin-top: 2rem !important;
  }

  .agile-corporate-img-div img {
    margin-top: 1rem !important;
  }

  /* -======================== Application Transformation ================- */
  /* -------- business area in AT-------- */
  .application-trans-areaser h5,
  .application-trans-areaser p {
    margin-right: 30px !important;
  }

  .application-trans-areaser {
    box-shadow: 0px 0px 4px 0px #b5b5b5;
    padding: 28px 0px 40px 30px;
    margin-bottom: 16px;
  }

  .application-trans-areaser p {
    line-height: 21px;
    margin-bottom: 10px !important;
  }

  .application-trans-areaser .app-trans-top-customer {
    margin-right: 25px;
  }

  .application-trans-small-box {
    position: unset !important;
    float: right !important;
    width: 40px;
    height: 40px;
    background: #fef2ea;
    display: none;
  }

  /* ------------- words from us section --------- */
  .apt-words-from-us-cards {
    width: 46%;
    height: 12rem;
  }

  .apt-words-from-us-cards p {
    padding: 0 5px;
  }

  /* ----- Our Approach Towards Quality Engineering Process section ---- */
  .aps-right-arrow {
    display: none !important;
  }

  .apo-modernization-num h2 {
    font-size: 48px !important;
    float: right;
    height: 4.5rem !important;
  }

  #quality-enng-what-think-first {
    margin-top: 1rem !important;
  }

  #aps-transformation-quality-enng {
    margin: 3rem 0 1rem 0 !important;
  }

  #aps-quality-eng-process {
    margin-top: 2rem;
  }

  .application-services-sub-section-heading {
    font-size: 40px !important;
    text-align: center !important;
  }

  .application-services-sub-section-heading span {
    font-size: 37px !important;
  }

  .application-services-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .application-services-sub-under-img-right {
    margin-top: 2rem !important;
  }

  .application-sub-section-heading-spec {
    font-size: 40px !important;
  }

  .application-services-head-main-title span {
    display: inline-block !important;
  }

  .application-services-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  .application-services-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 95% !important;
    padding-bottom: 13px;
  }

  .application-services-sub-front-txt p {
    line-height: 19px !important;
  }

  div#application-services-success-stories-multi,
  div#application-services-success-stories-multi-data {
    width: 100% !important;
  }

  #application-services-success-stories-multi-data h5 {
    font-size: 25px !important;
  }

  #application-services-success-stories-multi-data p {
    line-height: 30px !important;
  }

  .application-services-head-main-title span::after {
    width: 119px !important;
    margin-left: -195px !important;
    margin-top: 4px !important;
    height: 29px !important;
  }

  .application-services-head-main-title {
    font-size: 30px !important;
    text-align: center !important;
    display: unset !important;
  }

  .application-services-head-main-title span {
    font-size: 30px !important;
  }

  .application-services-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #application-services-service-area-cont {
    height: unset !important;
  }

  #application-services-service-area-cont {
    height: unset !important;
  }

  .app-service-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }





  .app-service-inner-banner-bg-btn {
    margin-top: 2rem !important;
  }

  .why-choose-web-devlopment-app-content-fst p {
    font-size: 16px !important;
    text-align: center !important;
  }

  .why-choose-web-devlopment-app-content-snd ul li {
    margin-top: 10px !important;
    font-size: 16px !important;
  }

  .why-choose-web-devlopment-app-content-snd ul {
    padding-left: unset !important;
  }

  .why-choose-web-app h6 {
    margin-top: 1rem !important;
  }

  .develop-web-app-how .wap-data-number,
  .develop-web-app-how .wap-data-title,
  .develop-web-app-how .wap-data-desc {
    padding: 1rem 0 0 0 !important;
  }

  .develop-web-app-how {
    margin-top: 0rem !important;
    padding: 1rem !important;
  }

  .develop-web-app-how button {
    padding: 6px 9px !important;
    margin: 8px 5px !important;
    font-size: 14px !important;
  }

  .custom-web-app-how p {
    font-size: 16px !important;
  }

  .custom-web-app-how {
    margin-top: 1rem !important;
  }

  #aps-transformation .card-body {
    height: 190px !important;
  }
}

/* Tablets iPads (Portrait) */
@media (min-width: 768px) and (max-width: 991px) {

  /* --================= application service Main page css  ===================- */
  #application-expertise,
  #application-industries,
  #application-sevices,
  #application-blog,
  #sales-what-we-do,
  #application-services-network-services,
  #application-services-what-think-seocnd,
  .why-choose-web-app,
  .how-we-develop-sec,
  .app-dev-about-beno {
    padding-top: 3rem !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }

  .application-sub-section-heading span::after {
    margin-left: -48px !important;
    width: 172px !important;
    margin-top: -39px !important;
  }

  #smart-platforms-app span::after {
    margin-left: -318px !important;
    margin-top: 0px !important;
  }

  .application-sub-section-heading-spec span::after {
    margin-left: -331px !important;
    width: 188px !important;
    margin-top: 10px !important;
  }

  /* -------------- application service banner css ------------ */
  .application-service-banner {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset !important;
    padding: 4rem 0 !important;
  }

  #play-button-application-banner,
  .application-know-how-btn {
    width: 50% !important;
  }

  .app-serv-bnr-play-btn-main {
    margin-left: 50% !important;
  }

  .navbar-index-application ul li a {
    padding: 10px 22px !important;
  }

  .application-banner-para {
    text-align: center;
  }

  p.application-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  /* --------- application service ---------- */
  .application-sr-front-txt {
    width: unset !important;
  }

  .application-sub-section-heading span {
    display: inline !important;
    margin-left: 10px !important;
    font-size: 40px !important;
  }

  .app-smart-best-head {
    padding: 15px 20px !important;
  }

  /*------------- application Industries --------------------*/
  div#application-industries-smart-desc,
  div#application-industries-smart-img {
    width: 100% !important;
  }

  div#application-industries-smart-desc {
    padding: 0 12px !important;
  }

  #application-industries-smart {
    padding: 10px 0px 0px 10px !important;
    height: 22.5rem !important;
    margin: 2% 1% !important;
    width: 47% !important;
  }

  #application-industries-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
  }

  #application-industries-smart-desc p {
    margin-right: 7px !important;
    line-height: 25px !important;
    font-size: 16px !important;
  }

  /* --------- smart sevices section ---------- */

  .app-smart-best {
    width: 48% !important;
    margin: 1% !important;
  }

  .app-smart-best-head h2 {
    font-size: 35px !important;
  }

  .app-smart-best-head span {
    font-size: 20px !important;
  }

  .app-smart-best p {
    font-size: 17px !important;
    line-height: 26px !important;
  }

  /* -------------------- successful journey ------------- */
  .application-sub-section-heading-spec {
    font-size: 40px !important;
  }

  .appser-head-div {
    height: unset !important;
    padding: 0px 2rem !important;
    width: 95% !important;
  }

  .application-ds-successful-journey #app-jour-btn button {
    font-size: 26px !important;
    padding: 5px 0px !important;
  }

  .app-dev-about-beno-content {
    height: 180px !important;
  }

  .app-dev-about-beno-image img {
    height: 430px !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css application sub pages---------------- */

  .ads-sub-banner {
    margin-top: 0 !important;
    height: unset !important;
    padding-bottom: 3rem;
  }

  .ads-sub-banner-Heading {
    font-size: 55px !important;
    line-height: 60px !important;
  }

  .ads-sub-banner-para {
    font-size: 22px !important;
    line-height: 32px !important;
    margin-top: 1rem !important;
  }

  .ads-sub-banner_btn {
    width: 45% !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .ads-sub-banner-num-btn {
    width: 55% !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  /* ------------- all application sub pages main heading and sub heading  css ---------------- */
  .application-sub-section-heading {
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .application-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  /* -======================= all application pages business area section ================- */
  section#customer-business-areas {
    margin-top: 2rem !important;
  }

  div#business-areas-cont-appser {
    width: 50%;
  }

  .appser-inner-area-customer h5 {
    font-size: 23px !important;
  }

  /* ----------- what we think section ------------------ */
  section#application-what-think-first {
    margin-top: 2rem !important;
  }

  section#application-what-think-seocnd {
    margin-top: 1rem !important;
  }

  /* ------------ Corporate Adaptability --------------- */
  .application-ds-successful-journey,
  div#agile-corporate-main {
    margin-top: 2rem !important;
  }

  .agile-corporate-para h3 {
    font-size: 24px !important;
    margin-bottom: unset !important;
    line-height: 40px !important;
  }

  .agile-corporate-para p,
  .agile-corporate-para ul li {
    font-size: 18px !important;
    line-height: 29px !important;
  }

  /* -======================== Application Transformation ================- */
  /* -------- business area in AT-------- */
  .application-trans-areaser h5 {
    font-size: 20px !important;
    margin: 25px 10px 15px 0px !important;
  }

  .application-trans-areaser .app-trans-top-customer {
    margin-right: 10px;
  }

  .application-trans-areaser p {
    font-size: 17px !important;
    margin: 0px 10px 0 0 !important;
  }

  .application-trans-areaser {
    padding: 15px 0px 40px 10px !important;
  }

  /* ------------- words from us section --------- */
  .dgo-enforcement-cards {
    width: 30% !important;
  }

  .apt-words-from-us-cards {
    width: 46%;
    height: 12rem;
  }

  /* ----- Our Approach Towards Quality Engineering Process section ---- */
  .aps-right-arrow {
    display: none;
  }

  .apo-modernization-num h2 {
    font-size: 48px !important;
    float: right;
    height: 2.6rem !important;
  }

  #quality-enng-what-think-first {
    margin-top: 2rem !important;
  }

  #aps-transformation-quality-enng {
    margin: 3rem 0 2rem 0 !important;
  }

  #aps-quality-eng-process {
    margin-top: 2rem;
  }

  .application-services-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 97%;
  }

  .application-services-sub-front-txt p {
    line-height: 19px !important;
  }

  #application-services-success-stories-multi-data h5 {
    font-size: 22px !important;
  }

  #application-services-success-stories-multi-data p {
    line-height: 28px !important;
  }

  .application-services-head-main-title span::after {
    width: 143px !important;
    margin-left: -227px !important;
    margin-top: 6px !important;
    height: 32px !important;
  }

  .application-services-head-main-title,
  .application-services-head-main-title span {
    font-size: 45px !important;
  }

  .develop-web-app-how {
    margin-top: 0rem !important;
  }

  .why-choose-web-app h6 {
    font-size: 30px;
  }

  .application-services-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #application-services-service-area-cont-img {
    width: 100% !important;
  }

  .application-services-service-area-cont-desc {
    width: 100% !important;
  }

  #application-services-service-area-cont-desc {
    width: 100% !important;
  }

  #application-services-service-area-cont-img {
    width: 100% !important;
  }

  .application-services-service-area-cont-desc {
    width: 100% !important;
  }

  #application-services-service-area-cont,
  #application-services-service-area-cont-desc {
    width: 100% !important;
  }

  #application-services-service-area-cont {
    height: unset !important;
  }

  .app-service-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .application-para-know-how a {
    padding: 10px 20px;
  }

  .application-services-sub-under-img-right {
    margin-top: 2rem !important;
  }

  .application-sub-section-heading-spec {
    font-size: 45px !important;
  }
}

/* Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1200px) {

  /* --================= application service Main page css  ===================- */
  .application-sub-section-heading span::after {
    margin-left: -43px !important;
    width: 222px !important;
    margin-top: -47px !important;
  }

  #smart-platforms-app span::after {
    margin-top: 24px !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }

  /* -------------- application service banner css ------------------ */
  .application-service-banner {
    height: unset !important;
    max-height: unset !important;
    padding: 4rem 0 !important;
  }

  .application-banner-front {
    margin-top: 2rem !important;
  }

  .application-banner-front {
    width: 80% !important;
  }

  p.application-banner-heading {
    font-size: 60px !important;
  }

  .navbar-index-application ul li a {
    padding: 10px 40px !important;
  }

  .navbar-index-application ul li a {
    color: #fff;
    font-size: 20px;
    padding: 10px 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  /*------------- application Industries --------------------*/
  div#application-industries-smart-desc,
  div#application-industries-smart-img {
    width: 100% !important;
  }

  div#application-industries-smart-desc {
    padding: 0 12px !important;
  }

  #application-industries-smart {
    padding: 10px 0px 0px 10px !important;
    height: 23.1rem !important;
  }

  #application-industries-smart-img img {
    width: 98% !important;
    object-fit: cover;
    margin-right: unset !important;
  }

  #application-industries-smart-desc p {
    margin-right: 10px !important;
  }

  /* --------- smart sevices section ---------- */
  .application-smart-sevices {
    margin: 3rem 0px !important;
  }

  .app-smart-best {
    width: 48% !important;
    margin: 1% !important;
  }

  .app-smart-best-head h2 {
    font-size: 45px !important;
  }

  .app-smart-best-head span {
    font-size: 24px !important;
  }

  .app-smart-best p {
    font-size: 18px !important;
    line-height: 32px !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css application sub pages---------------- */
  .ads-sub-banner_btn {
    width: 40% !important;
    margin-top: 3rem !important;
  }

  .ads-sub-banner-num-btn {
    width: 60% !important;
    margin-top: 3rem !important;
  }

  .ads-sub-banner-Heading {
    font-size: 46px !important;
  }

  /* ------------- all application sub pages main heading and sub heading  css ---------------- */
  .application-sub-section-heading span {
    display: inline !important;
    margin-left: 10px !important;
  }

  /* -================ application service area section ===============- */
  .appser-inner-area-customer h5 {
    font-size: 23px !important;
  }

  /* ----------- what we think section ------------------ */
  section#application-what-think-first {
    margin-top: 1rem !important;
  }

  section#application-what-think-seocnd {
    margin-top: 2rem !important;
  }

  /* ------------ Corporate Adaptability --------------- */
  .appser-head-div {
    padding: 0px 1rem !important;
    width: 99% !important;
    height: unset !important;
  }

  .agile-corporate-para p,
  .agile-corporate-para ul li {
    font-size: 19px !important;
    line-height: 30px !important;
  }

  /* -======================== Application Transformation ================- */
  /* -------- business area in AT-------- */
  .application-trans-areaser {
    padding: 44px 0px 40px 20px !important;
  }

  .application-trans-areaser .app-trans-top-customer {
    margin-right: 20px;
  }

  /* ------------- words from us section --------- */
  .dgo-enforcement-cards {
    width: 30% !important;
  }

  .apt-words-from-us-cards {
    width: 47% !important;
  }

  /* ----- Our Approach Towards Quality Engineering Process section ---- */
  .aps-right-arrow img {
    max-width: 8rem;
  }

  .apo-modernization-num h2 {
    font-size: 60px;
    float: right;
  }

  .application-services-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  .application-services-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 94.4% !important;
  }

  .application-services-sub-front-txt p {
    line-height: 19px !important;
  }

  #application-services-success-stories-multi-data h5 {
    font-size: 27px !important;
  }

  .application-services-head-main-title span::after {
    margin-left: -290px !important;
    margin-top: 9px !important;
  }

  .application-services-head-main-title {
    font-size: 40px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {

  /* --==================== all application sub pages commen section  css ==========================- */
  /* ----------------- banner section css application sub pages---------------- */
  .ads-sub-banner_btn {
    width: 30% !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .ads-sub-banner-num-btn {
    width: 70% !important;
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }

  /* -================ application service area section ===============- */
  .appser-inner-area-customer {
    padding: 15px 0px 0px 15px !important;
  }

  /* ------------ Corporate Adaptability --------------- */
  .appser-head-div {
    padding: 0px 1rem !important;
    width: 99% !important;
    height: unset !important;
  }

  .application-services-sub-triangle-down {
    border-left: 28px solid transparent !important;
  }
}

@media screen and (min-width: 1400px) {
  .application-services-sub-front-txt {
    width: 95.6% !important;
  }

  #app-srvice-head-bg span::after {
    content: none !important;
  }

  #app-srvice-head-bg::after {
    content: none !important;
  }
}

#aps-transformation .card-body {
  height: 140px;
}


/* service banner */
/* service banner */
/* service banner */
/* service banner */
/* service banner */
/* service banner */

.service-banner {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 5s ease-out;

  &:hover {
    transform: scale(1.03);
  }
}

.service-banner-content {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  height: 100%;
  padding-left: 4rem;

  >h1 {
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    /* border: 3px solid #CD4409;
    border-right: none;
    padding: 1rem 0 1rem 3rem; */
  }

  >p {
    font-size: 18px;
  }
}

.service-banner-button {
  display: flex;
  gap: 1rem;

  >a {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 25px;
    transition: all 0.6s ease-in-out;
    border: 2px solid #CD4409;


    &:first-of-type {
      background-color: #fff;
      color: #CD4409;

      &:hover {
        color: #fff;
        background-color: #CD4409;
      }
    }

    &:last-of-type {
      background-color: #CD4409;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #CD4409;
      }
    }
  }
}

.head-text-black {
  color: #14212b !important;
}

.para-text-black {
  color: #14212b !important;
}

@media screen and (max-width: 480px) {
  .service-banner {
    height: 350px !important;
    margin-top: 0 !important;
  }

  .service-banner-content {
    width: 100% !important;
    padding: 1rem !important;
    align-items: center;
    gap: 1rem !important;

    >h1 {
      font-size: 30px !important;
      text-align: center !important;
    }

    >p {
      font-size: 16px !important;
      text-align: center !important;
    }
  }

}


@media (min-width: 481px) and (max-width: 767px) {
  .service-banner {
    height: 400px !important;
    margin-top: 0 !important;
  }

  .service-banner-content {
    width: 100% !important;
    padding: 2rem !important;
    align-items: center;
    gap: 1.5rem !important;

    >h1 {
      font-size: 35px !important;
      text-align: center !important;
    }

    >p {
      font-size: 17px !important;
      text-align: center !important;
    }
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .service-banner {
    margin-top: 0 !important;
    height: 450px !important;
  }

  .service-banner-content {
    width: 100% !important;
    padding: 3rem !important;
    align-items: center;
    gap: 2rem !important;

    >h1 {
      font-size: 45px !important;
      text-align: center !important;
    }

    >p {
      font-size: 18px !important;
      text-align: center !important;
    }
  }

}

@media (min-width: 992px) and (max-width: 1024px) {
  .service-banner {
    height: 80vh !important;
  }

  .service-banner-content {
    width: 70% !important;
    padding: 3rem !important;
    gap: 2rem !important;

    >h1 {
      font-size: 45px !important;
    }

    >p {
      font-size: 18px !important;
    }
  }

}


@media (min-width: 1025px) and (max-width: 1200px) {
  .service-banner {
    height: 85vh !important;
  }

  .service-banner-content {
    width: 70% !important;
    padding: 3rem !important;
    gap: 2rem !important;

    >h1 {
      font-size: 55px !important;
    }

    >p {
      font-size: 20px !important;
    }
  }

}