/* -======================== main Bpo service page ================- */
a {
  text-decoration: none;
}


.bpo-lpo-banner {
  background-position: top !important;
}

.bpo-serv-banner-title::after {
  margin-left: -348px !important;
  width: 276px !important;
  margin-top: 26px !important;
}

.bpo-serv-our-segments-heading h2::after {
  margin-left: -398px !important;
}

#bpo-heading-ntw::after {
  margin-left: -273px !important;
  width: 184px !important;
}

#bpo-heading-hpn::after {
  width: 184px !important;
  margin-left: -34.2rem !important;
}

.recent-blogs-title-main::after {
  width: 197px !important;
  margin-left: -18.2rem !important;
  margin-top: 24px !important;
}

.understand-head-bg span::after {
  width: 168px !important;
  margin-left: -242px !important;
  margin-top: 11px !important;
}

.service-area-head-bg span::after {
  width: 169px !important;
  margin-left: -457px !important;
  margin-top: 9px !important;
}

.why-we-head-bg span::after {
  width: 168px !important;
  margin-left: -468px !important;
  margin-top: 12px !important;
}

.sales-bg-head span::after {
  width: 103px !important;
  margin-left: -100px !important;
  margin-top: 11px !important;
}

#expertise,
#services,
#industries,
#why-us,
#our-blog {
  padding-top: 5rem !important;
}

#bpo-serv-wedo-content1,
#bpo-serv-wedo-content2,
#bpo-serv-wedo-content3,
#bpo-serv-wedo-content4,
#bpo-serv-wedo-content5,
#bpo-serv-wedo-content6,
#bpo-serv-wedo-content7,
#load-more-content,
#load-more-content-second,
#load-more-content-second-next {
  display: none;
}

.bpo-serv-segments_box.hidden-segments,
.hidden-wedo_box {
  display: none;
}

.bpo-serv-segments_box.visible-segments,
.visible-wedo_box {
  display: block;
}

#bpo-serv-wedo-mless-btn1,
#bpo-serv-wedo-mless-btn2,
#bpo-serv-wedo-mless-btn3,
#bpo-serv-wedo-mless-btn4,
#bpo-serv-wedo-mless-btn5,
#bpo-serv-wedo-mless-btn6,
#bpo-serv-wedo-mless-btn7,
#bpo-serv-wedo-mless-btn8 {
  border: unset !important;
  background: #fff !important;
  color: #CD4409 !important;
  font-size: 17px;
  font-weight: 600;
}

#bpo-serv-wedo-mless-btn:hover,
#bpo-serv-wedo-mless-btn-se:hover,
#bpo-serv-wedo-mless-btn-th:hover,
#bpo-serv-wedo-mless-btn-fo:hover,
#bpo-serv-wedo-mless-btn-fiv:hover,
#bpo-serv-wedo-mless-btn-six:hover,
#bpo-serv-wedo-mless-btn-sev:hover,
#bpo-serv-wedo-mless-btn-egt:hover {
  color: #262626 !important;
}

/* ------- banner css -------------- */
section#bpo-service-banner {
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  width: 100%;
  height: 450px;
  /* margin-top: 10.6rem; */
  transition: all 3s;
  display: flex;
  align-items: center;
}

section#bpo-service-banner:hover {
  transform: scale(1.03);
}

.bpo-serv-banner-title {
  font-size: 65px;
  font-weight: 800;
  color: #14212b;
  margin-top: 50px;
  position: relative;
  line-height: 1.1;
}

.bpo-serv-banner_desc {
  color: #14212b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

div#bpo-serv-banner-col-first {
  margin-top: 8%;
  margin-left: 4%;
  width: 11%;
}

@keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}

.bpo-serv-banner-work-btn {
  margin: 2rem 0 0 0;
}

.bpo-serv-banner-work-btn a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff !important;
}

.bpo-serv-banner-work-btn a svg {
  padding: 4px 5px 0 5px;
}

.bpo-serv-banner-work-btn a:hover {
  color: #CD4409 !important;
  background-color: #fff;
  border: 3px solid #CD4409;
}

.bpo-inner-banner-bg-btn {
  margin-top: 3.5rem;
}

.bpo-inner-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.bpo-inner-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.bpo-inner-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.bpo-inner-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* ------ index section bpo -------- */
section.bpo-serv-page-index {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 90px;
  width: 100%;
  z-index: 9;
}

.sticky-bpo {
  position: fixed;
  top: 0;
}

.bpo-serv-ind-item.active {
  background-color: white;
  color: #CD4409;
}

.bpo-serv-indexing-nav ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.bpo-serv-indexing-nav ul li {
  display: inline-flex;
}

.bpo-serv-indexing-nav ul li:hover,
.bpo-serv-indexing-nav ul li a:hover {
  background-color: #ffffff;
  color: #CD4409;
}

.bpo-serv-indexing-nav ul li a {
  color: #fff;
  font-size: 17px;
  padding: 10px 50px 10px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/*------------ bpo service  Expertise section css --------------*/
.bpo-serv-expertise {
  width: 100%;
}

.bpo-serv-expertise-pre-haad {
  color: #14212b;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 0 !important;
}

.shape-down-bg-bpo img {
  position: absolute;
  margin-top: -118px;
  z-index: -99999;
  max-width: 100%;
}

#bpo-serv-expertise-cont-fluid {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
}

.bpo-serv-expertise-title h2 {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.bpo-serv-segments-row {
  margin-top: 2rem;
}

.bpo-serv-expertise-desc {
  color: #14212b;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

#bpo-serv-expertise-card-row {
  margin: 5rem 0 3rem 0;
}

.bpo-serv-expertise-service_box {
  margin: 10px;
  padding: 12px 0 15px 10px;
  width: 31%;
  clip-path: polygon(0 17%, 100% 0%, 100% 82%, 0% 100%);
  background-color: #fef2ea !important;
}

div.bpo-serv-expertise-service_box h6 {
  color: #14212b;
  font-size: 24px;
  margin-top: 60px;
  font-style: normal;
  margin-left: 15px !important;
  font-weight: 600;
  line-height: normal;
}

div.bpo-serv-expertise-service_box p {
  color: #262626 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 20px;
  margin-left: 15px !important;
  line-height: normal;
}

.bpo-serv-expertise-service_box img {
  margin-bottom: 13px !important;
  margin-left: 15px !important;
}

.bpo-serv-expertise-small {
  width: 70px;
  height: 50px;
  margin-top: -12px;
  margin-right: -0.2px;
  background: #CD4409;
  float: right;
  opacity: 0;
  clip-path: polygon(0 30%, 100% 0%, 100% 60%, 0% 90%);
  border-radius: 2px;
}

.bpo-serv-expertise-service_box:hover:after {
  content: "";
  height: 340px;
  width: 3px;
  margin-top: -17rem;
  float: right;
  background: #CD4409;
}

.bpo-serv-expertise-service_box:hover .bpo-serv-expertise-small {
  opacity: 1;
}

/*--------------- bpo service segments css what we do --------------*/

span.shadow-img-segments-right {
  float: right;
  margin-top: 50px;
}

span.shadow-img-segments-left {
  float: left;
  margin-top: 400px;
}

.shadow-img-segments-right img,
.shadow-img-segments-left img {
  width: 99px;
  height: 100% !important;
}

.bpo-serv-our-segments-heading h2 {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.bpo-serv-our-segments-desc {
  color: #14212b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.bpo-serv-segments-card {
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  border: unset !important;
  height: 350px;
  text-align: center;
}

.bpo-serv-segments_box {
  margin-bottom: 2rem;
}

.bpo-serv-segments-card figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

figure.bpo-serv-segments-effect-steve img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  object-fit: cover;
}

figure .bpo-serv-segments-intro-img {
  position: absolute;
  bottom: -1px;
  width: 90%;
  height: 40%;
  margin-left: 5% !important;
}

figure.bpo-serv-segments-effect-steve h2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #212121;
  line-height: normal;
  text-transform: capitalize;
  word-spacing: -0.15em;
  background: #ffffff;
}

figure.bpo-serv-segments-effect-steve p {
  padding: 0.5em;
  opacity: 0;
  font-size: 15px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  line-height: 21px;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 10px 11px !important;
  margin: 0;
  height: 100% !important;
}

figure.bpo-serv-segments-effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.bpo-serv-segments-effect-steve:hover p {
  opacity: 1;
}

.btn-div-segments {
  margin-top: 2rem;
}

.load-more-segments-btn {
  border-radius: 33px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
}

.load-more-segments-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ------------- bpo service industries -------------- */
#industries {
  margin-top: 0rem !important;
}

.bpo-serv-what_wedo-innr {
  position: relative;
  width: 100% !important;
  border: none !important;
  overflow: hidden;
}

.bpo-serv-what_wedo-innr a {
  text-decoration: none;
}

.bpo-serv-what_wedo_box {
  margin-bottom: 2rem;
}

.bpo-serv-what_wedo-innr img {
  width: 100%;
  height: 250px;
  border: 5px solid #fef2ea;
  object-fit: cover;
  transition: 0.5s;
}

.bpo-serv-what_wedo-innr img:hover {
  border: 5px solid #CD4409;
}

h5#bpo-serv-wedo-card-title {
  color: #14212b;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

}

h5#bpo-serv-wedo-card-title:hover {
  color: #CD4409;
}

p#bpo-serv-wedo-card-desc {
  color: #14212b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 26px;
}

/*----------- happen section css  ------------*/
#bpo-serv-happens-row-main {
  margin-top: 3rem;
}

.bpo-serv-happens-sec {
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
}

div#bpo-serv-happens-row-second {
  margin-top: 1rem;
}

.bpo-serv-happens-box-left-col {
  padding-right: 6rem;
}

h2.bpo-serv-happens-box-head {
  color: #14212b;
  margin-top: 25px;
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
}

.bpo-serv-happens-box-head span {
  color: #CD4409;
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
}

p.bpo-serv-happens-box-desc {
  color: #14212b;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

p.bpo-serv-happens-box-desc ul {
  margin-top: 2rem;
  font-weight: 700;
}

.bpo-serv-happens-box-right-img {
  width: 26.5rem;
  height: 24rem;
  object-fit: cover;
  border: 12px solid #fef2ea;
  background-size: cover;
}

.bpo-serv-happens-box-back-img {
  width: 26.5rem;
  height: 24rem;
  border: 12px solid #fef2ea;
  background-blend-mode: overlay;
  padding: 26%;
  background-size: cover;
  background-color: #c5d9cd;
}

.bpo-serv-happens-box-vid-play {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 25%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.bpo-serv-happens-box-vid-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.bpo-serv-happens-box-vid-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.bpo-serv-happens-box-vid-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border-heppens {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

p.bpo-serv-happens-box-btn-title {
  color: #14212b;
  font-size: 24px;
  margin-top: -16px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.bpo-serv-happens-box-left-col-second {
  padding-left: 2rem;
}

#bpo-serv-happens-row-main-second {
  margin: 2rem 0;
}




/* --==================== all sub pages commen section  css ==========================- */
/* ----------- all sub pages banners css ------------ */
.bpo-sub-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  /* background-color: #e7f0eb9c; */
  background-position: center center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10.5rem;
}

.bpo-insurence-sub-banner {
  background-position: top !important;
}

.bpo-sub-banner-Heading {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
}

.bpo-health-sub-banner-Heading,
.bpo-health-sub-banner-para,
.bpo-insurence-sub-banner-para {
  color: #14212b !important;
}

.bpo-insurence-sub-banner-Heading {
  font-size: 55px !important;
  color: #14212b !important;
}

.bpo-sub-banner-para {
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.bpo-sub-banner_btn,
.bpo-sub-banner-num-btn {
  margin: 3% 0 0 0%;
}

.bpo-sub-banner_btn a {
  background: #CD4409;
  padding: 12px 30px;
  font-size: 23px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.bpo-sub-banner_btn a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

.bpo-sub-banner-num-btn a {
  background: #ffffff;
  padding: 11px 25px;
  font-size: 22px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #CD4409;
  border: 2px solid #CD4409;
}

/* ----------------- all sub pages main heading and sub heading  css --------- */
.bpo-sub-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.bpo-sub-section-heading span {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.bpo-sub-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
}

.bpo-sub-section-sub-heading ul li span {
  font-weight: 600;
  text-align: left;
}

/* ---------------  blogs section heading and sub heading and latest one blog css --------- */
.recent-blogs-title-main {
  color: #000;
  font-size: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.recent-blogs-desc-main {
  color: #262626;
  text-align: center;
  margin: 1rem 0 2rem 0;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

/* ---------- all sub pages Service Areas section  css ------------ */
#bpo-sub-service-areas-cont-first {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  margin: 2% 2% 0 0;
  height: 15rem;
  position: relative;
  width: 48%;
}

#bpo-sub-service-areas-cont-first:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#bpo-sub-service-areas-cont-first:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#bpo-sub-service-areas-cont-first-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#bpo-sub-service-areas-cont-first-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#bpo-sub-service-areas-cont-first-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}



/* ------------------------- BPO service what-we-do section -------------------- */
.bpo-What-we-do {
  background-size: 100%;
  background-blend-mode: overlay;
  background-color: #ffffff42;
}

.bpo-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 20rem;
}

.bpo-sub-video-play-button-under {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 9%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.bpo-sub-video-play-button-under:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.bpo-sub-video-play-button-under:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.bpo-sub-video-play-button-under span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

p.bpo-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.bpo-sub-front-txt {
  position: relative;
  z-index: 1;
  width: 94.6%;
  height: max-content;
  padding: 12px 18px 2px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  float: right;
  margin-bottom: 0.8rem;
}

.bpo-sub-front-txt h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

img.doi-understand-img-do {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.bpo-sub-front-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 12.8rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.bpo-sub-front-txt-unique::after {
  width: 4rem !important;
}

.legel-bpo-sub-heading::after {
  width: 10.9rem !important;
}

.doi-bpo-sub-heading::after {
  width: 11.7rem !important;
}

.health-bpo-sub-head::after {
  width: 8.6rem !important;
}

.finance-bpo-sub-head::after {
  width: 9.8rem !important;
}

.insurance-bpo-sub-head::after {
  width: 4rem !important;
}

.talent-bpo-sub-heade::after {
  width: 16rem !important;
}

.bpo-sub-front-txt h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.bpo-sub-front-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.bpo-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

/* {----------------------- work with us section  -----------------------} */
.bpo-sub-work-with-us {
  padding-top: 3rem;
}

.bpo-sub-why-box-content {
  margin: 10px;
  padding: 10px;
  width: 48%;
  border: 1px solid #d1d1d1;
  border-bottom: 5px solid #CD4409;
}

.bpo-sub-why-box-content h2 {
  color: #CD4409;
  font-size: 55px;
  font-weight: 800;
  text-align: center;
}

.bpo-sub-why-box-content p {
  color: #333;
  font-size: 17px;
  text-align: center;
}

/* ----------- bpo sub section case study ------------- */
#doi-efficiency-row {
  margin-top: 2rem;
}

img.doi-efficiencyes {
  width: 100%;
  margin-bottom: 1rem;
  height: 13rem;
  object-fit: cover;
}

#doi-maximum-efficiency-second h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

#doi-maximum-efficiency-second h6::after {
  position: absolute;
  content: "";
  margin-left: -7.8rem;
  margin-top: 1.2rem;
  width: 7.8rem;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.risk-management-doi::after {
  margin-left: -7.4rem !important;
  width: 7.5rem !important;
}

.operational-efficiency-doi::after {
  margin-left: -9rem !important;
  width: 9rem !important;
}

#doi-maximum-efficiency-second h5 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#doi-maximum-efficiency-second p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.talent-maximum-efficiency-head::after {
  width: 7rem !important;
  margin-left: -7rem !important;
}

.hr-solution-stremling::after {
  width: 4.7rem !important;
  margin-left: -4.6rem !important;
}

.compliance-centric-finance::after {
  width: 12.2rem !important;
}

/* ----------- bpo sub section Key-factors ------------- */
.bussness-img-fifth {
  width: 100%;
  height: 410px;
  border: 10px solid #ffffff;
  padding: 26%;
  background-size: cover;
  box-shadow: 0px 0px 4px 0px #cccccc;
}


.talent-deliver-content h4 {
  color: #262626;
  font-size: 30px;
  font-weight: 600;
}

.talent-deliver-content p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

.talent-deliver-content ul li {
  color: #262626;
  font-size: 17px;
  font-weight: 700;
  line-height: 40px;
}

/* --==================== sales page css ==========================- */
/* all section padding top */
#sales-what-we-do,
#sales-service-areas,
#sales-work-with-us,
#sales-state-sec,
#finance-what-we-do,
#finance-service-areas,
#health-what-we-do,
#health-service-areas,
#health-why-work,
#doi-service-areas,
#doi-benefits,
#doi-case-study,
#legel-what-we-do,
#legel-service-areas,
#legel-work-with-us,
#operations-designed,
#services {
  padding-top: 4rem !important;
}

/* ---------- state of sales ----------------- */

.state-lower-img {
  width: 100%;
  height: 175px;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #e7f0eb9c;
}

.state-img-sec {
  padding: 6px 6px 4px 6px;
  width: 47%;
  height: 13.5rem;
  border: 1px solid #d1d1d1;
  /* margin-left: 13px; */
  height: max-content;
}

.state-ml-left {
  margin-left: 11px;
}

#bpo-sub-triangle-down-row-main {
  margin-left: 12px;
}

.current-head {
  font-size: 17px;
  text-align: center;
  margin-top: 3rem;
  padding-top: 20px;
  font-weight: 600;
}

.state-top {
  margin-bottom: 10px;
}

.sales-state-video-play {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 6.3rem;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  text-align: justify;
  padding: 6px 0 0 18px;
}

.sales-state-video-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.sales-state-video-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.sales-state-video-play span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

.sales-videoplay {
  margin-top: -5.8rem;
}

.service-area-br,
.work-with-us-br {
  display: none;
}

/* --==================== talent page css ==========================- */
.talent-we-can-help-bg br,
.talent-key-factors-bg br {
  display: none;
}

/* talent page all section padding top */
#talent-what-we-do,
#talent-service-areas,
#talent-management-inr,
#deliver-sec-business,
#talent-what-we-think,
#maximum-efficiency {
  padding-top: 4rem !important;
}



.talent-what-we-head-bg::after {
  width: 168px !important;
  margin-top: -44px !important;
}

.talent-we-can-help-bg::after {
  width: 180px !important;
  margin-left: -4px !important;
  margin-top: -44px !important;
}

.talent-key-factors-bg::after {
  width: 227px !important;
  margin-top: -44px !important;
}

.talent-what-we-think-bg::after {
  width: 112px !important;
}

/* ---------------section Management ------------------- */

.inr-sec-ment {
  align-items: center;
  background-blend-mode: overlay;
  background-color: #e7f0eb9c;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  min-height: 25rem;
  object-fit: cover;
}

.talent-inr-Values {
  background-color: #fff;
  box-shadow: 0 0 4px 0 #bdbbbb;
  margin-top: -130px;
  padding: 30px 0;
}

.talent-inr-Values:hover {
  background-color: #CD4409;
  fill: #fff;
}

.talent-value-inr-sec.text-center svg {
  fill: #d1d1d1 !important;
  width: 100px;
  height: 90px;
}

.talent-inr-Values:hover .talent-value-inr-sec.text-center svg {
  fill: #fff !important;
}

.talent-value-inr-sec h4 {
  text-align: center;
  font-size: 24px;
  margin-top: 15px;
  color: #d1d1d1;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.talent-inr-Values:hover .talent-value-inr-sec h4 {
  color: #ffffff;
}

/* --==================== insurance page css ==========================- */
.insurance-service-area-bg-head br {
  display: none;
}

.insurance-what-we-head-bg::after {
  width: 111px !important;
}

/* all section padding top */
#insurance-what-we-do,
#insurance-service-areas,
#insurance-experience,
#insurance-we-think,
#fin-service-pace,
#doi-what-we-do {
  padding-top: 4rem;
}

/* -------Digital Insurance Experience ---------- */

ul.insurance-experience-tabs {
  display: inline-flex;
  width: 100%;
  margin: 2rem 0px;
}

ul.insurance-experience-tabs li {
  list-style: none;
  margin: 0 4% 1% 0;
  color: #333;
  position: relative;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.insurance-experience-tabs .nav-item button.active {
  background-color: transparent;
  color: #333 !important;
}

.insurance-experience-tabs .nav-item button.active::after {
  content: "";
  border-bottom: 4px solid #CD4409;
  width: 175px;
  position: absolute;
  left: -5px;
  bottom: -14px;
  border-radius: 4px 4px 0 0;
}

/* ------  what we think sectoin  ------ */
/* #insurance-we-think {
  margin-top: 4rem;
  margin-bottom: 4rem;
} */

#insurance-we-think-second {
  padding: 0 5% 0 5%;
}

.insurance-we-think-img {
  width: 100%;
  height: 19rem;
  object-fit: cover;
}

.triangle-down-insurance-think {
  border-left: 27px solid transparent;
  border-top: 42px solid #14212b;
  position: relative;
  top: 65%;
  right: 100%;
}

#insurance-we-think-grid-row {
  margin-top: 6rem;
}

img.insurance-we-think-grid-first-card-img {
  height: 16rem;
}

h6.insurance-we-think-grid-first-card-category {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h6.insurance-we-think-grid-first-card-category::after {
  content: "";
  display: block;
  position: relative;
  width: 7rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

h5.insurance-we-think-grid-first-card-title {
  color: #CD4409;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.insurance-we-think-grid-first-card-link {
  text-decoration: none;
}

p.insurance-we-think-grid-first-card-para {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

/* --==================== finance page css ==========================- */
/* ------- Innovations that change the pace ---------- */

.fin-pace-card-content {
  border: 4px solid #CD4409;
  margin: 12rem 22px 0 0;
}

img#fin-pace-card-img {
  width: 95%;
  height: 22rem;
  bottom: 11rem;
  position: relative;
  border-radius: 4px;
  object-fit: cover;
  margin: auto;
}


.fin-pace-card-content h6 {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  margin-top: -10rem;
  padding: 0 10px 0 10px;
}

.fin-pace-card-content p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  padding: 0px 10px 10px 10px;
}

/* --==================== health page css ==========================- */
/* ---- work with us ------------ */
.helth-roww {
  display: flex;
  justify-content: center;
  align-items: center;
}


.helth-para-1 p {
  color: #333;
  font-size: 19px;
  line-height: normal;
}

.helth-para-2 p {
  color: #CD4409;
  font-size: 50px;
  font-weight: 800;
  border: 1px solid #d1d1d1;
  text-align: center;
  border-left: 5px solid #CD4409;
  padding: 15px 0px;
}

.helth-iconn {
  text-align: end;
}

.helth-iconn p svg {
  width: 28px !important;
  fill: #CD4409 !important;
}

/* --==================== doi page css ==========================- */


/* ------ benefits beno support ------ */


.doi-service-area-cards {
  width: 31% !important;
  border: 1px solid #b3b3b3af;
  margin: 10px;
  padding: 15px;
  border-left: 5px solid #CD4409;
}

.doi-service-area-cards h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.doi-service-area-cards p {
  color: #696969;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


/* --==================== legel process page css ==========================- */




.LPO-head {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.LPO-head span {
  color: #14212b;
  font-weight: 700;
  font-size: 45px;
}

.LPO-img {
  display: block;
  margin: auto;
  text-align: center;
  width: 45%;
  margin-top: 2rem;
}


/* Mobile Devices */
@media only screen and (max-width: 480px) {

  /* -======================== main Bpo service page ================- */
  /* -------------- bpo page heading effect --------- */
  .banner-heading-effect-bg::after {
    width: 168px !important;
    margin-left: -225px !important;
    margin-top: 3px !important;
  }

  .recent-blogs-title-main::after {
    width: 158px !important;
    margin-left: -14.6rem !important;
    margin-top: 10px !important;
  }

  .bpo-serv-our-segments-heading h2::after {
    margin-left: -300px !important;
    width: 143px !important;
    margin-top: 3px !important;
  }

  #bpo-heading-ntw::after {
    margin-left: -132px !important;
    width: 133px !important;
    margin-top: 0 !important;
  }

  .bpo-serv-expertise-title h2::after {
    width: 168px !important;
    margin-top: 8px !important;
    margin-left: -250px !important;
  }

  #expertise,
  #services,
  #industries,
  #why-us,
  #our-blog {
    padding-top: 3rem !important;
  }

  /* ------- banner css -------------- */
  .bpo-serv-banner-first-cri {
    display: none !important;
  }

  section#bpo-service-banner {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .bpo-serv-banner-title {
    font-size: 40px !important;
    text-align: center;
  }

  .bpo-serv-banner_desc,
  p.bpo-sub-banner-para {
    font-size: 18px !important;
    margin-top: 1.5rem !important;
    text-align: center;
    line-height: 26px !important;
  }

  div#bpo-serv-banner-col-first,
  .bpo-serv-banner-know-how {
    margin-top: 1rem !important;
    margin-left: unset !important;
    text-align: center !important;
    width: 100% !important;
  }

  .bpo-serv-banner-work-btn {
    margin: 0 0 3rem 0 !important;
  }

  .bpo-serv-indexing-nav ul {
    padding: 0 !important;
  }

  .bpo-serv-indexing-nav ul li a {
    padding: 8px 7px 8px 7px !important;
  }

  /*------------ bpo service  Expertise section css --------------*/
  .bpo-serv-expertise-title h2 {
    font-size: 40px !important;
  }

  #bpo-serv-expertise-card-row {
    margin: 3rem 0 -3rem 0 !important;
  }

  .bpo-serv-expertise-desc {
    line-height: 24px !important;
    margin: 0 !important;
  }

  .bpo-serv-what_wedo_box {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }

  #bpo-serv-expertise-cont-fluid {
    background-image: unset !important;
  }

  .shape-down-bg-bpo {
    display: none !important;
  }

  .bpo-serv-expertise-pre-haad {
    font-size: 17px !important;
  }

  .bpo-serv-expertise-service_box {
    width: 100% !important;
    height: max-content !important;
    margin: 0 0 -25px 0 !important;
  }

  .bpo-serv-expertise-service_box:hover:after {
    height: 303px !important;
    margin-top: -17rem !important;
  }

  br {
    display: none;
  }

  /*--------------- bpo service segments css what we do --------------*/
  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  .bpo-serv-service-sec {
    margin: 3rem 0 0 0 !important;
  }

  .bpo-serv-our-segments-heading h2 {
    font-size: 37px !important;
  }

  .bpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .bpo-serv-segments-card {
    height: 300px !important;
  }

  .bpo-serv-segments_box {
    margin-bottom: 1rem !important;
  }

  figure.bpo-serv-segments-effect-steve img {
    height: 100% !important;
  }

  figure .bpo-serv-segments-intro-img {
    height: 50%;
  }

  figure.bpo-serv-segments-effect-steve p {
    font-size: 15px !important;
    height: 100% !important;
    line-height: 19px;
  }

  /* ------ industries -------- */
  p#bpo-serv-wedo-card-desc {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  /* -------- bpo service happen section ---------*/
  .bpo-serv-happens-box-head span {
    font-size: 22px !important;
  }

  #bpo-serv-happens-row-main {
    margin-top: 0rem !important;
  }

  .btn-div-segments {
    margin-top: 0rem !important;
  }

  h2.bpo-serv-happens-box-head {
    text-align: center !important;
    margin-top: 24px !important;
    font-size: 21px !important;
  }

  .bpo-serv-happens-box-left-col {
    padding-right: 10px !important;
  }

  p.bpo-serv-happens-box-desc,
  .bpo-serv-happens-box-desc ul li {
    line-height: 24px !important;
  }

  p.bpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-right-img,
  .bpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 23rem !important;
  }

  .bpo-serv-happens-box-vid-play {
    margin-top: 60% !important;
  }

  .bpo-serv-happens-box-left-col-second {
    padding-left: 10px !important;
  }

  .bpo-serv-happens-sec {
    background-image: unset !important;
  }

  #bpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  section.bpo-sub-banner {
    margin-top: 0px !important;
    height: 100% !important;
  }

  h1.bpo-sub-banner-Heading {
    text-align: center !important;
    font-size: 40px !important;
    line-height: 1.1 !important;
  }

  .bpo-sub-banner_btn,
  .bpo-sub-banner-num-btn {
    text-align: center;
    margin-top: 2rem !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  .bpo-sub-section-heading {
    font-size: 30px !important;
    text-align: center !important;
  }

  .bpo-sub-section-heading span {
    font-size: 30px !important;
  }

  .bpo-sub-section-sub-heading {
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center;
  }


  /* -------------- all sub pages Service Areas section  css -------------------- */
  div#bpo-sub-service-areas-cont-first {
    width: 91%;
    height: max-content;
    margin: 5% 0px 3% 5%;
  }

  #bpo-sub-service-areas-cont-first::after {
    height: 30%;
  }

  #bpo-sub-service-areas-cont-first-desc h4 {
    margin-top: 1rem !important;
  }

  #bpo-sub-service-areas-cont-first::before {
    width: 40%;
  }

  /* ------------------------- BPO service what-we-do section -------------------- */

  .bpo-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 91.5%;
  }

  .doi-understand-img-do {
    height: 200px !important;
  }

  .talent-bpo-sub-heade::after {
    width: 11rem !important;
  }

  .bpo-sub-front-txt h4 {
    font-size: 18px !important;
  }

  .bpo-sub-front-txt p {
    line-height: 20px !important;
  }

  .bpo-sub-video-play-button-under {
    margin-top: 4rem !important;
  }

  /* ---------comman section work with us css -------------------------- */
  .bpo-sub-why-box-content {
    margin-bottom: 10px !important;
    padding: 0px !important;
    width: 93.5% !important;
  }

  .bpo-sub-why-box-content h2 {
    font-size: 60px !important;
  }

  .bpo-sub-why-box-content p {
    font-size: 17px !important;
    padding: 2px;
  }

  /* ----------- bpo sub section Key-factors ------------- */
  .bussness-img-fifth {
    width: 100% !important;
  }

  #doi-maximum-efficiency-second h5 {
    font-size: 25px !important;
  }

  #doi-maximum-efficiency-second p {
    font-size: 17px !important;
    line-height: 27px !important;
  }


  /* --==================== sales page css ==========================- */
  /* all section padding */
  #sales-what-we-do,
  #sales-service-areas,
  #sales-work-with-us,
  #sales-state-sec,
  #doi-what-we-do,
  #doi-service-areas,
  #operations-designed,
  #health-service-areas,
  #health-why-work,
  #services {
    padding-top: 2rem !important;
  }

  /* all heading background */
  .understand-head-bg span::after {
    width: 142px !important;
    margin-left: -199px !important;
    margin-top: -1px !important;
  }

  .service-area-head-bg span::after {
    width: 144px !important;
    margin-left: -213px !important;
    margin-top: -43px !important;
  }

  .why-we-head-bg span::after {
    width: 156px !important;
    margin-left: -115px !important;
    margin-top: -44px !important;
  }

  .sales-bg-head span::after {
    width: 79px !important;
    margin-left: -79px !important;
    margin-top: -3px !important;
  }

  /* ---------- state of sales ----------------- */
  .state-img-sec {
    width: 100% !important;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  .state-lower-img {
    width: 100% !important;
  }

  .state-hard-down {
    margin-bottom: 6px;
  }

  .sales-state-video-play {
    margin-top: 6rem;
    /* margin-left: 45% !important; */
  }

  .sales-videoplay {
    margin-top: -6rem;
  }

  .bpo-sub-work-with-us,
  .sales-state-sec {
    padding-top: 2rem !important;
  }

  .service-area-br,
  .work-with-us-br {
    display: block;
  }

  /* --==================== talent page css ==========================- */
  /* talent page all section padding top */
  #talent-what-we-do,
  #talent-service-areas,
  #talent-management-inr,
  #deliver-sec-business,
  #talent-what-we-think,
  #maximum-efficiency,
  #legel-what-we-do,
  #legel-service-areas {
    padding-top: 2rem !important;
  }

  .talent-we-can-help-bg br {
    display: block !important;
  }

  .talent-key-factors-bg br {
    display: block !important;
  }

  /* talent page all heading background */
  .talent-we-can-help-bg::after {
    width: 139px !important;
    margin-left: -3px !important;
    margin-top: -89px !important;
  }

  .talent-key-factors-bg::after {
    width: 188px !important;
    margin-top: -90px !important;
  }

  .talent-what-we-think-bg::after {
    width: 95px !important;
  }

  /* ---------------section Management ------------------- */
  .inr-sec-ment {
    min-height: 10rem !important;
    width: 100%;
  }

  .talent-inr-Values {
    margin: 15px 15px 10px !important;
    padding: 10px 0 !important;
  }

  .talent-value-inr-sec.text-center svg {
    width: 70px !important;
  }

  .talent-value-inr-sec h4 {
    font-size: 22px !important;
    margin-top: 0 !important;
  }

  .talent-deliver-content h4 {
    font-size: 30px !important;
    line-height: 33px;
    margin-top: 20px;
    text-align: center !important;
  }

  .talent-deliver-content p {
    font-size: 17px !important;
    line-height: 28px !important;
    text-align: center !important;
  }

  .talent-deliver-content ul li {
    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
  }

  .talent-values-play-btn {
    margin-top: 6rem !important;
  }

  .bpo-sub-under-img-right {
    margin-top: 0px !important;
  }

  /* --==================== insurance page css ==========================- */
  .insurance-service-area-bg-head br {
    display: block !important;
  }

  #insurance-what-we-do,
  #insurance-service-areas,
  #insurance-experience,
  #insurance-we-think,
  #finance-what-we-do,
  #finance-service-areas,
  #fin-service-pace,
  #health-what-we-do,
  #doi-benefits,
  #doi-case-study,
  #legel-work-with-us {
    padding-top: 2rem !important;
    background-image: none !important;
  }

  /*insurance all heading background */
  .insurance-what-we-bg-head::after {
    width: 136px !important;
  }

  .digital-insurance-head-bg::after {
    width: 137px !important;
    margin-top: -90px !important;
  }

  .insurance-what-we-head-bg::after {
    width: 87px !important;
    margin-top: -45px !important;
  }



  /* insurance banner */
  section.bpo-sub-banner {
    padding: 50px 0 !important;
  }

  /* -------Digital Insurance Experience ---------- */


  ul.insurance-experience-tabs {
    display: grid;
    padding: 0;
  }

  .insurance-experience-tabs .nav-item button {
    background-color: #fff !important;
    color: #CD4409 !important;
    outline: auto;
    padding: 4% 10% !important;
    border-radius: 25px;
    margin-bottom: 10px;
  }

  .insurance-experience-tabs .nav-item button.active {
    background-color: #CD4409 !important;
    color: #fff !important;
    padding: 4% 10% !important;
  }

  .insurance-experience-tabs .nav-item button.active::after {
    width: 0px !important;
  }

  /* --------------- We think insurance section  ------------------- */
  #insurance-we-think-first p {
    margin-top: 0 !important;
  }

  .insurance-we-think-img {
    width: 98% !important;
    object-fit: cover;
  }

  .triangle-down-insurance-think {
    right: 98% !important;
    top: 67% !important;
  }

  #insurance-we-think-grid-row {
    margin-top: 4rem !important;
  }

  #insurance-we-think-grid-first {
    margin-bottom: 1rem;
  }

  h6.insurance-we-think-grid-first-card-category::after {
    width: 7rem !important;
  }

  div#bpo-serv-banner-col-first {
    margin-top: -37% !important;
  }

  /* --==================== finance page css ==========================- */
  /* service that change the pace */

  .fin-pace-card-content {
    margin: 12rem 0px 0 16px !important;
    width: 90% !important;
  }

  img#fin-pace-card-img {
    height: 18rem !important;
    bottom: 10rem !important;
  }

  .fin-pace-card-content h6 {
    font-size: 24px !important;
    margin-top: -9rem !important;
  }

  .fin-pace-card-content p {
    font-size: 17px !important;
    line-height: 21px !important;
  }

  /* --==================== health page css ==========================- */
  /* ---- work with us ------------ */
  .helth-iconn {
    width: 10% !important;
  }

  .helth-para-1 {
    width: 90% !important;
  }


  .helth-roww {
    /* margin-bottom: 2rem; */
  }

  /* --==================== doi page css ==========================- */


  .doi-service-area-cards {
    width: 94% !important;
    height: max-content !important;
    padding: 7px !important;
  }

  #doi-data-counter-row {
    /* margin: 1rem 0px !important; */
  }

  /* --==================== legel process page css ==========================- */




  .LPO-head {
    color: #CD4409 !important;
    font-size: 30px !important;
    font-weight: 700;
    text-align: center;
  }

  .LPO-head span {
    color: #14212b;
    font-weight: 700;
    font-size: 30px;
  }

  .LPO-img {
    width: 100% !important;
  }

  .bpo-inner-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .bpo-inner-banner-bg-btn {
    margin-top: 0rem !important;
    height: unset !important;
    /* padding-bottom: 5rem !important; */
  }
}

/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 767px) {

  /* all heading background */
  .talent-what-we-think-bg::after {
    width: 94px !important;
  }

  .talent-we-can-help-bg::after {
    width: 163px !important;
    margin-top: -44px !important;
  }

  .talent-key-factors-bg::after {
    width: 154px !important;
    margin-top: -44px !important;
  }

  .insurance-what-we-head-bg::after {
    width: 87px !important;
  }



  /* -======================== main Bpo service page ================- */
  #expertise,
  #services,
  #industries,
  #why-us,
  #our-blog,
  #health-service-areas,
  #doi-service-areas,
  #doi-case-study,
  #health-why-work {
    padding-top: 2rem !important;
  }

  .bpo-serv-expertise-title h2::after {
    width: 170px !important;
    margin-left: -244px !important;
    margin-top: 5px !important;
  }

  #bpo-heading-hpn::after {
    width: 149px !important;
    margin-left: -27.5rem !important;
  }

  .bpo-serv-our-segments-heading h2::after {
    margin-left: -320px !important;
    width: 149px !important;
    margin-top: 4px !important;
  }

  #bpo-heading-ntw::after {
    margin-left: -141px !important;
    width: 139px !important;
  }

  .banner-heading-effect-bg::after {
    margin-left: -266px !important;
    width: 208px !important;
    margin-top: 13px !important;
  }

  br {
    display: none;
  }

  /* ------- banner css -------------- */
  .bpo-serv-banner-first-cri {
    display: none !important;
  }

  section#bpo-service-banner {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .bpo-serv-banner-title {
    font-size: 50px !important;
    text-align: center;
  }

  .bpo-serv-banner_desc {
    font-size: 18px !important;
    margin-top: 1.5rem !important;
    text-align: center;
    line-height: 32px !important;
  }

  div#bpo-serv-banner-col-first,
  .bpo-serv-banner-know-how {
    margin-top: 0 !important;
    margin-left: unset !important;
    text-align: center !important;
    width: 100% !important;
  }

  .bpo-serv-banner-work-btn {
    margin: 0 0 3rem 0 !important;
  }

  .btn-div-segments {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  /*------------ bpo service  Expertise section css --------------*/
  .bpo-serv-indexing-nav ul li a {
    font-size: 18px !important;
    padding: 10px 25px 10px 24px !important;
  }

  .bpo-serv-expertise-title h2 {
    font-size: 40px !important;
  }

  .bpo-serv-expertise-desc {
    line-height: 26px !important;
    margin: 0 !important;
  }

  #bpo-serv-expertise-card-row {
    margin: 4rem 0 1rem 0 !important;
  }

  #bpo-serv-expertise-cont-fluid {
    background: unset !important;
  }

  .bpo-serv-expertise-pre-haad {
    font-size: 17px !important;
  }

  .bpo-serv-expertise-service_box {
    width: 100% !important;
    height: max-content !important;
    margin: -48px 0 20px 0 !important;
  }

  /*--------------- bpo service segments css what we do --------------*/
  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  /* .bpo-serv-service-sec {
    margin: 3rem 0 3rem 0 !important;
  } */
  .bpo-serv-our-segments-heading h2 {
    font-size: 40px !important;
  }

  .bpo-serv-our-segments-desc {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  /* -------- bpo service happen section ---------*/
  #bpo-serv-happens-row-main {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-head span {
    font-size: 36px !important;
    font-size: 24px !important;
  }

  h2.bpo-serv-happens-box-head {
    text-align: center !important;
    font-size: 22px !important;
  }

  .bpo-serv-happens-box-left-col {
    padding-right: 20px !important;
  }

  p.bpo-serv-happens-box-desc,
  .bpo-serv-happens-box-desc ul li {
    line-height: 28px !important;
  }

  p.bpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-right-img,
  .bpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 25rem !important;
  }

  .bpo-serv-happens-box-vid-play {
    margin-top: 25% !important;
  }

  .bpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  #bpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .bpo-sub-banner {
    margin-top: 0rem !important;
    background-position: center;
  }

  .bpo-sub-banner-Heading {
    font-size: 40px !important;
    line-height: 50px !important;
  }

  .bpo-sub-banner-para {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .bpo-sub-banner_btn {
    width: 45% !important;
    margin-top: 3rem !important;
  }

  .bpo-sub-banner-num-btn {
    width: 50% !important;
    margin-top: 3rem !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  .bpo-sub-section-heading {
    font-size: 48px !important;
  }

  .bpo-sub-section-heading span {
    font-size: 37px !important;
  }

  .bpo-sub-section-sub-heading {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  /* -------------- all sub pages Service Areas section  css -------------------- */
  div#bpo-sub-service-areas-cont-first-img,
  #bpo-sub-service-areas-cont-first-desc {
    width: 100% !important;
  }

  #bpo-sub-service-areas-cont-first::after {
    height: 30% !important;
  }

  #bpo-sub-service-areas-cont-first::before {
    width: 35% !important;
  }

  #bpo-sub-service-areas-cont-first {
    margin: 3% 14px 6px 7px !important;
    height: max-content !important;
    width: 97% !important;
  }

  #bpo-sub-service-areas-cont-first-desc h4 {
    font-size: 24px !important;
  }

  /* ------------------------ BPO service what-we-do section -------------------- */

  .bpo-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 95% !important;
    padding-bottom: 13px;
  }

  .bpo-sub-front-txt p {
    line-height: 19px !important;
  }

  .bpo-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  #doi-maximum-efficiency-second h5 {
    font-size: 20px !important;
  }

  #doi-maximum-efficiency-second p {
    line-height: 24px !important;
    font-size: 16px;
  }

  /* -----------  work with us section -------------------- */
  .bpo-sub-why-box-content {
    width: 98% !important;
  }

  /* ----------- bpo sub section case study ------------- */
  div#doi-maximum-efficiency-first {
    width: 30% !important;
  }

  /* ----------- bpo sub section Key-factors ------------- */
  .bussness-img-fifth {
    width: 100% !important;
    background-position: center !important;
  }

  /* --==================== sales page css ==========================- */
  /* ---------- state of sales ----------------- */
  .state-img-sec {
    padding: 6px 6px 4px 6px;
    width: 92%;
    margin-bottom: 1rem;
    border: 1px solid #d1d1d1;
  }

  .sales-state-video-play {
    margin-top: 5.3rem;
    /* margin-left: 19% !important; */
  }

  .sales-videoplay {
    margin-top: -5rem;
  }

  .state-lower-img {
    width: 100% !important;
    height: 160px !important;
  }

  .state-hard-down {
    margin-bottom: 6px;
  }

  /* --==================== talent page css ==========================- */
  /* talent page all section padding top */
  #talent-what-we-do,
  #talent-service-areas,
  #talent-management-inr,
  #deliver-sec-business,
  #talent-what-we-think,
  #maximum-efficiency,
  #health-what-we-do,
  #doi-what-we-do,
  #doi-benefits,
  #legel-service-areas,
  #legel-work-with-us {
    padding-top: 2rem;
  }

  .talent-inr-Values {
    margin-top: 26px !important;
  }

  /* ---------------section Management ------------------- */
  .talent-value-inr-sec h4 {
    font-size: 18px !important;
  }

  .talent-deliver-content h4 {
    font-size: 28px !important;
  }

  .talent-deliver-content p {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  .bussness-img-fifth {
    background-position: 50% !important;
    width: 100% !important;
  }

  .talent-deliver-content ul li {
    font-size: 17px !important;
    line-height: 28px !important;
  }

  div#talent-deliver-content-col-first {
    width: 100% !important;
  }

  div#talent-deliver-content-col-second {
    width: 100% !important;
  }

  .video-play-button-heppens {
    margin-top: 6rem !important;
  }

  div#doi-maximum-efficiency-second {
    width: 70% !important;
  }

  img.doi-efficiencyes {
    height: 15rem !important;
  }

  /* --==================== insurance page css ==========================- */
  #insurance-what-we-do,
  #insurance-service-areas,
  #insurance-experience,
  #insurance-we-think,
  #sales-what-we-do,
  #sales-service-areas,
  #sales-work-with-us,
  #sales-state-sec,
  #finance-what-we-do,
  #finance-service-areas,
  #fin-service-pace,
  #legel-what-we-do,
  #operations-designed,
  #services {
    padding-top: 2rem;
  }

  /* all heading background */
  .insurance-what-we-bg-head::after {
    width: 142px !important;
  }

  /* -------Digital Insurance Experience ---------- */
  .insurance-experience-tabs .nav-item button.active::after {
    width: 100px !important;
    left: 0px !important;
    bottom: -8px !important;
  }

  .talent-deliver-content {
    margin-top: 1rem !important;
  }

  div#insurance-col-first-tabs,
  #insurance-col-second-tabs,
  .insurance-col-first-img-tabs {
    width: 100% !important;
  }

  .digital-play-video-beno {
    margin: 0px 0px 0px 50% !important;
  }

  /* --------------- We think insurance section css ------------------- */
  .insurance-we-think-img {
    margin: 3rem 0 0 0 !important;
  }

  .triangle-down-insurance-think {
    top: 65% !important;
  }

  p.insurance-we-think-grid-first-card-para {
    line-height: 21px !important;
  }

  img.insurance-we-think-grid-first-card-img {
    height: 14rem !important;
  }

  div#insurance-we-think-grid-first {
    margin-bottom: 1rem !important;
  }

  /* --==================== health page css ==========================- */
  /* ---- work with us ------------ */
  .helth-iconn {
    width: 10% !important;
  }

  .helth-para-1 {
    width: 90% !important;
  }

  .helth-para-2 {
    width: 90% !important;
    margin-left: 10% !important;
  }

  .helth-roww {
    margin-bottom: 2rem;
  }

  /* --==================== doi page css ==========================- */
  .doi-service-area-cards {
    width: 47% !important;
  }


  #doi-data-counter-row {
    margin: 2rem 0px !important;
  }

  /* --==================== legel process page css ==========================- */




  .operations-heading {
    margin-top: 2rem !important;
  }

  #operations-designed-cols {
    width: 100% !important;
  }

  .LPO-img {
    width: 65% !important;
  }

  .bpo-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }
}

/* Tablets iPads (Portrait) */
@media (min-width: 768px) and (max-width: 991px) {
  .recent-blogs-desc-main {
    font-size: 16px;
    line-height: 24px;
  }

  /* -======================== main Bpo service page ================- */
  /* all sections heading background */
  .talent-key-factors-bg::after {
    width: 189px !important;
  }

  br {
    display: none;
  }

  #bpo-heading-ntw::after {
    margin-left: -142px !important;
    width: 142px !important;
  }

  #bpo-heading-hpn::after {
    width: 149px !important;
    margin-left: -27.5rem !important;
  }

  #expertise,
  #services,
  #industries,
  #why-us,
  #our-blog,
  #health-what-we-do,
  #health-service-areas,
  #doi-service-areas,
  #doi-what-we-do,
  #doi-benefits,
  #doi-case-study,
  #legel-what-we-do,
  #legel-service-areas,
  #operations-designed,
  #health-why-work {
    padding-top: 3rem !important;
  }

  .bpo-serv-expertise-service_box:hover:after {
    height: 318px !important;
    margin-top: -17rem !important;
  }

  .bpo-serv-our-segments-heading h2::after {
    margin-left: -326px !important;
    margin-top: 3px !important;
    width: 155px !important;
  }

  /* ------- banner css -------------- */
  .bpo-serv-banner-first-cri {
    display: none !important;
  }

  section#bpo-service-banner {
    height: 100% !important;
    margin-top: 0px !important;
  }

  .bpo-serv-banner-title {
    margin: 4rem 5rem 0 5rem !important;
    text-align: center;
  }

  .bpo-serv-banner_desc {
    margin-top: 1.5rem !important;
    text-align: center;
  }

  .doi-service-area-cards {
    width: 30% !important;
  }

  #bpo-serv-banner-play-row {
    width: 100% !important;
    text-align: center;
    margin: 0rem 0 2rem 0 !important;
  }

  #bpo-serv-banner-col-first,
  .bpo-serv-banner-know-how {
    width: 35% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bpo-serv-banner-col-first {
    margin: 0 0rem 0px 6rem !important;
  }

  .bpo-serv-banner-know-how {
    margin: 0 6rem 0 0 !important;
  }

  .bpo-serv-banner-work-btn {
    margin: unset !important;
  }

  .bpo-serv-indexing-nav ul li a {
    padding: 10px 25px 10px 25px !important;
  }

  /*------------ bpo service  Expertise section css --------------*/
  #bpo-serv-expertise-cont-fluid {
    background: unset !important;
  }

  .bpo-serv-expertise-service_box {
    width: 47% !important;
    margin: 0 10px !important;
  }

  /*--------------- bpo service segments css what we do --------------*/
  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  .bpo-serv-our-segments-heading h2 {
    font-size: 40px !important;
  }

  .bpo-serv-our-segments-desc {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  .bpo-serv-segments_box {
    width: 50% !important;
  }

  figure.bpo-serv-segments-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ------ industries -------- */
  .bpo-serv-what_wedo_box {
    width: 50% !important;
  }

  p#bpo-serv-wedo-card-desc {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  /* -------- bpo service happen section ---------*/
  .bpo-serv-happens-box-head span {
    font-size: 24px !important;
  }

  .bpo-serv-happens-box-left-col {
    padding-right: 10px !important;
  }

  h2.bpo-serv-happens-box-head {
    font-size: 22px !important;

  }

  p.bpo-serv-happens-box-desc,
  .bpo-serv-happens-box-desc ul li {
    line-height: 25px !important;
  }

  p.bpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-right-img,
  .bpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 27rem !important;
  }

  .bpo-serv-happens-box-vid-play {
    margin-top: 5rem !important;
  }

  .bpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  #bpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .bpo-sub-banner {
    margin-top: 0 !important;
  }

  .bpo-sub-banner-Heading {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  .bpo-sub-banner-para {
    font-size: 18px !important;
    line-height: 28px !important;
    margin-top: 1rem !important;
  }

  .bpo-sub-banner_btn {
    width: 45% !important;
    margin-top: 3rem !important;
  }

  .bpo-sub-banner-num-btn {
    width: 55% !important;
    margin-top: 3rem !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */
  #bpo-sub-service-areas-cont-first::after {
    height: 9rem !important;
  }

  #bpo-sub-service-areas-cont-first::before {
    width: 9rem !important;
  }

  div#bpo-sub-service-areas-cont-first-desc,
  div#bpo-sub-service-areas-cont-first-img {
    width: 100% !important;
  }

  #bpo-sub-service-areas-cont-first {
    height: max-content !important;
  }

  /* ------------------------- BPO service what-we-heading section -------------------- */

  .bpo-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 97%;
  }

  .bpo-sub-front-txt p {
    line-height: 19px !important;
  }

  /* -------------- why should you work with us section ----------- */
  .bpo-sub-why-box-content {
    width: 46.8% !important;
  }

  /* ----------- bpo sub section Key-factors ------------- */
  .bussness-img-fifth {
    width: 100% !important;
    background-position: center !important;
  }

  #doi-maximum-efficiency-second h5 {
    font-size: 20px;
  }

  #doi-maximum-efficiency-second p {
    line-height: 28px;
    font-size: 17px;
  }

  /* --==================== sales page css ==========================- */
  /* ---------- state of sales ----------------- */
  /* padding top all sections */
  #sales-what-we-do,
  #sales-service-areas,
  #sales-work-with-us,
  #sales-state-sec,
  #services {
    padding-top: 3rem !important;
  }

  .understand-para,
  .sales-state-para {
    line-height: 28px !important;
  }

  #bpo-sub-service-areas-cont-first-desc h4 {
    font-size: 24px !important;
  }

  .service-area-para,
  .work-area-para {
    font-size: 19px !important;
    line-height: 31px !important;
  }

  .state-img-sec {
    width: 46.8% !important;
    height: max-content;
  }

  .state-ml-left {
    margin-left: 6px;
  }

  /* --==================== talent page css ==========================- */
  /* talent page all section padding top */
  #talent-what-we-do,
  #talent-service-areas,
  #talent-management-inr,
  #deliver-sec-business,
  #talent-what-we-think,
  #maximum-efficiency,
  #finance-what-we-do,
  #finance-service-areas,
  #fin-service-pace,
  #legel-work-with-us {
    padding-top: 3rem;
  }

  /* ---------------section Management ------------------- */
  .talent-value-inr-sec h4 {
    font-size: 19px !important;
  }

  .video-play-button-heppens {
    margin-top: 6rem !important;
  }

  .talent-deliver-content h4 {
    font-size: 32px !important;
  }

  .talent-deliver-content p,
  .talent-deliver-content ul li {
    font-size: 17px !important;
    line-height: 30px !important;
  }

  /* --==================== insurance page css ==========================- */
  #insurance-what-we-do,
  #insurance-service-areas,
  #insurance-experience,
  #insurance-we-think {
    padding-top: 3rem;
  }

  /* all heading background */
  .insurance-service-area-bg-head::after,
  .insurance-what-we-bg-head::after {
    width: 170px !important;
  }

  .insurance-what-we-bg-head::after {
    width: 152px !important;
  }

  /* -------Digital Insurance Experience ---------- */
  .triangle-down-insurance-think {
    top: 58% !important;
  }


  /* --==================== health page css ==========================- */
  /* ---- work with us ------------ */
  .helth-iconn {
    width: 10% !important;
  }

  .helth-para-1 {
    width: 90% !important;
  }

  .helth-para-2 {
    width: 90% !important;
    margin-left: 10% !important;
  }

  .helth-roww {
    margin-bottom: 2rem;
  }

  /* --==================== legel process page css ==========================- */

  #operations-designed-cols {
    width: 100% !important;
  }

  .LPO-img {
    width: 65% !important;
  }

  .insurance-experience-tabs .nav-item button.active::after {
    width: 143px !important;
  }

  .bpo-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .LPO-head {
    font-size: 45px !important;
  }

  .LPO-head span {
    font-size: 45px;
  }
}

/* Laptops and Desktops */
@media (min-width: 992px) and (max-width: 1200px) {
  br {
    display: none !important;
  }

  /* -======================== main Bpo service page ================- */
  /* --------- heading effect ----------- */
  .banner-heading-effect-bg::after {
    margin-left: -318px !important;
  }

  /* ------- banner css -------------- */
  .bpo-serv-banner-first-cri {
    margin-left: 12% !important;
  }

  .bpo-serv-banner-second {
    width: 70% !important;
  }

  .bpo-serv-banner-title {
    font-size: 60px !important;
    margin-top: 7rem !important;
  }

  #bpo-serv-banner-col-first,
  .bpo-serv-banner-know-how {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bpo-serv-banner-col-first {
    width: 30% !important;
  }

  .bpo-serv-banner-know-how {
    width: 65% !important;
  }

  .bpo-serv-banner_desc {
    margin-top: 2rem !important;
  }

  /*--------------- bpo service segments css what we do --------------*/
  .bpo-serv-expertise-service_box:hover:after {
    height: 298px !important;
    margin-top: -16rem !important;
  }

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  figure.bpo-serv-segments-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ------ industries -------- */
  h5#bpo-serv-wedo-card-title {
    font-size: 20px !important;
  }

  /* -------- bpo service happen section ---------*/
  .bpo-serv-happens-box-left-col {
    padding-right: 20px !important;
  }

  p.bpo-serv-happens-box-desc,
  .bpo-serv-happens-box-desc ul li {
    line-height: 32px !important;
  }

  p.bpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-right-img,
  .bpo-serv-happens-box-back-img {
    width: 100% !important;
    height: 26rem !important;
  }

  .bpo-serv-happens-box-vid-play {
    margin-top: 75% !important;
  }

  .bpo-serv-happens-box-left-col-second {
    padding-left: 20px !important;
  }

  #bpo-serv-happens-row-main-second {
    margin: 1rem 0 0 0 !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .bpo-sub-banner_btn {
    width: 40% !important;
    margin-top: 3rem !important;
  }

  .bpo-sub-banner-num-btn {
    width: 60% !important;
    margin-top: 3rem !important;
  }

  .bpo-sub-banner-Heading {
    font-size: 57px !important;
  }

  /* ------------- all sub pages main heading and sub heading  css ---------------- */

  #bpo-sub-service-areas-cont-first::before {
    width: 9rem !important;
  }

  div#bpo-sub-service-areas-cont-first-desc,
  div#bpo-sub-service-areas-cont-first-img {
    width: 100% !important;
  }

  #bpo-sub-service-areas-cont-first {
    height: max-content !important;
  }

  /* ------------------------- BPO service what-we-do section -------------------- */
  .bpo-sub-front-txt {
    float: unset !important;
    margin-left: 1.6rem;
    width: 94.4% !important;
  }

  .bpo-sub-front-txt p {
    line-height: 19px !important;
  }

  .bpo-sub-video-play-button-under {
    margin-top: 3rem !important;
  }

  /* -------------- work with us section ----------- */
  .bpo-sub-why-box-content {
    width: 47.8% !important;
  }

  /* --==================== sales page css ==========================- */
  /* ---------- state of sales ----------------- */
  .state-ml-left {
    margin-left: 6px;
  }

  .understand-para,
  .sales-state-para {
    line-height: 28px !important;
  }

  .service-area-para,
  .work-area-para {
    font-size: 19px !important;
    line-height: 31px !important;
  }

  .state-img-sec {
    width: 49.2% !important;
  }

  /* --==================== talent page css ==========================- */
  /* ---------------section Management ------------------- */
  .talent-value-inr-sec h4 {
    font-size: 25px !important;
  }

  .talent-deliver-content p {
    line-height: 30px !important;
  }

  .talent-deliver-content ul li {
    font-size: 20px !important;
    line-height: 35px !important;
  }

  /* --==================== insurance page css ==========================- */
  /* all heading background  */
  .insurance-what-we-bg-head::after {
    width: 152px !important;
  }

  .insurance-service-area-bg-head::after {
    width: 152px !important;
  }

  /* -------Digital Insurance Experience ---------- */
  .triangle-down-insurance-think {
    top: 54% !important;
  }

  .bpo-sub-banner {
    height: 550px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {

  /* -======================== main Bpo service page ================- */
  /*-------- bpo service segments css -------*/
  figure.bpo-serv-segments-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }



  /* -------- bpo service happen section ---------*/
  .bpo-serv-happens-box-left-col {
    padding-right: 2rem !important;
  }

  p.bpo-serv-happens-box-desc,
  .bpo-serv-happens-box-desc ul li {
    line-height: 32px !important;
  }

  p.bpo-serv-happens-box-desc ul {
    margin-top: 1rem !important;
  }

  .bpo-serv-happens-box-right-img,
  .bpo-serv-happens-box-back-img {
    width: 100% !important;
  }

  /* --==================== all sub pages commen section  css ==========================- */
  /* ----------------- banner section css bpo sub pages---------------- */
  .bpo-sub-banner_btn {
    width: 30% !important;
    margin-top: 3rem !important;
  }

  .bpo-sub-banner-num-btn {
    width: 70% !important;
    margin-top: 3rem !important;
  }

  /* -------------------------BPO service what-we-do section -------------------- */
  .bpo-sub-triangle-down {
    border-left: 28px solid transparent !important;
  }

  /* ----------- bpo sub section Key-factors ------------- */
  .bussness-img-fifth {
    width: 100% !important;
    background-position: center !important;
    height: 430px;
  }

  /* --==================== insurance page css ==========================- */
  /* all heading background */
  .insurance-what-we-bg-head::after {
    width: 156px !important;
  }

  .insurance-service-area-bg-head::after {
    width: 171px !important;
  }


  /* -------Digital Insurance Experience ---------- */
  .triangle-down-insurance-think {
    top: 13rem !important;
  }
}

@media (min-width: 1400px) {

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }

  /* ======sales page========= */
  .bpo-sub-front-txt {

    width: 95.6% !important;

  }
}

@media (min-width: 1600px) {

  span.shadow-img-segments-right,
  .shadow-img-segments-left {
    display: none !important;
  }
}