#capabilities {
    padding-top: 5rem !important;
}

/* ---------banner section --------------- */
.education-indus-main-banner-head {
    color: #fff;
    margin-right: 19rem;
}

.education-indus-main-banner-head-unique {
    color: #fff !important;
}

#education-indus-main-banner {
    background-color: unset !important;
}

.education-indus-main-bet-content h3 {
    width: 100%;
}

.education-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* background-blend-mode: overlay; */
    /* background-color: #bbcac29c; */
    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.education-indus-main-bet-content h3 {
    color: #14212b;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.education-indus-main-bet-content p {
    color: #14212b;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.education-indus-main-banner-content {
    width: 63%;
}

.education-indus-main-banner-content-ed-aps {
    width: 37% !important;
}

.indus-education-banner-main-btn {
    margin: 3rem 0;
    text-align: end;
    width: 100%;
}

.indus-education-banner-main-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 10px 25px;
    background: #CD4409;
    font-weight: 600;
    transition: all 0.5s;
    margin-left: 10px;
}

.indus-education-banner-main-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-education {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-education:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.engineering-capabilities-education {
    padding-top: 4rem;
}

/* ---------- indexing section --------------- */
section.education-main-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.education-main-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.education-main-indus-index-nav ul li {
    display: inline-flex;
}

.education-main-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.education-main-indus-index-nav ul li a.active {
    background-color: white;
    color: #CD4409;
}

.education-main-indus-index-nav ul li:hover,
.education-main-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.sticky-education {
    position: fixed;
    top: 0;
}

.index-education-main-indus-item.active {
    background-color: white;
    color: #CD4409;
}


/* --------------- about section ------------- */
.education-about-sec {
    padding-top: 4rem;
}



#education-provide-sec-efft::after {
    margin-left: -457px !important;
}

#education-effort-sec-efft::after {
    margin-left: -560px !important;
}

.education-section-heading {
    color: #CD4409 !important;
    font-size: 45px;
    font-weight: 700;
    position: relative;
}

.education-section-heading span {
    color: #14212b !important;
}

.education-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.education-section-sub-heading ul {
    margin-top: 1rem;
}

.education-section-sub-heading ul li {
    font-weight: 600;
}

.education-section-sub-heading span {
    color: #CD4409;
    font-weight: 600;
}

.education-section-sub-heading b {
    font-weight: 600 !important;
}

.education-sub-img-about {
    height: 18rem;
    width: 100%;
    object-fit: cover;
}

.education-sub-img-about-ed-aps {
    height: 21rem !important;
}

.education-sub-img-about-down-box {
    width: 95.3%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: left;
    margin-bottom: 0.8rem;
}

.education-sub-img-about-down-box h6 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.education-sub-img-about-down-box h6::after {
    content: "";
    display: block;
    position: relative;
    width: 8.7rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.education-sub-img-about-down-box h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.education-sub-img-about-down-box p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.education-sub-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.education-section-img-heading {
    font-weight: 600;
    color: #14212b !important;
}



/* ---------- Industries cater section ----------- */
.education-indus-cater-sec {
    background-image: url('../../../../public/assets/img/bpo/under-back.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.education-section-heading {
    color: #CD4409 !important;
    font-size: 45px;
    font-weight: 700;
    position: relative;
}

.education-section-heading span {
    color: #14212b !important;
}

.industries-cater-head-effect-education::after {
    width: 169px !important;
    margin-left: -561px !important;
}

.education-section-sub-heading {
    color: #14212b !important;
    font-size: 16px;
    line-height: 26px;
}

.education-section-sub-heading span {
    color: #CD4409;
    font-weight: 600;
}

.education-section-sub-heading b {
    font-weight: 600 !important;
}

.education-main-indus-cater {
    width: 31% !important;
    border: 1px solid #b3b3b3af;
    margin: 10px;
    padding: 15px;
    border-left: 5px solid #CD4409;
}

.education-main-indus-cater h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.education-main-indus-cater p {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* ------------------------------eng capabilities ------------------------------ */

.engineering-capabilities-pre-head {
    color: #262626;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.engineering-capabilities-head-div {
    height: 100%;
    border-left: 5px solid #CD4409;
    padding: 0px 3rem;
    width: 100%;
}

.egg-education-sub-section-heading-spec {
    color: #14212B;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.egg-education-sub-section-heading-spec span {
    color: #CD4409;
}

.egg-education-accordion-main {
    margin-top: 3rem;
}

.egg-education-accordion-quest,
.accordion-button {
    color: #14212B;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    background-color: #fff !important;
    line-height: 30px;
}

.egg-education-accordion-quest span {
    color: #CD4409;
    font-size: 25px;
    margin-right: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: rotate(0deg);
    content: "\2212";
}

.egg-education-accordion-img-tabs {
    width: 100%;
    height: 340px;
    background-size: cover;
}

.egg-education-content-img-btn-custo {
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: inline-block;
    width: 32px;
    margin-top: 34%;
    margin-left: 50% !important;
    border-radius: 50%;
    color: #fff;
    padding: 6px 0 0 18px;
}

.egg-education-content-img-btn-custo:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 65px;
    height: 65px;
    background: #CD4409;
    border-radius: 50%;
    animation: pulse-border-heppens 1500ms ease-out infinite;
}

.egg-education-content-img-btn-custo span svg {
    position: relative;
    z-index: 3;
    font-size: 26px;
}

.egg-education-content-img-btn-custo:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #CD4409;
    border-radius: 50%;
    transition: all 200ms;
}

p.egg-education-img-fifth-btn {
    color: #14212B;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.egg-education-tabs-accor-content p {
    color: #262626;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
}

.egg-education-tabs-accor-content ul li {
    color: #262626;
    font-size: 17px;
    font-weight: 600;
    line-height: 35px;
}

.egg-education-tabs-accor-content a {
    background: #CD4409;
    padding: 5px 15px;
    margin: 20px 15px;
    font-size: 20px;
    text-decoration: none;
    font-weight: 600;
    border-radius: 25px;
    color: #fff;
}

.egg-education-tabs-accor-content a:hover {
    color: #CD4409;
    background-color: #fff;
    outline: auto;
}

/* ---------------------------cloud hoghlight  --------------------------- */
.education-could-highlights-sec {
    margin-top: 5rem;
}

.education-could-highlights-heading {
    color: #262626;
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
}

.education-could-highlights-content {
    margin-top: 1rem;
    border-left: 4px solid #CD4409;
    padding: 2rem 0 2rem 3rem;
}


.education-could-highlights-content ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
}

.education-could-highlights-content ul li::before {
    content: "\2022";
    color: #CD4409;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 27px;
    margin-left: -1em;
}

.ecom-indus-banner-ed-aps {
    background-position: 0% 0% !important;
    height: 450px !important;
}

.education-could-highlights-content ul li {
    color: #262626;
    font-size: 17px;
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.education-cloud-agnostic h2 {
    /* margin-top: 1rem; */
    font-size: 33px;
    font-weight: 600;
}

.education-cloud-agnostic p {
    font-size: 17px;
    font-weight: bold;
    margin-top: 15px;
}

.education-cloud-agnostic ul li {
    /* margin-left: -1rem; */
    margin-top: 10px;
    font-size: 18px;
}

.education-cloud-agnostic ul li::marker {
    color: #CD4409;
}

.education-cloud-agnostic ul li span {
    font-weight: bold;
    color: #CD4409;
}



@media screen and (max-width: 480px) {
    .education-indus-main-banner-content {
        width: 100% !important;
    }

    #capabilities {
        padding-top: 2rem !important;
    }

    .education-could-highlights-sec {
        margin-top: 2rem;
    }

    /* ------------- indexing section ------------------ */
    .education-main-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .education-main-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    .education-main-indus-index-nav a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    /* --------------- about section ------------- */
    .education-about-sec {
        padding-top: 2rem !important;
    }

    .education-section-heading::after {
        content: unset !important;
    }

    .education-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .education-section-heading::after {
        width: 144px !important;
        margin-left: -485px !important;
        margin-top: 3px !important;
    }

    .education-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .education-sub-img-about-down-box {
        width: 91.3% !important;
    }

    .education-sub-img-about-down-box h4 {
        font-size: 18px !important;
    }

    .education-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    /* ---------- Industries cater section ----------- */
    .education-section-heading::after {
        content: unset !important;
    }

    .education-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .industries-cater-head-effect-education::after {
        width: 169px !important;
        margin-left: -499px !important;
        margin-top: 6px !important;
    }

    .education-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .education-main-indus-cater {
        width: 94% !important;
        height: max-content !important;
        padding: 7px !important;
    }

    /* ------------------------------ eng capabilities  -----------------------*/


    .engineering-capabilities-pre-head {
        font-size: 14px !important;
        line-height: 24px !important;
    }

    .engineering-capabilities-head-div {
        height: unset !important;
        padding: 0px 2% !important;
        width: 100% !important;
    }

    .egg-education-sub-section-heading-spec {
        font-size: 30px !important;
    }

    .egg-education-accordion-main {
        margin-top: 2rem !important;
    }

    .egg-education-accordion-quest,
    .accordion-button {
        font-size: 18px !important;
        line-height: 23px !important;
    }

    .egg-education-accordion-quest,
    .accordion-button {
        font-size: 18px !important;
        line-height: 23px !important;
    }

    .accordion-flush .accordion-item .accordion-button,
    .accordion-flush .accordion-item .accordion-button.collapsed {
        padding: 13px 5px !important;
    }

    .accordion-button::after {
        font-size: 18px !important;
        right: 0.2rem !important;
        width: 20px !important;
        line-height: 22px !important;
        height: 20px !important;
        padding: 0px 3px 1px 5px !important;
    }

    .egg-education-accordion-img-tabs {
        height: 235px !important;
    }

    .egg-education-tabs-accor-content p,
    .egg-education-tabs-accor-content ul li {
        margin-top: 1rem !important;
        line-height: 24px !important;
        font-size: 16px !important;
    }

    .egg-education-tabs-accor-content a {
        padding: 4px 10px !important;
        font-size: 18px !important;
    }

    .education-could-highlights-heading {
        font-size: 30px;
        text-align: center;
    }

    .education-cloud-agnostic h2 {
        font-size: 27px;
        text-align: center;
    }

    .education-cloud-agnostic p {
        text-align: center;
    }

    /* ---------------------------our blogs --------------------------- */
}

@media (max-width: 767px) and (min-width: 481px) {


    #about,
    #capabilities,
    #our-blog,
    .education-could-highlights-sec {
        padding-top: 2rem !important;
    }

    /* ---------------------banner section ----------------------------------- */

    .education-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .education-indus-main-bet-content {
        width: 100% !important;
    }

    .education-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .education-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    /* ---------------------indexing section ----------------------------------- */

    .education-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* --------------- about section ------------- */
    .education-about-sec {
        padding-top: 2rem !important;
    }

    .education-section-heading {
        font-size: 40px !important;
        text-align: center;
    }

    .education-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .education-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    .education-sub-img-about-down-box {
        width: 94.5% !important;
    }

    /* ---------- Industries cater section ----------- */

    .education-section-heading::after {
        width: 152px !important;
        margin-left: -262px !important;
        margin-top: -44px !important;
    }

    .education-section-heading {
        font-size: 40px !important;
        text-align: center;
    }

    .education-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .education-main-indus-cater {
        width: 46% !important;
    }

    .education-main-indus-cater {
        padding: 9px !important;
    }

    .education-main-indus-cater h2 {
        font-size: 19px !important;
    }

    .education-main-indus-cater p {
        font-size: 15px !important;
    }

    /* --------------------------- eng  capabilities --------------------------------- */


    .engineering-capabilities-pre-head {
        font-size: 14px !important;
    }

    .engineering-capabilities-head-div {
        height: unset !important;
        width: unset !important;
        padding: 0px 1.5rem;
    }

    .egg-education-sub-section-heading-spec {
        font-size: 35px !important;
    }

    .egg-education-accordion-quest,
    .accordion-button {
        font-size: 20px !important;
    }

    .egg-education-accordion-quest,
    .accordion-button {
        font-size: 20px !important;
    }

    div#egg-education-accordion-tabs,
    #egg-education-col-second-tabs {
        width: 100%;
    }

    .egg-education-accordion-img-tabs {
        height: 300px !important;
    }

    .egg-education-content-img-btn-custo {
        margin-top: 26% !important;
    }

    .egg-education-tabs-accor-content p,
    .egg-education-tabs-accor-content ul li {
        font-size: 16px !important;
        line-height: 26px !important;
    }

    .egg-education-tabs-accor-content a {
        font-size: 18px !important;
    }

    .egg-education-tabs-accor-content {
        margin-top: 1rem !important;
    }

    .education-could-highlights-heading {
        font-size: 28px;
        text-align: center;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    #capabilities,
    #about,
    #our-blog {
        padding-top: 3rem !important;
    }

    /* ---------------------banner section ------------- */

    .education-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .education-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .education-indus-main-bet-content {
        width: 100% !important;
    }

    .education-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 25px 0 25px !important;
    }

    /* ---------------------indexing section ----------------------------------- */

    .education-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* --------------- about section ------------- */
    .education-about-sec {
        padding-top: 2rem !important;
    }

    .education-section-heading::after {
        width: 166px !important;
        margin-left: -549px !important;
        margin-top: 10px !important;
    }

    .education-section-sub-heading {
        line-height: 26px !important;
        font-size: 16px !important;
    }

    .education-sub-img-about-down-box {
        width: 92% !important;
    }

    /* ---------- Industries cater section ----------- */

    .education-section-sub-heading {
        line-height: 26px !important;
        font-size: 16px !important;
    }

    .education-main-indus-cater h2 {
        font-size: 20px !important;
    }

    .education-main-indus-cater p {
        font-size: 16px !important;
    }

    .education-main-indus-cater {
        width: 30% !important;
    }

    /* -------------------------------- eng capabilities ------------------------------------- */



    .engineering-capabilities-pre-head {
        font-size: 14px !important;
    }

    .engineering-capabilities-head-div {
        height: unset !important;
        width: 100% !important;
        padding: 0rem 2rem !important;
    }

    .egg-education-sub-section-heading-spec {
        font-size: 35px !important;
    }

    .egg-education-content-img-btn-custo {
        margin-top: 50% !important;
    }

    .egg-education-tabs-accor-content {
        margin-top: 0px !important;
    }

    .egg-education-tabs-accor-content p,
    .egg-education-tabs-accor-content ul li {
        font-size: 16px !important;
        line-height: 26px !important;
    }

    .egg-education-tabs-accor-content a {
        font-size: 17px !important;
    }

    /* ---------------------------our blogs --------------------------- */
}

@media (max-width: 1200px) and (min-width: 992px) {

    /* ---------------------banner section ----------------------------------- */

    .education-indus-banner {
        padding: 5rem 0 4rem 0 !important;
    }

    .education-indus-main-bet-content {
        width: 100% !important;
    }

    /* --------------- about section ------------- */
    .education-about-sec {
        padding-top: 2rem !important;
    }

    .education-section-sub-heading {
        line-height: 28px !important;
    }

    .education-sub-img-about-down-box {
        width: 94.3% !important;
    }

    /* ---------- Industries cater section ----------- */

    .education-section-sub-heading {
        line-height: 28px !important;
    }

    /* ----------------------------- eng capabilities -----------------------------   */


    .engineering-capabilities-head-div {
        height: unset !important;
        width: 100% !important;
        padding: 0rem 2rem !important;
    }

    .egg-education-sub-section-heading-spec {
        font-size: 40px !important;
    }

    .egg-education-content-img-btn-custo {
        margin-top: 50% !important;
    }

    /* ---------------------------our blogs --------------------------- */
}

@media (max-width: 1400px) and (min-width: 1201px) {

    /* ---------------------banner section ----------------------------------- */
    .education-indus-main-bet-content h3 {
        padding: 25px !important;
    }

    /* ---------------------eng benefits  ----------------------------------- */
    .egg-education-content-img-btn-custo {
        margin-top: 35% !important;
    }
}