/* all heading br display none */
.main-logistic-indus-about-eff br {
    display: none;
}


/* all section padding top */
#about,
#provides,
#services,
#our-blog,
#overview,
#benefits {
    padding-top: 4rem !important;
}



.logistic-main-ind-capab-head-bg::after {
    margin-left: -317px;
}

.main-logistic-indus-whats-new-eff::after {
    width: 107px;
    margin-left: -156px;
    margin-top: 12px;
}


/*=========== ecommerce industry all pages heading and sub heading css ===========*/
.indus-main-logistic-section-heading {
    font-size: 45px;
    font-weight: 700;
    position: relative;
    color: #CD4409;
}

.indus-main-logistic-section-heading span {
    color: #14212b !important;
}

.logistic-main-about-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.logistic-main-about-sub-heading span {
    color: #CD4409;
    font-weight: 700;
}

.logistic-main-about-sub-heading b ul {
    margin-top: 12px;
}

.logistic-main-about-sub-heading b ul {
    text-align: left;
}

/* --------  ites Industry all banner css ---------- */
.logistic-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.logistic-indus-main-banner-head {
    color: #14212B !important;
    margin-right: 19rem;
}

#logistic-indus-main-banner {
    background-color: unset !important;
}

.logistic-indus-main-bet-content h3 {
    width: 100%;
}


.logistic-indus-main-banner-content {
    width: 63%;
}

.logistic-indus-main-banner-content-log-bpo {
    width: 50% !important;
}


.logistic-indus-main-bet-content h3 {
    color: #14212b;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.indus-education-banner-main-btn-logistic {
    margin: 0 !important;
    margin-top: 2rem !important;
}

.logistic-indus-main-bet-content-unique {
    width: 47% !important;
}

.logistic-indus-main-bet-content-unique h3 {
    font-size: 37px !important;
}

.logistic-indus-main-bet-content p {
    color: #14212b;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.indus-logistic-banner-main-btn {
    margin: 2rem 0 0 0;
    text-align: end;
}

.indus-logistic-banner-main-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 8px 20px;
    background: #CD4409;
    font-weight: 600;
    transition: 0.5s;
    margin-left: 10px;
}

.bnr-nbtn-ind-logistic {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-logistic:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.indus-logistic-banner-main-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}




/* ------------------ main page indexing ---------------- */
section.logistic-main-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-logistic-indus {
    position: fixed;
    top: 0;
}

.logistic-main-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.logistic-main-indus-index-nav ul li {
    display: inline-flex;
}

.logistic-main-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.logistic-main-indus-index-nav ul li a.active {
    background-color: #fff !important;
    color: #CD4409 !important;
}

.logistic-main-indus-index-nav ul li:hover,
.logistic-main-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.index-logistic-main-indus-item.active {
    background-color: white;
    color: #CD4409;
}

/* --------------- about section ------------- */
.logistic-ind-sub-img-about {
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.logistic-main-indus-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.logistic-main-indus-front-txt {
    position: relative;
    z-index: 1;
    width: 94.6%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: right;
    margin-bottom: 0.8rem;
}

.logistic-main-indus-front-txt h6 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.logistic-main-indus-front-txt h6::after {
    content: "";
    display: block;
    position: relative;
    width: 12rem !important;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.logistic-main-indus-front-txt h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.logistic-main-indus-front-txt p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

/* ------------Logistic Provides section-------------- */

/* ------------------our capabilities--------------- */
.logistic-main-ind-capabilities-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}


.logistic-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.logistic-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.logistic-ind-inner-area-customer-main:hover .logistic-main-indus-serve-small-box {
    margin-left: 22rem;
}

.logistic-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.logistic-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.logistic-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.logistic-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.logistic-ind-inner-area-customer-main .logistic-main-ind-serve-circle-main .logistic-main-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.logistic-ind-inner-area-customer-main .logistic-main-ind-serve-circle-main .logistic-main-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}


/* ------  whats new in logistics section  ------ */
.logistic-main-whats-new-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}

div#logistic-ind-frid-first-whats-new-sec {
    margin-top: 1rem;
}

img.logistic-ind-whats-new-grid-first-card-img {
    height: 16rem;
    object-fit: cover;
    border-radius: 0;
}

h6.logistic-ind-whats-new-grid-first-card-category {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

h6.logistic-ind-whats-new-grid-first-card-category::after {
    content: "";
    display: block;
    position: relative;
    width: 6.8rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

h5.logistic-ind-whats-new-grid-first-card-title {
    color: #CD4409;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.logistic-ind-whats-new-grid-first-card-link {
    text-decoration: none;
}

p.logistic-ind-whats-new-grid-first-card-para {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.card.logistic-ind-whats-new-grid-first-card {
    border: none;
}

/* ------------- logistic provides section --------- */
.logistics-bpo-prodv-head {
    color: #14212B;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.logistics-bpo-prodv-para {
    color: #262626;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.inner-logistic-main-provides li {
    list-style: none;
    margin: 14px;
}

.inner-logistic-main-provides li a {
    color: #979797;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    transition: 0.5s ease-in;
    text-decoration: none;
}

.inner-logistic-main-provides li a:hover {
    color: #CD4409;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
}

.inner-logistic-main-provides li svg {
    font-size: 18px;
    margin-right: 10px;
    color: #CD4409;
    transition: 0.5s ease-in;
    display: none;
}

.inner-logistic-main-provides li:hover svg {
    display: inline-block;
}

.ind-logis-main-we-think-img {
    width: 100%;
    height: 19rem;
    object-fit: cover;
}

/* -------------------------------------benefits section --------------------------------- */

.logistic-benefits-main {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.logistics-benefits-inner {
    border: 2px dashed #CD4409;
    padding: 1rem;
    margin: 1rem 0;
    width: 30%;
}

.logistics-benefits-inner h3 {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
}

.logistics-benefits-inner p {
    text-align: center;
}


/* ----------------------logistics in BPO page service section -----------------*/
.logistics-bpo-advantage {
    background-image: url("../../../../public/assets/img/sape-down-bp.png");
    background-repeat: no-repeat;
}

.indus-main-logistics-section-heading {
    font-size: 45px;
    font-weight: 700;
    position: relative;
    color: #CD4409;
}

.indus-main-logistics-section-heading span {
    color: #14212b !important;
}

.logistics-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.logistics-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.logistics-ind-inner-area-customer-main:hover .logistics-main-indus-serve-small-box {
    margin-left: 22rem;
}

.logistics-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.logistics-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.logistics-ind-inner-area-customer-main .logistics-main-ind-serve-circle-main .logistics-main-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.logistics-ind-inner-area-customer-main .logistics-main-ind-serve-circle-main .logistics-main-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}

.logistics-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.logistics-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.text-white {
    color: #fff !important;
}

@media only screen and (max-width: 480px) {

    /* all heading br display none */
    .main-logistic-indus-about-eff br {
        display: block;
    }

    .main-logistic-indus-whats-new-eff br {
        display: block;
    }

    /* all headings bg effect */
    .main-logistic-indus-about-eff::after {
        width: 107px !important;
        margin-left: -154px !important;
        margin-top: -43px !important;
    }

    .logi-main-provides-head-bg::after {
        content: unset !important;
    }

    .logistic-main-about-sub-heading {
        font-size: 16px !important;
        line-height: 27px !important;
    }

    .logistic-main-ind-capab-head-bg::after {
        margin-left: -211px !important;
        margin-top: -5px !important;
    }

    .main-logistic-indus-whats-new-eff::after {
        width: 81px;
        margin-left: -103px;
        margin-top: -7px;
    }

    /* --============== all travel pages headings and sub headings ===============- */
    .indus-main-logistic-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    /* all section padding top */
    #about,
    #provides,
    #services,
    #our-blog,
    #overview,
    #benefits {
        padding-top: 2rem !important;
    }

    /* ---- all page banner css -------------- */
    .logistic-indus-banner {
        margin-top: 0% !important;
        padding: 3rem 0 1rem 0 !important;
        height: unset !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .logistic-indus-main-bet-content {
        width: 100% !important;
        text-align: center;
    }

    .logistic-indus-main-bet-content h3 {
        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .logistic-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 26px !important;
        padding: 10px 5px 0 5px !important;
        text-align: center;
    }

    .indus-logistic-banner-main-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    .logistic-main-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .logistic-main-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- about section ------------- */
    .logistic-ind-sub-img-about {
        height: 100% !important;
    }

    .logistic-main-indus-front-txt {
        float: unset !important;
        margin-left: 0rem;
        width: 93.2%;
    }

    .logistic-main-indus-front-txt h4 {
        font-size: 20px !important;
    }

    .logistic-main-indus-front-txt p {
        line-height: 20px !important;
    }

    /* ---------------our capabilities----------- */
    .logistic-main-ind-capabilities-sec {
        background-image: none !important;
    }

    .logistics-bpo-prodv-para {
        font-size: 16px !important;
    }

    /* -------------- service sectoin ---------- */
    .logistic-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .logistic-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .logistic-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .logistic-main-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }

    /* --------------- whats new in logistics section  ------------------- */
    h6.logistic-ind-whats-new-grid-first-card-category::after {
        width: 7rem !important;
    }

    /*------------ logistic provides section--------- */
    .inner-logistic-main-provides {
        margin: 0 !important;
        padding: 0 !important;
    }

    .inner-logistic-main-provides li {
        margin: 20px 5px 20px 5px !important;
    }

    .inner-logistic-main-provides li a {
        font-size: 20px !important;
    }


    .logistics-benefits-inner {

        width: 95%;
    }

    /* ---------------------------services ------------------------------------*/

    .indus-main-logistics-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .logistics-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .logistics-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .logistics-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .logistics-main-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }
}

@media (max-width: 767px) and (min-width: 481px) {

    /* all section padding top */
    #about,
    #provides,
    #services,
    #our-blog,
    #overview,
    #benefits {
        padding-top: 2rem !important;
    }

    .main-logistic-indus-about-eff br {
        display: none;
    }

    .main-logistic-indus-whats-new-eff br {
        display: block;
    }

    /* all heading background effect*/
    .main-logistic-indus-about-eff::after {
        margin-left: -404px !important;
        margin-top: 3px !important;
    }

    .logi-main-provides-head-bg::after {
        margin-left: -398px !important;
        margin-top: 3px !important;
    }


    .logistic-main-ind-capab-head-bg::after {
        margin-left: -277px !important;
        margin-top: 4px;
    }

    .main-logistic-indus-whats-new-eff::after {
        width: 92px;
        margin-left: -133px;
        margin-top: 1px;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-logistic-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    /* ---------------indexing section--------- */
    .logistic-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* ---- all page banner css -------------- */
    .logistic-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .logistic-indus-main-bet-content {
        width: 100% !important;
    }

    .logistic-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .logistic-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-logistic-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    /* --------------- about section ------------- */

    .logistic-ind-sub-img-about {
        height: 300px !important;
    }

    .logistic-main-indus-front-txt {
        float: unset !important;
        width: 95% !important;
        padding-bottom: 13px;
    }

    .logistic-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- our capabilities sectoin ---------- */
    .logistic-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .logistic-ind-inner-area-customer-main p {
        margin-right: 15px !important;
    }

    /* --------------- whats new in logistics section css ------------------- */

    .triangle-down-insurance-think {
        top: 65% !important;
    }

    p.logistic-ind-whats-new-grid-first-card-para {
        line-height: 21px !important;
    }

    img.logistic-ind-whats-new-grid-first-card-img {
        height: 14rem !important;
    }

    .logistics-benefits-inner {
        width: 95%;
    }

    /* --------------------services -------------------------------*/

    .indus-main-logistics-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .indus-main-logistics-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    #logistics-ind-capabilities-main {
        width: 100% !important;
    }

    .logistics-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .logistics-ind-inner-area-customer-main p {
        margin-right: 15px !important;
        text-align: justify !important;
    }


}

@media (max-width: 991px) and (min-width: 768px) {

    /* all heading br */
    .main-logistic-indus-whats-new-eff br {
        display: block;
    }

    #about,
    #provides,
    #services,
    #our-blog,
    #overview,
    #benefits {
        padding-top: 3rem !important;
    }

    /* all heading background effect */
    .main-logistic-indus-about-eff::after {
        margin-top: 12px !important;
    }

    .logistic-main-ind-capab-head-bg::after {
        margin-left: -320px !important;
        margin-top: 10px !important;
    }

    .main-logistic-indus-whats-new-eff::after {
        width: 106px;
        margin-left: -154px;
        margin-top: 7px;
    }

    /* ---- all page banner css -------------- */
    .logistic-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .logistic-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .logistic-indus-main-bet-content {
        width: 100% !important;
    }

    .logistic-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 25px 0 25px !important;
    }

    /* --------------ites indexing section------------ */
    .logistic-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* about ites section start */
    .logistic-main-indus-front-txt {
        float: unset !important;
        width: 93%;
    }

    .logistic-main-indus-front-txt p {
        line-height: 19px !important;
    }

    .inner-logistic-main-provides li a {
        font-size: 20px !important;
    }

    .inner-logistic-main-provides li {
        margin: 10px !important;
    }

    .logistics-bpo-prodv-para {
        font-size: 16px !important;
        line-height: 23px !important;
    }

    /* -------------- Our Capabilities ---------- */

    div#logistic-ind-capabilities-main {
        width: 50%;
    }

    .logistic-ind-inner-area-customer-main h5 {
        font-size: 22px !important;
    }

    /* --------------- whats new section --------------- */
    #logistic-ind-frid-first-whats-new-sec {
        width: 50% !important;


    }

    div#logistics-ind-capabilities-main {
        width: 50%;
    }

    .logistics-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {

    /* all heading br  */
    .main-logistic-indus-whats-new-eff br {
        display: block;
    }

    /* ---- all page banner css -------------- */

    .logistic-indus-main-bet-content {
        width: 80% !important;
    }

    /* --------------- about section ------------- */
    .logistic-main-indus-front-txt {
        float: unset !important;
        width: 94.4% !important;
    }

    .logistic-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- service sectoin ---------- */
    .logistic-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* -------------- service sectoin ---------- */
    .logistics-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }
}

@media (max-width: 1400px) and (min-width: 1201px) {

    /* ---- all page banner css -------------- */
    .logistic-indus-main-bet-content h3 {
        padding: 25px !important;
    }

    .logistic-main-indus-front-txt {
        width: 95% !important;
    }

    /* -------------- service sectoin ---------- */
    .logistic-ind-inner-area-customer-main {
        padding: 15px 5px 15px 15px !important;
    }

    /* -------------- service sectoin ---------- */
    .logistics-ind-inner-area-customer-main {
        padding: 15px 0px 0px 15px !important;
    }

}

#it-main-service-sec-effect::after {
    width: 146px !important;
    margin-left: -457px !important;
}

#it-main-indus-cater-sec-effect::after {
    width: 150px !important;
    margin-left: -270px !important;
}

/* ---------banner section --------------- */
.it-main-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

#tech-stack {
    padding-top: 4rem;
}

.it-indus-main-bet-content h3 {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
    display: inline-block;
}

.it-indus-main-banner-content {
    width: 80%;
}

.indus-it-banner-main-btn {
    margin: 3rem 0;
    text-align: end;
    width: 100%;
}

.indus-it-banner-main-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 10px 25px;
    background: #CD4409;
    font-weight: 600;
    transition: all 0.5s;
    margin-left: 10px;
}

.indus-it-banner-main-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-indus-it {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-indus-it:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

/* ---------- indexing section --------------- */
section.it-main-indus-page-index {
    background: #CD4409;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.it-main-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.it-main-indus-index-nav ul li {
    display: inline-flex;
}

.it-main-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.it-main-indus-index-nav ul li a.active {
    background-color: white;
    color: #CD4409;
}

.it-main-indus-index-nav ul li:hover,
.it-main-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.sticky-it {
    position: fixed;
    top: 0;
}

.index-it-main-indus-item.active {
    background-color: white;
    color: #CD4409;
}

/* --------------- about section ------------- */
.it-main-about-sec {
    padding-top: 4rem;
}

.it-main-section-heading {
    color: #CD4409 !important;
    font-size: 45px;
    font-weight: 700;
    position: relative;
}

.it-main-section-heading span {
    color: #14212b !important;
}

.it-main-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.it-main-section-sub-heading ul {
    margin-top: 1rem;
}

.it-main-section-sub-heading ul li {
    font-weight: 600;
}

.it-main-section-sub-heading span {
    color: #CD4409;
    font-weight: 600;
}

.it-main-section-sub-heading b {
    font-weight: 600 !important;
}

.it-main-sub-img-about {
    height: 18rem;
    width: 100%;
    object-fit: cover;
}

.it-main-sub-img-about-down-box {
    width: 95.3%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: left;
    margin-bottom: 0.8rem;
}

.it-main-sub-img-about-down-box h6 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.it-main-sub-img-about-down-box h6::after {
    content: "";
    display: block;
    position: relative;
    width: 8.7rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.it-main-sub-img-about-down-box h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.it-main-sub-img-about-down-box p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.it-main-sub-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

/* ------------ service section --------------- */
.it-main-service-sec {
    padding-top: 4rem;
}

.it-main-service-head-add {
    margin-top: 3rem;
}

.it-main-service-head-add h3 {
    font-size: 35px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #CD4409;
}

.it-main-service-content {
    border: 2px solid #CD4409;
    padding: 10px;
    position: relative;
    margin-top: 1rem;
    padding-right: 2.5rem;
}

.it-main-service-content h4 {
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
    color: #14212B;
}

.it-main-service-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin-right: 5px;
    color: #696969;
}

.it-main-service-content-small-box-down {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.it-main-service-content:hover {
    border: 2px solid #14212b;
}

.it-main-service-content:hover .it-main-service-content-small-box-down {
    background: #14212b;
}

.it-main-service-content-small-box-down svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

/* ---------- expertise section ----------- */
.it-main-indus-cater-sec {
    background-image: url('../../../../public/assets/img/bpo/under-back.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.it-main-indus-expertise {
    border: 1px solid #b3b3b3af;
    padding: 15px;
    border-left: 5px solid #CD4409;
    margin-top: 1rem;
}

.it-main-indus-expertise h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.it-main-indus-expertise p {
    color: #696969;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* / ----------------  it indus css -----------------  / */
.it-main-technology-sec {
    padding-top: 4rem;
}

.it-tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 1rem 0 1rem;
}

.it-inner-title {
    border: 1px solid #c1c1c1;
    border-left: 4px solid #CD4409;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.it-inner-title h3 {
    font-weight: 700;
}

.it-inner-img-icon {
    border: 1px solid #c1c1c1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100px;
}

.it-inner-img-icon img {
    width: 50px;
    height: 50px;
    object-fit: contain;

}

.tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.816);
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    bottom: -30px;
    left: 0;
    z-index: 1;
}

#angular:hover>.tooltiptext,
#css:hover>.tooltiptext,
#html:hover>.tooltiptext,
#mysql:hover>.tooltiptext,
#mongodb:hover>.tooltiptext,
#postgresql:hover>.tooltiptext,
#wordpress:hover>.tooltiptext,
#magenta:hover>.tooltiptext,
#shopify:hover>.tooltiptext,
#salesforce:hover>.tooltiptext,
#java:hover>.tooltiptext,
#swift:hover>.tooltiptext,
#kotlin:hover>.tooltiptext,
#react-native:hover>.tooltiptext,
#web3:hover .tooltiptext,
#blockchain:hover>.tooltiptext,
#oracle:hover>.tooltiptext,
#python:hover>.tooltiptext,
#node-js:hover>.tooltiptext,
#express:hover>.tooltiptext,
#php:hover>.tooltiptext,
#ruby:hover>.tooltiptext,
#dotnet:hover>.tooltiptext,
#django:hover>.tooltiptext,
#flask:hover>.tooltiptext,
#aws:hover>.tooltiptext,
#azure:hover>.tooltiptext,
#gcs:hover>.tooltiptext {
    visibility: visible;
}

.technplogy-tab-container {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    padding-bottom: 1rem;

}

.technplogy-tab-container button {
    cursor: pointer;
    background-color: #EFEFEF;
    color: #14212B;
    font-weight: 700;
    font-size: 16px;
    border: none;
    padding: 12px 0;
    border-radius: 25px;
    transition: all 0.5s ease;
    width: 10rem;
}

.technplogy-tab-container button:hover {
    color: #ffffff;
    background: #F1742D;
}


@media screen and (max-width: 480px) {
    #tech-stack {
        padding-top: 2rem !important;
    }

    .it-main-service-sec,
    .it-main-technology-sec {
        padding-top: 2rem !important;
    }

    .it-main-service-head-add {
        margin-top: 2rem !important;
    }


    .it-main-service-head-add h3 {
        font-size: 30px !important;
        text-align: center !important;

    }

    .it-main-section-heading::after {
        width: 100px !important;
        height: 32px !important;
        margin-left: -236px !important;
        margin-top: 2px !important;
    }

    /* ---------- banner section ----------- */
    .it-main-indus-banner {
        margin-top: 0% !important;
        padding: 3rem 0 !important;
        height: unset !important;
    }

    .it-indus-main-bet-content {
        width: 100% !important;
        text-align: center;
    }

    .indus-it-banner-main-btn {
        margin: 3rem 0 0 0 !important;
    }

    .it-indus-main-bet-content h3 {
        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .indus-it-banner-main-btn a {
        display: block !important;
        margin: 1rem !important;
        padding: 10px 20px !important;
        text-align: center !important;
    }

    /* ------------- indexing section ------------------ */
    .it-main-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .it-main-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- about section ------------- */
    .it-main-about-sec {
        padding-top: 2rem !important;
    }

    .it-main-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .it-main-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .it-main-sub-img-about-down-box {
        width: 91.3% !important;
    }

    .it-main-sub-img-about-down-box h4 {
        font-size: 18px !important;
    }

    .it-main-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    /* ----------- our expertise section ------------- */
    .it-main-indus-expertise {
        height: max-content !important;
        padding: 7px !important;
    }

    .it-tech-stack {
        justify-content: center;
    }

    .it-inner-img-icon {
        border: 1px solid #c1c1c1;
        padding: 10px;
        width: 31%;
    }

    .technplogy-tab-container {
        justify-content: center !important;
    }

    .technplogy-tab-container button {
        font-size: 12px;
        width: 8rem;
        padding: 10px !important;
    }
}

@media (max-width: 767px) and (min-width: 481px) {

    .it-main-service-sec,
    .it-main-technology-sec {
        padding-top: 2rem !important;
    }

    #tech-stack {
        padding-top: 2rem !important;
    }

    .it-main-service-head-add {
        margin-top: 2rem !important;
    }

    .it-main-service-head-add h3 {
        font-size: 30px !important;
        text-align: center !important;

    }

    .it-main-section-heading::after {
        width: 130px !important;
        margin-left: -312px !important;
        margin-top: 5px !important;
    }

    /* ------- banner section ----------------- */
    .it-main-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
    }

    .it-indus-main-bet-content {
        width: 100% !important;
    }

    .it-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    /* -------------- indexing section ------------------ */
    .it-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* --------------- about section ------------- */
    .it-main-about-sec {
        padding-top: 2rem !important;
    }

    .it-main-section-heading {
        font-size: 40px !important;
        text-align: center;
    }

    .it-main-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .it-main-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    .it-main-sub-img-about-down-box {
        width: 94.5% !important;
    }

    /* ----------- our expertise section ------------- */
    .it-main-indus-expertise {
        padding: 9px !important;
    }

    .it-main-indus-expertise h2 {
        font-size: 19px !important;
    }

    .it-main-indus-expertise p {
        font-size: 15px !important;
    }

    .it-inner-img-icon {
        width: 15%;
    }

    .technplogy-tab-container {
        justify-content: center !important;
    }

    .technplogy-tab-container button {
        font-size: 12px;
        width: 8rem;
        padding: 10px !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    .it-main-service-sec,
    .it-main-technology-sec {
        padding-top: 3rem !important;
    }

    #tech-stack {
        padding-top: 3rem !important;
    }

    .it-main-service-head-add {
        margin-top: 3rem !important;
    }

    /* ---------------------banner section ------------- */
    .it-main-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
    }

    .it-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .it-indus-main-bet-content {
        width: 100% !important;
    }

    /* --------------- indexing section -------------- */

    .it-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* --------------- about section ------------- */
    .it-main-about-sec {
        padding-top: 2rem !important;
    }

    .it-main-section-heading {
        font-size: 40px !important;
    }

    .it-main-section-heading::after {
        content: unset !important;
    }

    .it-main-section-sub-heading {
        line-height: 26px !important;
        font-size: 16px !important;
    }

    .it-main-sub-img-about-down-box {
        width: 92% !important;
    }

    /* ----------- our expertise section ------------- */
    .it-main-indus-expertise h2 {
        font-size: 20px !important;
    }

    .it-main-indus-expertise p {
        font-size: 16px !important;
    }

    .technplogy-tab-container {
        justify-content: center !important;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {

    /* --------------- about section ------------- */
    .it-main-about-sec {
        padding-top: 2rem !important;
    }

    .it-main-section-sub-heading {
        line-height: 28px !important;
    }

    .it-main-sub-img-about-down-box {
        width: 94.3% !important;
    }

}