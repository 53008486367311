.manufacturing-provides-main-page-efft::after {

  width: 183px !important;
  margin-left: -458px !important;
}

.industries-cater-head-effect-manufact::after {
  width: 169px !important;
  margin-left: -561px !important;
}

#capabilities,
#trending-insights,
#challenges {
  padding-top: 4rem !important;
}

/* -================ all comman page css =========- */
/* --------  comman page banner css ---------- */
.manufacturing-indus-banner-main {
  /* margin-top: 10.5rem; */
  /* padding: 10rem 0 4rem 0; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 450px;
  display: flex;
  align-items: center;
}

.manufacturing-indus-content h3 {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  border-top: 3px solid #CD4409;
  border-bottom: 3px solid #CD4409;
  border-left: 3px solid #CD4409;
  padding: 35px;
}

.manufacturing-indus-content {
  width: 76%;
}

.manufacturing-indus-content p {
  color: #fff;
  font-size: 20px;

}

.manufacturing-indus-banner-btn {
  margin: 2rem 0;
  text-align: end;
}

.manufacturing-indus-banner-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-left: 10px;
}

.manufacturing-indus-banner-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-manufacturing-indus {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-manufacturing-indus:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

/*=========== industry technology all pages heading and sub heading css ===========*/
.manufacturing-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.manufacturing-section-heading-dgt {
  font-size: 43px !important;
}

.manufacturing-section-heading span {
  color: #14212b !important;
}

.manufacturing-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 30px;
}

.manufacturing-section-sub-heading span {
  color: #CD4409;
  font-weight: 600;
}

.manufacturing-section-sub-heading b {
  font-weight: 600 !important;
}

/* ------------------ main page indexing ---------------- */
section.manufacturing-indus-page-index {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 90px;
  width: 100%;
  z-index: 9;
}

.sticky-manufacturing {
  position: fixed;
  top: 0;
}

.manufacturing-indus-index-nav ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.manufacturing-indus-index-nav ul li {
  display: inline-flex;
}

.manufacturing-indus-index-nav ul li a {
  color: #fff;
  font-size: 20px;
  padding: 5px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.manufacturing-indus-index-nav ul li:hover,
.manufacturing-indus-index-nav ul li a:hover {
  background-color: #ffffff;
  color: #CD4409;
}

.manufacturing-indus-index-item.active {
  background-color: white;
  color: #CD4409;
}

/* --------------- about section ------------- */
.manufacturing-about-sec {
  padding-top: 4rem;
}

.manufacturing-sub-img-about {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.manufacturing-sub-triangle-down-right {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-top: 42px solid #14212b;
  float: right;
}

.manufacturing-sub-img-about-down-box {
  width: 95.3%;
  height: max-content;
  padding: 12px 18px 2px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  float: left;
  margin-bottom: 0.8rem;
}

.manufacturing-sub-img-about-down-box h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.manufacturing-sub-img-about-down-box h6::after {
  content: "";
  display: block;
  position: relative;
  width: 9.7rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.manufacturing-sub-img-about-down-box h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.manufacturing-sub-img-about-down-box p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* -------- support provides section --------  */
.visible-provide-manufacturing {
  display: block !important;
}

.hidden-provide-manufacturing {
  display: none;
}

.service-provides-beno-manufacturing {
  position: relative;
  margin-bottom: 30px;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing {
  position: relative;
  overflow: hidden;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact {
  position: relative;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact img {
  position: relative;
  width: 100%;
  height: 350px !important;
  object-fit: cover;
  display: block;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .caption-serv-prov-manufact {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  color: #ffffff;
  display: block;
  font-size: 22px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact {
  position: absolute;
  left: 0px;
  bottom: -400px;
  padding: 40px 35px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact p {
  position: relative;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
  line-height: 1.6em;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact:hover .caption-serv-prov-manufact {
  bottom: -60px;
}

.service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact:hover .overlay-box-serv-prov-manufact {
  bottom: 0px;
}

.btn-div-manufacturing-main {
  margin-top: 2rem;
}

.load-more-manufacturing-main-btn {
  background: #CD4409;
  border: none !important;
  border-radius: 33px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding: 8px 20px !important;
  text-decoration: none;
}

.load-more-manufacturing-main-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ---------- Industries cater section ----------- */
.manufacturing-indus-cater-sec {
  background-image: url('../../../../public/assets/img/bpo/under-back.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.manufact-main-indus-cater {
  width: 31% !important;
  border: 1px solid #b3b3b3af;
  margin: 10px;
  padding: 15px;
  border-left: 5px solid #CD4409;
}

.manufact-main-indus-cater h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.manufact-main-indus-cater p {
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* --------- Engineering Capabilities  section --------------------- */

.engineering-capabilities-pre-head {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.engineering-capabilities-head-div {
  height: 100%;
  border-left: 5px solid #CD4409;
  padding: 0px 3rem;
  width: 60%;
}

.egg-manufact-sub-section-heading-spec {
  color: #14212B;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.egg-manufact-sub-section-heading-spec span {
  color: #CD4409;
}

/* ------ accordition code -----*/
.egg-manufac-accordion-main {
  margin-top: 3rem;
}

.accordion-button::after {
  background-image: none;
  content: "\002B";
  font-size: 25px !important;
  font-weight: bold !important;
  position: absolute;
  right: 1rem;
  width: 28px;
  line-height: 28px !important;
  height: 28px;
  background: #000;
  border-radius: 30px;
  margin: 0px 0px !important;
  padding: 0px 6px 1px 7px !important;
  color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(0deg);
  content: "\2212";
}

.accordion-item {
  background-color: #fff;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}

.egg-manufac-accordion-quest,
.accordion-button {
  color: #14212B;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  background-color: #fff !important;
  line-height: 30px;
}

.egg-manufac-accordion-quest span {
  color: #CD4409;
  font-size: 25px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}

.egg-manufac-accordion-img-tabs {
  width: 100%;
  height: 340px;
  background-size: cover;
}

.egg-manufac-content-img-btn-custo {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  margin-top: 34%;
  margin-left: 50% !important;
  border-radius: 50%;
  color: #fff;
  padding: 6px 0 0 18px;
}

.egg-manufac-content-img-btn-custo:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 65px;
  height: 65px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.egg-manufac-content-img-btn-custo span svg {
  position: relative;
  z-index: 3;
  font-size: 26px;
}

.egg-manufac-content-img-btn-custo:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

p.egg-manufac-img-fifth-btn {
  color: #14212B;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.egg-manufac-tabs-accor-content p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
}

.egg-manufac-tabs-accor-content ul li {
  color: #262626;
  font-size: 17px;
  font-weight: 600;
  line-height: 35px;
}

.egg-manufac-tabs-accor-content a {
  background: #CD4409;
  padding: 5px 15px;
  margin: 20px 15px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  color: #fff;
}

.egg-manufac-tabs-accor-content a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}



/* =========
  ==================
  ========================
  manufacturing in digital transformation  page start
  ========================
  ==================
  ============ 
  */

/*---------------- manufacturing in dgt page about section start---------------  */

.manufacturing-heading-effect-dispnone::after {
  display: none;
}

/* --------trending insights and tools section----------- */
ul.insurance-experience-tabs {
  display: inline-flex;
  width: 100%;
  margin: 2rem 0px;
}

ul.insurance-experience-tabs li {
  list-style: none;
  margin: 0 4% 1% 0;
  color: #333;
  position: relative;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.insurance-experience-tabs .nav-item button.active {
  background-color: transparent;
  color: #333 !important;
}

.insurance-experience-tabs .nav-item button.active::after {
  content: "";
  border-bottom: 4px solid #CD4409;
  width: 175px;
  position: absolute;
  left: -5px;
  bottom: -14px;
  border-radius: 4px 4px 0 0;
}

/* -------- manufacturing section evolving ----------------- */

.manufacturing-section-main ul li {
  font-weight: 600;
}

.manufacturing-section-main ol li {
  font-weight: 600;
}


/* ----------------------ites in BPO page service section -----------------*/
.ites-bpo-advantage {
  background-image: url("../../../../public/assets/img/sape-down-bp.png");
  background-repeat: no-repeat;
}

.ites-ind-inner-area-customer-main {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.ites-ind-inner-area-customer-main:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #CD4409;
}

.ites-ind-inner-area-customer-main:hover .ites-main-indus-serve-small-box {
  margin-left: 22rem;
}

.ites-ind-inner-area-customer-main h5 {
  margin-right: 20px;
  color: #14212b;
  font-size: 25px;
  font-weight: 600;
}

.ites-ind-inner-area-customer-main p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.ites-ind-inner-area-customer-main .ites-main-ind-serve-circle-main .ites-main-ind-serve-cir-inr {
  color: #696969 !important;
  font-size: 17px;
  line-height: 28px;
  margin-right: 10px;
}

.ites-ind-inner-area-customer-main .ites-main-ind-serve-circle-main .ites-main-ind-serve-cir-inr span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

.ites-ind-inner-area-customer-main:hover .ites-main-indus-serve-small-box {
  margin-left: 22rem;
}

.ites-main-indus-serve-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.ites-main-indus-serve-small-box svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.ites-ind-inner-area-customer-main:hover .ites-main-indus-serve-small-box {
  margin-left: 22rem;
}

.ites-main-indus-serve-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.ites-main-indus-serve-small-box svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}


/* -------------our services and solution section---------------- */

.itescyber-digital-solution {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px;
  height: 15rem;
  position: relative;
  margin-top: 1rem !important;
}

.itescyber-digital-solution-img-dep img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

.itescyber-digital-solution-content-dep h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

.itescyber-digital-solution-content-dep p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.itescyber-digital-solution:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

.itescyber-digital-solution:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

/* -=========================== cloud services page css ================== */
/* -------- cloud Highlights section ----------------- */
.manufacturing-could-highlights-sec {
  margin-top: 5rem;
}

.manufacturing-could-highlights-heading {
  color: #262626;
  font-size: 33px;
  font-weight: 600;
  line-height: 40px;
}

.manufacturing-could-highlights-content {
  margin-top: 1rem;
  border-left: 4px solid #CD4409;
  padding: 2rem 0 2rem 3rem;
}


.manufacturing-could-highlights-content ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

.manufacturing-could-highlights-content ul li::before {
  content: "\2022";
  color: #CD4409;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 27px;
  margin-left: -1em;
}

.manufacturing-could-highlights-content ul li {
  color: #262626;
  font-size: 17px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

/* ----------------------manufacturing-cloud-agnostic -------------------*/

.manufacturing-could-highlights-sec p {
  font-size: 21px;
  font-weight: 600;
  line-height: 36px;
  padding: 1rem 0 0 0;
}

.manufacturing-cloud-agnostic {
  border: none;
  padding: 0 0 0rem 2rem;
}

.manufacturing-cloud-agnostic ul li span {
  font-weight: 700;
}


.manufacturing-challenges-para {
  color: #262626;
  font-size: 16px;
}

.challenges-left-main-head {
  padding-right: 2rem;
}

.challenges-right-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 26rem;
  width: 80%;
}



.challenges-left-arrow {
  color: #CD4409;
  font-size: 30px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.challenges-left-arrow h3 {
  color: #CD4409;
  font-size: 30px;
}

.challenges-left-arrow p {
  margin-left: 2.5rem;
  color: #111111;
}



.challenges-left-arrow svg {
  color: #CD4409;
  font-size: 30px;
  margin-top: -5px;
}

.challenges-left-li {
  margin-left: 2.5rem;
}

.challenges-left-li p {
  font-size: 16px;
  color: #979797;

}

.challenges-left-li>p {
  transition: all 0.3s ease-in;
  cursor: pointer;
  display: inline-block;
}

.challenges-left-li>p:hover {
  color: #CD4409;
}

.challenges-left-arrow.show {
  opacity: 1;
  max-height: 500px;
}


.manufacturing-hr-talent-head {
  display: inline-block;

}

.itescyber-digital-solution {
  height: 18rem !important;
}

.manufacturing-digital-solution-img-dep img {
  height: 16.7rem !important;
}

.bfsi-serve-exp-desc-right-aps p {
  color: black !important;
}

.challenges-left-arrow-aps h3 {
  color: black !important;
}

@media only screen and (max-width: 480px) {

  /* --------------- heading effect --------------------- */
  .manufacturing-section-heading::after {
    content: unset !important;
  }

  /* ---- all page banner css -------------- */
  .manufacturing-indus-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 1rem 0 !important;
    height: 100% !important;
  }

  .manufacturing-indus-content h3 {
    width: 100%;
  }

  .manufacturing-indus-content h3 {
    text-align: center;
    padding: 0 5px !important;
    font-size: 35px !important;
  }

  .manufacturing-indus-banner-btn a {
    display: block !important;
    margin: 1rem !important;
    text-align: center !important;
    padding: 10px 20px !important;
  }

  .manufacturing-indus-content {
    width: 100% !important;
  }

  /* --============== all technology pages headings and sub headings ===============- */

  .manufacturing-section-heading {
    font-size: 30px !important;
    text-align: center;
    position: relative;
  }

  .manufacturing-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* ---------------- page indexing css --------------- */
  .manufacturing-indus-index-nav ul li a {
    font-size: 17px !important;
    padding: 5px 15px !important;
  }

  .manufacturing-indus-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- about section ------------- */
  .manufacturing-about-sec {
    padding-top: 2rem !important;
  }

  .manufacturing-sub-img-about-down-box {
    width: 91.3% !important;
  }

  .manufacturing-sub-img-about-down-box h4 {
    font-size: 18px !important;
  }

  .manufacturing-sub-img-about {
    padding-top: 2rem !important;
    height: 300px !important;
  }

  /* --------------- main page providers secton -------------- */

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact p {
    line-height: 22px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact h4 {
    font-size: 22px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact {
    padding: 20px 8px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .caption-serv-prov-manufact {
    font-size: 18px !important;
    padding: 15px 0px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact img {
    height: 100% !important;
  }

  /* ---------- Industries cater section ----------- */
  .manufact-main-indus-cater {
    width: 94% !important;
    height: max-content !important;
    padding: 7px !important;
  }

  /* --------- Engineering Capabilities  section --------------------- */
  #capabilities,
  #trending-insights,
  #challenges {
    padding-top: 2rem !important;
  }

  .engineering-capabilities-pre-head {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .egg-manufact-sub-section-heading-spec {
    font-size: 30px !important;
  }

  .egg-manufac-tabs-accor-content p,
  .egg-manufac-tabs-accor-content ul li {
    margin-top: 1rem !important;
    line-height: 24px !important;
    font-size: 16px !important;
  }

  .egg-manufac-tabs-accor-content a {
    padding: 4px 10px !important;
    font-size: 18px !important;
  }

  .egg-manufac-accordion-img-tabs {
    height: 235px !important;
  }

  .egg-manufac-accordion-quest,
  .accordion-button {
    font-size: 18px !important;
    line-height: 23px !important;
  }

  .engineering-capabilities-head-div {
    height: unset !important;
    padding: 0px 2% !important;
    width: 100% !important;
  }

  .egg-manufac-accordion-main {
    margin-top: 2rem !important;
  }

  .accordion-flush .accordion-item .accordion-button,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    padding: 13px 5px !important;
  }

  .accordion-button::after {
    font-size: 18px !important;
    right: 0.2rem !important;
    width: 20px !important;
    line-height: 22px !important;
    height: 20px !important;
    padding: 0px 3px 1px 5px !important;
  }

  .indus-ites-section-heading {
    font-size: 30px !important;
    text-align: center;
    position: relative;

  }

  .itescyber-digital-solution {
    height: unset !important;
  }


  /* ------------------insurance-experience-tab--------------------*/
  ul.insurance-experience-tabs {
    display: grid;
    padding: 0;
  }

  .insurance-experience-tabs .nav-item button {
    background-color: #fff !important;
    color: #CD4409 !important;
    outline: auto;
    padding: 2% 6% !important;
    border-radius: 25px;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .insurance-experience-tabs .nav-item button.active {
    background-color: #CD4409 !important;
    color: #fff !important;
    padding: 2% 6% !important;
    font-size: 15px;
  }

  .insurance-experience-tabs .nav-item button.active::after {
    width: 0px !important;
  }

  .manufacturing-could-highlights-sec {
    margin-top: 2rem !important;
  }

  .manufacturing-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  .manufacturing-could-highlights-heading {
    font-size: 30px;
    line-height: 29px;
  }

  .ites-bpo-advantage {
    padding-top: unset !important;
  }

  .challenges-left-arrow h3 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .challenges-left-arrow p {
    margin-left: unset !important;
    text-align: center !important;
  }

  .challenges-right-img {
    height: 20rem !important;
    width: 100% !important;
  }

  .challenges-left-li {
    margin-left: unset !important;
  }

  .challenges-left-li p {
    text-align: center !important;
  }
}

@media (max-width: 767px) and (min-width: 481px) {

  /* ----------------------- heading efffect ---------------- */
  .manufacturing-section-heading::after {
    width: 152px !important;
    margin-left: -262px !important;
    margin-top: -44px !important;
  }

  .manufacturing-provides-main-page-efft::after {
    width: 183px !important;
    margin-left: -412px !important;
    margin-top: 6px !important;
  }

  .industries-cater-head-effect-manufact::after {
    width: 169px !important;
    margin-left: -499px !important;
    margin-top: 6px !important;
  }

  /* ---- all page banner css -------------- */
  .manufacturing-indus-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
  }

  .manufacturing-indus-content h3 {
    width: 100% !important;
  }

  .manufacturing-indus-content h3 {
    text-align: center;
    padding: 0 10px !important;
    font-size: 40px !important;
  }

  .manufacturing-indus-banner-btn {
    text-align: center !important;
  }

  .manufacturing-indus-content {
    width: 100% !important;
  }

  .manufacturing-indus-content p {
    text-align: center !important;
  }

  /* ---------------- page indexing ------------------ */
  .manufacturing-indus-index-nav ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .manufacturing-indus-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- about section ------------- */
  .manufacturing-about-sec {
    padding-top: 2rem !important;
  }

  .manufacturing-sub-img-about {
    padding-top: 2rem !important;
    height: 300px !important;
  }

  .manufacturing-sub-img-about-down-box {
    width: 94.5% !important;
  }

  /* --------------- all technology pages heading and sub heading ---------- */
  .manufacturing-section-heading {
    font-size: 40px !important;
    text-align: center;
  }

  .manufacturing-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* ---------- Industries cater section ----------- */
  .manufact-main-indus-cater {
    width: 46% !important;
  }

  .manufact-main-indus-cater {
    padding: 9px !important;
  }

  .manufact-main-indus-cater h2 {
    font-size: 19px !important;
  }

  .manufact-main-indus-cater p {
    font-size: 15px !important;
  }

  /* --------- Engineering Capabilities  section --------------------- */
  #capabilities,
  #trending-insights,
  #challenges {
    padding-top: 2rem !important;
  }

  .engineering-capabilities-pre-head {
    font-size: 14px !important;
  }

  .egg-manufact-sub-section-heading-spec {
    font-size: 35px !important;
  }

  .engineering-capabilities-head-div {
    height: unset !important;
    width: unset !important;
    padding: 0px 1.5rem;
  }

  .egg-manufac-accordion-quest,
  .accordion-button {
    font-size: 20px !important;
  }

  .egg-manufac-tabs-accor-content p,
  .egg-manufac-tabs-accor-content ul li {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .egg-manufac-tabs-accor-content a {
    font-size: 18px !important;
  }

  div#egg-manufac-accordion-tabs,
  #egg-manufac-col-second-tabs {
    width: 100%;
  }

  .egg-manufac-accordion-img-tabs {
    height: 300px !important;
  }

  .egg-manufac-tabs-accor-content {
    margin-top: 1rem !important;
  }

  .egg-manufac-content-img-btn-custo {
    margin-top: 26% !important;
  }

  .indus-ites-section-heading {
    font-size: 39px !important;
    text-align: center;
  }

  .indus-ites-section-heading {
    font-size: 39px !important;
    text-align: center;
  }

  .itescyber-digital-solution {
    height: unset !important;
  }

  /* --------------------------insurence experience ----------------------------*/

  .insurance-experience-tabs .nav-item button.active::after {
    left: 0px !important;
    bottom: -8px !important;
    width: 75px !important;
  }



  .nav-item button {
    font-size: .9rem;
  }

  /* ------------------- cloud service ------------------ */
  .manufacturing-could-highlights-sec {
    margin-top: 2rem !important;
  }

  .manufacturing-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  .challenges-left-arrow h3 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .challenges-left-arrow p {
    margin-left: unset !important;
    text-align: center !important;
  }

  .challenges-right-img {
    height: 20rem !important;
    width: 100% !important;
  }

  .challenges-left-li {
    margin-left: unset !important;
  }

  .challenges-left-li p {
    text-align: center !important;
  }

  .challenges-left-main-head {
    padding-right: unset !important;
  }

}

@media (max-width: 991px) and (min-width: 768px) {


  /* ---- all page banner css -------------- */
  .manufacturing-indus-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
  }

  .manufacturing-indus-content h3 {
    width: 100% !important;
  }

  .manufacturing-indus-content h3 {
    text-align: center;
    padding: 0 25px !important;
  }

  .manufacturing-indus-banner-btn {
    text-align: right !important;
  }

  .manufacturing-indus-banner-btn a {
    margin: 0 1rem !important;
  }

  /* ----------------- page indexing --------------- */
  .manufacturing-indus-index-nav ul li a {
    font-size: 18px !important;
    padding: 8px 20px !important;
  }

  .manufacturing-indus-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- all technology pages heading and sub heading ---------- */
  .manufacturing-section-sub-heading {
    line-height: 26px !important;
    font-size: 16px !important;
  }

  /* --------------- about section ------------- */
  .manufacturing-about-sec {
    padding-top: 2rem !important;
  }

  .manufacturing-sub-img-about-down-box {
    width: 92% !important;
  }

  /* ------------ providers main section ----------- */
  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact p {
    font-size: 15px !important;
    line-height: 22px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact .overlay-box-serv-prov-manufact {
    padding: 20px 15px !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact img {
    height: 100% !important;
  }

  .service-provides-beno-manufacturing .inner-box-serv-prov-manufacturing .image-box-serv-prov-manufact:hover .caption-serv-prov-manufact {
    bottom: -104px !important;
  }

  /* ---------- Industries cater section ----------- */
  .manufact-main-indus-cater h2 {
    font-size: 20px !important;
  }

  .manufact-main-indus-cater p {
    font-size: 16px !important;
  }

  .manufact-main-indus-cater {
    width: 30% !important;
  }

  /* --------- Engineering Capabilities  section --------------------- */
  #capabilities,
  #trending-insights,
  #challenges {
    padding-top: 3rem !important;
  }

  .engineering-capabilities-pre-head {
    font-size: 14px !important;
  }

  .egg-manufact-sub-section-heading-spec {
    font-size: 35px !important;
  }

  .engineering-capabilities-head-div {
    height: unset !important;
    width: 100% !important;
    padding: 0rem 2rem !important;
  }

  .egg-manufac-tabs-accor-content {
    margin-top: 0px !important;
  }

  .egg-manufac-tabs-accor-content p,
  .egg-manufac-tabs-accor-content ul li {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .egg-manufac-tabs-accor-content a {
    font-size: 17px !important;
  }

  .egg-manufac-content-img-btn-custo {
    margin-top: 50% !important;
  }

  .insurance-experience-tabs .nav-item button.active::after {
    left: 0px !important;
    bottom: -8px !important;
    width: 100px !important;
  }



  .nav-item button {
    font-size: 1.2rem;
  }

  /* ------------------- cloud service ------------------ */
  .manufacturing-could-highlights-sec {
    margin-top: 2rem !important;
  }

  .manufacturing-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  .itescyber-digital-solution {
    height: unset !important;
  }

  .itescyber-digital-solution-img-dep,
  .itescyber-digital-solution-content-dep {
    width: 100% !important;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {

  /* ---- all page banner css -------------- */
  .manufacturing-indus-banner-main {
    padding: 5rem 0 4rem 0 !important;
  }

  .manufacturing-indus-banner-btn {
    margin: 1rem 0 !important;
  }

  /* --------------- all technology pages heading and sub heading ---------- */
  .manufacturing-section-sub-heading {
    line-height: 28px !important;
  }

  /* --------------- about section ------------- */
  .manufacturing-about-sec {
    padding-top: 2rem !important;
  }

  .manufacturing-sub-img-about-down-box {
    width: 94.3% !important;
  }

  /* --------- Engineering Capabilities  section --------------------- */
  .egg-manufac-content-img-btn-custo {
    margin-top: 50% !important;
  }

  .engineering-capabilities-head-div {
    height: unset !important;
    width: 100% !important;
    padding: 0rem 2rem !important;
  }

  .egg-manufact-sub-section-heading-spec {
    font-size: 40px !important;
  }


  /* --------------digital service and solution section start--------------- */
  .itescyber-digital-solution {
    height: max-content !important;
  }

  /* ------------------- cloud service ------------------ */
  .manufacturing-could-highlights-sec {
    margin-top: 3rem !important;
  }

  .manufacturing-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }
}

@media (max-width: 1400px) and (min-width: 1201px) {

  /* ---- all page banner css -------------- */
  .manufacturing-indus-banner-main {
    padding: 7rem 0 2rem 0 !important;
  }

  /* --------- Engineering Capabilities  section --------------------- */

  .egg-manufac-content-img-btn-custo {
    margin-top: 35% !important;
  }
}

.ecom-indus-main-bet-content p {
  color: #fff !important;
}