/* all heading br display none */
.main-ecom-indus-about-eff br {
    display: none;
}



/* all section padding top */
#about,
#provides,
#services,
#our-blog,
#overview {
    padding-top: 4rem !important;
}

#bpo-commerce-indus-sec,
#faq,
#services,
#our-service-managed-commerce-indus-sec {
    padding-top: 4rem !important;
}


.main-ecom-indus-about-eff::after {
    content: unset !important;
}

.ecom-main-indus-provides-head-bg::after {
    margin-left: -457px;
    width: 190px;
}

.ecom-main-ind-capab-head-bg::after {
    margin-left: -317px;
}

.bpo-ecom-indus-about-eff::after {
    content: unset !important;
}

/*=========== ecommerce industry all pages heading and sub heading css ===========*/
.indus-main-ecom-section-heading {
    font-size: 45px;
    font-weight: 700;
    position: relative;
    color: #CD4409;
}

.indus-main-ecom-section-heading span {
    color: #14212b !important;
}

.ecom-main-indus-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.ecom-main-indus-section-sub-heading span {
    color: #CD4409;
    font-weight: 700;
}

#slip-box-questoin-ask-indus {
    margin-top: 4rem;
    margin-bottom: 0 !important;
}

.ecom-main-indus-section-sub-heading b ul {
    margin-top: 12px;
}

.ecom-main-indus-section-sub-heading b ul {
    text-align: left;
}

/* --------  ites Industry all banner css ---------- */
.ecom-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* background-blend-mode: overlay; */
    /* background-color: #bbcac29c; */
    /* padding: 5rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.ecom-indus-banner-hr-talent {
    height: 430px !important;
}

.ecom-indus-main-banner-head {
    color: #14212B !important;
    margin-right: 19rem;
}

.ecom-indus-main-banner-head-unique {
    color: #fff !important;
}

#ecom-indus-main-banner {
    background-color: unset !important;
}

.ecom-indus-main-bet-content h3 {
    width: 100%;
}

.ecom-indus-inner-banner-content {
    width: 75%;
}

.ecom-indus-main-banner-content {
    width: 63%;
}

.ecom-indus-main-banner-content-main {
    width: 79% !important;

}

.ecom-indus-main-bet-content h3 {
    color: #14212b;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.ecom-indus-main-bet-content p {
    color: #14212b;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.indus-ecom-banner-main-btn {
    margin: 3rem 0;
    text-align: end;
    width: 100%;
}

#ecomm-indus-inner-page-bnt {
    margin: 1rem 0 !important;
}

.indus-ecom-banner-main-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 10px 25px;
    background: #CD4409;
    font-weight: 600;
    transition: all 0.5s;
    margin-left: 10px;
}

.bnr-nbtn-ind-ecom {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-ecom:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.indus-ecom-banner-main-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}




/* ------------------ main page indexing ---------------- */
section.ecom-main-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-ites-indus {
    position: fixed;
    top: 0;
}

.ecom-main-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.ecom-main-indus-index-nav ul li {
    display: inline-flex;
}

.ecom-main-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.ecom-main-indus-index-nav ul li a.active {
    background-color: white;
    color: #CD4409;
}

.ecom-main-indus-index-nav ul li:hover,
.ecom-main-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.index-ites-indus-item.active {
    background-color: white;
    color: #CD4409;
}


/* --------------- about section ------------- */


#ites-indus-read-btn-about {
    border: none !important;
    color: #CD4409;
    font-size: 18px;
    font-weight: 600;
    background: unset !important;
}

.ecom-ind-sub-img-about {
    height: 18rem;
    width: 100%;
    object-fit: cover;
}

.ecom-main-indus-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.ecom-main-indus-front-txt {
    position: relative;
    z-index: 1;
    width: 94.6%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: right;
    margin-bottom: 0.8rem;
}

.ecom-main-indus-front-txt h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.ecom-main-indus-front-txt h6::after {
    content: "";
    display: block;
    position: relative;
    width: 12.8rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.ecom-main-indus-front-txt h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.ecom-main-indus-front-txt p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.ecom-main-indus-img-sub-heading::after {
    width: 9.3rem !important;
}

/* -------- support provides section --------  */
.ecom-main-indus-provides-head-bg br {
    display: none !important;
}

.visible-provide {
    display: block !important;
}

.hidden-provide {
    display: none;
}

.service-provides-main-ecom-indus {
    position: relative;
    margin-bottom: 30px;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide {
    position: relative;
    overflow: hidden;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide {
    position: relative;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide img {
    position: relative;
    width: 100%;
    height: 350px !important;
    object-fit: cover;
    display: block;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-caption-service-provide {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    color: #ffffff;
    display: block;
    font-size: 22px;
    padding: 20px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide {
    position: absolute;
    left: 0px;
    bottom: -353px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide h4 {
    position: relative;
    color: #ffffff;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide p {
    position: relative;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.6em;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide:hover .ecom-main-indus-caption-service-provide {
    bottom: -60px;
}

.service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide:hover .ecom-main-indus-overlay-box-service-provide {
    bottom: 0px !important;
}

.btn-div-travel-main {
    margin-top: 2rem;
}

.load-more-ecom-main-btn {
    background: #CD4409;
    border: none !important;
    border-radius: 33px;
    transition: all 0.3s;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 20px !important;
    text-decoration: none;
}

.load-more-ecom-main-btn:hover {
    background: #fff;
    color: #CD4409;
    outline: 2px solid !important;
}

/* ------------------our capabilities--------------- */
.ecom-main-ind-capabilities-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}


.ecom-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.ecom-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.ecom-ind-inner-area-customer-main:hover .ecom-main-indus-serve-small-box {
    margin-left: 22rem;
}

.ecom-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.ecom-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.ecom-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.ecom-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.ecom-ind-inner-area-customer-main .ecom-main-ind-serve-circle-main .ecom-main-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.ecom-ind-inner-area-customer-main .ecom-main-ind-serve-circle-main .ecom-main-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}

/* ------ benefits beno support ------ */

.ecom-main-indus-service-area-cards {
    width: 31% !important;
    border: 1px solid #b3b3b3af;
    margin: 10px;
    padding: 15px;
    border-left: 5px solid #CD4409;
}

.ecom-main-indus-service-area-cards h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ecom-main-indus-service-area-cards p {
    color: #696969;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* -===================== bpo page ============ */
/* ----------- outsourcing service ------------ */
.ecom-indus-outsourcing-sec {
    background: url('../../../../public/assets/img/shape-12-1.png');
    background-repeat: no-repeat;
    /* background-position: center; */
    margin-top: -4rem !important;
}



.service-ecomm-indus-bpo-main {
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 10px;
    height: 15rem;
    position: relative;
    margin-top: 1rem !important;
}



.service-ecomm-indus-bpo-main-hr-talent {
    height: 17rem !important;
}

.service-ecomm-indus-bpo-img-dep img {
    width: 100%;
    height: 13.7rem;
    object-fit: cover;
}

.service-ecomm-indus-bpo-img-dep-hr-talent img {
    height: 15.7rem !important;
}

.service-ecomm-indus-bpo-content-dep h4 {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    margin-top: 17px;
    font-weight: 600;
    line-height: normal;
}

.service-ecomm-indus-bpo-content-dep p {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.service-ecomm-indus-bpo-main:hover::before {
    position: absolute;
    content: "";
    width: 7rem;
    height: 8px;
    transition: 0.7s;
    left: -1px;
    top: -8px;
    background-color: #CD4409;
}

.service-ecomm-indus-bpo-main:hover::after {
    position: absolute;
    content: "";
    width: 8px;
    transition: 0.7s;
    height: 7rem;
    left: -9px;
    top: -8px;
    background-color: #CD4409;
}

/* ----------------- support process --------------- */
.ecom-indus-support-process-sec {
    background-image: url('../../../../public/assets/img/bpo/under-back.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* -------- eCommerce App Idea section ----------------- */
.ecom-indus-app-idea-sec {
    background: #F38242;
    padding: 2rem 0;
    margin-bottom: 4rem;
}

.ecom-indus-app-idea-sec h3 {
    color: #14212B;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ecom-indus-app-idea-sec h3 span {
    color: #FFF;
}

.app-idea-counter-serve {
    padding: 1rem 1rem 0 1rem;
}

.app-idea-counter-serve h4 {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.app-idea-counter-serve p {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

/* ------------- technology tools section ----------- */
.language-tools-tabs-main ul {
    justify-content: center !important;
}

.language-tools-tabs-main ul li {
    margin: 1rem 7px;
}

.language-tools-tabs-main ul li a {
    border-radius: 5px !important;
    border: 1px solid #00973A !important;
    background: #FFF !important;
    color: #00973A !important;
}

.language-tools-tabs-main ul li a.active {
    background: #00973A !important;
    color: #fff !important;
}

div#language-tools-tabs-content-inr img {
    width: 90px;
    height: 78px;
    margin-top: 2rem;
    object-fit: contain;
}

div#language-tools-tabs-content-inr {
    text-align: center;
    justify-content: center;
}

/* -=================== managed it support ================ */
.beneftis-mananged-ecomme-inr {
    margin-top: 2rem;
}

.beneftis-mananged-ecomme-inr ul {
    border-left: 4px solid #CD4409;
    padding-left: 3rem;
}

.beneftis-mananged-ecomme-inr ul li {
    margin-bottom: 1rem;
    font-size: 17px;
}

.beneftis-mananged-ecomme-inr ul li::marker {
    color: #CD4409 !important;
}

.why-choose-managed-ecomm-inus-main {
    margin-top: 1rem;
    background: linear-gradient(90deg, #FFF 0%, #FEF2EA 100%);
}

.why-choose-managed-ecomm-inus-inner {
    padding: 1rem;
}

.why-choose-managed-ecomm-inus-main h3 {
    color: #CD4409;
    opacity: 0.5;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: end;
}

.why-choose-managed-ecomm-inus-inner h4 {
    color: #212121;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.why-choose-managed-ecomm-inus-inner p {
    color: #262626;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

/* -=============== cyber security page =================- */
#online-assets-managed-commerce-indus-sec {
    margin-top: 3rem;
    opacity: 0.9;
    background: #FEF2EA;
}

#online-assets-manag-inr-content-img img {
    height: 275px;
    width: 100%;
    border-left: 10px solid #F48F55;
    object-fit: cover;
    border-radius: 120% 0% 0% 10% / 312% 10% 304% 0%;
}

.online-assets-managed-commerce-inr-content {
    padding: 3rem 2rem;
}

.online-assets-managed-commerce-inr-content h3 {
    color: #14212B;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2rem;
}

.text-white {
    color: #fff !important;
}

.beneftis-mananged-ecomme-inr-manage ul {
    display: flex;
    flex-direction: column;
}

.beneftis-mananged-ecomme-inr-manage ul li {
    transition: all 0.7s;
}

.beneftis-mananged-ecomme-inr-manage ul li:hover {
    color: CD4409;
    transform: scale(1.03);
}

@media only screen and (max-width: 480px) {

    #bpo-commerce-indus-sec,
    #faq,
    #services,
    #our-service-managed-commerce-indus-sec {
        padding-top: 2rem !important;
    }

    #slip-box-questoin-ask-indus {
        margin-top: 2rem !important;
    }

    /* all heading br display none */
    .main-ecom-indus-about-eff br {
        display: block;
    }

    /* all headings bg effect */
    .main-ecom-indus-about-eff::after {
        content: unset !important;
    }

    .ecom-main-ind-capab-head-bg::after {
        margin-left: -211px !important;
        margin-top: -2px !important;
    }

    .ecom-main-carter-head-bg::after {
        margin-left: -232px !important;
        margin-top: -5px !important;
        width: 138px !important;
    }

    #ecom-indus-main-banner {
        height: unset !important;
    }




    .why-choose-managed-ecomm-inus-main h3 {
        text-align: center;
    }

    .online-assets-managed-commerce-inr-content h3 {
        font-size: 30px;
    }

    .ecom-main-indus-provides-head-bg::after {
        margin-left: -148px;
        width: 143px;
        margin-top: -40px;
    }

    .online-assets-managed-commerce-inr-content {
        padding: 0 2rem;
    }

    #online-assets-manag-inr-content-img img {
        margin-top: 1rem !important;
    }

    .ecom-main-indus-section-sub-heading {
        margin-bottom: 1rem !important;
    }

    /* --============== all travel pages headings and sub headings ===============- */
    .indus-main-ecom-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .ecom-main-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 0 1rem 0 !important;
    }

    /* all section padding top */
    #about,
    #provides,
    #services,
    #our-blog,
    #overview {
        padding-top: 2rem !important;
    }


    /* ---- all page banner css -------------- */
    .ecom-indus-banner {
        margin-top: 0% !important;
        padding: 3rem 0 1rem 0 !important;
        height: 400px !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .ecom-indus-main-bet-content {
        width: 100% !important;
        text-align: center;
    }

    .ecom-indus-main-bet-content h3 {

        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .ecom-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 26px !important;
        padding: 10px 5px 0 5px !important;
        text-align: center;
    }

    .indus-ites-banner-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    .ecom-main-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .ecom-main-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    .indus-ecom-banner-main-btn a {
        display: block !important;
        margin: 1rem !important;
        padding: 10px 20px !important;
        text-align: center !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .ecom-ind-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .travel-serv-index-nav ul li a {
        padding: 8px 12px !important;
    }

    .ecom-main-indus-front-txt {
        float: unset !important;
        margin-left: 0rem;
        width: 93.2%;
    }

    .ecom-main-indus-front-txt h4 {
        font-size: 24px !important;
    }

    .ecom-main-indus-front-txt p {
        line-height: 20px !important;
    }

    /* --------------- main page providers secton -------------- */
    .service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide p {
        line-height: 22px !important;
    }

    .service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide h4 {
        font-size: 22px !important;
    }

    .service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-overlay-box-service-provide {
        padding: 20px 8px !important;
        bottom: -432px !important;
    }

    .service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide .ecom-main-indus-caption-service-provide {
        font-size: 18px !important;
        padding: 15px 0px !important;
    }

    .service-provides-main-ecom-indus .ecom-main-indus-inner-box-service-provide .ecom-main-indus-image-box-service-provide img {
        height: 100% !important;
    }

    .ecom-main-indus-provides-head-bg br {
        display: block !important;
    }

    /* ---------------our capabilities----------- */
    .ecom-main-ind-capabilities-sec {
        background-image: none !important;

    }

    /* -------------- service sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    .ecom-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .ecom-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .ecom-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .ecom-main-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }

    /* effortlessly section */
    .ecom-main-indus-service-area-cards {
        width: 94% !important;
        height: max-content !important;
        padding: 7px !important;
    }

    /* -============= bpo page ============== */
    /* --------- outsorcing service section --------------- */
    .service-ecomm-indus-bpo-main {
        height: unset !important;
    }

    .online-assets-managed-commerce-inr-content {
        padding: 2rem 2rem !important;
    }

    #online-assets-managed-commerce-indus-sec {
        margin-top: 0 !important;

    }
}

@media (max-width: 767px) and (min-width: 481px) {


    #bpo-commerce-indus-sec,
    #services,
    #faq,
    #our-service-managed-commerce-indus-sec {
        padding-top: 2rem !important;
    }

    #slip-box-questoin-ask-indus {
        margin-top: 2rem !important;
    }

    .main-ecom-indus-about-eff br {
        display: block;
    }

    /* all heading background effect*/
    .main-ecom-indus-about-eff::after {
        content: unset !important;
    }

    .ecom-main-indus-provides-head-bg::after {
        margin-left: -397px !important;
    }

    .ecom-main-ind-capab-head-bg::after {
        margin-left: -272px !important;
    }

    .ecom-main-carter-head-bg::after {
        margin-left: -304px !important;
    }


    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-ecom-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .ecom-main-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }


    /* ---------------indexing section--------- */
    .ecom-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* ---- all page banner css -------------- */
    .ecom-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .ecom-indus-main-bet-content {
        width: 100% !important;
    }

    .ecom-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .ecom-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    .indus-ites-banner-btn {
        text-align: center !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-ecom-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .ecom-main-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .ecom-ind-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .ecom-main-indus-front-txt {
        float: unset !important;
        width: 95% !important;
        padding-bottom: 13px;
    }

    .ecom-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- our capabilities sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    #ecom-ind-business-areas-main {
        width: 100% !important;
    }

    .ecom-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .ecom-ind-inner-area-customer-main p {
        margin-right: 15px !important;
        text-align: justify !important;
    }

    /* effortlessly section */

    .ecom-main-indus-service-area-cards {
        width: 46% !important;
    }

    /* -============= bpo page ============== */
    /* --------- outsorcing service section --------------- */
    .service-ecomm-indus-bpo-main {
        height: unset !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    #bpo-commerce-indus-sec,
    #services,
    #faq,
    #our-service-managed-commerce-indus-sec {
        padding-top: 3rem !important;
    }

    #slip-box-questoin-ask-indus {
        margin-top: 3rem !important;
    }

    /* all heading background effect */
    .main-ecom-indus-about-eff::after {
        content: unset !important;
    }

    .ecom-main-indus-provides-head-bg::after {
        margin-top: 13px !important;
    }

    .ecom-main-ind-capab-head-bg::after {
        margin-left: -320px !important;
        margin-top: 10px !important;
    }

    .ecom-main-carter-head-bg::after {
        margin-left: -350px !important;
        margin-top: 10px !important;
    }

    /* ---- all page banner css -------------- */
    .ecom-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .ecom-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .ecom-indus-main-bet-content {
        width: 100% !important;
    }

    .ecom-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 25px 0 25px !important;
    }

    .indus-ites-banner-btn {
        text-align: center !important;
    }

    .indus-ites-banner-btn a {
        margin: 0 2rem !important;
    }

    /* --------------ites indexing section------------ */
    .ecom-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* about ites section start */
    .ecom-main-indus-front-txt {
        float: unset !important;
        width: 93%;
    }

    .ecom-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- Our Capabilities ---------- */
    div#ecom-ind-business-areas-main {
        width: 50%;
    }



    .ecom-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* we effortlessly handle it all section  */
    .ecom-main-indus-service-area-cards {
        width: 30% !important;
    }

    /* -============= bpo page ============== */
    /* --------- outsorcing service section --------------- */
    .service-ecomm-indus-bpo-main {
        height: unset !important;
    }

    .service-ecomm-indus-bpo-img-dep,
    .service-ecomm-indus-bpo-content-dep {
        width: 100% !important;
    }
}



@media (max-width: 1200px) and (min-width: 992px) {

    /*-------------all heading background effect  --------------*/
    .ecom-main-carter-head-bg::after {
        margin-left: -352px !important;

    }



    /* ---- all page banner css -------------- */
    .ecom-indus-banner {
        padding: 5rem 0 4rem 0 !important;
    }

    .indus-ites-banner-btn {
        margin: 1rem 0 !important;
        width: 100%;
    }

    .indus-ites-banner-btn a {

        margin: 0rem 1rem !important;
        text-align: center;
    }

    .ecom-indus-main-bet-content {
        width: 100% !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .ecom-main-indus-section-sub-heading {
        line-height: 28px !important;
    }


    /* --------------- about section ------------- */
    .ecom-main-indus-front-txt {
        float: unset !important;
        width: 94.4% !important;
    }

    .ecom-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- service sectoin ---------- */
    .ecom-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* -============= bpo page ============== */
    /* --------- outsorcing service section --------------- */
    .service-ecomm-indus-bpo-main {
        height: max-content !important;
    }
}

@media (max-width: 1400px) and (min-width: 1201px) {

    /* all heading background effect */
    .ecom-main-carter-head-bg::after {
        margin-left: -352px !important;

    }

    /* ---- all page banner css -------------- */

    .ecom-indus-main-bet-content h3 {
        padding: 25px !important;
    }


    /* ites page about section  */
    .ecom-main-indus-front-txt {
        width: 94.5% !important;
    }

    /* -------------- service sectoin ---------- */
    .ecom-ind-inner-area-customer-main {
        padding: 15px 5px 15px 15px !important;
    }
}

@media (min-width: 1401px) {
    .ecom-main-carter-head-bg::after {
        margin-left: -349px !important;
        margin-top: 10px !important;

    }
}