/*============ section background=========== */
#digital-exp-about,
#dge-cm-what-we-think {
  background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
  background-repeat: no-repeat;
  background-size: cover;

}


#digital-exp-about,
#experience-industry,
#experience-services,
#experience-benefits,
#experience-solutions,
#dge-content-marketing-counter,
#dge-cm-what-we-think,
.dge-driven-sec,
#quality-assurance {
  padding-top: 4rem;
}

.dge-sub-under-img-right {
  margin-top: unset !important;
  height: 100%;
}

#experience-security-indus-head-effect::after {
  width: 207px !important;
  margin-left: -359px !important;
  margin-top: 9px !important;
}

#experience-security-service-head-effect::after {
  margin-left: -269px !important;
  margin-top: 9px !important;
}

/* -------- pages  heading and sub heading css --------- */
.dgt-experience-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.dgt-experience-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.dgt-experience-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

/* ------------- banner css ------------- */
.dgt-experience-banner-main {
  background-blend-mode: overlay;
  width: 100%;
  height: 450px;
  max-height: 450px;
  /* margin-top: 10.5rem; */
  background-color: #727272;
  background-size: cover;
  /* padding: 4rem 0; */

  transition: all 3s;
  display: flex;
  align-items: center;
}

.dgt-experience-banner-main:hover {
  transform: scale(1.03);
}

.dgt-experience-banner-overlay {
  background-blend-mode: unset !important;
  background-color: unset !important;
  background-position: 0% 32% !important;

}

.dgt-experience-banner-heading {
  font-size: 62px;
  text-transform: none;
  font-weight: 800;
  color: #14212B;
  line-height: 1.1;
}

.dgt-experience-banner-para {
  color: #14212B;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
  margin-top: 2rem;
}

#play-button-dgt-experience {
  display: flex;
  align-items: center;
}

div#play-button-dgt-experience-banner {
  position: relative;
}

.dgt-experience-it-bnr-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 63%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.dgt-experience-it-bnr-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.dgt-experience-it-bnr-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.dgt-experience-it-bnr-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.dgt-experience-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff;
}

.dgt-experience-banner-know-how a svg {
  padding: 4px 5px 0 5px;
}

.dgt-experience-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}

div#play-button-dgt-experience-banner {
  position: relative;
}

/* banner buttons */
.content_marketing-dge-banner-bg-btn {
  margin-top: 3.5rem;
}

.content_marketing-dge-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.content_marketing-dge-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.content_marketing-dge-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.content_marketing-dge-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* --------- page index css ----------------- */
section.nag-page-index-dgt-experience {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 99;
}

section.nag-page-index-dgt-experience.sticky-dgt-experience {
  position: fixed;
  top: 90px !important;
}

.nav-link-index-dgt-experience.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-dgt-experience ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-index-dgt-experience ul li {
  display: inline-flex;
}

.navbar-index-dgt-experience ul li:hover,
.navbar-index-dgt-experience ul li a:hover {
  background-color: #ffffff;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-index-dgt-experience ul li a {
  color: #fff;
  font-size: 18px;
  padding: 7px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* --------- about section  ---------------------- */
.dgt-experience-about-btn-bln {
  margin-top: 2rem;
}

.dgt-experience-about-btn-bln a {
  background: #CD4409;
  padding: 10px 25px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.dgt-experience-about-btn-bln a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

#dgt-experience-about-dev-img {
  margin-top: 1rem;
}

.dgt-experience-about-introimg img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.dgt-experience-about-triangle-down::after {
  width: 12% !important;
}

.dgt-experience-about-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.dgt-experience-about-img-desc-txt {
  width: 95.5%;
  height: max-content;
  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-top: -2.5rem;
  float: right;
}

.dgt-experience-about-img-desc-txt h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.dgt-experience-about-img-desc-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 7rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.dgt-experience-about-img-desc-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.dgt-experience-about-img-desc-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* ------------ industries sction -------------- */
.dgt-experience-indus-item-row {
  margin-top: 2rem;
}

.dgt-experience-indus-item-box {
  margin-bottom: 2rem;
}

.dgt-experience-indus-item-box.hidden-segments,
.hidden-wedo_box {
  display: none;
}

.dgt-experience-indus-item-box.visible-segments,
.visible-wedo_box {
  display: block;
}

.dgt-experience-indus-item-card {
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  border: unset !important;
  height: 350px;
  text-align: center;
}

.dgt-experience-indus-item-card figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

figure.dgt-experience-indus-item-effect-steve img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  object-fit: cover;
}

figure.dgt-experience-indus-item-effect-steve h2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #212121;
  line-height: normal;
  text-transform: capitalize;
  word-spacing: -0.15em;
  background: #ffffff;
}

figure.dgt-experience-indus-item-effect-steve p {
  padding: 0.5em;
  opacity: 0;
  font-size: 15px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  line-height: 21px;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 10px 11px !important;
  margin: 0;
  height: 100% !important;
}

figure.dgt-experience-indus-item-effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.dgt-experience-indus-item-effect-steve:hover p {
  opacity: 1;
}

figure .dgt-experience-indus-item-intro {
  position: absolute;
  bottom: -1px;
  width: 90%;
  height: 40%;
  margin-left: 5% !important;
}

.btn-div-manage-it-main {
  margin-top: 2rem;
}

.load-more-dgt-experience-it-btn {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.load-more-dgt-experience-it-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ----------------- servcie section ------------- */
.dgt-experience-service-crd-main {
  margin-bottom: 2rem;
  border: 2px solid #eedfd7;
  text-align: center;
  height: 500px;
}

.dgt-experience-service-crd-main a {
  text-decoration: none !important;
}

.dgt-experience-service-crd-img-main {
  overflow: hidden;
  margin: 5px;
}

.dgt-experience-service-crd-img-main:hover img {
  transform: scale(1.1);
}

.dgt-experience-service-crd-main:hover {
  border: 2px solid #CD4409;
}

.dgt-experience-service-crd-main .card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.dgt-experience-service-crd-main img {
  width: 100%;
  height: 220px;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  object-fit: cover;
  border-radius: 5px;
}

.dgt-experience-service-crd-main h3 {
  color: #14212b;
  margin-top: 1rem;
  font-size: 20px;
  padding: 0 8px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    color: #CD4409;
  }
}

.dgt-experience-service-crd-main p {
  color: #696969;
  text-align: center;
  padding: 0 8px !important;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.dgt-experience-service-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dgt-experience-service-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.btn-div-dgt-experience-it-main {
  margin-top: 1rem;
}

.hidden-service-experience {
  display: none;
}

.visible-service-experience {
  display: block;
}

/* ------------- BENEFITS section ------------- */
.experience-benefits-sec h5 {
  color: #262626;
  font-size: 14px;
  font-weight: 600;
}

.experience-benefits-sec h2 {
  color: #14212b;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  border-left: 4px solid #CD4409;
  padding-left: 25px;
}

.experience-benefits-sec h2 span {
  color: #CD4409;
  font-weight: 600;
}

.experience-benefits-content {
  margin: 2rem 0 0 2rem;
}

.experience-benefits-content li {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

/* -------------- product and solutions section ----------- */
.dgtexp-solutions-accordion-main {
  margin-top: 3rem;
}

.dgtexp-solutions-accordion-main .accordion-body p {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.dgtexp-solutions-accordion-quest,
.accordion-button {
  color: #14212B;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  background-color: #fff !important;
  line-height: 30px;
}

.dgtexp-solutions-accordion-quest span {
  color: #CD4409;
  font-size: 32px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}



/* =============
==============
================
digital experience service, content marketing page start
====================
========================
==========================
*/

/*-============= about section========== */
.about-dge-cm-bg-effect {
  background-image: url(http://localhost:3001/static/media/back-effet.d1990775547e6f4d5a1d.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}


/* ==========dge content marketing page counter section start================= */
#dge-content-marketing-counter,
.dge-driven-sec,
#experience-services {
  background-image: url('../../../../public/assets/img/shape-12-1.png');
  background-repeat: no-repeat;
  background-position: bottom;
}

.dge-counter-sec-img {
  border: 1px solid #D1D1D1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.dge-counter-sec-img img {
  padding: 10px;

}

.website-transformation-img {
  padding: 0;
  height: 7rem;
}

.website-transformation-content p {
  margin-top: 0 !important;
}

.dge-counter-sec-content {
  align-items: center
}

.dge-cm-counter {
  margin-top: 20px;
  display: flex;
}

.dge-counter-sec-content h3 {
  margin-top: 12px;
  color: #CD4409;
  font-weight: 600;
}

.dge-counter-sec-content p {
  font-size: 18px;
}


/* ===============what we think section============= */
.cyber-security-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 16rem;
}

.cyber-security-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.cyber-security-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

/* ===========services area where we can help you========== */
/* ---------- all sub pages Service Areas section  css ------------ */
.content-marketing-dge-service-sec {
  margin-top: 3rem;
}

#dge-content-marketing-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#dge-content-marketing-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#dge-content-marketing-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#dge-content-marketing-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#dge-content-marketing-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#dge-content-marketing-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}



/*--------------------------- dge result driven section----------------------- */

.dge-driven-img img {
  height: 22rem;
  width: 100%;
  object-fit: cover;

}

.website-transformation-img-height img {
  height: 30rem !important;

}

.dge-driven-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dge-driven-content-unique h2 {

  margin-top: 4rem;
}

.dge-driven-content h2 {
  /* margin-top: 1rem; */
}



.dge-driven-section {
  margin-top: 2rem;
  display: flex;
}

/* {----------  content marketing in digital experirence think -------------} */
.user-expr-head-fosize span {
  font-size: 40px !important;
}

.dge-cont-mart-maximum-efficiency-first img {
  width: 100%;
  margin-bottom: 1rem;
  height: 12.5rem;
  object-fit: cover;
}

.dge-cont-mart-efficiency-second h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.dge-cont-mart-efficiency-second h6::after {
  position: absolute;
  content: "";
  margin-left: -9.7rem;
  margin-top: 1.2rem;
  width: 9.7rem;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.dge-cont-mart-efficiency-second h5 {
  color: #000;
  font-size: 24px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dge-cont-mart-efficiency-second p {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}


/* 
      ============
      ==================
    =========================
    website transformation and localization page start
    =========================
    =====================
    ===============  */
.website-trans-quality-assur {
  margin-top: 0.5rem;
  padding: 0 0px 0 35px;
}

.website-trans-quality-assur h2 {
  font-size: 22px;
  font-weight: 600;
  color: #CD4409;
  border-top: 6px solid #14212b;
  border-radius: 10px;
  padding-top: 1rem;
}

.website-trans-quality-assur p {
  margin-top: 14px;
}

.website-trans-content-border {

  margin-top: 24px;
  padding-right: 15px;
}

/* -----------------------------  tronsforning online section ----------------------- */
.dge-transforming {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.dge-transforming-list {
  display: flex;
  align-items: center !important;

  >img {
    width: 20px;
    object-fit: contain;
  }

  >h5 {
    font-size: 16px;
    margin-bottom: unset;
  }

}

/* ================ responsive start ============= */

@media only screen and (max-width: 480px) {

  #digital-exp-about,
  #experience-industry,
  #experience-services,
  #experience-benefits,
  #experience-solutions,
  #dge-content-marketing-counter,
  #dge-cm-what-we-think,
  .dge-driven-sec,
  #quality-assurance {
    padding-top: 2rem !important;
  }


  .dgt-experience-head-main-title::after,
  .heading-bg-effect-dnone::after {
    content: unset !important;
  }

  #experience-security-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
    margin-top: 9px !important;
  }

  #experience-security-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 9px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .dgt-experience-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center;
  }

  .dgt-experience-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  /* ----------- banners css ------------ */
  .dgt-experience-banner-main {
    margin-top: unset !important;
    height: unset !important;
    max-height: unset !important;
    padding: 3rem 0 !important;
  }

  .dgt-experience-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  .content_marketing-dge-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .content_marketing-dge-banner-bg-btn {
    margin-top: 2rem !important;
  }

  /* -------play button-------- */
  .dgt-experience-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .dgt-experience-it-bnr-btn-main {
    margin-top: 20% !important;
  }

  .dgt-experience-know-how-btn {
    text-align: center;
  }

  /* --------- page index css ----------------- */
  .navbar-index-dgt-experience ul li a {
    font-size: 16px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-dgt-experience {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-dgt-experience li {
    margin: 5px !important;
  }

  /* --------- about section  ---------------------- */
  .dgt-experience-about-btn-bln {
    margin-top: unset !important;
    justify-content: center;
    display: flex;
  }

  #dgt-experience-about-dev-img {
    margin-top: 2rem !important;
  }

  .dgt-experience-about-img-desc-txt {
    width: 92% !important;
  }

  .dgt-experience-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ----------- what we think section -------------- */
  .cyber-security-sub-under-img-right {
    margin-top: 1rem !important;
  }

  /* ------------ industries sction -------------- */
  .dgt-experience-indus-item-box {
    margin-bottom: 1rem !important;
  }

  .dgt-experience-indus-item-card {
    height: 300px !important;
  }

  figure.dgt-experience-indus-item-effect-steve img {
    height: 100% !important;
  }

  figure.dgt-experience-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px;
  }

  figure .dgt-experience-indus-item-intro {
    height: 50%;
  }

  /* ----------------- servcie section ------------- */
  .dgt-experience-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-dgt-experience-it-main {
    margin-top: 0rem !important;
  }

  /* ------------- BENEFITS section ------------- */
  .experience-benefits-sec h2 {
    font-size: 28px !important;
    line-height: 36px !important;
    border-left: 3px solid #CD4409 !important;
    padding-left: 10px !important;
  }

  .experience-benefits-content {
    margin: 0rem 0 0 0rem !important;
  }

  .experience-benefits-content li {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-top: 0.6rem !important;
  }

  /* ------------------------ product and solutions section -------------------- */
  .dgtexp-solutions-accordion-main {
    margin-top: 1rem !important;
  }

  .experience-benefits-content ul {
    margin-bottom: 0 !important;
  }


  /* ==========
  ================
  ===================
  website transformation and localization page start
  ==============
  ================
  ===================
   */

  /* ==============quality assurance process start=============== */
  .website-trans-quality-assur {
    margin-top: 0.5rem;
    padding: 0 0px 0 0px;
    text-align: center;
  }



  /* {----------  content marketing in digital experirence think -------------} */

  .dge-cont-mart-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .dge-cont-mart-efficiency-second h6 {
    margin-top: unset !important;
  }

  .content-marketing-dge-service-sec {
    margin-top: 2rem;
  }

  /* ===============dge driven section ===============*/
  .dge-driven-section {
    margin-top: 0rem;
  }

  .dge-driven-img {
    margin-top: 1rem;
  }

  /* ------------ service secion ------------- */

  #dge-content-marketing-area-cont {
    height: 100% !important;
  }


  /* ===========website transformation page ecommerce stores worldwide section start------------- */
  .dge-cm-counter {
    display: unset;
  }

  .website-transformation-img {

    margin: 0 0px 0 0;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  #digital-exp-about,
  #experience-industry,
  #experience-services,
  #experience-benefits,
  #experience-solutions,
  #dge-content-marketing-counter,
  #dge-cm-what-we-think,
  .dge-driven-sec,
  #quality-assurance {
    padding-top: 2rem !important;
  }

  .dgt-experience-head-main-title::after {
    width: 156px !important;
    margin-left: -180px !important;
    margin-top: -33px !important;
    height: 30px !important;
  }

  #experience-security-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
    margin-top: 5px !important;
  }

  #experience-security-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 4px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .dgt-experience-head-main-title,
  .dgt-experience-head-main-title span {
    font-size: 30px !important;
    text-align: center;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  /* -----------  banners css ------------ */
  .dgt-experience-banner-main {
    margin-top: 0px !important;
    height: 100% !important;
  }

  .dgt-experience-banner-para {
    text-align: center;
  }

  div#play-button-dgt-experience {
    margin: 2rem 0 0 0 !important;
  }

  #play-button-dgt-experience-banner,
  .dgt-experience-know-how-btn {
    width: 50% !important;
  }

  .dgt-experience-it-bnr-btn-main {
    margin-top: 35% !important;
    margin-left: 50% !important;
  }

  .dgt-experience-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-dgt-experience-banner,
  .dgt-experience-know-how-btn {
    width: 50% !important;
  }

  .content_marketing-dge-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  /* --------- page index css ----------------- */
  .nav-link-index-dgt-experience {
    font-size: 27px;
    padding: 3px 13px 3px 13px;
  }

  .navbar-index-dgt-experience ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .navbar-index-dgt-experience ul {
    padding: 0 !important;
  }

  /* --------- about section  ---------------------- */
  .dgt-experience-about-btn-bln {
    margin-top: unset !important;
    display: flex !important;
    justify-content: center !important;
  }

  .dgt-experience-about-img-desc-txt {
    width: 95% !important;
  }

  #dgt-experience-about-dev-img {
    margin-top: 2rem !important;
  }

  .cyber-security-sub-under-img-right {
    margin-top: 1rem !important;
  }

  /* ------------ industries sction -------------- */
  .btn-div-manage-it-main {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  /* ----------------- servcie section ------------- */
  .dgt-experience-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-dgt-experience-it-main {
    margin-top: 0rem;
  }

  /* ------------- BENEFITS section ------------- */
  .experience-benefits-sec h2 {
    font-size: 28px !important;
    line-height: 36px !important;
    border-left: 3px solid #CD4409 !important;
    padding-left: 10px !important;
  }

  .experience-benefits-content {
    margin: 0rem 0 0 0rem !important;
  }

  .experience-benefits-content li {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-top: 0.6rem !important;
  }

  /* ------------ service secion ------------- */

  #dge-content-marketing-area-cont {
    height: 100% !important;
  }

  /* {----------  content marketing in digital experirence think -------------} */

  .dge-cont-mart-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .dge-cont-mart-efficiency-second h6 {
    margin-top: unset !important;
  }

  .content-marketing-dge-service-sec {
    margin-top: 2rem;
  }


  /* ===========website transformation page ecommerce stores worldwide section start------------- */
  .dge-cm-counter {
    display: unset;
  }



  /* ==========
  ================
  ===================
  website transformation and localization page start
  ==============
  ================
  ===================
   */
  .website-trans-quality-assur {
    margin: 0.5rem 16px 0 0;
    padding: 0 0px 0 17px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  #digital-exp-about,
  #experience-industry,
  #experience-services,
  #experience-benefits,
  #experience-solutions,
  #dge-content-marketing-counter,
  #dge-cm-what-we-think,
  .dge-driven-sec,
  #quality-assurance {
    padding-top: 2rem !important;
  }

  .dgt-experience-head-main-title::after {
    width: 181px !important;
    margin-left: -12px !important;
    margin-top: -33px !important;
    height: 32px !important;
  }

  #experience-security-indus-head-effect::after {
    width: 165px !important;
    margin-left: -283px !important;
    margin-top: 7px !important;
    height: 30px !important;
    margin-top: 9px !important;
  }

  #experience-security-service-head-effect::after {
    margin-left: -212px !important;
    width: 138px !important;
    height: 30px !important;
    margin-top: 9px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .dgt-experience-head-main-title,
  .dgt-experience-head-main-title span {
    font-size: 35px !important;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  /* ----------- banners css ------------ */
  .dgt-experience-banner-main {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset;
  }

  .dgt-experience-banner-para {
    text-align: center;
  }

  .dgt-experience-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-dgt-experience {
    margin-top: 2rem;
  }

  #play-button-dgt-experience-banner,
  .dgt-experience-know-how-btn {
    width: 50% !important;
  }

  .dgt-experience-it-bnr-btn-main {
    margin-top: 25% !important;
    margin-left: 50% !important;
  }

  .content_marketing-dge-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  /* --------- page index css ----------------- */
  .navbar-index-dgt-experience ul li a {
    font-size: 17px !important;
    padding: 8px 25px !important;
  }

  /* --------- about section ---------- */
  .dgt-experience-about-img-desc-txt {
    width: 93% !important;
  }

  .dgt-experience-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ------------ industries sction -------------- */
  .dgt-experience-indus-item-box {
    width: 50% !important;
  }

  figure.dgt-experience-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ----------------- servcie section ------------- */
  .experience-services-div-main {
    width: 50% !important;
    display: unset !important;
  }

  .dgt-experience-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-dgt-experience-it-main {
    margin-top: 0rem;
  }

  /* ------------- BENEFITS section ------------- */
  .experience-benefits-sec h2 {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .experience-benefits-content li {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-top: 0.6rem !important;
  }

  /* ------------ service secion ------------- */

  #dge-content-marketing-area-cont {
    height: 100% !important;
  }

  #dge-content-marketing-area-cont-img,
  #dge-content-marketing-area-cont-desc {
    width: 100% !important;
  }

  /* {----------  content marketing in digital experirence think -------------} */
  .dge-cont-mart-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .dge-cont-mart-efficiency-second h6 {
    margin-top: unset !important;
  }

  .content-marketing-dge-service-sec {
    margin-top: 2rem;
  }


  /* ==========
  ================
  ===================
  website transformation and localization page start
  ==============
  ================
  ===================
   */
  .website-trans-quality-assur {
    margin-top: 0.5rem;
    padding: 0px 0px 0 20px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .dgt-experience-head-main-title::after {
    margin-left: -12px !important;
  }

  #experience-security-indus-head-effect::after {
    margin-left: -320px !important;
    margin-top: 7px !important;
    margin-top: 7px !important;
  }

  #experience-security-service-head-effect::after {
    margin-left: -239px !important;
    width: 155px !important;
    margin-top: 4px !important;
  }

  /* ------------- pages heading and sub heading ------- */
  .dgt-experience-head-main-title {
    font-size: 40px !important;
  }

  /* ----------- banners css ------------ */
  .dgt-experience-banner-heading {
    font-size: 50px;
  }

  .navbar-index-dgt-experience ul li a {
    font-size: 17px !important;
    padding: 7px 43px !important;
  }

  /* ------------ industries sction -------------- */
  figure.dgt-experience-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {

  /* ------------ industries sction -------------- */
  figure.dgt-experience-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  width: 100%;
  height: 50px;
  background-color: #000000f4;
  gap: 5px;
}


.breadcrumb a {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
}