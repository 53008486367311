#hr-talent-about,
#hr-talen-industry,
#hr-talent-services,
#hr-talent-approach,
#hr-talent-benefits,
#hr-talent-circle,
#hr-talent-aquacious-help,
#hr-talent-labour-managed,
#hr-talent-driven-sec,
#hr-talent-what-we-think,
.talent-hr-crime-sec {
  padding-top: 5rem;
}



#hr-talent-indus-head-effect::after {
  width: 207px !important;
  margin-left: -359px !important;
}

#hr-talent-service-head-effect::after {
  margin-left: -269px !important;
}

#hr-talent-benefits-head-effect::after {
  width: 174px !important;
  margin-left: -484px !important;
  margin-top: 9px !important;
}

/* -------- pages  heading and sub heading css --------- */
.hr-talent-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.hr-talent-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.hr-talent-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

/* ------------- banner css ------------- */
.hr-talent-banner-main {
  background-blend-mode: overlay;
  width: 100%;
  height: 450px;
  max-height: 450px;
  /* margin-top: 10.5rem; */
  background-color: #727272;
  background-size: cover;
  /* padding: 4rem 0; */
  background-position: center;
  transition: all 3s;
  display: flex;
  align-items: center;
}

.hr-talent-banner-main:hover {
  transform: scale(1.03);
}

.hr-talent-banner-main-consult,
.hr-talent-banner-main-acquacious,
.hr-talent-banner-main-contingent {
  background-blend-mode: unset !important;
  background-color: unset !important;


}

.hr-talent-banner-heading {
  font-size: 62px;
  text-transform: none;
  font-weight: 800;
  color: #14212b;
  line-height: 1.1;
}

.hr-talent-banner-para {
  color: #14212b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
  margin-top: 2rem;
}

#play-button-hr-talent {
  display: flex;
  align-items: center;
}

div#play-button-hr-talent-banner {
  position: relative;
}

.hr-talent-it-bnr-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 50%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.hr-talent-it-bnr-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.hr-talent-it-bnr-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.hr-talent-it-bnr-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.hr-talent-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff;
}

.hr-talent-banner-know-how a svg {
  padding: 4px 5px 0 5px;
}

.hr-talent-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}

div#play-button-hr-talent-banner {
  position: relative;
}

/* --------- page index css ----------------- */
section.nag-page-index-hr-talent {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 99;
}

section.nag-page-index-hr-talent.sticky-hr-talent {
  position: fixed;
  top: 90px !important;
}

.nav-link-index-hr-talent.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-hr-talent ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-index-hr-talent ul li {
  display: inline-flex;
}

.navbar-index-hr-talent ul li:hover,
.navbar-index-hr-talent ul li a:hover {
  background-color: #ffffff;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-index-hr-talent ul li a {
  color: #fff;
  font-size: 18px;
  padding: 7px 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* --------- about section  ---------------------- */
.hr-talent-about-btn-bln {
  margin-top: 2rem;
}

.hr-talent-about-btn-bln a {
  background: #CD4409;
  padding: 10px 25px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.hr-talent-about-btn-bln a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

#hr-talent-about-dev-img {
  margin-top: 1rem;
}

.hr-talent-about-introimg img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.hr-talent-about-triangle-down::after {
  width: 12% !important;
}

.hr-talent-about-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.hr-talent-about-img-desc-txt {
  width: 95.5%;
  height: max-content;
  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-top: -2.5rem;
  float: right;
}

.hr-talent-about-img-desc-txt h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.hr-talent-about-img-desc-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 8rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.hr-talent-about-img-desc-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.hr-talent-about-img-desc-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* ------------ industries sction -------------- */
.hr-talent-indus-item-row {
  margin-top: 2rem;
}

.hr-talent-indus-item-box {
  margin-bottom: 2rem;
}

.hr-talent-indus-item-box.hidden-segments,
.hidden-wedo_box,
.hidden-hrservice {
  display: none;
}

.hr-talent-indus-item-box.visible-segments,
.visible-wedo_box,
.visible-hrservice {
  display: block;
}

.hr-talent-indus-item-card {
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  border: unset !important;
  height: 350px;
  text-align: center;
}

.hr-talent-indus-item-card figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

figure.hr-talent-indus-item-effect-steve img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  object-fit: cover;
}

figure.hr-talent-indus-item-effect-steve h2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #212121;
  line-height: normal;
  text-transform: capitalize;
  word-spacing: -0.15em;
  background: #ffffff;
}

figure.hr-talent-indus-item-effect-steve p {
  padding: 0.5em;
  opacity: 0;
  font-size: 15px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  line-height: 21px;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 10px 11px !important;
  margin: 0;
  height: 100% !important;
}

figure.hr-talent-indus-item-effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.hr-talent-indus-item-effect-steve:hover p {
  opacity: 1;
}

figure .hr-talent-indus-item-intro {
  position: absolute;
  bottom: -1px;
  width: 90%;
  height: 40%;
  margin-left: 5% !important;
}

.btn-div-manage-it-main {
  margin-top: 2rem;
}

.load-more-hr-talent-it-btn {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.load-more-hr-talent-it-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ----------------- servcie section ------------- */
.hr-talent-service-crd-main {
  margin-bottom: 2rem;
  border: 2px solid #eedfd7;
  text-align: center;
}

.hr-talent-service-crd-main a {
  text-decoration: none !important;
}

.hr-talent-service-crd-img-main {
  overflow: hidden;
  margin: 5px;
}

.hr-talent-service-crd-img-main:hover img {
  transform: scale(1.1);
}

.hr-talent-service-crd-main:hover {
  border: 2px solid #CD4409;
}

.hr-talent-service-crd-main .card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.hr-talent-service-crd-main img {
  width: 100%;
  height: 220px;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  object-fit: cover;
  border-radius: 5px;
}

.hr-talent-service-crd-main h3 {
  color: #14212b;
  margin-top: 1rem;
  font-size: 20px;
  padding: 0 8px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    color: #CD4409;
  }
}

.hr-talent-service-crd-main p {
  color: #696969;
  text-align: center;
  padding: 0 8px !important;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.hr-talent-service-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.hr-talent-service-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

/* ---------------- approch section ------------- */
.hr-talent-approach-sec {
  background-image: url("../../../../public/assets/img/shape-12-1.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.hr-talent-main-approach-box {
  position: relative;
  margin: 10px;
  padding: 60px;
  width: 23.1%;
  border: 1px solid #d1d1d1;
  background: #fffbf9;
  align-items: center;
}

.hr-talent-approach-main-heading {
  color: #14212b;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-left: 4px solid #CD4409;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.hr-talent-approach-main-heading span {
  color: #CD4409;
}

#hr-talent-approach-row-main-content {
  margin: 2rem 0;
}

.hr-talent-main-approach-box h2 {
  color: #14212b;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.hr-talent-main-approach-box::before,
.hr-talent-main-approach-box::after {
  position: absolute;
  border-style: solid;
  border-color: #CD4409;
  border-width: 1px;
  height: 80px;
  width: 80px;
  content: "";
}

.hr-talent-main-approach-box::before {
  top: -6px;
  left: -6px;
  border-width: 5px 0 0 5px;
}

.hr-talent-main-approach-box::after {
  bottom: -6px;
  right: -6px;
  border-width: 0 5px 5px 0;
}

/* ---------- smart service section ------------ */
.hr-talent-benefits-sec {
  background-image: url(../../../../public/assets/img/bpo/under-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hr-talent-benefits-main {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 0px;
  width: 48%;
  margin: 10px;
}

.hr-talent-benefits-main:hover .hr-talent-benefits-main-errow {
  display: block;
}

.hr-talent-benefits-main .hr-talent-benefits-main-errow {
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 0px 15px 0px 0px;
  background: #FEF2EA;
  display: none;
}

.hr-talent-benefits-main .hr-talent-benefits-main-errow svg {
  padding: 16px;
  color: #CD4409;
  font-size: 18px !important;
  rotate: -45deg;
}

.hr-talent-benefits-main-head {
  padding: 20px 40px;
}

.hr-talent-benefits-main-head h2 {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hr-talent-benefits-main-head img {
  width: 50px;
  object-fit: contain;
  margin-top: -1rem;
}

.hr-talent-benefits-main-head a {
  text-decoration: none;
}

.hr-talent-benefits-main-head h2 {
  color: #CD4409 !important;
  font-size: 42px !important;
  font-weight: 700 !important;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #D1D1D1;
}

.hr-talent-benefits-main-head span {
  color: #14212B !important;
  font-size: 26px !important;
  font-weight: 600 !important;
}

.hr-talent-benefits-main-head p {
  color: #262626;
  font-size: 17px;
  font-weight: 400;
  padding-top: 15px;
  line-height: 28px;
}


/* --------------------hr talent aquacious -------------------------------------*/

.hr-talent-aquacious-banner-bg-btn {
  margin-top: 3.5rem;
}

.hr-talent-aquacious-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.hr-talent-aquacious-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.hr-talent-aquacious-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.hr-talent-aquacious-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* ---------------------------hr talent aquacious circle section ----------------------- */

.circle-sec-hr-talent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-talent-aquacious-circle {
  width: 230px;
  height: 230px;
  background-color: #FEF2EA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 1rem 1rem;
}

.hr-talent-acqacious-head {
  color: #CD4409;
  font-weight: 700;
  font-size: 45px;

}

.hr-talent-acqacious-para {
  text-align: center;
  font-size: 13px;
  padding: 0 10px;
}

.hr-talent-banner-overlay {
  background-color: #c5c5c5;
}


/* ---------- all sub pages Service Areas section  css ------------ */
.hr-talent-aquaciousservice-sec {
  margin-top: 3rem;
}



/* -------- pages  heading and sub heading css --------- */
.hr-talent-aquacioushead-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.hr-talent-aquacioushead-main-title span {
  color: #CD4409 !important;
  display: block;
}

.hr-talent-aquacioushead-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

#hr-talent-aquaciousservice-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#hr-talent-aquaciousservice-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#hr-talent-aquaciousservice-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#hr-talent-aquaciousservice-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#hr-talent-aquaciousservice-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#hr-talent-aquaciousservice-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

/* -------------------------------hr-talent-labour-managed ---------------------------------- */

.hr-talent-labour-managed-heading {
  text-align: center;
  font-weight: 600;
  font-size: 45px;
  margin-bottom: 1rem;
}

.hr-talent-labour-managed-heading span {
  color: #CD4409;

}

.hr-talent-labour-managed-main-para {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 4rem;
}

.hr-talent-labour-managed-box {}

.hr-talent-labour-managed-box h4 {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 2rem;
  position: relative;
}

.hr-talent-labour-managed-box h4::after {
  content: "";
  width: 50%;
  height: 5px;
  position: absolute;
  bottom: -34%;
  left: 24%;
  background-color: #CD4409;
  border-radius: 10px;
}

.hr-talent-labour-managed-box p {
  text-align: center;
  margin-top: 3rem;
}


/* ---------- all sub pages Service Areas section  css ------------ */
.hr-talent-service-sec {
  margin-top: 3rem;
}

.hr-talent-service-sec {
  margin-top: 2rem;
}

.dgt-experience-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.dgt-experience-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.dgt-experience-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

#talent-hr-content-marketing-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#talent-hr-content-marketing-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#talent-hr-content-marketing-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#talent-hr-content-marketing-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#talent-hr-content-marketing-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

#talent-hr-content-marketing-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}


/* ----------- cyber crime section for cloud infra security page ---------- */

.talent-hr-crime-head {
  background-color: #CD4409;
  padding: 25px 50px;
  /* height: 100%; */
  /* line-height: 200%; */
}

.talent-hr-crime-head h3 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: center;
}

.talent-hr-crime-head p {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.talent-hr-crime-content .intelligence-driven-crime {
  padding: 15px 12px;
  box-shadow: 0px 0px 10px 1px #D1D1D1;
  margin-top: 0.7rem;
}

.talent-hr-crime-content .intelligence-driven-crime h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  color: #14212B;
  border-bottom: 3px solid #CD4409;
}

.talent-hr-crime-content .intelligence-driven-crime p {
  font-size: 16px;
  font-weight: 400;
}

/* ------------------------------- hr consult ------------------------------------------ */

#hr-talent-approach-row-main-content ul li {
  font-weight: 600;
  margin-left: 1rem;
}


/* ---------------------------- hr talent driving success -------------------------------------- */

.hr-driven-content h2 {
  color: #CD4409;
  font-size: 35px;
  font-weight: 600;
  margin: 15px 0;
}

.hr-driven-content h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 15px 0;
}

.hr-driven-content p {
  line-height: 26px;
  margin: 15px 0;
}

.hr-driven-img img {
  width: 300px;
  height: 350px;
}

#app-datamig-hr-talent-about-effect span::after {
  content: unset !important;
}

.hr-talent-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

.hr-talent-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 16rem;
}

.hr-talent-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.hr-talent-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

#hr-talent-stories-think {
  margin-top: 2rem;
}

.hr-talent-maximum-efficiency-first img {
  width: 100%;
  margin-bottom: 1rem;
  height: 12.5rem;
  object-fit: cover;
}

.hr-talent-maximum-efficiency-first-img img {
  height: 300px !important;
}



.hr-talent-maximum-efficiency-second h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.hr-talent-maximum-efficiency-second h6::after {
  position: absolute;
  content: "";
  margin-left: -10.5rem;
  margin-top: 1.2rem;
  width: 10.5rem;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.hr-talent-maximum-efficiency-second h5 {
  color: #000;
  font-size: 24px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hr-talent-maximum-efficiency-second h2 {
  color: #CD4409;
  font-weight: 600;
}

.hr-talent-maximum-efficiency-second p {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}


.technology-section-heading-remove-bg::after {
  content: unset !important;
}


@media only screen and (max-width: 480px) {

  #hr-talent-about,
  #hr-talen-industry,
  #hr-talent-services,
  #hr-talent-approach,
  #hr-talent-benefits,
  #hr-talent-labour-managed,
  #hr-talent-circle,
  #hr-talent-driven-sec,
  #hr-talent-what-we-think,
  .talent-hr-crime-sec {
    padding-top: 2rem !important;
  }

  .hr-talent-head-main-title span::after,
  #hr-talent-benefits-head-effect::after {
    content: unset !important;
  }

  #hr-talent-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
  }

  #hr-talent-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 4px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center;
  }

  .hr-talent-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  /* ----------- banners css ------------ */
  .hr-talent-banner-main {
    margin-top: unset !important;
    height: unset !important;
    max-height: unset !important;
    padding: 3rem 0 !important;
  }

  .hr-talent-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  /* -------play button-------- */
  .hr-talent-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .hr-talent-it-bnr-btn-main {
    margin-top: 20% !important;
  }

  .hr-talent-know-how-btn {
    text-align: center;
  }

  /* --------- page index css ----------------- */
  .navbar-index-hr-talent ul li a {
    font-size: 16px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-hr-talent {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-hr-talent li {
    margin: 5px !important;
  }

  /* --------- about section  ---------------------- */
  .hr-talent-about-btn-bln {
    margin-top: unset !important;
    justify-content: center;
    display: flex;
  }

  #hr-talent-about-dev-img {
    margin-top: 2rem !important;
  }

  .hr-talent-about-img-desc-txt {
    width: 92% !important;
  }

  .hr-talent-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ------------ industries sction -------------- */
  .hr-talent-indus-item-box {
    margin-bottom: 1rem !important;
  }

  .hr-talent-indus-item-card {
    height: 300px !important;
  }

  figure.hr-talent-indus-item-effect-steve img {
    height: 100% !important;
  }

  figure.hr-talent-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 17px;
  }

  figure .hr-talent-indus-item-intro {
    height: 50%;
  }

  .btn-div-manage-it-main {
    margin-top: 0rem !important;
  }

  /* ----------------- servcie section ------------- */
  .hr-talent-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-hr-talent-it-main {
    margin-top: 0rem !important;
  }

  /* ---------------- approch section ------------- */
  .hr-talent-approach-main-heading {
    font-size: 30px !important;
    padding-left: 15px !important;
  }

  #hr-talent-approach-row-main-content {
    margin: 1rem 0 !important;
  }

  .hr-talent-main-approach-box {
    width: 94%;
  }

  /* -------- benefits section -------------- */
  .hr-talent-benefits-main {
    width: 93% !important;
  }

  .hr-talent-benefits-main-head {
    padding: 10px !important;
  }

  .hr-talent-benefits-main-head h2 {
    font-size: 34px !important;
  }

  .hr-talent-benefits-main-head span {
    font-size: 20px !important;
  }

  .hr-talent-benefits-main-head p {
    font-size: 16px !important;
    padding-top: 5px !important;
    line-height: 26px !important;


  }

  /* -----------------------------------hr talent aquacious ------------------------- */

  .hr-talent-aquacious-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .hr-talent-aquacious-banner-bg-btn {
    margin-top: 2rem !important;
  }

  /* ------------ service secion ------------- */
  .hr-talent-aquaciousservice-sec {
    margin-top: 2rem;
  }

  .hr-talent-aquacioushead-main-title span::after {
    content: unset !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-aquacioushead-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center;
  }

  .hr-talent-aquacioushead-main-title span {
    display: inline;
    margin-left: 5px;
  }

  .hr-talent-aquacioushead-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #hr-talent-aquaciousservice-area-cont {
    height: 100% !important;
  }


  /* -------------------hr-talent-labour-managed ---------------------------*/
  .hr-talent-labour-managed-box h4 {
    font-size: 30px;
  }

  .hr-talent-labour-managed-heading {
    font-size: 30px;

  }

  /* --------------------------------------------services ---------------------------*/

  .dgt-experience-head-main-title {
    font-size: 30px !important;
    position: relative;
    text-align: center;
  }

  .dgt-experience-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #talent-hr-content-marketing-area-cont {
    height: 100% !important;
  }


  /* -------- cyber crime section ------------- */

  .hr-driven-img img {
    width: 100% !important;
    height: 100% !important;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  .hr-talent-sub-under-img-right {
    margin-top: 1rem !important;
  }

  .hr-talent-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .hr-talent-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  #hr-talent-about,
  #hr-talen-industry,
  #hr-talent-services,
  #hr-talent-approach,
  #hr-talent-benefits,
  #hr-talent-circle,
  #hr-talent-labour-managed,
  #hr-talent-driven-sec,
  #hr-talent-what-we-think,
  .talent-hr-crime-sec {
    padding-top: 2rem !important;
  }

  .hr-talent-head-main-title span::after {
    width: 134px !important;
    margin-left: -222px !important;
    margin-top: 4px !important;
    height: 29px !important;
  }

  #hr-talent-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
  }

  #hr-talent-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 4px !important;
  }

  #hr-talent-benefits-head-effect::after {
    width: 129px !important;
    margin-left: -322px !important;
    margin-top: -7px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-head-main-title,
  .hr-talent-head-main-title span {
    font-size: 30px !important;
    text-align: center;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  /* -----------  banners css ------------ */
  .hr-talent-banner-main {
    margin-top: 0px !important;
    height: 100% !important;
  }

  .hr-talent-banner-para {
    text-align: center;
  }

  div#play-button-hr-talent {
    margin: 2rem 0 0 0 !important;
  }

  #play-button-hr-talent-banner,
  .hr-talent-know-how-btn {
    width: 50% !important;
  }

  .hr-talent-it-bnr-btn-main {
    margin-top: 35% !important;
    margin-left: 50% !important;
  }

  .hr-talent-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-hr-talent-banner,
  .hr-talent-know-how-btn {
    width: 50% !important;
  }

  /* --------- page index css ----------------- */
  .nav-link-index-hr-talent {
    font-size: 27px;
    padding: 3px 13px 3px 13px;
  }

  .navbar-index-hr-talent ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .navbar-index-hr-talent ul {
    padding: 0 !important;
  }

  /* --------- about section  ---------------------- */
  .hr-talent-about-btn-bln {
    margin-top: unset !important;
    display: flex !important;
    justify-content: center !important;
  }

  .hr-talent-about-img-desc-txt {
    width: 95% !important;
  }

  #hr-talent-about-dev-img {
    margin-top: 2rem !important;
  }

  /* ------------ industries sction -------------- */
  .btn-div-manage-it-main {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  /* ----------------- servcie section ------------- */
  .hr-talent-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-hr-talent-it-main {
    margin-top: 0rem;
  }

  /* ------- apporch section ----------- */
  .hr-talent-approach-main-heading {
    font-size: 30px !important;
  }

  .hr-talent-main-approach-box {
    width: 45.8%;
  }

  /* -------- benefits section -------------- */
  .hr-talent-benefits-main {
    width: 95% !important;
  }

  .hr-talent-aquacious-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  /* ------------ service secion ------------- */
  .hr-talent-aquaciousservice-sec {
    margin-top: 2rem;
  }

  .hr-talent-aquacioushead-main-title span::after {
    width: 140px !important;
    margin-left: -316px !important;
    margin-top: 4px !important;
    height: 30px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-aquacioushead-main-title {
    font-size: 30px !important;
    text-align: center;
  }

  .hr-talent-aquacioushead-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #hr-talent-aquaciousservice-area-cont {
    height: 100% !important;
  }

  .hr-talent-labour-managed-heading {
    font-size: 30px;
  }

  /* ------------ service secion ------------- */
  .hr-talent-service-sec {
    margin-top: 2rem;
  }

  .dgt-experience-head-main-title::after {
    width: 156px !important;
    margin-left: -180px !important;
    margin-top: -33px !important;
    height: 30px !important;
  }

  .dgt-experience-head-main-title,
  .dgt-experience-head-main-title span {
    font-size: 30px !important;
    text-align: center;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  #talent-hr-content-marketing-area-cont {
    height: 100% !important;
  }

  .hr-driven-img img {
    width: 100% !important;
    height: 100% !important;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: center;
  }

  .hr-talent-sub-under-img-right {
    margin-top: 1rem !important;
  }

  .hr-talent-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .hr-talent-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  #hr-talent-about,
  #hr-talen-industry,
  #hr-talent-services,
  #hr-talent-approach,
  #hr-talent-benefits,
  #hr-talent-driven-sec,
  #hr-talent-what-we-think,
  .talent-hr-crime-sec {
    padding-top: 3rem;
  }

  .hr-talent-head-main-title span::after {
    width: 152px !important;
    margin-left: -260px !important;
    margin-top: 6px !important;
    height: 32px !important;
  }

  #hr-talent-indus-head-effect::after {
    width: 165px !important;
    margin-left: -283px !important;
    margin-top: 7px !important;
    height: 30px !important;
  }

  #hr-talent-service-head-effect::after {
    margin-left: -212px !important;
    width: 138px !important;
    height: 30px !important;
    margin-top: 6px !important;
  }

  #hr-talent-benefits-head-effect::after {
    width: 156px !important;
    margin-left: -379px !important;
    margin-top: 0px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-head-main-title,
  .hr-talent-head-main-title span {
    font-size: 35px !important;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  /* ----------- banners css ------------ */
  .hr-talent-banner-main {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset;
  }

  .hr-talent-banner-para {
    text-align: center;
  }

  .hr-talent-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-hr-talent {
    margin-top: 2rem;
  }

  #play-button-hr-talent-banner,
  .hr-talent-know-how-btn {
    width: 50% !important;
  }

  .hr-talent-it-bnr-btn-main {
    margin-top: 25% !important;
    margin-left: 50% !important;
  }

  /* --------- page index css ----------------- */
  .navbar-index-hr-talent ul li a {
    font-size: 17px !important;
    padding: 8px 30px !important;
  }

  /* --------- about section ---------- */
  .hr-talent-about-img-desc-txt {
    width: 93% !important;
  }

  .hr-talent-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ------------ industries sction -------------- */
  .hr-talent-indus-item-box {
    width: 50% !important;
  }

  figure.hr-talent-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ----------------- servcie section ------------- */
  .hr-talent-services-div-main {
    width: 50% !important;
    display: unset !important;
  }

  .hr-talent-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-hr-talent-it-main {
    margin-top: 0rem;
  }

  /* -------------- apporch section -------------  */
  .hr-talent-main-approach-box {
    width: 46.2%;
  }

  /* -------- benefits section -------------- */
  .hr-talent-benefits-main {
    width: 47% !important;
  }

  .hr-talent-benefits-main-head {
    padding: 20px 20px !important;
  }

  .hr-talent-benefits-main-head span {
    font-size: 24px !important;
  }

  /* ---------------------------------hrtalent aquacious -----------------------------*/

  .hr-talent-aquacious-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  /* ------------ service secion ------------- */
  .hr-talent-aquaciousservice-sec {
    margin-top: 2rem;
  }

  .hr-talent-aquacioushead-main-title span::after {
    width: 143px !important;
    margin-left: -366px !important;
    margin-top: 5px !important;
    height: 32px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .hr-talent-aquacioushead-main-title {
    font-size: 35px !important;
  }

  .hr-talent-aquacioushead-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #hr-talent-aquaciousservice-area-cont-img,
  #hr-talent-aquaciousservice-area-cont-desc {
    width: 100% !important;
  }

  /* ------------ service secion ------------- */
  .hr-talent-service-sec {
    margin-top: 2rem;
  }

  .dgt-experience-head-main-title::after {
    width: 181px !important;
    margin-left: -12px !important;
    margin-top: -33px !important;
    height: 32px !important;
  }

  .dgt-experience-head-main-title,
  .dgt-experience-head-main-title span {
    font-size: 35px !important;
  }

  .dgt-experience-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  #talent-hr-content-marketing-area-cont {
    height: 100% !important;
  }

  #talent-hr-content-marketing-area-cont-img,
  #talent-hr-content-marketing-area-cont-desc {
    width: 100% !important;
  }

  .hr-driven-img img {
    width: 100% !important;
    height: 100% !important;
  }

  .hr-talent-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }


  .hr-talent-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .hr-talent-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

}

@media (min-width: 992px) and (max-width: 1200px) {
  .hr-talent-head-main-title span::after {
    margin-left: -333px !important;
    margin-top: 9px !important;
  }

  #hr-talent-indus-head-effect::after {
    margin-left: -320px !important;
    margin-top: 7px !important;
  }

  #hr-talent-service-head-effect::after {
    margin-left: -239px !important;
    width: 155px !important;
    margin-top: 5px !important;
  }

  #hr-talent-benefits-head-effect::after {
    width: 164px !important;
    margin-left: -432px !important;
    margin-top: 5px !important;
  }

  /* ------------- pages heading and sub heading ------- */
  .hr-talent-head-main-title {
    font-size: 40px !important;
  }

  /* ----------- banners css ------------ */
  .hr-talent-banner-heading {
    font-size: 50px;
  }

  /* ------------ industries sction -------------- */
  figure.hr-talent-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ----------------- approch section ------------ */
  .hr-talent-main-approach-box {
    width: 22.5% !important;
    padding: 60px 20px !important;
  }

  /* ---------- benefits section -------------- */
  .hr-talent-benefits-main {
    width: 47% !important;
  }

  /* ---------------------------services section ---------------------------*/

  .dgt-experience-head-main-title::after {
    margin-left: -12px !important;
  }

  .dgt-experience-head-main-title {
    font-size: 40px !important;
  }

  .talent-hr-crime-head {
    background-color: #CD4409;
    padding: 75px 50px !important;
  }

  .intelligence-driven-crime h6 {
    font-size: 19px !important;
  }



}

@media (min-width: 1201px) and (max-width: 1440px) {

  /* ------------ industries sction -------------- */
  figure.hr-talent-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }



}

#hr-talent-approach-row-main-content>div {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}