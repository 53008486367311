/* all section padding-top */
#overview,
#about,
#services,
#ites-industry-approach,
#ites-industry-project-deliver,
#ites-dgt-success {
    padding-top: 4rem;
}

/*=========== Ites industry all pages heading and sub heading css ===========*/
.indus-ites-section-heading {
    font-size: 45px;
    font-weight: 700;
    position: relative;
    color: #CD4409;
}

.indus-ites-section-heading-cyber {
    font-size: 40px !important;
}

.indus-ites-section-heading span {
    color: #14212b !important;
}

.ites-indus-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.ites-indus-section-sub-heading span {
    color: #CD4409;
    font-weight: 700;
}

.ites-indus-section-sub-heading b ul {
    margin-top: 12px;
}

.ites-indus-section-sub-heading b ul {
    text-align: left;
}



.ites-indus-about-eff::after {
    content: unset !important;
}

.ites-indus-benifits-eff::after {
    margin-left: -301px !important;
    width: 172px;
}

.ites-indus-provides-head-bg::after {
    margin-left: -457px;
    width: 190px;
}

.ites-indus-effort-head-bg::after {
    margin-left: -563px;
    width: 190px;
}

/* --------  ites Industry all banner css ---------- */
.ites-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* background-blend-mode: overlay; */
    /* background-color: #bbcac29c; */
    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.ites-indus-banner-cloud {
    background-blend-mode: unset !important;
    background-color: unset !important;
    padding: 5rem 0 2rem 0;
}

.dge-travel-banner-content-dge {
    width: 50% !important;
}

.travel-section-heading-dge {
    font-size: 40px !important;
}

.ites-indus-banner-dgt {
    padding: 5rem 0 2rem 0 !important;

}

.ites-indus-banner-cyber {
    padding: 3rem 0 2rem 0 !important;
}

.ites-indus-main-banner-head {
    color: #fff !important;
    margin-right: 19rem;
}

.ites-indus-main-banner-head-manage {
    display: inline-block;
    color: #000;

}

#ites-indus-main-banner {
    background-color: unset !important;
}

.ites-indus-bet-content h3 {
    width: 100%;
}


.ites-indus-banner-content {
    width: 55%;
}

.ites-indus-banner-manage {
    padding: 3rem 0 2rem 0 !important;
}

.ites-indus-bet-content h3 {
    color: #14212b;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.ites-indus-bet-content p {
    color: #14212b;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.indus-ites-banner-btn {
    margin: 2rem 0;
    /* text-align: end; */
    width: 100%;

}

.travel-ind-banner-btn-unique {
    text-align: unset !important;
}

.indus-ites-banner-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 8px 20px;
    background: #CD4409;
    font-weight: 600;
    transition: all 0.5s;
    margin-left: 10px;

}

.bnr-nbtn-ind-ites {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-ites:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.indus-ites-banner-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}




/* ------------------ main page indexing ---------------- */
section.ites-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-ites-indus {
    position: fixed;
    top: 0;
}

.ites-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.ites-indus-index-nav ul li {
    display: inline-flex;
}

.ites-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.ites-indus-index-nav ul li:hover,
.ites-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.index-ites-indus-item.active {
    background-color: white;
    color: #CD4409;
}


/* --------------- about section ------------- */


#ites-indus-read-btn-about {
    border: none !important;
    color: #CD4409;
    font-size: 18px;
    font-weight: 600;
    background: unset !important;
}

.ites-indus-sub-img-about {
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.ites-indus-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.ites-indus-front-txt {
    position: relative;
    z-index: 1;
    width: 94.6%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: right;
    margin-bottom: 0.8rem;
}

.ites-indus-front-txt h6 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.ites-indus-front-txt h6::after {
    content: "";
    display: block;
    position: relative;
    width: 12.8rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.ites-indus-front-txt h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.ites-indus-front-txt p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.ites-indus-img-sub-heading::after {
    width: 9.5rem !important;
}

/* -------- support provides section --------  */
.ites-indus-provides-head-bg br {
    display: none !important;
}

.visible-provide {
    display: block !important;
}

.hidden-provide {
    display: none;
}

.service-provides-ites-indus {
    position: relative;
    margin-bottom: 30px;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide {
    position: relative;
    overflow: hidden;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide {
    position: relative;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide img {
    position: relative;
    width: 100%;
    height: 350px !important;
    object-fit: cover;
    display: block;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-caption-service-provide {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    color: #ffffff;
    display: block;
    font-size: 22px;
    padding: 20px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide {
    position: absolute;
    left: 0px;
    bottom: -353px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide h4 {
    position: relative;
    color: #ffffff;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide p {
    position: relative;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.6em;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide:hover .ites-indus-caption-service-provide {
    bottom: -60px;
}

.service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide:hover .ites-indus-overlay-box-service-provide {
    bottom: 0px;
}



/* --==================== effortlessly section css ==========================- */

.ites-indus-effort-head-bg br {
    display: none;
}

/* ------ benefits beno support ------ */

.ites-indus-service-area-cards {
    width: 31% !important;
    border: 1px solid #b3b3b3af;
    margin: 10px;
    padding: 15px;
    border-left: 5px solid #CD4409;
}

.ites-indus-service-area-cards h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ites-indus-service-area-cards p {
    color: #696969;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* {----------------------- work with us section  -----------------------} */

.ites-indus-work-box-content {
    margin: 10px;
    padding: 10px;
    width: 48%;
    border: 1px solid #d1d1d1;
    border-bottom: 5px solid #CD4409;
    background-color: #FEF2EA;

}

.ites-indus-work-box-content h2 {
    color: #CD4409;
    font-size: 35px;
    font-weight: 800;
    text-align: center;
}

.ites-indus-work-box-content p {
    color: #333;
    font-size: 17px;
    text-align: center;
    padding: 0 30px;
}

/* -------------ites in digital transformation page start---------*/

/* -------------banner section---------*/

.ites-indus-bet-content p {
    color: #fff;
}


/* --------------digital service and solution section start--------------- */

.ites-dgt-bg-effect {
    background-image: url("../../../../public/assets/img/shape-12-1.png");
    background-repeat: no-repeat;
}

.itesdgt-digital-solution {
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 10px;
    height: 15rem;
    position: relative;
    margin-top: 1rem !important;
}

.itesdgt-digital-solution-img-dep img {
    width: 100%;
    height: 13.7rem;
    object-fit: cover;
}

.itesdgt-digital-solution-content-dep h4 {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    margin-top: 17px;
    font-weight: 600;
    line-height: normal;
}

.itesdgt-digital-solution-content-dep p {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.itesdgt-digital-solution:hover::before {
    position: absolute;
    content: "";
    width: 7rem;
    height: 8px;
    transition: 0.7s;
    left: -1px;
    top: -8px;
    background-color: #CD4409;
}

.itesdgt-digital-solution:hover::after {
    position: absolute;
    content: "";
    width: 8px;
    transition: 0.7s;
    height: 7rem;
    left: -9px;
    top: -8px;
    background-color: #CD4409;
}

/* {=======================Retail in digital page ites success stories section============== } */
.ites-dgt-section-para {
    text-align: center;
    margin-top: 12px;
}

.ites-dgt-success-content {
    background-color: #FEF2EA;
    border-radius: 100px;
    padding: 45px 50px;
    width: 62rem;
    margin: 2rem 0 0 54px;
}

.ites-dgt-success-content h4 {
    text-align: right;
    position: relative;
}


.ites-dgt-success-content p {
    text-align: right;
    font-size: 17px;
}

.ites-dgt-success-img {
    /* background-color: #fff; */
}

.ites-dgt-success-img img {
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgba(0, 151, 58, 0.20);
    padding: 12px;
    background-color: #fff;
    margin-top: -17px;
    margin-left: 16px;
}

/* --------------------------ites industry ------------------*/

#ites-industry-approach {
    background-image: url(../../../../public/assets/img/shape-12-1.png);
    background-repeat: no-repeat;
    background-position: bottom;
}

#itsupport-approach {
    background-image: none;
    padding-top: 3rem !important;
}

.approach-bg-head span {
    display: block;
}

.ites-industry-approach-cards-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ites-industry-approach-enforcement-cards {
    border: 2px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem 5px;
    margin: 10px;
    width: 23.2%;
}

.ites-industry-approach-enforcement-cards:hover {
    border: 2px solid #CD4409;
}

.ites-industry-approach-enforcement-cards img {
    width: 80px;
    height: 85px;
    margin-top: 3rem;
    object-fit: contain;
}

.ites-industry-approach-enforcement-cards h2 {
    margin-top: 2.5rem;
    color: #14212b;
    font-size: 22px;
    font-weight: 600;
}

/* ----------- Managed IT Support Services ------------ */

.ites-industry-accordion-body-cont {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

button.accordion-button {
    box-shadow: none !important;
}

/* -------- ites-industry-project-deliver ------------- */


.ites-industry-project-deliver {
    width: 88%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

.ites-industry-project-deliver div {

    /* border: 1px solid black; */
    align-items: center;
    line-height: 50%;
}

.ites-industry-project-deliver div h3 {
    color: #CD4409;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 14px;
}

.ites-industry-project-deliver div p {
    color: #262626;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    height: 60px;
}



/* ----------------------ites in BPO page service section -----------------*/
.ites-bpo-advantage {
    background-image: url("../../../../public/assets/img/sape-down-bp.png");
    background-repeat: no-repeat;
}

.ites-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.ites-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.ites-ind-inner-area-customer-main:hover .ites-main-indus-serve-small-box {
    margin-left: 22rem;
}

.ites-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.ites-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.ites-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.ites-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.ites-ind-inner-area-customer-main .ites-main-ind-serve-circle-main .ites-main-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.ites-ind-inner-area-customer-main .ites-main-ind-serve-circle-main .ites-main-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}

.ites-ind-inner-area-customer-main:hover .ites-main-indus-serve-small-box {
    margin-left: 22rem;
}

.ites-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.ites-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}


/*-------------------- ites in bpo page slip box contant us section---------------------------- */
#ites-bpo-flip-contact {
    margin-top: 4rem;
}

.ites-bpo-slip-box-question {
    opacity: 0.9;
    background: #CD4409;
}

.ites-bpo-slip-box-question ul li {
    list-style: none;
    display: inline-flex;
    padding: 10px 50px;
}

.ites-bpo-slip-box-question ul li p {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
}

.ites-bpo-slip-box-question ul li a {
    border-radius: 33px;
    background: #00973a;
    padding: 8px 25px;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    transition: 0.5s;
}

.ites-bpo-slip-box-question ul li a:hover {
    background: #fff;
    color: #00973a;
}

.text-black {
    color: #14212b !important;
}

@media only screen and (max-width: 480px) {

    /* all headings bg effect */
    .ites-indus-about-eff::after {
        content: unset !important;
    }

    .ites-indus-benifits-eff::after {
        display: none;
    }

    .ites-indus-provides-head-bg::after {
        margin-left: -148px;
        width: 143px;
        margin-top: -40px;
    }


    /* --============== all travel pages headings and sub headings ===============- */
    .indus-ites-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;

    }

    .ites-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* padding top all sections */
    #about,
    #provides,
    #services,
    #our-blog,
    #ites-dgt-success,
    #ites-industry-approach,
    #ites-industry-project-deliver {
        padding-top: 2rem;
    }

    #ites-bpo-flip-contact {
        margin-top: 2rem !important;
    }


    /* ---- all page banner css -------------- */
    .ites-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 1rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .ites-indus-bet-content {
        width: 100% !important;
    }

    .ites-indus-bet-content h3 {
        text-align: center;
        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .ites-indus-bet-content p {
        font-size: 18px !important;
        line-height: 26px !important;
        padding: 10px 5px 0 5px !important;
        text-align: center;
    }

    .indus-ites-banner-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    .ites-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .ites-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .ites-indus-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .travel-serv-index-nav ul li a {
        padding: 8px 12px !important;
    }

    .ites-indus-front-txt {
        float: unset !important;
        margin-left: 0rem;
        width: 93.2%;
    }

    .ites-indus-front-txt h4 {
        font-size: 24px !important;
    }

    .ites-indus-front-txt p {
        line-height: 20px !important;
    }

    /* --------------- main page providers secton -------------- */
    .service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide p {
        line-height: 22px !important;
    }

    .service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide h4 {
        font-size: 22px !important;
    }

    .service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-overlay-box-service-provide {
        padding: 20px 8px !important;
    }

    .service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide .ites-indus-caption-service-provide {
        font-size: 18px !important;
        padding: 15px 0px !important;
    }

    .service-provides-ites-indus .ites-indus-inner-box-service-provide .ites-indus-image-box-service-provide img {
        height: 100% !important;
    }

    .ites-indus-provides-head-bg br {
        display: block !important;
    }

    /* effortlessly section */
    .ites-indus-service-area-cards {
        width: 94% !important;
        height: max-content !important;
        padding: 7px !important;
    }

    .ites-indus-effort-head-bg br {
        display: block;

    }

    /* --------- work with us section -------------------------- */
    .ites-indus-work-box-content {
        margin-bottom: 10px !important;
        padding: 0px !important;
        width: 93.5% !important;
    }

    .ites-indus-work-box-content h2 {
        font-size: 60px !important;
    }

    .ites-indus-work-box-content p {
        font-size: 17px !important;
        padding: 2px;
    }


    /* -------------ites in digital transformation page start---------*/


    /* --------------digital service and solution section start--------------- */

    .itesdgt-digital-solution {
        height: unset !important;
    }

    .indus-main-ites-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }


    .ites-dgt-success-content {
        background-color: #fef2ea;
        border-radius: 25px;
        height: 17rem;
        margin: 1rem 0 0;
        padding: 20px 0;
        position: relative;
        width: 100%;
    }

    .retaildgt-success-content p {
        font-size: 17px;
        text-align: center;
    }


    .ites-dgt-success-img img {
        height: 90px;
        margin-left: 16px;
        margin-top: -69px;
        padding: 5px;
        width: 90px;
    }

    .ites-industry-approach-enforcement-cards {
        width: 92% !important;
    }

    .ites-industry-project-deliver div p {

        height: unset !important;
    }

    .ites-industry-project-deliver {
        margin: 1rem auto 0 auto;
    }

    .ites-online-assets h3 {
        font-size: 22px !important;
    }
}

@media (max-width: 767px) and (min-width: 481px) {

    #about,
    #services,
    #ites-dgt-success,
    #ites-industry-approach,
    #ites-industry-project-deliver {
        padding-top: 2rem !important;
    }

    #ites-bpo-flip-contact {
        margin-top: 2rem !important;
    }

    /* all heading background effect*/
    .ites-indus-about-eff::after {
        content: unset !important;
    }

    .ites-indus-provides-head-bg::after {
        margin-left: -397px;
    }

    .ites-indus-effort-head-bg::after {
        margin-left: -490px;
    }

    .ites-indus-benifits-eff::after {
        margin-left: -394px !important;
        width: 167px;
    }


    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-ites-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .ites-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }


    /* ---------------indexing section--------- */
    .ites-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* ---- all page banner css -------------- */
    .ites-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .ites-indus-bet-content {
        width: 100% !important;
    }

    .ites-indus-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .ites-indus-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    .indus-ites-banner-btn {
        text-align: center !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-ites-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .ites-indus-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .ites-indus-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .ites-indus-front-txt {
        float: unset !important;
        width: 95% !important;
        padding-bottom: 13px;
    }

    .ites-indus-front-txt p {
        line-height: 19px !important;
    }



    /* effortlessly section */

    .ites-indus-service-area-cards {
        width: 46% !important;
    }

    .ites-indus-work-box-content {
        width: 98% !important;
    }

    /* -------------ites in digital transformation page start---------*/


    /* --------------digital service and solution section start--------------- */

    .itesdgt-digital-solution {
        height: unset !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-ites-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

}

@media (min-width: 768px) and (max-width: 991px) {

    #about,
    #services,
    #ites-dgt-success,
    #ites-industry-approach,
    #ites-industry-project-deliver {
        padding-top: 3rem !important;
    }

    #ites-bpo-flip-contact {
        margin-top: 3rem !important;
    }

    .ites-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .itesdgt-digital-solution {
        height: unset !important;
    }

    .itesdgt-digital-solution-img-dep,
    .itesdgt-digital-solution-content-dep {
        width: 100% !important;
    }

    /* ===================]retail success stories section start================= */
    .ites-dgt-success-content {
        border-radius: 113px;
    }

    /* =============retail in digital transformation page ================ */

    /* ============ retail success stories section-================= */
    .ites-dgt-success-img img {
        width: 140px;
        height: 140px;
        margin-top: -5px;
        margin-left: 0;
    }

    .ites-dgt-success-content {
        border-radius: 100px;
        padding: 20px 16px;
        width: 39rem !important;
        margin: 2rem 0 0 0;
    }

    .ites-industry-approach-enforcement-cards {
        width: 46% !important;
    }

    .ites-ind-inner-area-customer-main h5 {
        font-size: 22px !important;
    }
}


@media (max-width: 1200px) and (min-width: 992px) {

    /*-------------all heading background effect  --------------*/
    .ites-indus-benifits-eff::after {
        margin-left: -457px !important;
    }

    /* ---- all page banner css -------------- */
    .ites-indus-banner {
        padding: 5rem 0 4rem 0 !important;
    }

    .indus-ites-banner-btn {
        margin: 1rem 0 !important;
        width: 100%;
    }

    .indus-ites-banner-btn a {

        margin: 0rem 1rem !important;
        text-align: center;
    }

    .ites-indus-bet-content {
        width: 100% !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .ites-indus-section-sub-heading {
        line-height: 28px !important;
    }


    /* --------------- about section ------------- */
    .ites-indus-front-txt {
        float: unset !important;
        width: 94.4% !important;
    }

    .ites-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- work with us section ----------- */
    .ites-indus-work-box-content {
        width: 47.8% !important;
    }


    /* -------------ites in digital transformation page start---------*/


    /* --------------digital service and solution section start--------------- */
    .itesdgt-digital-solution {
        height: max-content !important;
    }

    .ites-dgt-success-content {
        padding: 35px 50px;
        width: 48rem !important;
    }

    .ites-dgt-success-img img {
        height: 150px;
        margin-left: 1rem;
        margin-top: -13px;
        width: 150px;
    }

    .ites-industry-approach-enforcement-cards {
        width: 80% !important;
    }
}


@media (max-width: 1400px) and (min-width: 1201px) {
    /* ---- all page banner css -------------- */

    .ites-indus-bet-content h3 {
        padding: 25px !important;
    }

    /* ites page about section  */
    .ites-indus-front-txt {
        width: 94.5% !important;
    }
}

.ites-indus-bet-content-manage-it p {
    color: #111111 !important;

    padding: 0 !important;
}

.dge-travel-banner-content-manage-dge p {
    color: #fff !important;
    padding: 0 !important;
}