.contact-form-sec h2::after {
  margin-left: -434px !important;
}

/* ------------ contact banner css ------------ */
section.contact-banner {
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  /* margin-top: 10.6rem; */
  /* padding: 7rem 0; */
  height: 400px;
  display: flex;
  align-items: center;
}

.banner-content-contact h2 {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-content-contact p {
  color: #262626;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
}

.banner-content-contact {
  width: 65%;
}

/* ---------------- contact branch sectuion ------------- */

#contact-our-branch-content-main {
  perspective: 1000px;
}

.contact-our-branch-content-innr {
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

#contact-our-branch-content-main:hover .contact-our-branch-content-innr {
  transform: rotateY(180deg);
}

#contact-our-branch-content-main:hover .front-heading-branch {
  display: none;
}

.branch-overlay-box-contact {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.branch-overlay-box-contact {
  color: white;
  transform: rotateY(180deg);
  padding-left: 8px !important;
  padding-right: 8px !important;
}


.contact-our-branch-sec {
  margin-top: 3rem;
}

.contact-our-branch-sec h2,
.contact-form-sec h2 {
  color: #14212b;
  font-size: 40px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

#contact-our-branch-content-main {
  width: 20% !important;
  margin-top: 1rem;
}

.contact-our-branch-content-innr {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 2.5rem;
  width: 100%;
  height: 300px;
}

.contact-our-branch-content-innr h4 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* ------------ contact details section --------------- */
.contact-details-sec {
  margin-top: 4rem;
}

.contact-details-content-innr {
  margin-bottom: 1rem;
}

.contact-details-content-innr img {
  height: 36px;
  width: 36px;
  object-fit: cover;
  margin-top: 0.7rem;
}

.contact-details-content-innr h4 {
  color: #231f20;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact-details-content-innr p {
  color: #696969;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

/* --------------- contact form ---------------- */
.contact-form-sec {
  margin-top: 2rem;
  background-image: url('../../images/main-site/world-map-effect.png');
  background-repeat: no-repeat;
}

.contact-form-beno-main {
  margin-top: 2rem;
}

.contact-form-beno-main input,
.contact-form-beno-main select {
  color: #9f9f9f;
  font-size: 18px;
  padding-left: 20px;
  height: 53px;
  border: 1px solid #ececec;
  background-color: #fff;
  border-radius: 17px;
  box-shadow: 0px 2px 0px 0px #bbb !important;
  margin-top: 2rem;

}

.contact-form-beno-main input:focus,
.contact-form-beno-main select:focus {
  border-bottom: 2px solid #CD4409;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
}

.contact-form-beno-main textarea {
  margin-top: 2rem;
}

.contact-form-beno-main textarea:focus {
  border: 1px solid #CD4409;
  box-shadow: none !important;
}

.contact-form-beno-main input:hover,
.contact-form-beno-main select:hover {
  border-bottom: 2px solid #CD4409;
  box-shadow: none !important;
}

.contact-form-beno-main textarea:hover {
  border: 1px solid #CD4409;
}

.contact-form-beno-main button {
  margin-top: 2rem;
  border-radius: 33px;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  padding: 9px 40px;
}

.contact-form-beno-main button:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* =================- terms condition page ================= */
.terms-condition-content h2,
.terms-condition-content p {
  color: #fff !important;
}

.privacy-terms-content-sec {
  padding: 2rem 5rem;
}

.privacy-terms-content-sec h2 {
  color: #212121;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.privacy-terms-content-sec p {
  color: #212121;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

/* ------------- terms and prolicy accordion section ------- */
.policy-accordion-faqs {
  color: #14212B;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;

  line-height: 30px;
}

.policy-accordion-faqs button {
  background: #FEF2EA !important;
}

.accordion-item-policy-faqs {
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: 5px;
}

#accordion-message-faqs p {
  font-size: 16px !important;
}

#policy-contact-sec {
  margin-top: 3rem;
}

/* ------------ site map  section -------------- */
.service-sitemap-main {
  background-color: #14212B;
  margin-top: 3rem;
}

.service-sitemap-main h3 {
  color: #FFF;
  font-size: 22px;
  padding: 8px 0;
}

.sitemap-content-main {
  margin-top: 1.5rem;
}

.sitemap-content-main h4 {
  color: #212121;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sitemap-content-main p {
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0 !important;
  padding: 0 !important;
}

/* -********** explore banner css ************** */
section.explore-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  width: 100%;
  /* margin-top: 10.6rem; */
  height: 400px;
  max-height: 400px;
  display: flex;
  align-items: center;
}

.banner-content-explore {
  padding: 6rem 1rem 6rem 0;
}

.banner-content-explore h2 {
  color: #000000;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-content-explore p {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.explore-banner-sec-img img {
  height: 400px;
  object-fit: cover;
  mix-blend-mode: multiply;
}

/* ----------------- profile details section -------------- */
.profile-details-ceo h3 {
  color: #14212b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 2rem 0;
}

.profile-details-ceo a {
  border-radius: 33px;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  padding: 14px 20px;
}

.profile-details-ceo a:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ----------------- portfolio section ------------ */
.portfolio-ceo-sec {
  margin-top: 3rem;
}

.portfolio-ceo-sec .col-lg-4.col-md-4.col-sm-12 {
  margin-top: 2rem;
}

.portfolio-ceo-img img {
  transition: 0.5s ease-in-out;
  margin-bottom: 1rem;
  width: 100%;
  height: 200px;
  border-radius: 2px;
  object-fit: cover;
}

.portfolio-ceo-main {
  height: 100%;
  overflow: hidden;
  border-radius: 2px;
  border-radius: 2px 2px 0px 0px;
  /* background: #fff7f2; */
  box-shadow: 0 0 10px 0 #e2e1e098;
}

.portfolio-ceo-main:hover .portfolio-ceo-img img {
  transform: scale(1.1);
}

.portfolio-ceo-content {
  padding: 10px;
}

.portfolio-ceo-content h3 {
  color: #14212b;
  font-size: 18px;
  font-weight: 600;
}

.portfolio-ceo-content p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}

.portfolio-ceo-content a {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
}

.portfolio-ceo-content a:hover,
.portfolio-ceo-content h3:hover {
  color: #CD4409;
}

/* --------------- technology and trends filter form --------- */
#filter-techtrend {
  padding: 1rem 1.5rem;
}

#filter-techtrend h3 {
  color: #14212b;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#filter-techtrend a {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#filter-techtrend input,
#filter-techtrend select {
  border-radius: 10px;
  border: 2px solid #CD4409;
  height: 53px;
  color: #9f9f9f;
  font-size: 17px;
  margin-top: 10px;
}

#filter-techtrend input:focus,
#filter-techtrend select:focus {
  box-shadow: none !important;
  border: 2px solid #CD4409;
}

#technology-trend-filter-data-col {
  margin-bottom: 1.5rem;
}

.person-tech-trends {
  margin-bottom: 1rem;
}

.person-tech-trends img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.person-tech-trends h5 {
  color: #CD4409;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 !important;
  margin: 0 !important;
}

.person-tech-trends p {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 !important;
  margin: 0 !important;
}

/* ========================== careers main page ===================== */
/* ==========All section margin top============== */
#job-sec,
#skills-sec,
#Career-benefits,
#career-post-resume {
  margin-top: 4rem;
}

.career-clear-form-btn {
  border: none;
  background: none;
  color: #fff;
  font-weight: 600;
  margin-top: 10px;
  transition: all 0.8s;
  padding: 7px 20px;

  &:hover {
    text-decoration: underline;
  }
}

/* -================ all comman page css =========- */
/* --------  comman page banner css ---------- */
.career-main-banner {
  height: 400px;

  /* margin-top: 10.5rem; */
  background-size: cover;
  background-position: center;
  /* padding: 8rem 0 4rem 0; */
  display: flex;
  align-items: center;
}



.Career-bet-content h3 {
  color: #14212b;
  font-size: 50px;
  font-weight: 700;
  margin-top: -65px;
}

.Career-bet-content p {
  color: #14212b;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 0 25px 0 25px;
}

.Career-bet-content span {
  font-weight: 700;
  display: block;
}

.bnr-nbtn-ind-bfsi {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-bfsi:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

/*=================== career page find job section ==================*/
#find-job-career {
  background-color: #CD4409;
  padding: 40px;
  margin-top: 2rem;
}

form.career-find-job {
  text-align: center;
}

.career-find-job-head h3 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.career-find-job-head p {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.form-field-height {
  padding-left: 18px !important;
  margin: 10px;
  font-size: 18px;
  border-radius: 17px;
  border: 1px solid #ececec;
  background-color: #fff;
  box-shadow: 0px 2px 0px 0px #bbb !important;
  width: 95%;
  height: 50px;
}

.career-search-form {
  margin: 0 1rem;
  display: flex;
  align-items: center;
}

.career-search-form-btn {
  border-radius: 30px;
  padding: 7px 30px;
  font-size: 18px;
  text-transform: capitalize;
  background-color: #00973a;
  color: #fff;
  font-weight: 600;
  border: 1px solid #00973a;
  transition: 0.5s;
}

.career-search-form-btn:hover {
  border: 1px solid #00973a;
  color: #00973a;
  background-color: #fff;
  transition: 0.5s;
}

/* =============career page latest job opening section=========== */
.career-main-head h3 {
  color: #14212b;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.career-opening-img img {
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
  margin-left: -10px;
}

.career-opening-content-box {
  border: 1px solid #d1d1d1;
  border-left: 4px solid #CD4409;
}

.career-opening-content {
  margin-top: -20px;
}

.career-opening-content h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}

.career-opening-content p {
  color: #696969;
  font-size: 16px;
}

.career-opening-upper-content h4 {
  color: #CD4409;
  font-size: 17px;
  font-weight: 600;
}

.career-opening-upper-content p {
  color: #212121;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  justify-content: end;
}

.career-opening-upper-content {
  padding: 10px 0;
}

.career-opening-all-content {
  margin-left: -16px;
}

.career-job-opening-sec {
  margin-top: 12px;
}

/* {=========== Find jobs as per skills ============} */

.career-main-skills-box-content {
  margin: 10px;
  padding: 30px;
  width: 23.1%;
  border-bottom: 3px solid #CD4409;
  background: #fffbf9;
  align-items: center;
}

.career-main-skills-box-content h2 {
  color: #d15233;
  font-size: 25px;
  font-weight: 800;
  text-align: center;
}

.career-main-skills-box-content p {
  color: #333;
  font-size: 18px;
  text-align: center;
}

/* {=========== Career page our benifit section ============} */

.career-main-benifit-content {
  width: 31% !important;
  border: 1px solid #b3b3b3af;
  margin: 10px;
  padding: 15px;
  border-left: 5px solid #CD4409;
}

.career-main-benifit-content h2 {
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.career-main-benifit-content p {
  color: #696969;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* =========== post your resume =========== */
#career-post-resume {
  padding: 3rem 0;
}

.career-post-resume-form {
  margin: 0 1rem;
}

.career-post-resume-form-btn {
  border-radius: 35px;
  padding: 7px 32px;
  font-size: 20px;
  background-color: #CD4409;
  color: #fff;
  font-weight: 600;
  border: 2px solid #CD4409;
  transition: 0.5s;
}

.career-resume-submit-btn button:hover {
  border: 2px solid #CD4409;
  color: #CD4409;
  background-color: #fff;
  transition: 0.5s;
}

.career-post-resume-form input:hover {
  border-bottom: 2px solid #CD4409 !important;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #ececec;
  outline: 0;
  box-shadow: none;
}

.career-resume-submit-btn {
  margin-top: 2.5rem;
}

/* -------------- career detail page --------------------- */
#subcribe-content-data-details {
  margin: 0 20%;
}

/* =----- career detail banner buttons ----*/
.career-detail-banner-btn {
  margin: 3rem -27px;
  text-align: left;
  width: 100%;
}

.career-detail-banner-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 10px 40px;
  background: #CD4409;
  font-weight: 600;
  transition: all 0.5s;
  margin-left: 30px;

}

.career-deail-banner-apply-btn {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.career-deail-banner-apply-btn:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.career-detail-banner-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* ---- details job ---- */
#career-detail-job-detail {
  margin-top: 2rem;
}

.career-detail-job-detail-head h3 {
  font-size: 26px;
  font-weight: 700;
  color: #CD4409;
  padding: 1rem 0;
}

.career-det-job-detail-content {
  border: 1px solid #D1D1D1;
  background: #FFF;
  padding: 35px 25px;
}

.career-det-job-detail-content ul li {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

.career-det-job-detail-content p {
  font-size: 17px;
  font-weight: 400;

}

.career-det-job-detail-content p span {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}




/*--------------------------------      banner new ceo-------------------------------     */
#our-ceo-about,
#our-ceo-rising {
  padding-top: 4rem !important;
}

section.our-ceo-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-top: 10.6rem;
  height: 180px;
  display: flex;
  align-items: center;
}


.our-ceo-banner-head h2 {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.our-ceo-about-img img {
  width: 400px;
}

.our-ceo-about-head h2 {
  color: #14212b;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.our-ceo-about-sub-head h2 {
  color: #14212b;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
  border-bottom: 3px solid #CD4409;
  font-weight: 600;
}

.our-ceo-about-down-line {
  width: 100%;
  height: 7px;
  background-color: #CD4409;
}

.our-ceo-inner-para-container {
  padding: 2rem 0 0 0;
}




.our-ceo-inner-para h5 {
  font-size: 25px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 25px;
}

.our-ceo-inner-para p {
  font-size: 17px;
  color: #14212b;
  line-height: 27px;
}

.our-ceo-desc {
  margin: 5rem 0rem 0 3rem;
}

.our-ceo-desc p {
  font-size: 17px;
  color: #14212b;
  line-height: 27px;
}

.our-ceo-rising-head h2 {
  color: #14212b;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.our-ceo-rising-para p {
  font-size: 17px;
  color: #14212b;
  line-height: 27px;
}

.our-ceo-rising-para h4 {
  margin: 15px 0 15px 0;
  font-weight: 500;
  line-height: 26px;
}

.our-ceo-learn-more {
  text-align: center;
  margin: 30px 0 20px 0;

}

.our-ceo-learn-more button {
  background-color: #CD4409;
  font-size: 14px;
  font-weight: 600;
  border-radius: 22px;
  color: #fff;
  padding: 10px 18px;
  border: none;
}

.technology-explore-filter-clear-serach {
  background: none;
  border: none;
  outline: none;
}

.tech-trends-data-not-found {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination>span {
  cursor: pointer;
  border: 1px solid #c1c1c1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;
}

.pagination>span:hover,
.pagination_selected {
  color: #fff;
  background-color: #CD4409;
  border: none;
}

.search_disabled {
  cursor: not-allowed;
  color: #fff;
}

.no-job-found {
  display: flex;
  flex-direction: column;
  align-items: center;

}

@media only screen and (max-width: 480px) {

  /* ************** contact page ***************** */
  /* -------- heading effect ------------ */
  .contact-our-branch-sec h2::after {
    width: 139px !important;
    margin-left: -256px !important;
    margin-top: -2px !important;
  }

  .contact-form-sec h2::after {
    width: 144px !important;
    margin-left: -327px !important;
    margin-top: -2px !important;
  }

  /* ------------ contact banner css ------------ */
  section.contact-banner {
    margin-top: unset !important;
    padding: 3rem 0 !important;
  }

  .banner-content-contact h2 {
    text-align: center;
  }

  .banner-content-contact p {
    font-size: 17px !important;
    line-height: 27px !important;
    text-align: center;
  }

  .banner-content-contact {
    width: unset !important;
  }

  /* ---------------- contact branch sectuion ------------- */
  .contact-our-branch-sec {
    margin-top: 2rem !important;
  }

  .contact-our-branch-sec h2,
  .contact-form-sec h2 {
    font-size: 30px !important;
  }

  #contact-our-branch-content-main {
    width: 50% !important;
    margin-top: 1rem;
  }

  .contact-our-branch-content-innr {
    padding-top: 1.5rem !important;
    height: 200px !important;
  }

  .contact-our-branch-content-innr h4 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }

  /* ------------ contact details section --------------- */
  .contact-details-sec {
    margin-top: 2rem !important;
  }

  .contact-details-content-innr {
    margin-bottom: unset !important;
    text-align: center !important;
  }

  /* ------------- terms and prolicy accordion section ------- */
  .policy-accordion-faqs,
  .accordion-button {
    font-size: 18px !important;
    line-height: 23px !important;
  }

  /* ------------ site map  section -------------- */
  .service-sitemap-main {
    margin-top: 1rem !important;
  }

  .service-sitemap-main h3 {
    font-size: 20px !important;
  }

  .sitemap-content-main p {
    font-size: 15px !important;
    line-height: 26px !important;
  }

  .sitemap-content-main {
    margin-top: 0.5rem !important;
  }

  .sitemap-content-main h4 {
    font-size: 19px !important;
  }

  /* =================- terms condition page ================= */
  .privacy-terms-content-sec {
    padding: 1rem 0rem !important;
  }

  .privacy-terms-content-sec h2 {
    font-size: 23px !important;
  }

  .privacy-terms-content-sec p {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  /* ----********** explore banner css **********----- */
  .banner-content-explore {
    padding: 3rem 0rem 0rem 0 !important;
  }

  section.explore-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 400px !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .banner-content-explore h2 {
    text-align: center;
    font-size: 25px;
  }

  .banner-content-explore p {
    font-size: 17px !important;
    line-height: 27px !important;
    text-align: center;
  }

  .explore-banner-sec-img {
    text-align: center !important;
  }

  .explore-banner-sec-img img {
    height: 240px !important;
  }

  .profile-details-ceo h3 {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  /* --------------- technology and trends filter form --------- */
  #technology-explore-filter-sec {
    margin-top: 2rem !important;
  }

  #technology-explore-filter {
    width: 50% !important;
  }

  #filter-techtrend {
    padding: 0rem 0rem 2rem 0 !important;
  }

  /* ========================== careers main page ===================== */
  #job-sec,
  #skills-sec,
  #Career-benefits,
  #career-post-resume {
    margin-top: 2rem;
  }

  /* ===================== all section heading================= */
  .career-main-head h3 {
    font-size: 30px;
  }

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    margin-top: 0rem !important;
    padding: 3rem 0 1rem 0 !important;
    height: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .Career-main-banner {
    margin-top: 0 !important;
  }

  .Career-bet-content h3 {
    text-align: center;
    padding: 0 5px !important;
    font-size: 35px !important;
  }

  .Career-bet-content p {
    font-size: 18px !important;
    line-height: 26px !important;
    padding: 10px 5px 0 5px !important;
    text-align: center;
  }

  /*================= career page find job section ================== */
  .career-find-job-head h3 {
    font-size: 19px;
  }

  .career-find-job-head p {
    font-size: 16px;
  }

  .career-search-form {
    margin: 0 0rem;
  }

  .career-search-form-btn {
    margin-top: 1rem;
  }

  .career-search-form {
    display: unset;
  }

  /*================= career page our benifit section ================== */
  .career-main-benifit-content {
    width: 94% !important;
    height: max-content !important;
    padding: 7px !important;
  }

  .career-main-head h3 {
    margin-bottom: 0.5rem;
  }

  /*=================== career page find jobs as per skills section==================== */

  .career-main-skills-box-content {
    margin-bottom: 10px !important;
    padding: 30px 0px !important;
    width: 93.5% !important;
  }

  .career-main-skills-box-content h2 {
    font-size: 30px !important;
  }

  .career-main-skills-box-content p {
    font-size: 17px !important;
    padding: 2px;
  }

  /*=================== career page latest jobs opening section==================== */

  .career-opening-img img {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    object-fit: cover;
    margin-left: 0;
  }

  .career-opening-upper-content h4 {
    font-size: 16px;
  }

  .career-opening-upper-content p {
    font-size: 16px;
  }

  .career-opening-upper-content {
    margin-left: 2px;
  }

  .career-post-resume-form {
    margin: unset !important;
  }

  .career-opening-upper-content h4,
  .career-opening-upper-content p {
    width: 100% !important;
    text-align: start !important;
    justify-content: left !important;
  }

  .career-opening-content h3 {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-left: 14px !important;
  }

  .career-opening-content p {
    font-size: 15px;
    margin-left: 14px !important;
  }

  /* ==============career pagelatest jobs opening section */
  .career-opening-content-box {
    margin: 0 1px;
  }

  /*============= career detail banner button================ */
  .career-detail-banner-btn {
    display: block !important;
    margin: 3rem 0 !important;
    text-align: center !important;
  }

  .career-detail-banner-btn a {
    margin: 1rem;
    display: block;

  }

  #find-job-career {
    padding: 20px 0 !important;
  }

  #subcribe-content-data-details {
    margin: unset !important;
  }

  #career-detail-job-detail {
    margin-top: 1rem;
  }

  .career-main-banner {
    margin-top: 0rem !important;
    height: 300px !important;
  }


  /* ------------------------------------ our ceo new banner ----------------------------- */
  #our-ceo-about,
  #our-ceo-rising {
    padding-top: 2rem !important;
  }

  section.our-ceo-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 200px !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .our-ceo-banner-head h2 {
    font-size: 35px !important;
  }

  .our-ceo-about-head h2 {
    font-size: 35px !important;
    text-align: center;
  }

  .our-ceo-about-img img {
    width: 100% !important;
  }

  .our-ceo-about-sub-head h2 {
    font-size: 25px;
    text-align: center;
  }

  .our-ceo-inner-para p {
    font-size: 16px;
    text-align: center;
  }

  .our-ceo-inner-para h5 {
    font-size: 22px;
    text-align: center;
  }

  .our-ceo-inner-para ol li {
    font-size: 16px;
  }

  .our-ceo-rising-para p {
    font-size: 16px;
    text-align: center;
  }

  .our-ceo-rising-para h4 {
    text-align: center;
  }

  .our-ceo-rising-head h2 {
    font-size: 25px;
    text-align: center;
  }

  .our-ceo-desc {
    margin: 1rem 0rem 0 2rem;
    width: 100%;
  }

  .tech-trends-data-not-found {
    height: unset !important;
  }
}

@media (max-width: 767px) and (min-width: 481px) {

  /* ************** contact page ***************** */
  /* -------- heading effect ------------ */
  .contact-our-branch-sec h2::after {
    width: 139px !important;
    margin-left: -256px !important;
    margin-top: -2px !important;
  }

  .contact-form-sec h2::after {
    width: 144px !important;
    margin-left: -327px !important;
    margin-top: -2px !important;
  }

  /* ------------ contact banner css ------------ */
  section.contact-banner {
    margin-top: unset !important;
    padding: 3rem 0 !important;
  }

  .banner-content-contact p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .banner-content-contact {
    width: unset !important;
  }

  /* ---------------- contact branch sectuion ------------- */
  .contact-our-branch-sec {
    margin-top: 2rem !important;
  }

  .contact-our-branch-sec h2,
  .contact-form-sec h2 {
    font-size: 30px !important;
  }

  #contact-our-branch-content-main {
    width: 50% !important;
    margin-top: 1rem;
  }

  .contact-our-branch-content-innr {
    padding-top: 1.5rem !important;
    height: 200px !important;
  }

  .contact-our-branch-content-innr h4 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }

  /* ------------ contact details section --------------- */
  .contact-details-sec {
    margin-top: 2rem !important;
  }

  #contact-details-first-col {
    width: 50% !important;
  }

  .contact-details-content-innr {
    margin-bottom: unset !important;
    text-align: center !important;
  }

  /* ------------- terms and prolicy accordion section ------- */
  .policy-accordion-faqs,
  .accordion-button {
    font-size: 20px !important;
  }

  /* ------------ site map  section -------------- */
  .service-sitemap-main {
    margin-top: 2rem !important;
  }

  .service-sitemap-main h3 {
    font-size: 20px !important;
  }

  .sitemap-content-main {
    margin-top: 1rem !important;
  }

  .sitemap-content-main h4 {
    font-size: 19px !important;
  }

  .slip-box-questoin-ask ul li {
    padding: 10px 10px !important;
  }

  /* =================- terms condition page ================= */
  .privacy-terms-content-sec {
    padding: 2rem 0rem !important;
  }

  .privacy-terms-content-sec h2 {
    font-size: 24px !important;
  }

  .privacy-terms-content-sec p {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  /* ----************* explore banner css ************---- */
  .banner-content-explore {
    padding: 3rem 0rem 0rem 0 !important;
  }

  section.explore-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 100% !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .banner-content-explore h2 {
    font-size: 30px !important;
    text-align: center;
  }

  .banner-content-explore p {
    font-size: 17px !important;
    line-height: 27px !important;
    text-align: center;
  }

  .our-ceo-about-sub-head h2 {
    text-align: center !important;
  }

  .explore-banner-sec-img {
    text-align: center !important;
  }

  .explore-banner-sec-img img {
    height: 250px !important;
  }

  .profile-details-ceo h3 {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  /* --------------- technology and trends filter form --------- */
  #technology-explore-filter-sec {
    margin-top: 2rem !important;
  }

  #technology-explore-filter {
    width: 50% !important;
  }

  #filter-techtrend {
    padding: 0rem 0rem 2rem 0 !important;
  }

  /* ========================== careers main page ===================== */
  #job-sec,
  #skills-sec,
  #Career-benefits,
  #career-post-resume {
    margin-top: 3rem;
  }

  /* ===================== all section heading================= */
  .career-main-head h3 {
    font-size: 35px;
  }

  /* ---- all page banner css -------------- */
  .career-main-banner {
    margin-top: 0;
  }



  .Career-bet-content h3 {
    text-align: center;
    padding: 0 10px !important;
    font-size: 40px !important;
  }

  .Career-bet-content p {
    text-align: center;

    font-size: 18px !important;
    line-height: 32px !important;
    padding: 10px 10px 0 10px !important;
  }

  /* ===============career page find job section==================== */
  .career-search-form {
    display: unset !important;
  }

  .career-find-job-head p {
    font-size: 18px;
  }

  .career-find-job-head h3 {
    font-size: 32px;
  }

  .career-search-form-btn {
    margin-top: 1rem;
  }

  /*=================== career page latest jobs opening section==================== */
  .career-opening-content-box {
    margin: 0 1px;
  }

  .career-main-head h3 {
    margin-bottom: 1rem;
  }

  .career-opening-img img {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    object-fit: cover;
    margin-left: 0;
  }

  .career-opening-upper-content h4 {
    font-size: 16px;
  }

  .career-opening-upper-content p {
    font-size: 16px;
  }

  .career-opening-upper-content {
    margin-left: 2px;
  }

  .career-opening-content h3 {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-left: 14px !important;
  }

  .career-opening-content p {
    font-size: 15px;
    margin-left: 14px !important;

  }

  /* -----------  career page find jobs as per skills section -------------------- */
  .career-main-skills-box-content {
    width: 95.6% !important;
  }


  /* career page our benifit section */
  .career-main-benifit-content {
    width: 45% !important;
  }

  .career-detail-banner-btn {
    text-align: center;
  }

  #career-detail-job-detail {
    margin-top: 1rem;
  }

  #subcribe-content-data-details {
    margin: unset !important;
  }

  .career-main-banner {
    height: 350px !important;
  }

  /* -------------------------- our ceo new banner ---------------------------------------- */
  #our-ceo-about,
  #our-ceo-rising {
    padding-top: 2rem !important;
  }

  section.our-ceo-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 200px !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .our-ceo-about-img img {
    width: 49% !important;
  }

  .our-ceo-desc {
    margin: 8rem 0rem 0 1rem;
  }
}

@media (max-width: 991px) and (min-width: 768px) {

  /* ************** contact page ***************** */
  /* -------- heading effect ------------ */
  .contact-our-branch-sec h2::after {
    width: 139px !important;
    margin-left: -256px !important;
    margin-top: -2px !important;
  }

  .contact-form-sec h2::after {
    width: 144px !important;
    margin-left: -327px !important;
    margin-top: -2px !important;
  }

  /* ------------ contact banner css ------------ */
  section.contact-banner {
    margin-top: unset !important;
    padding: 4rem 0 !important;
  }

  .banner-content-contact p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .banner-content-contact {
    width: 78% !important;
  }

  /* ---------------- contact branch sectuion ------------- */
  .contact-our-branch-sec {
    margin-top: 2rem !important;
  }

  .contact-our-branch-sec h2,
  .contact-form-sec h2 {
    font-size: 30px !important;
  }

  #contact-our-branch-content-main {
    width: 33% !important;
    margin-top: 1rem;
  }

  .contact-our-branch-content-innr {
    padding-top: 2.5rem !important;
    height: 280px !important;
  }

  .contact-our-branch-content-innr h4 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }

  /* ------------ contact details section --------------- */

  #contact-details-first-col {
    width: 50% !important;
  }

  /* ------------ site map  section -------------- */
  .service-sitemap-main {
    margin-top: 2rem !important;
  }

  .service-sitemap-main h3 {
    font-size: 20px !important;
  }

  .sitemap-content-main {
    margin-top: 1rem !important;
  }

  .sitemap-content-main h4 {
    font-size: 19px !important;
  }

  /* =================- terms condition page ================= */
  .privacy-terms-content-sec {
    padding: 2rem 0rem !important;
  }

  .privacy-terms-content-sec h2 {
    font-size: 24px !important;
  }

  .privacy-terms-content-sec p {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  /* ------------ explore more banner css ------------ */
  .banner-content-explore {
    padding: 3rem 0rem 3rem 0 !important;
  }

  section.explore-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 100% !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .banner-content-explore p {
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .explore-banner-sec-img img {
    height: 300px !important;
  }

  .profile-details-ceo h3 {
    font-size: 21px !important;
    line-height: 28px !important;
  }

  .portfolio-ceo-sec .col-lg-4.col-md-4.col-sm-12 {
    margin-top: 1rem !important;
    width: 50% !important;
  }

  /* --------------- technology and trends filter form --------- */
  #technology-explore-filter-sec {
    margin-top: 2rem !important;
  }

  #technology-explore-filter {
    width: 50% !important;
  }

  #filter-techtrend {
    padding: 0rem 0rem 2rem 0 !important;
  }

  /* ========================== careers main page ===================== */
  /* ---- all page banner css -------------- */
  .career-main-banner {
    margin-top: 0rem !important;
  }

  #mangdit-bfsi-banner {
    padding-bottom: 1rem !important;
  }


  .Career-bet-content h3 {
    padding: 0 25px !important;
  }

  .Career-bet-content p {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 10px 25px 0 25px !important;

  }

  /* ===========career page find job form section ==============*/
  .career-search-form {
    display: unset;
  }

  /* =======================career page Latest jobs opening section========================== */
  .career-opening-upper-content h4 {
    font-size: 17px;
  }

  .career-opening-upper-content p {
    font-size: 16px;
  }

  .career-opening-content p {
    font-size: 14px;
  }

  .career-opening-content h3 {
    font-size: 22px;
  }

  /* -------------- career page find jobs as per skills section ----------- */
  .career-main-skills-box-content {
    width: 46.8% !important;
  }


  /* ===========career page our benifits section========== */
  .career-main-benifit-content {
    width: 30% !important;
  }

  #subcribe-content-data-details {
    margin: 0 15% !important;
  }

  /* ------------------------------------- our ceo new banner ------------------------------ */
  #our-ceo-about,
  #our-ceo-rising {
    padding-top: 3rem !important;
  }

  section.our-ceo-banner {
    margin-top: unset !important;
    padding: 0rem 0 !important;
    height: 200px !important;
    max-height: 100% !important;
    background-position: unset !important;
  }

  .our-ceo-about-img img {
    width: 100% !important;
  }

  .tech-trends-data-not-found {
    height: unset !important;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  /* ---- all page banner css -------------- */



  /* -------------- career page latest jobs opening section ----------- */
  .career-main-skills-box-content {
    width: 31% !important;
  }

  /* ------------------------------------ our ceo about section -----------------------------  */

  .our-ceo-about-img img {
    width: 85% !important;
  }

}

@media (max-width: 1400px) and (min-width: 1201px) {

  /* ---- all page banner css -------------- */
  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    padding: 7rem 0 2rem 0 !important;
  }

  .Career-bet-content h3 {
    padding: 25px !important;
  }

  .Career-bet-content {
    width: 65% !important;
  }
}



/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */
/* single blog  */














.spad {
  padding-top: 2rem;
  padding-bottom: 2rempx;
}

.blog__details-content img {
  width: 300px;
  object-fit: contain;
  border-radius: 10px;
}


/* ----------------
blog pages
---------------------*/


.page-top-section {
  height: 400px;
  position: relative;
  margin-top: 10rem;
  text-align: center;
  background-size: cover;
}

.page-top-section:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #0d0d11;
  opacity: 0.74;
  z-index: 1;
}

.page-top-section .container {
  position: relative;
  z-index: 99;
}

.page-top-section h2 {
  position: relative;
  font-size: 45px;
  color: #fff;
  padding-bottom: 10px;
}

.page-top-section h2:after {
  position: absolute;
  content: "";
  width: 70px;
  height: 4px;
  bottom: 0;
  left: calc(50% - 35px);
  background: #10ddb4;
}

.site-pagination a {
  display: inline-block;
  font-size: 14px;
  color: #201a1a;
  font-weight: 500;
}

.site-pagination a.sp-next,
.site-pagination a.sp-prev {
  color: #8c8c8c;
  margin-right: 10px;
}

.site-pagination a.sp-next {
  margin-right: 0;
  margin-left: 10px;
}

.site-pagination a.active {
  color: #10ddb4;
}


.sb-widget {
  margin-bottom: 45px;
}


.sb-title {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 16px;
}





/* 
blog */

/*------------------
 Blog-section
---------------------*/

.blog-section {
  margin-top: 2rem;
}

.blog-section .blog-posts {
  margin-bottom: -70px;
}

.blog-post {
  margin-bottom: 70px;
}

.blog-post img {
  margin-bottom: 34px;
  width: 100%;
  height: 22rem;
  object-fit: cover;
}

.blog-post .post-date {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 3px 16px;
  margin-bottom: 25px;
  background: #CD4409;
  color: #fff;
}

.blog-post h3 {
  color: #0a0505;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.blog-post h4 {
  color: #140c0c;
  margin-bottom: 10px;
}

.blog-post .post-metas {
  margin-bottom: 30px;
}

.blog-post .post-metas .post-meta {
  position: relative;
  display: inline-block;
  color: #8c8c8c;
  font-size: 14px;
  padding-right: 15px;
  margin-right: 14px;
  margin-bottom: 5px;
}

.blog-post .post-metas .post-meta:after {
  position: absolute;
  content: "|";
  right: 0;
  top: 0;
  color: #8c8c8c;
  font-size: 14px;
}

.blog-post .post-metas .post-meta:last-child {
  margin-right: 0;
  padding-right: 0;
}

.blog-post .post-metas .post-meta:last-child:after {
  display: none;
}

.blog-post .post-metas a {
  color: #ffea00;
}

.blog-post .read-more {
  font-size: 14px;
  font-weight: 700;
  color: #CD4409;
}

.blog-post.featured-post,
.blog-post.review-post,
.blog-post.single-post {
  margin-bottom: 80px;
}

.blog-post.featured-post p {
  font-size: 17px;
  line-height: normal;
  color: #262626;
  text-align: justify;
  padding-right: 20px;
}



.blog-post.featured-post .post-metas a,
.blog-post.review-post .post-metas a,
.blog-post.single-post .post-metas a {
  color: #CD4409;
}

.blog-post.review-post .post-metas {
  margin-bottom: 5px;
}

.blog-post.review-post .rating {
  font-size: 17px;
  color: #ffd200;
  margin-bottom: 30px;
}

.blog-post.review-post .rating i {
  display: inline-block;
  margin-right: 4px;
}


/* sidenav ln item */
.latest-news-widget .ln-item {
  margin-bottom: 29px;
  position: relative;
}

.latest-news-widget .ln-item:last-child {
  margin-bottom: 0;
}

.latest-news-widget .ln-item img {
  width: 85px;
  height: 85px;
  float: left;
  margin-right: 27px;
  object-fit: cover;
}

.latest-news-widget .ln-text {
  overflow: hidden;
}

.latest-news-widget .ln-text .ln-date {
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  padding: 1px 5px;
  margin-bottom: 10px;
  background: #CD4409;
  color: #ffffff;
}

.latest-news-widget .ln-text h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}

.latest-news-widget .ln-text .ln-metas .ln-meta {
  position: relative;
  display: inline-block;
  color: #8c8c8c;
  font-size: 12px;
  padding-right: 13px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.latest-news-widget .ln-text .ln-metas .ln-meta:after {
  position: absolute;
  content: "|";
  right: 0;
  top: 0;
  color: #8c8c8c;
  font-size: 14px;
}

.latest-news-widget .ln-text .ln-metas .ln-meta:last-child {
  margin-right: 0;
  padding-right: 0;
}

.latest-news-widget .ln-text .ln-metas .ln-meta:last-child:after {
  display: none;
}

.latest-news-widget .ln-text .ln-metas a {
  color: #4f565b;
}

.latest-news-widget.top-five .ln-index {
  position: absolute;
  left: 19px;
  top: -25px;
  width: 45px;
  height: 45px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background: #CD4409;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog__details-content h1,
.blog__details-content h2,
.blog__details-content h3,
.blog__details-content h4,
.blog__details-content h5,
.blog__details-content h6,
.blog__details-content p {
  margin: 0;
  padding: 0;

}

.blog_description p {
  font-size: 16px !important;
}


/* blog detail  */

@charset "UTF-8";
/*-----------------------------------------------------------------------------------

Theme Name: Banizzo - Digital Agency HTML5 Template
Author: CodeXup
Support: codexup.dev@gmail.com
Description: Banizzo – is a Digital Agency HTML5 Template that comes with high-quality 2 Pre build Home Pages and many built-in awesome inner pages  which are perfect for any creative agencies, designers, digital agencies, freelancers, developers, designers, marketing, startup, blog and portfolio.
Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

01. VARIBALES DECLARARION
02. MIXIN DECLARATION
03. TYPOGRAPHY CSS START
04. THEME CSS START
05. BUTTON CSS START
06. TITLE CSS START
07. WIDGET CSS START
08. ANIMATION CSS START
09. LOADING CSS START
10. MENU CSS START
11. MODAL CSS START
12. HEADER CSS START
13. OFFCANVAS CSS START
14. HERO CSS START
15. FEATURE CSS START
16. ABOUT CSS START
17. SERVICE CSS START
18. COUNTER CSS START
19. TESTIMONIAL CSS START
20. PROCESS CSS START
21. TEAM CSS START
22. PORTFOLIO CSS START
23. HIRE CSS START
24. PRICE CSS START
25. BRAND CSS START
26. BLOG CSS START
27. CONTACT CSS START
28. FOOTER CSS START

**********************************************/
/*----------------------------------------*/
/*  01. VARIBALES DECLARARION
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #392D6A;
  --secondary: #DEFE5D;
  --white: #fff;
  --white-2: #E8E8E8;
  --white-3: #E2E2E2;
  --black: #000000;
  --black-2: #060606;
  --black-3: #232323;
  --gray: #555555;
  --gray-2: #999999;
  --green: #7D5DFE;
  --green-2: #09002E;
  --green-3: #120837;
  --green-4: #251F41;
  --green-5: #48339d;
  --font-unbounded: "Unbounded", sans-serif;
  --font-inter: "Inter", sans-serif;
}

/*----------------------------------------*/
/*  01. SPACE CSS START
/*----------------------------------------*/
.pb-100 {
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media (max-width: 1199px) {
  .pb-110 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199px) {
  .pb-110 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-110 {
    padding-bottom: 60px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-130 {
    padding-bottom: 60px;
  }
}

.pb-140 {
  /* padding-bottom: 140px; */
}

@media (max-width: 1199px) {
  .pb-140 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199px) {
  .pb-140 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}

@media (max-width: 1199px) {
  .pb-150 {
    padding-bottom: 100px;
  }
}

@media (max-width: 1199px) {
  .pb-150 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pb-150 {
    padding-bottom: 60px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media (max-width: 991px) {
  .pt-100 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-100 {
    padding-top: 60px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media (max-width: 1199px) {
  .pt-110 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-110 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-110 {
    padding-top: 60px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media (max-width: 1199px) {
  .pt-120 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-130 {
  padding-top: 130px;
}

@media (max-width: 1199px) {
  .pt-130 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-130 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-130 {
    padding-top: 60px;
  }
}

.pt-140 {
  /* padding-top: 4rem; */
}

@media (max-width: 1199px) {
  .pt-140 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-140 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-140 {
    padding-top: 60px;
  }
}

.pt-150 {
  padding-top: 150px;
}

@media (max-width: 1199px) {
  .pt-150 {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .pt-150 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .pt-150 {
    padding-top: 60px;
  }
}

.mt-100 {
  margin-top: 100px;
}

@media (max-width: 991px) {
  .mt-100 {
    margin-top: 90px;
  }
}

@media (max-width: 767px) {
  .mt-100 {
    margin-top: 60px;
  }
}

.mt-110 {
  margin-top: 110px;
}

@media (max-width: 1199px) {
  .mt-110 {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .mt-110 {
    margin-top: 90px;
  }
}

@media (max-width: 767px) {
  .mt-110 {
    margin-top: 60px;
  }
}

.mt-120 {
  margin-top: 120px;
}

@media (max-width: 1199px) {
  .mt-120 {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .mt-120 {
    margin-top: 90px;
  }
}

@media (max-width: 767px) {
  .mt-120 {
    margin-top: 60px;
  }
}

.mb-100 {
  margin-bottom: 100px;
}

@media (max-width: 991px) {
  .mb-100 {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .mb-100 {
    margin-bottom: 60px;
  }
}

.mb-110 {
  margin-bottom: 110px;
}

@media (max-width: 1199px) {
  .mb-110 {
    margin-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .mb-110 {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .mb-110 {
    margin-bottom: 60px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}

@media (max-width: 1199px) {
  .mb-120 {
    margin-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .mb-120 {
    margin-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .mb-120 {
    margin-bottom: 60px;
  }
}

/*----------------------------------------*/
/*  02. MIXIN DECLARATION
/*----------------------------------------*/
/*----------------------------------------*/
/* 03. TYPOGRAPHY CSS START
/*----------------------------------------*/
* {
  margin: 0;
  padding: 0;
}



html {
  scroll-behavior: smooth;
}



ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

/* p {
  padding: 0;
  margin: 0;
  font-style: normal;
  line-height: 1.6;
  font-weight: 400;
  font-size: 16px;
  color: var(--gray);
  font-family: var(--font-inter);
} */

.dark p {
  color: var(--gray-2);
}



/*----------------------------------------*/
/* 04. THEME CSS START
/*----------------------------------------*/
.dark {
  background-color: var(--black-2);
}

.dir-rtl {
  direction: rtl;
}

.mention {
  border-radius: 0px 30px 30px 0px;
  background-color: var(--secondary);
  position: absolute;
  top: 50%;
  inset-inline-start: 0;
  padding: 20px 15px 20px 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dir-rtl .mention {
  border-radius: 30px 0 0 30px;
}

@media (max-width: 1199px) {
  .mention {
    padding: 20px 5px 20px 0;
    border-radius: 0 20px 20px 0;
  }
}

@media (max-width: 767px) {
  .mention {
    display: none;
  }
}

.mention .text {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-family: var(--font-inter);
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.social-media {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-media li a {
  color: #999999;
  padding: 0 5px;
  font-size: 18px;
}

.social-media li a:hover {
  color: var(--primary);
}

.social-media.hover-1 li a:hover i {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.social-media.hover-1 li a i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.social--share {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social--share .text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  text-transform: capitalize;
}

.dark .social--share .text {
  color: var(--green);
}

.social--share .social-media {
  gap: 5px;
}

.search--box {
  position: relative;
  z-index: 3;
}

.search--box button {
  border: none;
  font-size: 18px;
  font-weight: 300;
  background-color: transparent;
}

.dark .search--box button {
  color: var(--white-3);
}

.search--box .search-close {
  display: none;
}

.search--box form {
  width: 300px;
  padding: 10px;
  position: absolute;
  inset-inline-end: -30px;
  background-color: var(--white-3);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

@media (max-width: 1399px) {
  .search--box form {
    inset-inline-end: -15px;
  }
}

@media (max-width: 767px) {
  .search--box form {
    inset-inline-end: -75px;
  }
}

.search--box form.showed {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

@media (max-width: 767px) {
  .search--box form.showed {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
}

.search--box form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--white-3);
}

.search--inline input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--white-3);
}

.separator {
  height: 1px;
  background-color: #EAEAEA;
  -webkit-margin-start: 195px;
  margin-inline-start: 195px;
  margin-right: 195px;
}

.dark .separator {
  background-color: var(--black-3);
}

@media (max-width: 1399px) {
  .separator {
    -webkit-margin-start: 30px;
    margin-inline-start: 30px;
    margin-right: 30px;
  }
}

.list-check {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding-top: 25px;
  padding-bottom: 50px;
}

@media (max-width: 1199px) {
  .list-check {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding-top: 30px;
  }
}

.list-check li {
  font-size: 18px;
  font-weight: 600;
  color: var(--black-2);
  position: relative;
  -webkit-padding-start: 35px;
  padding-inline-start: 35px;
}

@media (max-width: 991px) {
  .list-check li {
    font-size: 16px;
  }
}

.list-check li::before {
  position: absolute;
  content: "\f00c";
  inset-inline-start: 0;
  width: 25px;
  height: 25px;
  font-weight: 700;
  font-family: "Font Awesome 6 Free";
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 100px;
  color: var(--primary);
  text-align: center;
  line-height: 25px;
}

.list-check-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 25px;
}

@media (max-width: 1399px) {
  .list-check-2 {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .list-check-2 {
    gap: 15px;
  }
}

.list-check-2 li {
  font-size: 18px;
  color: var(--black-2);
  position: relative;
  -webkit-padding-start: 35px;
  padding-inline-start: 35px;
}

@media (max-width: 1199px) {
  .list-check-2 li {
    font-size: 16px;
    -webkit-padding-start: 25px;
    padding-inline-start: 25px;
  }
}

@media (max-width: 991px) {
  .list-check-2 li {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .list-check-2 li {
    font-size: 16px;
  }
}

.list-check-2 li::before {
  position: absolute;
  content: "\f058";
  inset-inline-start: 0;
  width: 25px;
  height: 25px;
  font-weight: 700;
  color: var(--primary);
  font-family: "Font Awesome 6 Free";
}

.list-check-2 li.disabled {
  color: var(--gray-6);
}

.list-check-2 li.disabled::before {
  color: var(--gray-2);
}

.cxu--go-top {
  position: fixed;
  inset-inline-end: 20px;
  bottom: 0;
  font-size: 14px;
  color: var(--primary);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  gap: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.cxu--go-top img {
  max-width: 16px;
}

.cxu--go-top.showed {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}

.cxu--go-top .icon-1 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cxu--go-top .icon-2 {
  position: absolute;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.cxu--go-top:hover {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
  background-position: 100% 100%;
}

.cxu--go-top:hover .icon-1 {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.cxu--go-top:hover .icon-2 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
}

@-webkit-keyframes preloaderAnimation {
  0% {
    opacity: 1;
    height: 20px;
  }

  50% {
    opacity: 0;
    height: 25px;
  }

  100% {
    opacity: 1;
    height: 20px;
  }
}

@keyframes preloaderAnimation {
  0% {
    opacity: 1;
    height: 20px;
  }

  50% {
    opacity: 0;
    height: 25px;
  }

  100% {
    opacity: 1;
    height: 20px;
  }
}

.error__area {
  height: 100vh;
  text-align: center;
}

.error__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

.error__content .number {
  font-size: 260px;
  line-height: 1;
  font-weight: 700;
  color: var(--primary);
}

.dark .error__content .number {
  color: var(--white);
}

@media (max-width: 991px) {
  .error__content .number {
    font-size: 200px;
  }
}

@media (max-width: 767px) {
  .error__content .number {
    font-size: 120px;
  }
}

.error__content .number span {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 3px var(--primary);
}

.dark .error__content .number span {
  -webkit-text-stroke: 3px var(--secondary);
}

.error__content .title {
  font-size: 48px;
  padding-bottom: 30px;
  color: var(--primary);
}

.dark .error__content .title {
  color: var(--white);
}

@media (max-width: 767px) {
  .error__content .title {
    font-size: 30px;
  }
}

.error__content p {
  max-width: 600px;
  padding-bottom: 30px;
}

.dark .error__content p {
  color: var(--white-3);
}

.home-wrapper-6 {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/*----------------------------------------*/
/* 05. BUTTON CSS START
/*----------------------------------------*/
.cxu-btn-primary {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--white);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 40px;
  border-radius: 30px;
  background-color: var(--green);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.dir-rtl .cxu-btn-primary i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .cxu-btn-primary {
    padding: 12px 24px;
  }
}

.cxu-btn-primary::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  inset-inline-start: 0;
  top: 0;
  background-color: var(--primary);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.cxu-btn-primary:hover {
  color: var(--white);
}

.cxu-btn-primary:hover::before {
  width: 100%;
}

.cxu-btn-border {
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--white);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 40px;
  border-radius: 30px;
  border: 1px solid var(--white-3);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.dir-rtl .cxu-btn-border i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .cxu-btn-border {
    padding: 12px 24px;
  }
}

.cxu-btn-border::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  inset-inline-start: -60px;
  top: -60px;
  border-radius: 100%;
  background-color: #5a42b9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
  opacity: 0;
}

.cxu-btn-border::after {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  inset-inline-end: -60px;
  bottom: -60px;
  border-radius: 100%;
  background-color: #5a42b9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
  opacity: 0;
}

.cxu-btn-border:hover {
  color: var(--white);
  border-color: var(--green);
  background-color: var(--green);
}

.cxu-btn-border:hover::after {
  opacity: 1;
  bottom: -30px;
  opacity: 1;
  inset-inline-end: -30px;
}

.cxu-btn-border:hover::before {
  opacity: 1;
  top: -30px;
  opacity: 1;
  inset-inline-start: -30px;
}

.modal__btn {
  display: inline-block;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  background-color: var(--secondary);
  position: relative;
}

.modal__btn i,
.modal__btn svg {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modal__btn svg {
  width: 1em;
  height: 1em;
}

.modal__btn:hover i,
.modal__btn:hover svg {
  color: var(--green);
}

.modal__btn .image,
.modal__btn .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal__btn .image {
  -webkit-animation: modalRotate 10s linear infinite;
  animation: modalRotate 10s linear infinite;
}

.modal__btn .icon {
  font-size: 24px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #555555;
  overflow: hidden;
}

.modal__btn.style-1:hover i {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.modal__btn.style-2 .icon-1 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.dir-rtl .modal__btn.style-2 .icon-1 {
  -webkit-transform: rotate(-90deg) translate(0, 0);
  transform: rotate(-90deg) translate(0, 0);
}

.modal__btn.style-2 .icon-2 {
  position: absolute;
  -webkit-transform: translate(-35px, 35px);
  transform: translate(-35px, 35px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.dir-rtl .modal__btn.style-2 .icon-2 {
  -webkit-transform: rotate(-90deg) translate(-35px, 35px);
  transform: rotate(-90deg) translate(-35px, 35px);
}

.modal__btn.style-2:hover .icon-1 {
  opacity: 0;
  -webkit-transform: translate(35px, -35px);
  transform: translate(35px, -35px);
}

.dir-rtl .modal__btn.style-2:hover .icon-1 {
  -webkit-transform: rotate(-90deg) translate(35px, -35px);
  transform: rotate(-90deg) translate(35px, -35px);
}

.modal__btn.style-2:hover .icon-2 {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.dir-rtl .modal__btn.style-2:hover .icon-2 {
  -webkit-transform: rotate(-90deg) translate(0, 0);
  transform: rotate(-90deg) translate(0, 0);
}

/*----------------------------------------*/
/*  06. TITLE CSS START
/*----------------------------------------*/
.sec-title {
  font-size: 70px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
}

.dark .sec-title {
  color: var(--green);
}

@media (max-width: 1399px) {
  .sec-title {
    font-size: 60px;
  }
}

@media (max-width: 1199px) {
  .sec-title {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .sec-title {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .sec-title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .sec-title {
    font-size: 26px;
  }
}

.sec-title-2 {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
}

.dark .sec-title-2 {
  color: var(--green);
}

@media (max-width: 1199px) {
  .sec-title-2 {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .sec-title-2 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .sec-title-2 {
    font-size: 32px;
  }
}

.sec-sub-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--white);
  text-transform: uppercase;
  font-family: var(--font-inter);
}

/*----------------------------------------*/
/*  07. WIDGET CSS START
/*----------------------------------------*/
.widget__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  text-transform: capitalize;
  margin-bottom: 15px;
}

.dark .widget__title {
  color: var(--green);
}

.widget__search {
  padding: 30px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 30px;
}

.dark .widget__search {
  background: var(--black);
}

@media (max-width: 1199px) {
  .widget__search {
    padding: 30px 15px;
  }
}

.widget__search form {
  position: relative;
}

.widget__search input {
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  outline: none;
  padding: 10px 40px 10px 10px;
  position: relative;
  border-radius: 3px;
  background-color: #f6f6f6;
  border: 1px solid #E1E1E1;
  font-family: var(--font-inter);
}

.widget__search input::-webkit-input-placeholder {
  opacity: 1;
}

.widget__search input::-moz-placeholder {
  opacity: 1;
}

.widget__search input:-ms-input-placeholder {
  opacity: 1;
}

.widget__search input::-ms-input-placeholder {
  opacity: 1;
}

.widget__search input::placeholder {
  opacity: 1;
}

.widget__search .submit {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 7px;
  top: 7px;
  color: var(--white);
  background-color: var(--green);
  background-repeat: no-repeat;
  background-position: 18px;
  border-radius: 3px;
  outline: none;
  border: none;
}

.widget__category {
  padding: 30px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 30px;
}

.dark .widget__category {
  background: var(--black);
}

@media (max-width: 1199px) {
  .widget__category {
    padding: 30px 15px;
  }
}

.widget__category ul li {
  padding-bottom: 10px;
}

.widget__category ul li:first-child {
  padding-top: 15px;
  border-top: 1px solid #EFEFEF;
}

.dark .widget__category ul li:first-child {
  border-color: var(--black-3);
}

.widget__category ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #EFEFEF;
  font-family: var(--font-inter);
  padding-bottom: 15px;
  transition: all 0.7s;
}

.widget__category ul li svg {
  display: none;
  color: #999999;

}

.widget__category ul li a:hover svg {
  display: block;
  color: var(--primary);

}

.dark .widget__category ul li a {
  border-color: var(--black-3);
}

.widget__category ul li a::before {
  position: absolute;
  /* content: "\f054"; */
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  top: 0;
  font-weight: 700;
  font-family: "Font Awesome 6 Free";
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
}

.widget__category ul li a:hover {
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  color: var(--primary);
}

.widget__category ul li a:hover::before {
  opacity: 1;
}

.widget__recent {
  padding: 30px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 30px;
}

.dark .widget__recent {
  background: var(--black);
}

.widget__tags {
  padding: 30px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 30px;
}

.dark .widget__tags {
  background: var(--black);
}

@media (max-width: 1199px) {
  .widget__tags {
    padding: 30px 15px;
  }
}

.widget__tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px 16px;
}

.widget__tags li {
  display: inline-block;
}

.widget__tags li:last-child a::after {
  display: none;
}

.widget__tags li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray);
  text-transform: capitalize;
  font-family: var(--font-inter);
  position: relative;
}

.dark .widget__tags li a {
  color: var(--gray-2);
}

.widget__tags li a::after {
  font-size: 14px;
  position: absolute;
  content: "/";
  width: 100%;
  height: 100%;
  inset-inline-end: calc(-100% - 5px);
  top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.widget__tags li a:hover {
  color: var(--green);
}

.widget__share {
  padding: 30px;
  border-radius: 20px;
  background: #F9F9F9;
  margin-bottom: 30px;
}

.dark .widget__share {
  background: var(--black);
}

@media (max-width: 991px) {
  .widget__share {
    margin-bottom: 0;
  }
}

.widget__share li a {
  font-size: 24px;
}

.widget__share li a i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.widget__share li a:hover {
  color: var(--green);
}

.widget__share li a:hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*----------------------------------------*/
/*  08. ANIMATION CSS START
/*----------------------------------------*/
@-webkit-keyframes cxuRipple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes cxuRipple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

@-webkit-keyframes moveBottom {
  0% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@keyframes moveBottom {
  0% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@-webkit-keyframes moveBottom2 {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  25% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  75% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes moveBottom2 {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  25% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  75% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes modalRotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes modalRotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes Loading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

@keyframes Loading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

@-webkit-keyframes scroll {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - 20px));
    transform: translateX(calc(-100% - 20px));
  }
}

@keyframes scroll {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - 20px));
    transform: translateX(calc(-100% - 20px));
  }
}

@-webkit-keyframes cxufadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes cxufadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cxufadeUp,
.cxufadeUp4,
.cxufadeUp3,
.cxufadeUp2 {
  opacity: 0;
  -webkit-animation-name: cxufadeUp;
  animation-name: cxufadeUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.cxufadeUp2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.cxufadeUp3 {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.cxufadeUp4 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes cxuSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes cxuSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.infinite-move {
  -webkit-animation: moveLeft 5s linear infinite;
  animation: moveLeft 5s linear infinite;
}

.infinite-move-2 {
  -webkit-animation: moveBottom 5s linear infinite;
  animation: moveBottom 5s linear infinite;
}

.infinite-move-3 {
  -webkit-animation: moveBottom2 3s linear infinite;
  animation: moveBottom2 3s linear infinite;
}

/*----------------------------------------*/
/*  09. LOADING CSS START
/*----------------------------------------*/
.cxu-preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--white-3);
  z-index: 9;
}

.cxu-preloader .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.cxu-preloader .lines {
  text-align: center;
}

.cxu-preloader .line {
  width: 3px;
  height: 20px;
  background: var(--primary);
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: preloaderAnimation 1000ms infinite ease-in-out;
  animation: preloaderAnimation 1000ms infinite ease-in-out;
}

.cxu-preloader .line-1 {
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.cxu-preloader .line-2 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

.cxu-preloader .line-3 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.cxu-preloader .line-4 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.cxu-preloader .line-6 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.cxu-preloader .line-7 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.cxu-preloader .line-8 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

.cxu-preloader .line-9 {
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.cxu-preloader .text {
  color: var(--primary);
  margin: 5px 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
}

/*----------------------------------------*/
/*  10. MENU CSS START
/*----------------------------------------*/
.main-menu ul li {
  display: inline-block;
  padding: 0 20px;
}

.main-menu ul li:hover>.sub-menu {
  top: 80px;
  opacity: 1;
  visibility: visible;
}

.main-menu ul li a {
  display: block;
  padding: 25px 3px;
  color: var(--gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  text-transform: uppercase;
  font-family: var(--font-inter);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}

.dark .main-menu ul li a {
  color: var(--white);
}

.main-menu ul li a::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 20px;
  opacity: 0;
  inset-inline-start: calc(50% - 3px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--primary);
  border-radius: 100px;
}

.main-menu ul li a:hover {
  color: var(--primary);
}

.dark .main-menu ul li a:hover {
  color: var(--green);
}

.main-menu ul li a:hover::after {
  opacity: 1;
}

.main-menu .sub-menu {
  position: absolute;
  width: 260px;
  top: 100px;
  background-color: var(--white);
  padding: 20px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 99;
}

.dark .main-menu .sub-menu {
  background-color: var(--black);
}

.main-menu .sub-menu li {
  padding: 0 30px;
  display: block;
  text-align: start;
  position: relative;
}

.main-menu .sub-menu li:hover .sub-menu {
  top: 0;
  inset-inline-start: 100%;
  opacity: 1;
  visibility: visible;
}

.main-menu .sub-menu li a {
  padding: 5px 0;
}

.main-menu .sub-menu li a:hover {
  letter-spacing: 1px;
}

.main-menu .sub-menu li a::after {
  display: none;
}

.main-menu .sub-menu .sub-menu {
  position: absolute;
  width: 260px;
  inset-inline-start: 90%;
  top: 0;
  background-color: var(--white);
  padding: 20px 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.dark .main-menu .sub-menu .sub-menu {
  background-color: var(--black);
}

.main-menu .sub-menu .sub-menu li {
  padding: 0 30px;
  display: block;
  text-align: start;
  position: relative;
}

.main-menu .sub-menu .sub-menu li a {
  padding: 5px 0;
}

.main-menu .has-submenu {
  position: relative;
}

.main-menu .has-submenu::after {
  position: absolute;
  content: "\f107";
  width: 15px;
  height: 15px;
  top: 26px;
  inset-inline-end: 5px;
  color: var(--gray);
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.dark .main-menu .has-submenu::after {
  color: var(--white);
}

.main-menu .has-submenu:hover::after {
  color: var(--primary);
}

.dark .main-menu .has-submenu:hover::after {
  color: var(--green);
}

.main-menu .sub-menu .has-submenu {
  position: relative;
}

.main-menu .sub-menu .has-submenu:hover::after {
  color: var(--primary);
}

.dark .main-menu .sub-menu .has-submenu:hover::after {
  color: var(--green);
}

.main-menu .sub-menu .has-submenu::after {
  content: "\f105";
  top: 8px;
  inset-inline-end: 20px;
}

.dir-rtl .main-menu .sub-menu .has-submenu::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*----------------------------------------*/
/*  13. OFFCANVAS CSS START
/*----------------------------------------*/
.close__menu {
  width: 40px;
  height: 40px;
  position: absolute;
  inset-inline-end: 15px;
  top: 10px;
  font-size: 24px;
  color: var(--primary);
  padding: 1px 11px;
  cursor: pointer;
  border: none;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.open__menu {
  background: transparent;
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
  border: 1px solid var(--white-3);
  border-radius: 100%;
  -webkit-margin-start: 20px;
  margin-inline-start: 20px;
  display: none;
}

@media (max-width: 1199px) {
  .open__menu {
    display: unset;
  }
}

.mobile__menu {
  display: none;
}

@media (max-width: 1199px) {
  .mobile__menu {
    display: block;
  }
}

.mean-container {
  top: 0;
  width: 350px;
  height: 100%;
  position: absolute;
  inset-inline-end: -360px;
  background-color: var(--primary);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: 3;
  padding: 60px 0;
}

@media (max-width: 374px) {
  .mean-container {
    width: 300px;
  }
}

.mean-container .mean-nav>ul {
  display: block !important;
}

.mean-container .mean-bar {
  padding: 0;
  background: none;
}

.mean-container .mean-nav {
  margin: 0;
  background-color: transparent;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 1px solid rgba(226, 226, 226, 0.1411764706);
}

.mean-container .mean-nav ul li a {
  color: var(--white);
  background-color: transparent;
  text-transform: capitalize;
  border-color: rgba(226, 226, 226, 0.1411764706);
}

.mean-container .mean-nav ul li li a {
  opacity: 1;
  border-color: rgba(226, 226, 226, 0.1411764706);
}

.mean-container .mean-nav ul li a.mean-expand {
  background: transparent;
  border-color: rgba(226, 226, 226, 0.1411764706) !important;
  font-size: 24px !important;
  font-weight: 400;
  line-height: 33px;
  border-bottom: none !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background-color: transparent;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.offcanvas__area {
  z-index: 8;
  position: relative;
}

.offcanvas__area-2 {
  width: 100%;
  height: 100%;
  top: 0;
  inset-inline-start: 0;
  position: fixed;
  background: var(--primary);
  z-index: 5;
  -webkit-padding-start: 50px;
  padding-inline-start: 50px;
}

@media (max-width: 1199px) {
  .offcanvas__area-2 {
    -webkit-padding-start: 30px;
    padding-inline-start: 30px;
  }
}

@media (max-width: 991px) {
  .offcanvas__area-2 {
    -webkit-padding-start: 15px;
    padding-inline-start: 15px;
  }
}

.offcanvas__menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--primary);
  -webkit-clip-path: circle(0px at 240px 50px);
  clip-path: circle(0px at 240px 50px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 1919px) {
  .offcanvas__menu {
    -webkit-clip-path: circle(0px at 70px 50px);
    clip-path: circle(0px at 70px 50px);
  }
}

.offcanvas__menu.active {
  -webkit-clip-path: circle(75%);
  clip-path: circle(75%);
}

.offcanvas__menu ul {
  position: relative;
  padding-top: 100px;
  height: 100vh;
}

.offcanvas__menu ul li {
  text-align: center;
}

.offcanvas__menu ul li a {
  font-size: 60px;
  padding: 5px 20px;
  font-weight: 600;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: var(--white);
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .offcanvas__menu ul li a {
    font-size: 36px;
  }
}

.offcanvas__menu ul li a:hover {
  color: var(--green);
}

.offcanvas__menu ul li a:hover::before {
  content: attr(data-text);
  opacity: 1;
  left: 50%;
  letter-spacing: 10px;
  width: 1800px;
  height: 1800px;
}

.offcanvas__menu ul li a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 120px;
  color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 500px;
  -webkit-transition: letter-spacing 0.5s, left 0.5s;
  transition: letter-spacing 0.5s, left 0.5s;
}

@media (max-width: 767px) {
  .offcanvas__menu ul li a:before {
    display: none;
  }
}

.offcanvas__menu-2 {
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 50px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .offcanvas__menu-2 {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

.offcanvas__menu-2 .menu-item {
  opacity: 0;
  position: relative;
  margin-bottom: 25px;
}

.offcanvas__menu-2 .menu-item a {
  font-size: 60px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--white);
  position: relative;
  text-transform: capitalize;
}

@media (max-width: 1199px) {
  .offcanvas__menu-2 .menu-item a {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .offcanvas__menu-2 .menu-item a {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .offcanvas__menu-2 .menu-item a {
    font-size: 32px;
  }
}

.offcanvas__menu-2 .menu-item.has-submenu>a:after {
  content: "\f078";
  position: absolute;
  font-size: 24px;
  top: 50%;
  -webkit-transform: translate(20px, -50%);
  transform: translate(20px, -50%);
  font-family: "Font Awesome 6 Free";
}

.dir-rtl .offcanvas__menu-2 .menu-item.has-submenu>a:after {
  -webkit-transform: translate(-20px, -50%);
  transform: translate(-20px, -50%);
}

.offcanvas__menu-2 .menu-item .sub-menu {
  display: none;
  padding-top: 10px;
}

.offcanvas__menu-2 .menu-item .sub-item {
  -webkit-padding-start: 30px;
  padding-inline-start: 30px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .offcanvas__menu-2 .menu-item .sub-item:last-child {
    padding-bottom: 0;
  }
}

.offcanvas__menu-2 .menu-item .sub-item a {
  font-size: 40px;
}

@media (max-width: 1199px) {
  .offcanvas__menu-2 .menu-item .sub-item a {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .offcanvas__menu-2 .menu-item .sub-item a {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .offcanvas__menu-2 .menu-item .sub-item a {
    font-size: 24px;
  }
}

.offcanvas__close {
  border-radius: 110px;
  padding: 14px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  color: var(--white);
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #D9D9D9;
  background-color: transparent;
  font-family: var(--font-inter);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  z-index: 5;
  inset-inline-end: 50px;
  top: 50px;
}

@media (max-width: 767px) {
  .offcanvas__close {
    top: 15px;
    padding: 12px 24px;
    inset-inline-end: 15px;
  }
}

.offcanvas__close:hover {
  border-color: var(--green);
  background-color: var(--green);
}

.offcanvas__close-2 {
  position: absolute;
  width: 100px;
  height: 100px;
  inset-inline-end: 50px;
  cursor: pointer;
  top: 50px;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .offcanvas__close-2 {
    top: 20px;
    inset-inline-end: 20px;
  }
}

@media (max-width: 767px) {
  .offcanvas__close-2 {
    width: 60px;
    height: 60px;
    top: 15px;
    inset-inline-end: 15px;
  }
}

@-webkit-keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 40% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 40% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.offcanvas__close-2 .outline-1 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--white);
  -webkit-animation: morph 5s linear infinite;
  animation: morph 5s linear infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
}

.offcanvas__close-2 .outline-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid var(--white);
  border-radius: 53% 47% 43% 57%/51% 39% 61% 49%;
}

.offcanvas__close-2 .line-1 {
  width: 50px;
  height: 2px;
  -webkit-transform: rotate(45deg) translate(18px, -18px);
  transform: rotate(45deg) translate(18px, -18px);
  display: inline-block;
  background-color: var(--white);
}

.dir-rtl .offcanvas__close-2 .line-1 {
  -webkit-transform: rotate(45deg) translate(-18px, 18px);
  transform: rotate(45deg) translate(-18px, 18px);
}

@media (max-width: 767px) {
  .offcanvas__close-2 .line-1 {
    width: 30px;
    height: 1px;
    -webkit-transform: rotate(45deg) translate(10px, -10px);
    transform: rotate(45deg) translate(10px, -10px);
  }

  .dir-rtl .offcanvas__close-2 .line-1 {
    -webkit-transform: rotate(45deg) translate(-10px, 10px);
    transform: rotate(45deg) translate(-10px, 10px);
  }
}

.offcanvas__close-2 .line-2 {
  width: 50px;
  height: 2px;
  -webkit-transform: rotate(-45deg) translate(-17px, -18px);
  transform: rotate(-45deg) translate(-17px, -18px);
  display: inline-block;
  background-color: var(--white);
}

.dir-rtl .offcanvas__close-2 .line-2 {
  -webkit-transform: rotate(-45deg) translate(17px, 18px);
  transform: rotate(-45deg) translate(17px, 18px);
}

@media (max-width: 767px) {
  .offcanvas__close-2 .line-2 {
    width: 30px;
    height: 1px;
    -webkit-transform: rotate(-45deg) translate(-11px, -11px);
    transform: rotate(-45deg) translate(-11px, -11px);
  }

  .dir-rtl .offcanvas__close-2 .line-2 {
    -webkit-transform: rotate(-45deg) translate(11px, 11px);
    transform: rotate(-45deg) translate(11px, 11px);
  }
}

.offcanvas__wrapper-2 {
  gap: 60px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 60px 2.5fr;
  grid-template-columns: 1fr 2.5fr;
}

@media (max-width: 1199px) {
  .offcanvas__wrapper-2 {
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 991px) {
  .offcanvas__wrapper-2 {
    gap: 30px;
  }
}

@media (max-width: 767px) {
  .offcanvas__wrapper-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.offcanvas__social-2 {
  opacity: 0;
  height: 100vh;
  padding-top: 100px;
  -webkit-border-end: 1px solid var(--green-4);
  border-inline-end: 1px solid var(--green-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .offcanvas__social-2 {
    display: none;
  }
}

.offcanvas__social-2 li {
  padding-bottom: 15px;
}

.offcanvas__social-2 li a {
  font-size: 24px;
  color: var(--white);
  margin-bottom: 10px;
  display: inline-block;
  text-transform: capitalize;
}

.offcanvas__social-2 li a:hover {
  color: var(--green);
}

.offcanvas__social-2 .contact {
  margin-top: auto;
}

.offcanvas__social-2 .contact li a {
  font-size: 22px;
  margin-bottom: 0;
  text-transform: lowercase;
}

@media (max-width: 1199px) {
  .offcanvas__social-2 .contact li a {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .offcanvas__social-2 .contact li a {
    font-size: 16px;
  }
}

.header__area-3.full {
  height: 10vh;
}

@media (max-width: 1399px) {
  .header__area-3.full {
    height: 12vh;
  }
}

@media (max-width: 767px) {
  .header__area-3.full {
    height: unset;
  }
}

.header__area-4 {
  position: absolute;
  z-index: 5;
  width: 100%;
}

.header__area-4 .search--box button {
  color: var(--white);
}

.header__wrapper {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 220px 20px auto 20px 220px;
  grid-template-columns: 220px auto 220px;
  padding: 10px 100px;
  border-bottom: 1px solid var(--white-2);
  position: relative;
}

.dark .header__wrapper {
  border-color: var(--black-3);
}

@media (max-width: 1919px) {
  .header__wrapper {
    padding: 10px 30px;
    -ms-grid-columns: 190px auto 190px;
    grid-template-columns: 190px auto 190px;
  }
}

@media (max-width: 1199px) {
  .header__wrapper {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 991px) {
  .header__wrapper {
    padding: 10px 15px;
  }
}

.header__wrapper::after,
.header__wrapper::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--white-2);
  top: 0;
}

.dark .header__wrapper::after,
.dark .header__wrapper::before {
  background-color: var(--black-3);
}

.header__wrapper::before {
  inset-inline-start: 320px;
}

@media (max-width: 1919px) {
  .header__wrapper::before {
    inset-inline-start: 240px;
  }
}

@media (max-width: 1199px) {
  .header__wrapper::before {
    display: none;
  }
}

.header__wrapper::after {
  inset-inline-end: 320px;
}

@media (max-width: 1919px) {
  .header__wrapper::after {
    inset-inline-end: 240px;
  }
}

@media (max-width: 1199px) {
  .header__wrapper::after {
    display: none;
  }
}

.header__wrapper-2 {
  gap: 60px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: auto 60px 1fr 60px auto;
  grid-template-columns: auto 1fr auto;
  padding: 10px 150px;
}

@media (max-width: 1919px) {
  .header__wrapper-2 {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .header__wrapper-2 {
    padding: 10px 15px;
  }
}

.header__wrapper-3 {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 15px 1fr 15px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 25px 195px;
}

@media (max-width: 1919px) {
  .header__wrapper-3 {
    padding: 20px 30px;
  }
}

@media (max-width: 1199px) {
  .header__wrapper-3 {
    -ms-grid-columns: 1fr auto 1fr;
    grid-template-columns: 1fr auto 1fr;
  }
}

@media (max-width: 767px) {
  .header__wrapper-3 {
    padding: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.header__wrapper-4 {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: auto 15px auto;
  grid-template-columns: auto auto;
  padding: 20px 100px;
}

@media (max-width: 1399px) {
  .header__wrapper-4 {
    padding: 20px 30px;
  }
}

@media (max-width: 991px) {
  .header__wrapper-4 {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .header__wrapper-4 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.header__menu {
  text-align: center;
}

@media (max-width: 1199px) {
  .header__menu {
    display: none;
  }
}

.header__menu-2 .main-menu ul li a {
  text-transform: capitalize;
}

.header__menu-3 .hamburger {
  border-radius: 110px;
  padding: 14px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  color: var(--gray);
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #D9D9D9;
  background-color: transparent;
  font-family: var(--font-inter);
}

.dark .header__menu-3 .hamburger {
  color: var(--gray-2);
  border-color: var(--black-3);
}

.header__logo-3 {
  text-align: center;
}

@media (max-width: 767px) {
  .header__logo-3 {
    text-align: end;
  }
}

.header__others {
  text-align: end;
}

.header__others a {
  padding: 15px 36px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .header__others a {
    padding: 13px 30px;
  }
}

@media (max-width: 767px) {
  .header__others a {
    display: none;
  }
}

.header__others-2 {
  text-align: end;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__others-2 .hamburger {
  color: var(--gray);
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5;
  font-family: var(--font-inter);
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header__others-3 {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .header__others-3 {
    display: none;
  }
}

@media (max-width: 991px) {
  .header__others-3 .social-media {
    display: none;
  }
}

.header__others-3 .resume {
  border-radius: 110px;
  padding: 14px 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  border: 1px solid #D9D9D9;
  background-color: transparent;
  font-family: var(--font-inter);
}

.dark .header__others-3 .resume {
  color: var(--white-3);
  border-color: var(--black-3);
}

.dir-rtl .header__others-3 .resume i {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

@media (max-width: 767px) {
  .header__others-3 .resume {
    display: none;
  }
}

.header__others-4 {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header__others-4 .hamburger {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 1px solid var(--white-3);
  border-radius: 100%;
}

@media (max-width: 767px) {
  .header__others-4 .btn-wrap {
    display: none;
  }
}

.header__others-4 .btn-wrap a {
  color: var(--white);
  padding: 15px 36px;
}

.hero__area {
  position: relative;
}

.hero__area-2 {
  padding-top: 70px;
}

@media (max-width: 767px) {
  .hero__area-2 {
    padding-top: 40px;
  }
}

.hero__area-3 {
  position: relative;
  background-image: url('../../images/cloud/cloud-about.png');
}

.dark .hero__area-3 {
  background-image: url('../../images/cloud/cloud-about.png');
}

.hero__area-3.full {
  height: 90vh;
}

@media (max-width: 1399px) {
  .hero__area-3.full {
    height: 88vh;
  }
}

@media (max-width: 767px) {
  .hero__area-3.full {
    height: unset;
  }
}

.hero__area-3 .star {
  position: absolute;
  inset-inline-start: 50%;
  top: 263px;
  z-index: 3;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

@media (max-width: 767px) {
  .hero__area-3 .star {
    top: 160px;
    inset-inline-start: 75%;
  }
}

.hero__area-3 .diamond {
  position: absolute;
  inset-inline-end: 26%;
  bottom: 220px;
  z-index: 3;
}

.hero__wrapper-3 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 767px) {
  .hero__wrapper-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.hero__right-3 {
  padding-top: 30px;
  text-align: center;
}

.hero__content {
  position: relative;
}

.hero__content .modal-wrap {
  position: absolute;
  display: block;
  inset-inline-end: 115px;
  top: 175px;
}

@media (max-width: 1199px) {
  .hero__content .modal-wrap {
    top: 115px;
  }
}

@media (max-width: 991px) {
  .hero__content .modal-wrap {
    top: 85px;
    inset-inline-end: 50px;
  }
}

@media (max-width: 767px) {
  .hero__content .modal-wrap {
    position: unset;
    margin-top: 20px;
  }
}

.hero__content .modal__btn {
  z-index: 5;
}

@media (max-width: 1199px) {
  .hero__content .modal__btn {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1199px) {
  .hero__content .modal__btn img {
    max-width: 80px;
  }
}

@media (max-width: 1199px) {
  .hero__content .modal__btn .icon {
    width: 50px;
    height: 50px;
  }
}

.hero__content-3 {
  padding-top: 110px;
}

@media (max-width: 1199px) {
  .hero__content-3 {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .hero__content-3 {
    padding-top: 60px;
  }
}

.hero__subtitle-3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--green);
  text-transform: uppercase;
  font-family: var(--font-unbounded);
  -webkit-padding-start: 55px;
  padding-inline-start: 55px;
  position: relative;
  margin-bottom: 20px;
}

.hero__subtitle-3::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  background-color: var(--green);
  inset-inline-start: 0;
  top: 50%;
}

.hero__title {
  font-size: 140px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
  display: initial;
}

.dark .hero__title {
  color: var(--green);
}

@media (max-width: 1399px) {
  .hero__title {
    font-size: 120px;
  }
}

@media (max-width: 1199px) {
  .hero__title {
    font-size: 100px;
  }
}

@media (max-width: 991px) {
  .hero__title {
    font-size: 75px;
    line-height: 1.2;
  }
}

@media (max-width: 767px) {
  .hero__title {
    font-size: 48px;
  }
}

.hero__title-wrap .img {
  margin-top: -80px;
  display: inline-block;
}

@media (max-width: 1199px) {
  .hero__title-wrap .img {
    max-width: 160px;
    margin-top: -50px;
  }
}

@media (max-width: 991px) {
  .hero__title-wrap .img {
    margin-top: -35px;
  }
}

@media (max-width: 767px) {
  .hero__title-wrap .img {
    max-width: 120px;
    margin-top: -20px;
  }
}

.hero__title-2 {
  font-size: 100px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
}

.dark .hero__title-2 {
  color: var(--green);
}

@media (max-width: 1399px) {
  .hero__title-2 {
    font-size: 80px;
  }
}

@media (max-width: 1199px) {
  .hero__title-2 {
    font-size: 70px;
  }
}

@media (max-width: 991px) {
  .hero__title-2 {
    font-size: 48px;
  }

  .hero__title-2 img {
    max-width: 120px;
  }
}

@media (max-width: 767px) {
  .hero__title-2 {
    font-size: 36px;
  }
}

.hero__title-3 {
  font-size: 100px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
}

.dark .hero__title-3 {
  color: var(--green);
}

@media (max-width: 1199px) {
  .hero__title-3 {
    font-size: 80px;
  }
}

@media (max-width: 991px) {
  .hero__title-3 {
    font-size: 60px;
  }
}

.hero__img {
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  z-index: -1;
}

@media (max-width: 1199px) {
  .hero__img {
    bottom: 20px;
    max-width: 500px;
  }
}

@media (max-width: 991px) {
  .hero__img {
    bottom: 100px;
    max-width: 400px;
  }
}

@media (max-width: 767px) {
  .hero__img {
    position: static;
    margin-top: 30px;
  }
}

.hero__img-2 {
  max-width: 80%;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 767px) {
  .hero__img-2 {
    max-width: 98%;
  }
}

.hero__img-2>img {
  width: 100%;
}

.hero__img-2 .modal__btn {
  width: 160px;
  height: 160px;
  position: absolute;
  inset-inline-start: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .hero__img-2 .modal__btn {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 991px) {
  .hero__img-2 .modal__btn img {
    max-width: 100px;
  }
}

.hero__img-2 .modal__btn .icon {
  width: 80px;
  height: 80px;
  color: var(--green);
  background-color: var(--white);
}

@media (max-width: 991px) {
  .hero__img-2 .modal__btn .icon {
    width: 60px;
    height: 60px;
  }
}

.hero__img-2 .explore {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--black);
  position: absolute;
  inset-inline-end: 180px;
  top: 150px;
  gap: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dark .hero__img-2 .explore {
  color: var(--white-3);
}

@media (max-width: 1199px) {
  .hero__img-2 .explore {
    top: 50px;
    inset-inline-end: 10px;
  }
}

@media (max-width: 991px) {
  .hero__img-2 .explore {
    top: 30px;
    inset-inline-end: 0;
  }
}

@media (max-width: 767px) {
  .hero__img-2 .explore {
    top: -10px;
  }
}

.hero__img-2 .icon {
  width: 60px;
  height: 60px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: var(--primary);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--white);
}

@media (max-width: 767px) {
  .hero__img-2 .icon {
    width: 40px;
    height: 40px;
  }
}

.hero__img-3 {
  position: relative;
}

.hero__img-3 .shape {
  position: absolute;
  z-index: 0;
  inset-inline-start: 0;
  bottom: 0;
}

.hero__img-3 .image {
  position: relative;
  z-index: 1;
}

.hero__img-3 .dribble,
.hero__img-3 .upwork {
  display: inline-block;
  border-radius: 15px;
  background: var(--white);
  padding: 20px 25px;
  -webkit-box-shadow: 40px 40px 80px 0px rgba(90, 36, 6, 0.05);
  box-shadow: 40px 40px 80px 0px rgba(90, 36, 6, 0.05);
  position: absolute;
  z-index: 3;
  color: var(--gray);
}

.dark .hero__img-3 .dribble,
.dark .hero__img-3 .upwork {
  color: var(--gray-2);
  background: var(--black);
}

@media (max-width: 1199px) {

  .hero__img-3 .dribble,
  .hero__img-3 .upwork {
    padding: 15px 20px;
  }
}

.hero__img-3 .dribble i,
.hero__img-3 .upwork i {
  font-size: 36px;
}

.hero__img-3 .dribble p,
.hero__img-3 .upwork p {
  margin-top: 5px;
  font-size: 12px;
  color: var(--gray-2);
  font-family: var(--font-unbounded);
}

.hero__img-3 .upwork {
  top: 185px;
  inset-inline-end: -55px;
  text-align: center;
  z-index: 5;
}

@media (max-width: 1199px) {
  .hero__img-3 .upwork {
    inset-inline-end: 0;
  }
}

.hero__img-3 .dribble {
  text-align: start;
  inset-inline-start: 0;
  bottom: 150px;
  z-index: 5;
}

@media (max-width: 991px) {
  .hero__img-3 .dribble {
    bottom: 60px;
  }
}

@media (max-width: 767px) {
  .hero__shape {
    position: absolute;
    top: 32%;
    inset-inline-start: 50%;
  }
}

.hero__shape img {
  margin-top: -50px;
  -webkit-animation: moveBottom 5s linear infinite;
  animation: moveBottom 5s linear infinite;
}

@media (max-width: 767px) {
  .hero__shape img {
    margin-top: 0;
  }
}

.hero__shape-2 img {
  margin-top: -60px;
}

@media (max-width: 1199px) {
  .hero__shape-2 img {
    margin-top: -20px;
    -webkit-margin-start: 80px;
    margin-inline-start: 80px;
    max-width: 80px;
  }
}

@media (max-width: 991px) {
  .hero__shape-2 img {
    margin-top: 0;
  }
}

.hero__info {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 130px 20px 540px;
  grid-template-columns: 130px 540px;
  margin-top: 80px;
}

@media (max-width: 1199px) {
  .hero__info {
    -ms-grid-columns: 130px 500px;
    grid-template-columns: 130px 500px;
  }
}

@media (max-width: 991px) {
  .hero__info {
    -ms-grid-columns: 100px 340px;
    grid-template-columns: 100px 340px;
  }
}

@media (max-width: 767px) {
  .hero__info {
    margin-top: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.hero__info p {
  margin-bottom: 40px;
}

.hero__info-2 {
  display: -ms-grid;
  display: grid;
  max-width: 660px;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  -ms-grid-columns: 210px auto;
  grid-template-columns: 210px auto;
}

@media (max-width: 991px) {
  .hero__info-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .hero__info-2 {
    gap: 15px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.hero__info-3 {
  gap: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

@media (max-width: 991px) {
  .hero__info-3 {
    gap: 20px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .hero__info-3 {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.hero__info-3 img {
  max-width: unset;
  margin-top: 20px;
}

.hero__text-3 p {
  font-size: 20px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .hero__text-3 p {
    font-size: 16px;
  }
}

.breadcrumb__area {
  padding-top: 140px;
  padding-bottom: 140px;
  background-image: url('../../images/cloud/cloud-about.png');
  background-position: center center;
  background-size: cover;
  position: relative;
}

@media (max-width: 991px) {
  .breadcrumb__area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.breadcrumb__area::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(33deg, #392D6A 23.25%, rgba(57, 45, 106, 0) 85.52%);
  mix-blend-mode: hard-light;
  top: 0;
  inset-inline-start: 0;
}

.breadcrumb__title {
  font-size: 70px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--white);
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .breadcrumb__title {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .breadcrumb__title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 36px;
  }
}

.breadcrumb__content p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border-radius: 45px;
  color: var(--white-3);
  display: inline-block;
  padding: 4px 15px;
  font-family: var(--font-inter);
  border: 1px solid var(--white-3);
  position: relative;
  z-index: 1;
}

.breadcrumb__content p a:hover {
  color: var(--white-3);
}

.cxu--hero-slider.style-1 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--hero-slider.style-1:hover .swiper-button-next,
.cxu--hero-slider.style-1:hover .swiper-button-prev {
  opacity: 1;
}

.cxu--hero-slider.style-1:hover .swiper-button-prev {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.cxu--hero-slider.style-1:hover .swiper-button-next {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.cxu--hero-slider.style-1 .slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 300px 200px 200px;
  position: relative;
}

@media (max-width: 1919px) {
  .cxu--hero-slider.style-1 .slide {
    padding: 260px 120px 150px;
  }
}

@media (max-width: 1399px) {
  .cxu--hero-slider.style-1 .slide {
    padding: 260px 60px 130px;
  }
}

@media (max-width: 1199px) {
  .cxu--hero-slider.style-1 .slide {
    height: 100vh;
    padding: 180px 50px 30px;
  }
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .slide {
    padding: 150px 15px 30px;
  }
}

.cxu--hero-slider.style-1 .slide::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  top: 0;
  background-color: rgba(57, 45, 106, 0.8);
}

.cxu--hero-slider.style-1 .info {
  gap: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  position: relative;
  z-index: 2;
}

@media (max-width: 991px) {
  .cxu--hero-slider.style-1 .info {
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .info {
    margin-top: 20px;
  }
}

.cxu--hero-slider.style-1 .info img {
  max-width: unset;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .shape {
    display: none;
  }
}

.cxu--hero-slider.style-1 .title {
  font-size: 110px;
  line-height: 1.1;
  color: var(--white);
}

@media (max-width: 1399px) {
  .cxu--hero-slider.style-1 .title {
    font-size: 90px;
  }
}

@media (max-width: 1199px) {
  .cxu--hero-slider.style-1 .title {
    font-size: 80px;
  }
}

@media (max-width: 991px) {
  .cxu--hero-slider.style-1 .title {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .title {
    font-size: 36px;
  }
}

.cxu--hero-slider.style-1 .title span {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--secondary);
}

.cxu--hero-slider.style-1 .text p {
  font-size: 20px;
  color: var(--white);
  max-width: 900px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .text p {
    font-size: 16px;
  }
}

.cxu--hero-slider.style-1 .swiper-pagination {
  color: var(--white);
  font-size: 20px;
  bottom: 50px;
}

@media (max-width: 767px) {
  .cxu--hero-slider.style-1 .swiper-pagination {
    bottom: 20px;
  }
}

.cxu--hero-slider.style-1 .swiper-button-next,
.cxu--hero-slider.style-1 .swiper-button-prev {
  opacity: 0;
  color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {

  .cxu--hero-slider.style-1 .swiper-button-next,
  .cxu--hero-slider.style-1 .swiper-button-prev {
    opacity: 1;
  }
}

@media (max-width: 767px) {

  .cxu--hero-slider.style-1 .swiper-button-next,
  .cxu--hero-slider.style-1 .swiper-button-prev {
    display: none;
  }
}

.cxu--hero-slider.style-1 .swiper-button-prev {
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}

@media (max-width: 1199px) {
  .cxu--hero-slider.style-1 .swiper-button-prev {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.cxu--hero-slider.style-1 .swiper-button-next {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}

@media (max-width: 1199px) {
  .cxu--hero-slider.style-1 .swiper-button-next {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.cxu--hero-slider.style-1 .autoplay-progress {
  position: absolute;
  inset-inline-end: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 400;
  color: var(--white);
}

.cxu--hero-slider.style-1 .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  inset-inline-start: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--white);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.cxu--hero-slider.style-1 .swiper-slide-active .title {
  -webkit-animation: cxufadeUp 1s linear 1;
  animation: cxufadeUp 1s linear 1;
}

.cxu--hero-slider.style-1 .swiper-slide-active p {
  -webkit-animation: cxufadeUp 1.25s linear 1;
  animation: cxufadeUp 1.25s linear 1;
}

.cxu--hero-slider.style-1 .swiper-slide-active a {
  -webkit-animation: cxufadeUp 1.5s linear 1;
  animation: cxufadeUp 1.5s linear 1;
}

.logo__slider .swiper-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.logo__slider .swiper-slide {
  width: auto !important;
}

.logo__slider-area {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--primary);
}

.logo__slider-area-2 {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--secondary);
}

@media (max-width: 991px) {
  .logo__slider-area-2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .logo__slider-area-2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.logo__slider-area-3 {
  padding-top: 30px;
}

@media (max-width: 767px) {
  .logo__slider-area-3 {
    padding-top: 10px;
  }
}

.logo__slider-area-3.full {
  height: 20vh;
  padding-top: 50px;
}

.logo__slider-area-4 {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F8F8F8;
}

.dark .logo__slider-area-4 {
  background-color: var(--black);
}

@media (max-width: 1199px) {
  .logo__slider-area-4 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.logo__slider-wrapper {
  gap: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo__slider-left {
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
}

.logo__slider-left .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.7;
  color: var(--white);
  font-family: var(--font-inter);
}

.logo__slider-right {
  -ms-flex-preferred-size: 90%;
  flex-basis: 90%;
}

.text__slider .swiper-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.text__slider .swiper-slide {
  width: auto !important;
}

.text__slider .slide {
  gap: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .text__slider .slide {
    gap: 30px;
  }
}

.text__slider .text {
  font-size: 120px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

@media (max-width: 1399px) {
  .text__slider .text {
    font-size: 100px;
  }
}

@media (max-width: 1199px) {
  .text__slider .text {
    font-size: 80px;
  }
}

@media (max-width: 991px) {
  .text__slider .text {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .text__slider .text {
    font-size: 40px;
  }
}

.text__slider.style-1 .text {
  color: var(--primary);
}

@media (max-width: 991px) {
  .text__slider.style-1 .shape img {
    max-width: 80px;
  }
}

.text__slider.style-2 .text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--green);
}

@media (max-width: 767px) {
  .text__slider.style-2 .shape img {
    max-width: 80px;
  }
}

.fullscreen__layout .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--gray-2);
}

@media (max-width: 1399px) {
  .fullscreen__layout .hero__title-3 {
    font-size: 60px;
  }

  .fullscreen__layout .hero__content-3 {
    padding-top: 70px;
  }

  .fullscreen__layout .hero__img-3 .shape {
    max-width: 460px;
  }

  .fullscreen__layout .hero__img-3 .image {
    max-width: 340px;
  }

  .fullscreen__layout .hero__img-3 .dribble {
    bottom: 60px;
  }

  .fullscreen__layout .about__right-3 ul {
    display: none;
  }

  .fullscreen__layout .about__right-3 p {
    margin-bottom: 30px;
  }

  .fullscreen__layout .cxu__service.style-3 .service-item {
    padding: 30px;
  }

  .fullscreen__layout .service__top-2 {
    margin-bottom: 30px;
  }

  .fullscreen__layout .sec-title {
    font-size: 40px;
  }

  .fullscreen__layout .portfolio__top-5 {
    margin-bottom: 30px;
  }

  .fullscreen__layout .cxu--portfolio .thumb {
    margin-bottom: 15px;
    height: 270px;
  }

  .fullscreen__layout .cxu--portfolio .thumb img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .fullscreen__layout .testimonial__left-3 {
    width: 35%;
  }

  .fullscreen__layout .testimonial__right-3 {
    width: 65%;
  }

  .fullscreen__layout .cta__content-3 .sec-title {
    font-size: 60px;
    margin-bottom: 30px;
  }

  .fullscreen__layout .footer__top-3 {
    padding-bottom: 30px;
  }

  .fullscreen__layout .footer__btm-3 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .fullscreen__layout .hero__info-3 {
    margin-top: 25px;
  }

  .fullscreen__layout .cxu--portfolio.style-5 {
    gap: 30px;
  }

  .fullscreen__layout .testimonial__top-3 {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .fullscreen__layout .hero__title-3 {
    font-size: 48px;
  }

  .fullscreen__layout .cxu__service.style-3 .service-item {
    padding: 20px;
  }

  .fullscreen__layout .cxu--portfolio.style-5 {
    gap: 15px;
  }

  .fullscreen__layout .cxu--portfolio ul li {
    margin-bottom: 5px;
  }

  .fullscreen__layout .sec-title {
    font-size: 30px;
  }

  .fullscreen__layout .cxu--testimonial.style-3 p {
    font-size: 18px;
  }

  .fullscreen__layout .blog__top-3 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .fullscreen__layout .hero__content-3 {
    padding-top: 40px;
  }

  .fullscreen__layout .cxu--portfolio.style-5 {
    gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .fullscreen__layout .portfolio__top-5 {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .fullscreen__layout .testimonial__top-3 {
    padding-top: 60px;
  }

  .fullscreen__layout .testimonial__left-3,
  .fullscreen__layout .testimonial__right-3 {
    width: 100%;
  }

  .fullscreen__layout .cta__content-3 .sec-title {
    font-size: 36px;
  }
}

.fullscreen__layout .cxu-fha {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s;
  transition: all 1s;
}

@media (max-width: 767px) {
  .fullscreen__layout .cxu-fha {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.fullscreen__layout .swiper-slide-active .cxu-fha {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.fullscreen__layout .swiper-slide-active .delay-1 {
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.fullscreen__layout .swiper-slide-active .delay-2 {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.fullscreen__layout .swiper-slide-active .delay-3 {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.fullscreen__layout .swiper-slide-active .delay-4 {
  -webkit-transition-delay: 1.6s;
  transition-delay: 1.6s;
}

@media (max-width: 767px) {
  .home_fullscreen_wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.about__area-3 {
  background-color: var(--primary);
}

.about__area-3.full {
  height: 100vh;
  padding-top: 150px;
}

@media (max-width: 1919px) {
  .about__area-3.full {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .about__area-3.full {
    height: unset;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.about__wrapper-3 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1.4fr;
  grid-template-columns: 1fr 1.4fr;
}

@media (max-width: 767px) {
  .about__wrapper-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about__wrapper-3 .sec-sub-title {
  font-family: var(--font-unbounded);
  position: relative;
  -webkit-padding-start: 60px;
  padding-inline-start: 60px;
}

.about__wrapper-3 .sec-sub-title::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  top: 48%;
  inset-inline-start: 0;
  background-color: var(--secondary);
}

.about__left-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .about__left-3 {
    gap: 100px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .about__left-3 {
    gap: 30px;
    padding-bottom: 10px;
  }
}

.about__title {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

@media (max-width: 767px) {
  .about__title {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.about__top {
  gap: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -180px;
}

@media (max-width: 1399px) {
  .about__top {
    margin-bottom: -150px;
  }
}

@media (max-width: 1199px) {
  .about__top {
    gap: 150px;
    margin-bottom: -100px;
  }
}

@media (max-width: 991px) {
  .about__top {
    gap: 50px;
    margin-bottom: -30px;
  }
}

@media (max-width: 767px) {
  .about__top {
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.about__right-3 .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .about__right-3 .title {
    font-size: 32px;
  }
}

@media (max-width: 991px) {
  .about__right-3 .title {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .about__right-3 .title {
    font-size: 24px;
  }
}

.about__right-3 p {
  font-size: 18px;
  color: var(--white-3);
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .about__right-3 p {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .about__right-3 p {
    font-size: 16px;
  }
}

.about__right-3 ul {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .about__right-3 ul {
    gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about__right-3 ul li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

@media (max-width: 767px) {
  .about__right-3 ul li {
    font-size: 16px;
  }
}

.about__right-3 ul li span {
  display: block;
  font-size: 25px;
  color: var(--white);
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .about__right-3 ul li span {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .about__right-3 ul li span {
    margin-bottom: 2px;
  }
}

.about__right-3 .call {
  color: var(--primary);
  background-color: var(--secondary);
}

.about__right-3 .call:hover {
  color: var(--white);
}

.about__right-3 .call:hover::before {
  background-color: var(--green);
}

.about__right-3 .call i {
  font-size: 14px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.about__text {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 30px auto;
  grid-template-columns: auto auto;
  position: relative;
}

@media (max-width: 991px) {
  .about__text {
    gap: 20px;
  }
}

.about__text .circle {
  -webkit-animation: rotate 10s linear infinite;
  animation: rotate 10s linear infinite;
}

.about__text .arrow {
  position: absolute;
  inset-inline-end: 0;
  bottom: 30px;
  -webkit-margin-end: -130px;
  margin-inline-end: -130px;
  -webkit-animation: moveBottom 5s linear infinite;
  animation: moveBottom 5s linear infinite;
}

@media (max-width: 991px) {
  .about__text .arrow {
    bottom: -80px;
    -webkit-margin-end: -60px;
    margin-inline-end: -60px;
  }
}

@media (max-width: 767px) {
  .about__text .arrow {
    bottom: -230px;
  }
}

.about__text p {
  max-width: 230px;
  font-size: 18px;
  font-weight: 500;
}

.about__img {
  position: relative;
}

.about__img .modal__btn {
  width: 160px;
  height: 160px;
  position: absolute;
  inset-inline-start: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .about__img .modal__btn {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 991px) {
  .about__img .modal__btn img {
    max-width: 100px;
  }
}

.about__img .modal__btn .icon {
  width: 80px;
  height: 80px;
}

@media (max-width: 991px) {
  .about__img .modal__btn .icon {
    width: 60px;
    height: 60px;
  }
}

.about-inner__wrapper {
  gap: 60px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 60px 1fr;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 991px) {
  .about-inner__wrapper {
    gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about-inner__wrapper-2 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1.4fr;
  grid-template-columns: 1fr 1.4fr;
}

@media (max-width: 991px) {
  .about-inner__wrapper-2 {
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 767px) {
  .about-inner__wrapper-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about-inner__wrapper-2 .sec-sub-title {
  position: relative;
  -webkit-padding-start: 60px;
  padding-inline-start: 60px;
  color: var(--green);
  font-family: var(--font-unbounded);
}

.about-inner__wrapper-2 .sec-sub-title::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  top: 48%;
  inset-inline-start: 0;
  background-color: var(--green);
}

.about-inner__left-2 .shape {
  margin-top: 100px;
  -webkit-margin-start: 200px;
  margin-inline-start: 200px;
}

@media (max-width: 991px) {
  .about-inner__left-2 .shape {
    -webkit-margin-start: 60px;
    margin-inline-start: 60px;
  }
}

@media (max-width: 767px) {
  .about-inner__left-2 .shape {
    display: none;
  }
}

.about-inner__right .title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 20px;
  color: var(--primary);
}

.dark .about-inner__right .title {
  color: var(--green-5);
}

@media (max-width: 1199px) {
  .about-inner__right .title {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .about-inner__right .title {
    font-size: 32px;
  }
}

.about-inner__right p {
  margin-bottom: 30px;
}

.about-inner__right a i {
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.about-inner__right-2 .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--primary);
  margin-bottom: 30px;
}

.dark .about-inner__right-2 .title {
  color: var(--green);
}

@media (max-width: 1199px) {
  .about-inner__right-2 .title {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .about-inner__right-2 .title {
    font-size: 24px;
  }
}

.about-inner__right-2 p {
  font-size: 18px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E1E1E1;
}

.dark .about-inner__right-2 p {
  border-color: var(--black-3);
}

@media (max-width: 767px) {
  .about-inner__right-2 p {
    font-size: 16px;
  }
}

.about-inner__right-2 ul {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .about-inner__right-2 ul {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about-inner__right-2 ul li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.about-inner__right-2 ul li span {
  display: block;
  font-size: 25px;
  color: var(--primary);
  margin-bottom: 5px;
}

.dark .about-inner__right-2 ul li span {
  color: var(--green);
}

@media (max-width: 1199px) {
  .about-inner__right-2 ul li span {
    font-size: 20px;
  }
}

.about-inner__right-2 .call i {
  font-size: 14px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.about-inner__hero-wrapper {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 991px) {
  .about-inner__hero-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1199px) {
  .about-inner__hero-content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.about-inner__hero-content .role {
  color: var(--gray);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 50px;
  margin-top: 10px;
}

.dark .about-inner__hero-content .role {
  color: var(--gray-2);
}

@media (max-width: 767px) {
  .about-inner__hero-content .role {
    margin-bottom: 30px;
  }
}

.about-inner__hero-content .wrap {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 80px 30px auto;
  grid-template-columns: 80px auto;
}

@media (max-width: 767px) {
  .about-inner__hero-content .wrap {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about-inner__hero-content .shape {
  padding-top: 30px;
}

@media (max-width: 767px) {
  .about-inner__hero-content .shape {
    display: none;
  }
}

.about-inner__hero-content .info p {
  font-size: 18px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .about-inner__hero-content .info p {
    font-size: 16px;
  }
}

.about-inner__hero-content .info p strong {
  color: var(--primary);
}

.dark .about-inner__hero-content .info p strong {
  color: var(--green);
}

.about-inner__hero-content .info p:first-child {
  text-indent: 40px;
}

.about-inner__hero-content .info a {
  margin-top: 10px;
}

.about-inner__hero-img img {
  -webkit-transform: translateX(150px);
  transform: translateX(150px);
}

@media (max-width: 1919px) {
  .about-inner__hero-img img {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
  }
}

@media (max-width: 1199px) {
  .about-inner__hero-img img {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@media (max-width: 991px) {
  .about-inner__hero-img img {
    max-width: 500px;
  }
}

@media (max-width: 767px) {
  .about-inner__hero-img img {
    max-width: 300px;
  }
}

.about-inner__counter-area {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: var(--primary);
}

.about-inner__counter-area .counter__item {
  position: relative;
}

.about-inner__counter-area .counter__item:last-child::after {
  display: none;
}

.about-inner__counter-area .counter__item::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #4F408D;
  top: 0;
  inset-inline-end: 80px;
}

@media (max-width: 767px) {
  .about-inner__counter-area .counter__item::after {
    display: none;
  }
}

.about-inner__counter-list {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 767px) {
  .about-inner__counter-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.about-inner__service-area {
  background-color: var(--primary);
}

.about-inner__service-area .sec-title {
  font-size: 50px;
  color: var(--white);
}

@media (max-width: 991px) {
  .about-inner__service-area .sec-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .about-inner__service-area .sec-title {
    font-size: 32px;
  }
}

.cta__area {
  text-align: center;
  background-color: var(--green);
  position: relative;
}

.cta__area .modal__btn {
  width: 160px;
  height: 160px;
}

@media (max-width: 991px) {
  .cta__area .modal__btn {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 991px) {
  .cta__area .modal__btn img {
    max-width: 100px;
  }
}

.cta__area .modal__btn .icon {
  width: 90px;
  height: 90px;
  background-color: var(--green);
}

@media (max-width: 991px) {
  .cta__area .modal__btn .icon {
    width: 60px;
    height: 60px;
  }
}

.cta__area .shape {
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 991px) {
  .cta__area .shape {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .cta__area .shape {
    max-width: 50px;
  }
}

.cta__area .shape-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .cta__area .shape-2 {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .cta__area .shape-2 {
    max-width: 50px;
  }
}

.cta__area .shape-3 {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 991px) {
  .cta__area .shape-3 {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .cta__area .shape-3 {
    max-width: 50px;
  }
}

.cta__area .shape-4 {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 991px) {
  .cta__area .shape-4 {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .cta__area .shape-4 {
    max-width: 50px;
  }
}

.cta__area-2 .cxu-btn-primary {
  color: #392D6A;
  text-transform: uppercase;
  background-color: var(--secondary);
}

.cta__area-2 .cxu-btn-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.cta__area-3 {
  text-align: center;
  background-color: var(--green);
}

.cta__area-3 .cxu-btn-primary {
  color: #392D6A;
  background-color: var(--secondary);
}

.cta__area-3 .cxu-btn-primary:hover {
  color: var(--white);
}

.cta__area-3.full {
  height: 60vh;
  padding-top: 120px;
}

@media (max-width: 1919px) {
  .cta__area-3.full {
    padding-top: 80px;
  }
}

@media (max-width: 1399px) {
  .cta__area-3.full {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .cta__area-3.full {
    height: 55vh;
  }
}

@media (max-width: 767px) {
  .cta__area-3.full {
    height: unset;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.cta__area-4 {
  background-color: var(--green);
}

.cta__area-4 .sec-title {
  color: var(--white);
  margin-bottom: 50px;
}

.cta__area-4 a:hover {
  color: var(--green);
  background-color: var(--white);
}

.cta__wrapper-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cta__content {
  position: relative;
}

.cta__content .shape-5 {
  position: absolute;
  inset-inline-start: 100px;
  bottom: 0;
}

@media (max-width: 767px) {
  .cta__content .shape-5 {
    display: none;
  }
}

.cta__content .sec-title {
  font-size: 80px;
  max-width: 990px;
  margin: 0 auto 50px;
  color: var(--white);
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .cta__content .sec-title {
    font-size: 50px;
    max-width: 700px;
  }
}

@media (max-width: 991px) {
  .cta__content .sec-title {
    font-size: 36px;
    max-width: 500px;
  }
}

@media (max-width: 767px) {
  .cta__content .sec-title {
    padding-top: 50px;
    margin-bottom: 30px;
  }
}

.cta__content-3 .sec-sub-title {
  margin-bottom: 15px;
  font-family: var(--font-unbounded);
}

@media (max-width: 767px) {
  .cta__content-3 .sec-sub-title {
    font-size: 16px;
  }
}

.cta__content-3 .sec-sub-title span {
  width: 44px;
  height: 1px;
  display: inline-block;
  background-color: var(--secondary);
  position: relative;
  top: -6px;
}

.cta__content-3 .sec-title {
  font-size: 80px;
  color: var(--white);
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .cta__content-3 .sec-title {
    font-size: 60px;
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .cta__content-3 .sec-title {
    font-size: 36px;
  }
}

.testimonial__area {
  background-color: var(--primary);
}

.testimonial__area-3.full {
  height: 80vh;
}

@media (max-width: 767px) {
  .testimonial__area-3.full {
    height: unset;
  }
}

.testimonial__area-4 {
  background-color: var(--primary);
}

.testimonial__area-4 .sec-title {
  color: var(--white);
}

.testimonial__area-4 .cxu--testimonial.style-2 .btn-prev,
.testimonial__area-4 .cxu--testimonial.style-2 .btn-next {
  outline: 5px solid var(--primary);
}

.testimonial__top-2 {
  text-align: center;
  margin-bottom: 60px;
}

.testimonial__top-3 {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 40px;
}

@media (max-width: 1199px) {
  .testimonial__top-3 {
    padding-top: 0;
  }
}

.testimonial__wrapper {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) {
  .testimonial__wrapper {
    gap: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.testimonial__wrapper-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .testimonial__wrapper-3 {
    gap: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.testimonial__left {
  float: left;
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .testimonial__left {
    width: 100%;
  }
}

.testimonial__left-3 {
  float: left;
  width: 50%;
}

@media (max-width: 767px) {
  .testimonial__left-3 {
    width: 100%;
  }
}

.testimonial__right {
  float: right;
  width: 60%;
}

@media (max-width: 767px) {
  .testimonial__right {
    float: left;
    width: 100%;
  }
}

.testimonial__right-3 {
  float: right;
  width: 50%;
}

@media (max-width: 767px) {
  .testimonial__right-3 {
    float: left;
    width: 100%;
  }
}

.testimonial__info p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--white);
  font-family: var(--font-inter);
}

.testimonial__info .client-img {
  margin-bottom: 15px;
}

.cxu--testimonial.style-1 .quote {
  margin-bottom: 30px;
}

.cxu--testimonial.style-1 p {
  color: var(--white);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
  font-family: var(--font-unbounded);
  margin-bottom: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #3D3A4D;
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-1 p {
    font-size: 24px;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .cxu--testimonial.style-1 p {
    font-size: 20px;
  }
}

.cxu--testimonial.style-1 .client {
  color: var(--white);
  font-size: 25px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -1px;
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-1 .client {
    font-size: 20px;
  }
}

.cxu--testimonial.style-1 .designation {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.cxu--testimonial.style-1 .navigation {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: -60px;
}

.dir-rtl .cxu--testimonial.style-1 .navigation {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cxu--testimonial.style-1 .navigation img {
  -webkit-filter: brightness(2);
  filter: brightness(2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--testimonial.style-1 .navigation .btn-prev,
.cxu--testimonial.style-1 .navigation .btn-next {
  width: 52px;
  height: 52px;
  border: 1px solid #6F6F6F;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 3;
}

.cxu--testimonial.style-1 .navigation .btn-prev:hover,
.cxu--testimonial.style-1 .navigation .btn-next:hover {
  border-color: var(--white);
  background-color: var(--white);
}

.cxu--testimonial.style-1 .navigation .btn-prev:hover img,
.cxu--testimonial.style-1 .navigation .btn-next:hover img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.cxu--testimonial.style-2 {
  padding: 60px 90px 120px;
  border-radius: 30px;
  background-color: #FBFAFF;
  position: relative;
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-2 {
    padding: 30px 60px 90px;
  }
}

@media (max-width: 767px) {
  .cxu--testimonial.style-2 {
    padding: 20px;
  }
}

.cxu--testimonial.style-2 .item {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 20px 1.8fr;
  grid-template-columns: 1fr 1.8fr;
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-2 .item {
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
  }
}

@media (max-width: 991px) {
  .cxu--testimonial.style-2 .item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--testimonial.style-2 .client-img {
  position: relative;
  margin: 30px;
}

.cxu--testimonial.style-2 .client-img img {
  width: 290px;
  height: 340px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 30px;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-2 .client-img img {
    width: 250px;
    height: 300px;
  }
}

.cxu--testimonial.style-2 .client-img::after {
  position: absolute;
  width: 290px;
  height: 340px;
  inset-inline-start: 0;
  top: 0;
  content: "";
  z-index: -1;
  border-radius: 30px;
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
  background: var(--secondary);
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-2 .client-img::after {
    width: 250px;
    height: 300px;
  }
}

.cxu--testimonial.style-2 .quote {
  margin-bottom: 30px;
}

.cxu--testimonial.style-2 p {
  color: var(--gray);
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 30px;
  font-family: var(--font-unbounded);
}

@media (max-width: 1199px) {
  .cxu--testimonial.style-2 p {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .cxu--testimonial.style-2 p {
    font-size: 18px;
  }
}

.cxu--testimonial.style-2 .client {
  color: #1D1D1D;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -1px;
  margin-bottom: 5px;
}

.cxu--testimonial.style-2 .designation {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.cxu--testimonial.style-2 .info-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cxu--testimonial.style-2 .navigation {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: -60px;
}

.dir-rtl .cxu--testimonial.style-2 .navigation {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 767px) {
  .cxu--testimonial.style-2 .navigation {
    display: none;
  }
}

.cxu--testimonial.style-2 .navigation img {
  -webkit-filter: brightness(2);
  filter: brightness(2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--testimonial.style-2 .btn-prev,
.cxu--testimonial.style-2 .btn-next {
  width: 52px;
  height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 3;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--white);
  border: 1px solid #D2D2D2;
  outline: 5px solid var(--white);
}

.dark .cxu--testimonial.style-2 .btn-prev,
.dark .cxu--testimonial.style-2 .btn-next {
  outline-color: var(--black-2);
}

.cxu--testimonial.style-2 .btn-prev:hover,
.cxu--testimonial.style-2 .btn-next:hover {
  border-color: var(--green);
  background-color: var(--green);
}

.cxu--testimonial.style-2 .btn-prev:hover img,
.cxu--testimonial.style-2 .btn-next:hover img {
  -webkit-filter: brightness(3);
  filter: brightness(3);
}

.cxu--testimonial.style-2 .btn-prev {
  left: -30px;
}

.cxu--testimonial.style-2 .btn-next {
  right: -30px;
}

.cxu--testimonial.style-3 .quote {
  margin-bottom: 30px;
}

.cxu--testimonial.style-3 p {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 20px;
  font-family: var(--font-unbounded);
}

@media (max-width: 991px) {
  .cxu--testimonial.style-3 p {
    font-size: 20px;
  }
}

.cxu--testimonial.style-3 .client {
  color: var(--primary);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -1px;
}

.dark .cxu--testimonial.style-3 .client {
  color: var(--green-5);
}

.cxu--testimonial.style-3 .designation {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.cxu--testimonial.style-3 .navigation {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: -60px;
}

.dir-rtl .cxu--testimonial.style-3 .navigation {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .cxu--testimonial.style-3 .navigation {
    margin-top: 15px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.cxu--testimonial.style-3 .navigation img {
  -webkit-filter: brightness(2);
  filter: brightness(2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--testimonial.style-3 .navigation .btn-prev,
.cxu--testimonial.style-3 .navigation .btn-next {
  width: 52px;
  height: 52px;
  border: 1px solid #6F6F6F;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 3;
}

.cxu--testimonial.style-3 .navigation .btn-prev:hover,
.cxu--testimonial.style-3 .navigation .btn-next:hover {
  border-color: var(--white);
  background-color: var(--green);
}

.cxu--testimonial.style-3 .navigation .btn-prev:hover img,
.cxu--testimonial.style-3 .navigation .btn-next:hover img {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

.portfolio__area-2 {
  background-color: var(--primary);
}

.portfolio__area-2 .sec-title {
  color: var(--white);
}

.portfolio__area-3 {
  padding-bottom: 170px;
  background-color: var(--primary);
}

@media (max-width: 767px) {
  .portfolio__area-3 {
    padding-bottom: 60px;
  }
}

.portfolio__area-3.full {
  height: 100vh;
  padding-bottom: 0;
  padding-top: 60px;
}

.portfolio__area-3.full .cxu--portfolio .title {
  color: var(--white);
}

@media (max-width: 767px) {
  .portfolio__area-3.full {
    height: unset;
    padding-bottom: 60px;
  }
}

.portfolio__top {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .portfolio__top {
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.portfolio__top .sec-sub-title {
  color: var(--primary);
  margin-bottom: 15px;
}

.dark .portfolio__top .sec-sub-title {
  color: var(--green);
}

.portfolio__top-2 {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .portfolio__top-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.portfolio__top-2 a {
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--font-inter);
}

@media (max-width: 767px) {
  .portfolio__top-2 a {
    padding: 12px 24px;
  }
}

.portfolio__top-3 {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .portfolio__top-3 {
    margin-bottom: 40px;
  }
}

.portfolio__top-3 .sec-title {
  color: var(--white);
}

.portfolio__top-4 {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 991px) {
  .portfolio__top-4 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .portfolio__top-4 {
    margin-bottom: 30px;
  }
}

.portfolio__top-5 {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .portfolio__top-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
}

.portfolio__top-5 .sec-title {
  color: var(--white);
}

.portfolio__text {
  max-width: 380px;
}

.portfolio__wrapper-4 {
  max-width: 1510px;
  margin: 0 auto;
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  -webkit-padding-end: 15px;
  padding-inline-end: 15px;
}

.portfolio__btm {
  max-width: 1510px;
  margin: 0 auto;
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  -webkit-padding-end: 15px;
  padding-inline-end: 15px;
}

.portfolio__btm-3 {
  margin-top: 65px;
  padding-top: 60px;
  text-align: center;
  border-top: 1px solid #473A7C;
}

@media (max-width: 991px) {
  .portfolio__btm-3 {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .portfolio__btm-3 {
    padding-top: 30px;
    margin-top: 30px;
  }
}

.portfolio__btm-3 .text {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--white);
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .portfolio__btm-3 .text {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .portfolio__btm-3 .text br {
    display: none;
  }
}

.portfolio__btm-3 a {
  font-weight: 400;
  text-transform: uppercase;
}

.portfolio__btm-4 {
  text-align: center;
  margin-top: 70px;
}

@media (max-width: 767px) {
  .portfolio__btm-4 {
    margin-top: 40px;
  }
}

.portfolio-inner__area .sec-title {
  font-size: 50px;
}

@media (max-width: 991px) {
  .portfolio-inner__area .sec-title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .portfolio-inner__area .sec-title {
    font-size: 32px;
  }
}

.portfolio__details {
  margin: 0 auto;
  max-width: 1200px;
}

.portfolio__details-top,
.portfolio__details .img-box {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2.1fr 30px 1fr;
  grid-template-columns: 2.1fr 1fr;
}

@media (max-width: 767px) {

  .portfolio__details-top,
  .portfolio__details .img-box {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.portfolio__details-top {
  margin-bottom: 60px;
}

@media (max-width: 991px) {
  .portfolio__details-top {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .portfolio__details-top {
    margin-bottom: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.portfolio__details .img-box img {
  border-radius: 30px;
}

.portfolio__details .img-1,
.portfolio__details .img-2 {
  overflow: hidden;
}

.portfolio__details .img-3 {
  margin-top: 30px;
  padding-bottom: 70px;
  margin-bottom: 50px;
  border-bottom: 1px solid #ECECEC;
  overflow: hidden;
}

.dark .portfolio__details .img-3 {
  border-color: var(--black-3);
}

@media (max-width: 767px) {
  .portfolio__details .img-3 {
    padding-bottom: 50px;
    margin-bottom: 40px;
  }
}

.portfolio__details .img-3 img {
  border-radius: 30px;
}

.portfolio__details .content p {
  max-width: 585px;
}

.portfolio__details .info p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
  margin-bottom: 10px;
  font-family: var(--font-inter);
}

.dark .portfolio__details .info p {
  color: var(--gray-2);
}

@media (max-width: 767px) {
  .portfolio__details .info p {
    font-size: 16px;
  }
}

.portfolio__details .info p a {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
}

.portfolio__details .info p a:hover {
  color: var(--primary);
}

.dark .portfolio__details .info p a:hover {
  color: var(--green);
}

.portfolio__details .info p strong {
  font-weight: 500;
  color: var(--primary);
}

.dark .portfolio__details .info p strong {
  color: var(--green);
}

.portfolio__details .title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
  text-transform: capitalize;
  margin-bottom: 30px;
}

.dark .portfolio__details .title {
  color: var(--green);
}

@media (max-width: 1199px) {
  .portfolio__details .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .portfolio__details .title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .portfolio__details .title {
    font-size: 32px;
  }
}

.cxu--portfolio {
  text-align: center;
}

.cxu--portfolio .thumb {
  margin-bottom: 20px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .cxu--portfolio .thumb {
    margin-bottom: 10px;
  }
}

.cxu--portfolio .thumb img {
  border-radius: 30px;
}

.cxu--portfolio .thumb:hover .modal__btn {
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
}

.cxu--portfolio .modal__btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: scale(0) translate(-50%, -50%);
  transform: scale(0) translate(-50%, -50%);
}

.cxu--portfolio .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--white);
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .cxu--portfolio .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .cxu--portfolio .title {
    font-size: 20px;
  }
}

.cxu--portfolio .title:hover {
  color: var(--green);
}

.cxu--portfolio .title a {
  color: inherit;
}

.cxu--portfolio ul li {
  display: inline-block;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.cxu--portfolio ul li:last-child {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.cxu--portfolio ul li a {
  font-size: 14px;
  padding: 5px 15px;
  color: #A7A7A7;
  font-weight: 400;
}

.cxu--portfolio .navigation {
  gap: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 50px;
}

.dir-rtl .cxu--portfolio .navigation {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cxu--portfolio .navigation .btn-next,
.cxu--portfolio .navigation .btn-prev {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid #D9D9D9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {

  .cxu--portfolio .navigation .btn-next,
  .cxu--portfolio .navigation .btn-prev {
    width: 60px;
    height: 60px;
  }
}

.cxu--portfolio .navigation .btn-next img,
.cxu--portfolio .navigation .btn-prev img {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--portfolio .navigation .btn-next:hover,
.cxu--portfolio .navigation .btn-prev:hover {
  border-color: var(--green);
  background-color: var(--green);
}

.cxu--portfolio .navigation .btn-next:hover img,
.cxu--portfolio .navigation .btn-prev:hover img {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

.cxu--portfolio.style-1 .swiper {
  padding-top: 80px;
}

@media (max-width: 767px) {
  .cxu--portfolio.style-1 .swiper {
    padding-top: 0;
  }
}

.cxu--portfolio.style-1 .swiper-slide {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.cxu--portfolio.style-1 .swiper-slide.swiper-slide-active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  z-index: 2;
}

@media (max-width: 767px) {
  .cxu--portfolio.style-1 .swiper-slide.swiper-slide-active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cxu--portfolio.style-1 .swiper-slide.swiper-slide-active .title,
.cxu--portfolio.style-1 .swiper-slide.swiper-slide-active ul li a {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

@media (max-width: 767px) {

  .cxu--portfolio.style-1 .swiper-slide.swiper-slide-active .title,
  .cxu--portfolio.style-1 .swiper-slide.swiper-slide-active ul li a {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cxu--portfolio.style-1 .swiper-slide.swiper-slide-active .title {
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .cxu--portfolio.style-1 .swiper-slide.swiper-slide-active .title {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .cxu--portfolio.style-1 .swiper-slide.swiper-slide-active .title {
    margin-bottom: 5px;
  }
}

.cxu--portfolio.style-1 .title {
  color: var(--primary);
}

.dark .cxu--portfolio.style-1 .title {
  color: var(--white-3);
}

.cxu--portfolio.style-1 .title:hover {
  color: var(--green);
}

.cxu--portfolio.style-1 .navigation {
  margin-top: 100px;
}

@media (max-width: 1199px) {
  .cxu--portfolio.style-1 .navigation {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .cxu--portfolio.style-1 .navigation {
    margin-top: 30px;
  }
}

.cxu--portfolio.style-2 {
  text-align: start;
  -webkit-margin-end: -500px;
  margin-inline-end: -500px;
}

@media (max-width: 1199px) {
  .cxu--portfolio.style-2 {
    -webkit-margin-end: -200px;
    margin-inline-end: -200px;
  }
}

@media (max-width: 767px) {
  .cxu--portfolio.style-2 {
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
}

.cxu--portfolio.style-2 .thumb {
  margin-bottom: 30px;
}

.cxu--portfolio.style-2 .title {
  margin-bottom: 0;
  margin-top: 10px;
}

.cxu--portfolio.style-2 .navigation {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
}

.dir-rtl .cxu--portfolio.style-2 .navigation {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cxu--portfolio.style-2 .navigation::after {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: #5B4D93;
  inset-inline-start: 230px;
}

.cxu--portfolio.style-2 .navigation .btn-next img,
.cxu--portfolio.style-2 .navigation .btn-prev img {
  -webkit-filter: brightness(2);
  filter: brightness(2);
}

.cxu--portfolio.style-2 .navigation .btn-next:hover img,
.cxu--portfolio.style-2 .navigation .btn-prev:hover img {
  -webkit-filter: brightness(100);
  filter: brightness(100);
}

.cxu--portfolio.style-3 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-padding-start: 60px;
  padding-inline-start: 60px;
  -webkit-padding-end: 60px;
  padding-inline-end: 60px;
}

@media (max-width: 991px) {
  .cxu--portfolio.style-3 {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}

@media (max-width: 767px) {
  .cxu--portfolio.style-3 {
    gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--portfolio.style-3 .thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cxu--portfolio.style-3 .thumb img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.cxu--portfolio.style-4 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 50px 1.3fr 50px 1fr;
  grid-template-columns: 1fr 1.3fr 1fr;
}

@media (max-width: 1199px) {
  .cxu--portfolio.style-4 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .cxu--portfolio.style-4 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .cxu--portfolio.style-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--portfolio.style-4 .title {
  color: var(--primary);
}

.dark .cxu--portfolio.style-4 .title {
  color: var(--white-3);
}

.cxu--portfolio.style-4 .title:hover {
  color: var(--green);
}

.cxu--portfolio.style-5 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 50px 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.cxu--portfolio.style-5 .title {
  color: var(--primary);
}

.cxu--portfolio.style-5 .title:hover {
  color: var(--green);
}

.cxu--portfolio.style-5 .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
}

.cxu--portfolio.style-5 .thumb img:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cxu--portfolio-6 {
  padding-top: 200px;
}

.cxu--portfolio-6 .title {
  position: absolute;
}

.cxu--portfolio-6 .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.cxu--portfolio-6 .wrap img {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 30px;
}

.cxu--portfolio-item-6 {
  width: 550px;
  height: 650px;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  margin: 50px;
}

.cxu-navigation.style-1 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 200px 1fr;
  grid-template-columns: 1fr 200px 1fr;
}

@media (max-width: 767px) {
  .cxu-navigation.style-1 {
    gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu-navigation.style-1 .prev,
.cxu-navigation.style-1 .next {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cxu-navigation.style-1 .prev {
  -ms-grid-columns: 50px auto;
  grid-template-columns: 50px auto;
}

.cxu-navigation.style-1 .next {
  -ms-grid-columns: auto 50px;
  grid-template-columns: auto 50px;
}

.cxu-navigation.style-1 .next .text {
  text-align: end;
}

.cxu-navigation.style-1 .icon {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #999999;
  border-radius: 100%;
  color: var(--gray-2);
}

.dir-rtl .cxu-navigation.style-1 .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dark .cxu-navigation.style-1 .icon {
  border-color: var(--black-3);
}

.cxu-navigation.style-1 .icon:hover {
  color: var(--white);
  background-color: var(--primary);
}

.cxu-navigation.style-1 .text p {
  font-weight: 500;
  text-transform: capitalize;
}

.cxu-navigation.style-1 .text a {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: var(--primary);
  text-transform: capitalize;
}

.dark .cxu-navigation.style-1 .text a {
  color: var(--green-5);
}

.cxu-navigation.style-1 .text a:hover {
  color: var(--green);
}

.cxu-navigation.style-1 .mid {
  text-align: center;
}

@media (max-width: 767px) {
  .cxu-navigation.style-1 .mid {
    display: none;
  }
}

.service__area {
  background-color: var(--primary);
}

.service__area-2.full {
  height: 100vh;
  padding-top: 120px;
}

@media (max-width: 1919px) {
  .service__area-2.full {
    padding-top: 80px;
  }
}

@media (max-width: 1399px) {
  .service__area-2.full {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .service__area-2.full {
    height: unset;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.service__area-2.full .btn-wrap {
  text-align: center;
  margin-top: 70px;
}

@media (max-width: 1919px) {
  .service__area-2.full .btn-wrap {
    margin-top: 50px;
  }
}

@media (max-width: 1399px) {
  .service__area-2.full .btn-wrap {
    margin-top: 30px;
  }
}

.service__top {
  text-align: center;
  padding-bottom: 55px;
}

@media (max-width: 767px) {
  .service__top {
    padding-bottom: 40px;
  }
}

.service__top .sec-title {
  color: var(--white);
  padding-top: 15px;
}

.service__top-2 {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .service__top-2 {
    margin-bottom: 40px;
  }
}

.service-inner__area .sec-title {
  font-size: 50px;
}

@media (max-width: 991px) {
  .service-inner__area .sec-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .service-inner__area .sec-title {
    font-size: 32px;
  }
}

.service-inner__area-2 .sec-sub-title,
.service-inner__area-2 .sec-title {
  color: var(--primary);
}

.dark .service-inner__area-2 .sec-sub-title,
.dark .service-inner__area-2 .sec-title {
  color: var(--green);
}

.service-inner__area-2 .cxu__service.style-1 .title {
  color: var(--primary);
}

.dark .service-inner__area-2 .cxu__service.style-1 .title {
  color: var(--green);
}

.service-inner__area-2 .cxu__service.style-1 .service-item {
  border-color: var(--white-3);
}

.dark .service-inner__area-2 .cxu__service.style-1 .service-item {
  border-color: var(--black-3);
}

.service-inner__area-2 .cxu__service.style-1 .service-item:first-child {
  border-color: var(--white-3);
}

.dark .service-inner__area-2 .cxu__service.style-1 .service-item:first-child {
  border-color: var(--black-3);
}

.service-inner__area-2 .cxu__service.style-1 .services li,
.service-inner__area-2 .cxu__service.style-1 .number {
  color: var(--gray);
}

.dark .service-inner__area-2 .cxu__service.style-1 .services li,
.dark .service-inner__area-2 .cxu__service.style-1 .number {
  color: var(--gray-2);
}

.service-inner__area-2 .cxu__service.style-1 .services li img {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}

.service-inner__area-2 .cxu__service.style-1 .link a {
  border-color: var(--white-3);
}

.dark .service-inner__area-2 .cxu__service.style-1 .link a {
  border-color: var(--black-3);
}

.service__details-top {
  text-align: center;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .service__details-top {
    margin-bottom: 30px;
  }
}

.service__details-top img {
  width: 100%;
}

.service__details-top .title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
  margin-bottom: 50px;
}

.dark .service__details-top .title {
  color: var(--green);
}

@media (max-width: 1199px) {
  .service__details-top .title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .service__details-top .title {
    font-size: 36px;
    margin-bottom: 30px;
  }
}

.service__details-wrapper {
  gap: 100px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2fr 100px 1fr;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .service__details-wrapper {
    gap: 30px;
    margin-bottom: 50px;
    -ms-grid-columns: 1.7fr 30px 1fr;
    grid-template-columns: 1.7fr 1fr;
  }
}

@media (max-width: 767px) {
  .service__details-wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.service__details-content h2,
.service__details-content h3,
.service__details-content h4 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--primary);
  margin-bottom: 20px;
  margin-top: 20px;
}

.dark .service__details-content h2,
.dark .service__details-content h3,
.dark .service__details-content h4 {
  color: var(--green);
}

@media (max-width: 1199px) {

  .service__details-content h2,
  .service__details-content h3,
  .service__details-content h4 {
    font-size: 32px;
  }
}

@media (max-width: 991px) {

  .service__details-content h2,
  .service__details-content h3,
  .service__details-content h4 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {

  .service__details-content h2,
  .service__details-content h3,
  .service__details-content h4 {
    font-size: 24px;
  }
}

.service__details-content h3 {
  font-size: 36px;
}

@media (max-width: 1199px) {
  .service__details-content h3 {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .service__details-content h3 {
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .service__details-content h3 {
    font-size: 22px;
  }
}

.service__details-content h4 {
  font-size: 30px;
}

@media (max-width: 1199px) {
  .service__details-content h4 {
    font-size: 28px;
  }
}

@media (max-width: 991px) {
  .service__details-content h4 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .service__details-content h4 {
    font-size: 20px;
  }
}

.service__details-content p {
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .service__details-content p {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .service__details-content p {
    padding-bottom: 20px;
  }
}

.service__details-sidebar .book-call {
  text-align: center;
  border-radius: 30px;
  overflow: hidden;
  background: var(--green);
  padding-bottom: 40px;
}

.service__details-sidebar .book-call img {
  width: 100%;
  border-radius: 30px 30px 65px 65px;
}

.service__details-sidebar .book-call .title {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--white);
  padding: 30px 60px;
}

@media (max-width: 1199px) {
  .service__details-sidebar .book-call .title {
    font-size: 22px;
    padding: 40px 20px;
  }
}

.service__details-sidebar .book-call .cxu-btn-primary {
  color: #392D6A;
  background-color: var(--secondary);
}

@media (max-width: 991px) {
  .service__details-sidebar .book-call .cxu-btn-primary {
    font-size: 14px;
  }
}

.service__details-sidebar .book-call .cxu-btn-primary:hover {
  color: var(--white);
}

.service__full-image {
  overflow: hidden;
}

.service__full-image img {
  width: 100%;
}

.service__related-wrapper {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 30px 2fr;
  grid-template-columns: 1fr 2fr;
}

@media (max-width: 991px) {
  .service__related-wrapper {
    gap: 50px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.service__related-wrapper .cxu__service.style-2 {
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 767px) {
  .service__related-wrapper .cxu__service.style-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.service__related-wrapper .sec-title {
  font-size: 50px;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .service__related-wrapper .sec-title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .service__related-wrapper .sec-title {
    font-size: 32px;
    padding-bottom: 15px;
  }
}

.service__related-wrapper p {
  padding-bottom: 30px;
}

.cxu__service.style-1 .service-item {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.2fr 15px 1fr 15px 1fr 15px 0.3fr;
  grid-template-columns: 0.2fr 1fr 1fr 0.3fr;
  border-bottom: 1px solid #4A3B86;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-bottom: 45px;
  padding-top: 45px;
  position: relative;
}

@media (max-width: 991px) {
  .cxu__service.style-1 .service-item {
    padding-top: 30px;
    padding-bottom: 30px;
    -ms-grid-columns: 0.2fr 1fr 1.5fr 0.2fr;
    grid-template-columns: 0.2fr 1fr 1.5fr 0.2fr;
  }
}

@media (max-width: 767px) {
  .cxu__service.style-1 .service-item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu__service.style-1 .service-item:first-child {
  border-top: 1px solid #4A3B86;
}

.cxu__service.style-1 .service-item:hover {
  background-color: var(--secondary);
  -webkit-margin-start: -30px;
  margin-inline-start: -30px;
  -webkit-padding-start: 30px;
  padding-inline-start: 30px;
  -webkit-padding-end: 30px;
  padding-inline-end: 30px;
  -webkit-margin-end: -30px;
  margin-inline-end: -30px;
  border-radius: 20px;
}

.cxu__service.style-1 .service-item:hover .number,
.cxu__service.style-1 .service-item:hover .title {
  color: var(--primary);
}

.cxu__service.style-1 .service-item:hover .services li {
  color: var(--gray);
}

.dark .cxu__service.style-1 .service-item:hover .services li {
  color: var(--gray-2);
}

.cxu__service.style-1 .service-item:hover .services li img {
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
}

.cxu__service.style-1 .service-item:hover .link a {
  border-color: var(--primary);
  background-color: var(--primary);
}

.cxu__service.style-1 .service-item:hover .hover-img {
  opacity: 1;
  visibility: visible;
}

.cxu__service.style-1 .title {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
  text-transform: capitalize;
}

@media (max-width: 1199px) {
  .cxu__service.style-1 .title {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-1 .title {
    font-size: 24px;
  }
}

.cxu__service.style-1 .number {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--white);
  text-transform: capitalize;
  font-family: var(--font-inter);
}

.cxu__service.style-1 .services {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}

.cxu__service.style-1 .services li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--white-3);
  text-transform: capitalize;
  font-family: var(--font-inter);
}

@media (max-width: 1199px) {
  .cxu__service.style-1 .services li {
    font-size: 16px;
  }
}

.cxu__service.style-1 .services li img {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}

.dir-rtl .cxu__service.style-1 .services li img {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.cxu__service.style-1 .link {
  text-align: end;
}

@media (max-width: 767px) {
  .cxu__service.style-1 .link {
    text-align: start;
    margin-top: 10px;
  }
}

.cxu__service.style-1 .link a {
  width: 90px;
  height: 90px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid var(--white);
  overflow: hidden;
}

@media (max-width: 991px) {
  .cxu__service.style-1 .link a {
    width: 60px;
    height: 60px;
  }
}

.cxu__service.style-1 .link a:hover .icon-1 {
  opacity: 0;
  -webkit-transform: translate(35px, -35px);
  transform: translate(35px, -35px);
}

.dir-rtl .cxu__service.style-1 .link a:hover .icon-1 {
  -webkit-transform: rotate(-90deg) translate(35px, -35px);
  transform: rotate(-90deg) translate(35px, -35px);
}

.cxu__service.style-1 .link a:hover .icon-2 {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.dir-rtl .cxu__service.style-1 .link a:hover .icon-2 {
  -webkit-transform: rotate(-90deg) translate(0, 0);
  transform: rotate(-90deg) translate(0, 0);
}

.cxu__service.style-1 .link .icon-1 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.dir-rtl .cxu__service.style-1 .link .icon-1 {
  -webkit-transform: rotate(-90deg) translate(0, 0);
  transform: rotate(-90deg) translate(0, 0);
}

.cxu__service.style-1 .link .icon-2 {
  position: absolute;
  -webkit-transform: translate(-35px, 35px);
  transform: translate(-35px, 35px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.dir-rtl .cxu__service.style-1 .link .icon-2 {
  -webkit-transform: rotate(-90deg) translate(-35px, 35px);
  transform: rotate(-90deg) translate(-35px, 35px);
}

.cxu__service.style-1 .hover-img {
  position: absolute;
  top: -120px;
  width: 300px;
  height: 370px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
}

.cxu__service.style-1 .hover-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cxu__service.style-2 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr 50px 1fr;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1199px) {
  .cxu__service.style-2 {
    gap: 25px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-2 {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .cxu__service.style-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu__service.style-2 .service-item {
  border-radius: 500px;
  background-color: #F9F9F9;
  padding: 60px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dark .cxu__service.style-2 .service-item {
  background-color: var(--black);
}

@media (max-width: 1399px) {
  .cxu__service.style-2 .service-item {
    padding: 40px;
  }
}

@media (max-width: 1199px) {
  .cxu__service.style-2 .service-item {
    padding: 40px 30px;
  }
}

.cxu__service.style-2 .service-item:hover {
  background-color: var(--green);
}

.cxu__service.style-2 .service-item:hover .icon {
  background-color: var(--secondary);
}

.cxu__service.style-2 .service-item:hover .icon img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.cxu__service.style-2 .service-item:hover .title,
.cxu__service.style-2 .service-item:hover .link a {
  color: var(--white);
}

.cxu__service.style-2 .service-item:hover p {
  color: var(--white-3);
}

.cxu__service.style-2 .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 120px;
  height: 120px;
  background-color: var(--green);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .cxu__service.style-2 .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
}

.cxu__service.style-2 .icon img {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .cxu__service.style-2 .icon img {
    max-width: 40px;
  }
}

.cxu__service.style-2 .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 20px;
  color: var(--primary);
}

@media (max-width: 1199px) {
  .cxu__service.style-2 .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .cxu__service.style-2 .title {
    font-size: 20px;
  }
}

.cxu__service.style-2 .title a:hover {
  color: inherit;
}

.cxu__service.style-2 p {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .cxu__service.style-2 p {
    margin-bottom: 20px;
  }
}

.cxu__service.style-2 .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--gray-2);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.dir-rtl .cxu__service.style-2 .link a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cxu__service.style-3 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr 50px 1fr;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1199px) {
  .cxu__service.style-3 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-3 {
    gap: 15px;
  }
}

@media (max-width: 767px) {
  .cxu__service.style-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu__service.style-3 .service-item {
  padding: 60px;
  text-align: center;
  border-radius: 30px;
  background-color: #FAFAFA;
}

.dark .cxu__service.style-3 .service-item {
  background-color: var(--black);
}

@media (max-width: 1199px) {
  .cxu__service.style-3 .service-item {
    padding: 40px 25px;
  }
}

.cxu__service.style-3 .icon {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .cxu__service.style-3 .icon {
    margin-bottom: 20px;
  }
}

.cxu__service.style-3 .title {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 20px;
  color: var(--primary);
}

.dark .cxu__service.style-3 .title {
  color: var(--green-5);
}

@media (max-width: 1199px) {
  .cxu__service.style-3 .title {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-3 .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.cxu__service.style-3 .title a:hover {
  color: var(--green);
}

.cxu__service.style-4 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1199px) {
  .cxu__service.style-4 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-4 {
    gap: 15px;
  }
}

@media (max-width: 767px) {
  .cxu__service.style-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu__service.style-4 .service-item {
  padding: 30px;
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 15px 3fr;
  grid-template-columns: 1fr 3fr;
  background-color: #FAFAFA;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
}

.dark .cxu__service.style-4 .service-item {
  background-color: var(--black);
}

.cxu__service.style-4 .service-item:hover {
  -webkit-box-shadow: 5px 5px 0px var(--primary);
  box-shadow: 5px 5px 0px var(--primary);
}

@media (max-width: 1199px) {
  .cxu__service.style-4 .service-item {
    padding: 40px 25px;
  }
}

.cxu__service.style-4 .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  color: var(--primary);
}

.dark .cxu__service.style-4 .title {
  color: var(--green-5);
}

@media (max-width: 1199px) {
  .cxu__service.style-4 .title {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .cxu__service.style-4 .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.cxu__service.style-4 .title a:hover {
  color: var(--green);
}

.cxu__service.style-4 p {
  margin-bottom: 20px;
}

.cxu__service.style-4 .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--gray);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.cxu__service.style-4 .link a i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dir-rtl .cxu__service.style-4 .link a i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cxu__service.style-4 .link a:hover {
  color: var(--green);
}

.cxu__service.style-4 .link a:hover i {
  padding-left: 5px;
}

.dir-rtl .cxu__service.style-4 .link a:hover i {
  padding-left: 5px;
}

.team__area-2 .btn-wrap {
  text-align: center;
  margin-top: 60px;
}

.team__top {
  text-align: center;
  margin-bottom: 60px;
}

.cxu--team .member {
  overflow: hidden;
}

.cxu--team .name {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--team .designation {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: var(--font-inter);
}

.cxu--team .bio {
  border-radius: 10px;
  background: #F4F4F4;
  display: inline-block;
  padding: 12px 15px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .cxu--team .bio {
    border-radius: 10px 0 0 10px;
  }
}

.cxu--team .social-media {
  padding: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--team .social-media li a:hover {
  color: var(--primary);
}

.cxu--team.style-1 {
  gap: 40px 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1199px) {
  .cxu--team.style-1 {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) {
  .cxu--team.style-1 {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .cxu--team.style-1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--team.style-1 .member:hover .social-media {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.cxu--team.style-1 .member:hover .bio {
  border-radius: 10px 0 0 10px;
  background-color: var(--green);
}

.dir-rtl .cxu--team.style-1 .member:hover .bio {
  border-radius: 0 10px 10px 0;
}

.cxu--team.style-1 .member:hover .name {
  color: var(--white);
}

.cxu--team.style-1 .member:hover .designation {
  color: #E2E2E2;
}

.cxu--team.style-1 .bio {
  min-width: 210px;
  margin-top: -50px;
}

.cxu--team.style-1 .social-media {
  border-radius: 10px 10px 10px 0px;
  background: var(--secondary);
  padding: 10px;
  display: inline-block;
  position: absolute;
  inset-inline-end: 0;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dir-rtl .cxu--team.style-1 .social-media {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  border-radius: 10px 10px 0 10px;
}

@media (max-width: 1199px) {
  .cxu--team.style-1 .social-media {
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }
}

.cxu--team.style-2 {
  text-align: center;
}

.cxu--team.style-2 .bio {
  min-width: 220px;
  margin-top: -60px;
}

.cxu--team.style-2 .social-media {
  padding: 10px 0 0 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.counter__area {
  background-color: var(--primary);
  position: relative;
}

.counter__area .sec-title {
  color: var(--white);
  margin-bottom: 30px;
}

.counter__area p {
  max-width: 480px;
  color: var(--white-3);
}

.counter__area .shape {
  top: 180px;
  position: absolute;
  inset-inline-end: 450px;
}

@media (max-width: 1919px) {
  .counter__area .shape {
    top: 250px;
  }
}

@media (max-width: 1399px) {
  .counter__area .shape {
    top: 280px;
  }
}

@media (max-width: 1199px) {
  .counter__area .shape {
    top: 26%;
    inset-inline-end: 35%;
  }
}

@media (max-width: 991px) {
  .counter__area .shape {
    top: 37%;
  }
}

@media (max-width: 767px) {
  .counter__area .shape {
    display: none;
  }
}

.counter__area-3 {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .counter__area-3 {
    -webkit-transform: unset;
    transform: unset;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.counter__area-4 {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: var(--primary);
}

@media (max-width: 1199px) {
  .counter__area-4 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.counter__area-5 {
  background-color: var(--primary);
  position: relative;
}

.counter__area-5 .sec-title {
  color: var(--white);
  margin-bottom: 30px;
}

.counter__area-5 p {
  max-width: 480px;
  color: var(--white-3);
}

.counter__area-5 .shape {
  position: absolute;
  inset-inline-start: 50%;
  top: 40%;
}

@media (max-width: 1199px) {
  .counter__area-5 .shape {
    inset-inline-start: 55%;
  }
}

@media (max-width: 991px) {
  .counter__area-5 .shape {
    top: 25%;
    inset-inline-start: 75%;
  }
}

@media (max-width: 767px) {
  .counter__area-5 .shape {
    display: none;
  }
}

.counter__area-5 .counter__list {
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  max-width: 860px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -webkit-margin-end: -200px;
  margin-inline-end: -200px;
  margin-top: 0;
}

@media (max-width: 1919px) {
  .counter__area-5 .counter__list {
    -webkit-margin-end: -50px;
    margin-inline-end: -50px;
  }
}

@media (max-width: 1399px) {
  .counter__area-5 .counter__list {
    margin-top: 30px;
  }
}

@media (max-width: 1199px) {
  .counter__area-5 .counter__list {
    margin-top: 50px;
    margin-bottom: 30px;
    -webkit-margin-end: unset;
    margin-inline-end: unset;
  }
}

@media (max-width: 991px) {
  .counter__area-5 .counter__list {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .counter__area-5 .counter__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -webkit-margin-start: 20px;
    margin-inline-start: 20px;
  }
}

.counter__image {
  position: absolute;
  inset-inline-end: 160px;
  bottom: 140px;
}

@media (max-width: 1919px) {
  .counter__image {
    bottom: 60px;
    inset-inline-end: 60px;
    max-width: 1060px;
  }
}

@media (max-width: 1399px) {
  .counter__image {
    max-width: 960px;
  }
}

@media (max-width: 1199px) {
  .counter__image {
    max-width: 700px;
  }
}

@media (max-width: 991px) {
  .counter__image {
    max-width: 500px;
    inset-inline-end: 15px;
    bottom: 90px;
  }
}

@media (max-width: 767px) {
  .counter__image {
    display: none;
  }
}

.counter__list {
  position: relative;
  margin-top: 70px;
  z-index: 1;
  display: -ms-grid;
  display: grid;
  gap: 90px;
}

@media (max-width: 767px) {
  .counter__list {
    -webkit-margin-start: 20px;
    margin-inline-start: 20px;
    padding-bottom: 30px;
  }
}

.counter__list-3 {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 100px;
  border-radius: 30px;
  background-color: var(--green);
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 767px) {
  .counter__list-3 {
    padding: 50px 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.counter__list-4 {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 767px) {
  .counter__list-4 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.counter__item .number {
  line-height: 1.1;
}

.counter__item p {
  font-family: var(--font-inter);
}

.counter__item.style-1 {
  position: relative;
}

.counter__item.style-1::after {
  position: absolute;
  content: "";
  width: 212px;
  height: 105px;
  -webkit-transform: rotate(-26deg);
  transform: rotate(-26deg);
  border: 1px solid #534C75;
  border-radius: 100%;
  top: 0;
  inset-inline-start: -30px;
  z-index: 0;
}

.counter__item.style-1 * {
  position: relative;
  z-index: 1;
}

.counter__item.style-1 .number {
  font-size: 80px;
  font-weight: 500;
  color: var(--secondary);
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .counter__item.style-1 .number {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .counter__item.style-1 .number {
    font-size: 50px;
  }
}

.counter__item.style-1 p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white-3);
  font-family: var(--font-inter);
}

.counter__item.style-2 .number {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .counter__item.style-2 .number {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .counter__item.style-2 .number {
    font-size: 40px;
  }
}

.counter__item.style-2 p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white-3);
}

.counter__item.style-3 .number {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px var(--white-3);
}

@media (max-width: 1199px) {
  .counter__item.style-3 .number {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .counter__item.style-3 .number {
    font-size: 36px;
  }
}

.counter__item.style-3 p {
  font-size: 16px;
  font-weight: 400;
  color: var(--white-3);
}

.pricing__area-2 {
  background-color: var(--primary);
}

.pricing__area-2 .sec-title {
  color: var(--white);
}

.pricing__area-2 .cxu--pricing.style-1 .price-btn a {
  color: var(--white);
  border-color: var(--white);
}

.pricing__area-2 .cxu--pricing.style-1 .price-btn a:hover {
  border-color: var(--green);
}

.pricing__area-2 .cxu--pricing.style-1 .price-btn .cxu-btn-primary:hover {
  color: var(--green);
}

.pricing__area-2 .cxu--pricing.style-1 .price-btn .cxu-btn-primary:hover::before {
  background-color: var(--white);
}

.pricing__area-2 .cxu--pricing.style-1 .cost,
.pricing__area-2 .cxu--pricing.style-1 .name {
  color: var(--white);
}

.pricing__area-2 .cxu--pricing.style-1 .cost span {
  color: var(--white-3);
}

.pricing__area-2 .cxu--pricing.style-1 .feature li,
.pricing__area-2 .cxu--pricing.style-1 .feature li i {
  color: var(--white-3);
}

.pricing__top {
  text-align: center;
  margin-bottom: 60px;
}

.pricing-inner__area .sec-title {
  font-size: 50px;
}

@media (max-width: 1199px) {
  .pricing-inner__area .sec-title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .pricing-inner__area .sec-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .pricing-inner__area .sec-title {
    font-size: 32px;
  }
}

.cxu--pricing.style-1 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 991px) {
  .cxu--pricing.style-1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--pricing.style-1 .pricing-item {
  border-radius: 30px;
  border: 1px solid #E5DFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dark .cxu--pricing.style-1 .pricing-item {
  border-color: var(--black-3);
}

.cxu--pricing.style-1 .top {
  padding: 30px 30px 30px 0;
  -webkit-margin-start: 30px;
  margin-inline-start: 30px;
  border-bottom: 1px solid #E5DFFF;
}

.dark .cxu--pricing.style-1 .top {
  border-color: var(--black-3);
}

.cxu--pricing.style-1 .content {
  padding: 30px;
}

.cxu--pricing.style-1 .name {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--green);
  margin-bottom: 10px;
}

.cxu--pricing.style-1 .cost {
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  color: var(--primary);
}

.dark .cxu--pricing.style-1 .cost {
  color: var(--green);
}

@media (max-width: 1199px) {
  .cxu--pricing.style-1 .cost {
    font-size: 40px;
  }
}

.cxu--pricing.style-1 .cost span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.cxu--pricing.style-1 .feature li {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--gray);
  margin-bottom: 10px;
  font-family: var(--font-inter);
}

.dark .cxu--pricing.style-1 .feature li {
  color: var(--gray-2);
}

.cxu--pricing.style-1 .feature li i {
  color: var(--green);
}

.cxu--pricing.style-1 .price-btn {
  margin-top: auto;
  padding: 0 30px 30px;
}

.cxu--pricing.style-1 .price-btn .cxu-btn-border {
  color: var(--green);
  border-color: var(--green);
}

.cxu--pricing.style-1 .price-btn .cxu-btn-border:hover {
  color: var(--white);
}

.award__area {
  background-color: var(--primary);
}

.award__top {
  margin-bottom: 50px;
}

.award__top .sec-title {
  font-size: 50px;
  color: var(--white);
}

@media (max-width: 991px) {
  .award__top .sec-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .award__top .sec-title {
    font-size: 32px;
  }
}

.award__item {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.1fr 15px 1fr 15px 1fr;
  grid-template-columns: 0.1fr 1fr 1fr;
  border-bottom: 1px solid #4A3B86;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-bottom: 45px;
  padding-top: 45px;
  position: relative;
}

@media (max-width: 767px) {
  .award__item {
    padding-top: 20px;
    padding-bottom: 20px;
    -ms-grid-columns: 0.1fr 1fr;
    grid-template-columns: 0.1fr 1fr;
  }
}

.award__item:first-child {
  border-top: 1px solid #4A3B86;
}

.award__item:hover {
  -webkit-padding-start: 30px;
  padding-inline-start: 30px;
  -webkit-padding-end: 30px;
  padding-inline-end: 30px;
  background-color: var(--secondary);
}

.award__item:hover .number,
.award__item:hover .title {
  color: var(--primary);
}

.award__item:hover p {
  color: var(--gray);
}

.award__item:hover .award-img {
  opacity: 1;
  visibility: visible;
}

.award__item .title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
  text-transform: capitalize;
}

.award__item .number {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--white);
  text-transform: capitalize;
  font-family: var(--font-inter);
}

.award__item .award-img {
  position: absolute;
  top: -120px;
  width: 300px;
  height: 370px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
  inset-inline-start: 40%;
}

@media (max-width: 767px) {
  .award__item .award-img {
    display: none;
  }
}

.award__item .award-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.award__item p {
  text-align: end;
  color: var(--gray-2);
}

@media (max-width: 767px) {
  .award__item p {
    grid-column: 1/-1;
    text-align: start;
  }
}

.blog__area-3 {
  background-color: #F8F8F8;
}

.dark .blog__area-3 {
  background-color: var(--black);
}

.blog__area-3.full {
  height: 100vh;
  padding-top: 140px;
}

@media (max-width: 1399px) {
  .blog__area-3.full {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .blog__area-3.full {
    padding-top: 60px;
  }

  .blog__area-3.full .thumb img {
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .blog__area-3.full {
    height: unset;
    padding-bottom: 60px;
  }
}

.blog__top {
  text-align: center;
  margin-bottom: 60px;
}

.blog__top .sec-sub-title {
  margin-bottom: 15px;
  color: var(--primary);
}

.dark .blog__top .sec-sub-title {
  color: var(--green);
}

.blog__top-3 {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media (max-width: 991px) {
  .blog__top-3 {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blog__top-3 {
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.blog__top-3 .cxu-btn-border {
  color: #999999;
  border-color: #999999;
  text-transform: uppercase;
  font-weight: 400;
}

.blog__top-3 .cxu-btn-border:hover {
  color: var(--white);
}

.blog__wrapper-4 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 30px 1fr;
  grid-template-columns: 3fr 1fr;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .blog__wrapper-4 {
    -ms-grid-columns: 2.5fr 1fr;
    grid-template-columns: 2.5fr 1fr;
  }
}

@media (max-width: 991px) {
  .blog__wrapper-4 {
    gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.blog__wrapper-4.no-sidebar {
  max-width: 90%;
  margin: 0 auto;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.blog__wrapper-4.no-sidebar .blog__sidebar {
  display: none;
}

.blog__wrapper-4.left-sidebar {
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
}

.blog__wrapper-4.left-sidebar .blog__sidebar {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.blog__wrapper-4.left-sidebar .cxu--posts {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.blog__tags {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  .blog__tags {
    gap: 10px;
  }
}

.blog__tags .text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  text-transform: capitalize;
}

.dark .blog__tags .text {
  color: var(--green);
}

.blog__tags .tags {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog__tags .tags a {
  padding: 3px 12px;
  display: inline-block;
  color: var(--gray-2);
  border-radius: 50px;
  font-family: var(--font-inter);
  border: 1px solid var(--gray-2);
}

.blog__tags .tags a:hover {
  color: var(--white);
  background-color: var(--green);
}

.blog__pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 70px;
}

.dir-rtl .blog__pagination ul .next,
.dir-rtl .blog__pagination ul .prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.blog__pagination ul a {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--gray-2);
  font-weight: 600;
  border-radius: 100%;
}

.blog__pagination ul a:hover,
.blog__pagination ul a.current {
  color: var(--white);
  background-color: var(--green);
}

.blog__details-top .thumb {
  overflow: hidden;
  border-radius: 30px;
  margin-bottom: 20px;
}

.blog__details-top .thumb img {
  width: 100%;
}

.blog__details-top .meta {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.blog__details-top .meta li span {
  color: #999999;
  padding: 3px 12px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 50px;
  font-family: var(--font-inter);
}

.blog__details-top .meta li a {
  color: #999999;
  padding: 3px 12px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 50px;
  font-family: var(--font-inter);
}

.blog__details-top .meta li a:hover {
  color: var(--white);
  background-color: var(--green);
}

.blog__details-top .title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  color: var(--primary);
  margin-bottom: 35px;
}

.dark .blog__details-top .title {
  color: var(--green);
}

@media (max-width: 1199px) {
  .blog__details-top .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .blog__details-top .title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .blog__details-top .title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.blog__details-content {
  /* padding-bottom: 30px; */
}

@media (max-width: 767px) {
  .blog__details-content {
    padding-bottom: 20px;
  }
}

.blog__details-content .large {
  font-size: 30px;
  font-weight: 700;
}

.blog__details-content p {
  /* padding-bottom: 25px; */
}

.blog__details-content .quote {
  padding: 35px 40px;
  border-radius: 30px;
  background: #F6F6F6;
  margin-bottom: 30px;
}

.dark .blog__details-content .quote {
  background: var(--black);
}

@media (max-width: 767px) {
  .blog__details-content .quote {
    padding: 30px 20px;
  }
}

.blog__details-content .quote img {
  margin-bottom: 25px;
}

.blog__details-content .quote blockquote {
  font-size: 25px;
  font-weight: 400;
  color: var(--primary);
}

.dark .blog__details-content .quote blockquote {
  color: var(--green);
}

@media (max-width: 991px) {
  .blog__details-content .quote blockquote {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .blog__details-content .quote blockquote {
    font-size: 18px;
  }
}

.blog__details-content .quote .author {
  font-weight: 500;
  padding-bottom: 0;
}

.blog__details-content .img-group {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .blog__details-content .img-group {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.blog__details-content .img-group img {
  border-radius: 30px;
}

.blog__details-content h1,
.blog__details-content h2,
.blog__details-content h3,
.blog__details-content h4,
.blog__details-content h5,
.blog__details-content h6 {
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 20px;
  line-height: 1;
}

.dark .blog__details-content h1,
.dark .blog__details-content h2,
.dark .blog__details-content h3,
.dark .blog__details-content h4,
.dark .blog__details-content h5,
.dark .blog__details-content h6 {
  color: var(--green);
}

.blog__details-btm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.dark .blog__details-btm {
  border-color: var(--black-3);
}

@media (max-width: 767px) {
  .blog__details-btm {
    gap: 30px;
    margin-bottom: 25px;
  }
}

.cxu--posts .title {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--primary);
}

.dark .cxu--posts .title {
  color: var(--green-5);
}

@media (max-width: 1199px) {
  .cxu--posts .title {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .cxu--posts .title {
    font-size: 24px;
  }
}

.cxu--posts .title a:hover {
  color: var(--green);
}

.cxu--posts .link {
  font-size: 16px;
  line-height: 1.5;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cxu--posts.style-1 .cxu-post {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ECECEC;
  position: relative;
}

.dark .cxu--posts.style-1 .cxu-post {
  border-color: var(--black-3);
}

@media (max-width: 1199px) {
  .cxu--posts.style-1 .cxu-post {
    gap: 0 30px;
    -ms-grid-columns: 1fr 30px 1.3fr;
    grid-template-columns: 1fr 1.3fr;
  }
}

@media (max-width: 991px) {
  .cxu--posts.style-1 .cxu-post {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .cxu--posts.style-1 .cxu-post {
    gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--posts.style-1 .cxu-post:first-child {
  border-top: 1px solid #ECECEC;
}

.dark .cxu--posts.style-1 .cxu-post:first-child {
  border-color: var(--black-3);
}

.cxu--posts.style-1 .cxu-post:hover .title {
  color: var(--green);
}

.cxu--posts.style-1 .cxu-post:hover .thumb-img {
  opacity: 1;
  visibility: visible;
}

.cxu--posts.style-1 .title {
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .cxu--posts.style-1 .title {
    margin-bottom: 20px;
  }
}

.cxu--posts.style-1 .link {
  font-weight: 400;
  padding: 15px 30px;
  color: var(--green);
  border: 1px solid var(--green);
}

.cxu--posts.style-1 .link:hover {
  color: var(--white);
}

.cxu--posts.style-1 .category li {
  display: inline-block;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.cxu--posts.style-1 .category li:last-child {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.cxu--posts.style-1 .category li a {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  color: var(--gray-2);
  border: 1px solid var(--gray-2);
}

.cxu--posts.style-1 .category li a:hover {
  color: var(--white);
  border-color: var(--primary);
}

.cxu--posts.style-1 .thumb-img {
  position: absolute;
  top: -120px;
  width: 380px;
  height: 440px;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  border-radius: 15px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .cxu--posts.style-1 .thumb-img {
    position: static;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1/3;
    width: 100%;
    height: 220px;
  }
}

@media (max-width: 1199px) {
  .cxu--posts.style-1 .thumb-img {
    opacity: 1;
    visibility: visible;
  }
}

.cxu--posts.style-1 .thumb-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cxu--posts.style-2 {
  gap: 50px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 767px) {
  .cxu--posts.style-2 {
    gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--posts.style-2 .cxu-post {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1.3fr;
  grid-template-columns: 1fr 1.3fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .cxu--posts.style-2 .cxu-post {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .cxu--posts.style-2 .cxu-post {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--posts.style-2 .title {
  font-size: 30px;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .cxu--posts.style-2 .title {
    font-size: 24px;
  }
}

.cxu--posts.style-2 p {
  margin-bottom: 20px;
}

.cxu--posts.style-2 .link {
  font-weight: 500;
  color: var(--gray-2);
  text-transform: uppercase;
  font-family: var(--font-inter);
}

.cxu--posts.style-2 .link i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.dir-rtl .cxu--posts.style-2 .link i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cxu--posts.style-2 .link:hover {
  color: var(--green);
}

.cxu--posts.style-2 .link:hover i {
  padding-left: 7px;
}

.cxu--posts.style-2 .blog__pagination ul {
  margin-top: 70px;
}

@media (max-width: 767px) {
  .cxu--posts.style-2 .blog__pagination ul {
    margin-top: 40px;
  }
}

.cxu--posts.style-3 {
  gap: 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 40px 1fr 40px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1199px) {
  .cxu--posts.style-3 {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .cxu--posts.style-3 {
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .cxu--posts.style-3 {
    gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.cxu--posts.style-3 .title {
  font-size: 25px;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .cxu--posts.style-3 .title {
    font-size: 20px;
  }
}

.cxu--posts.style-3 .thumb {
  margin-bottom: 20px;
  overflow: hidden;
}

.cxu--posts.style-3 .thumb img {
  width: 100%;
  border-radius: 30px;
}

.cxu--posts.style-3 p {
  margin-bottom: 20px;
}

.cxu--posts.style-3 .meta {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.cxu--posts.style-3 .meta li {
  position: relative;
}

.cxu--posts.style-3 .meta li:last-child::after {
  display: none;
}

.cxu--posts.style-3 .meta li::after {
  position: absolute;
  content: "/";
  inset-inline-end: -15px;
  color: var(--gray-2);
}

.cxu--posts.style-3 .meta li a {
  color: #999999;
  font-size: 14px;
  display: inline-block;
  font-family: var(--font-inter);
}

.cxu--posts.style-3 .meta li a:hover {
  color: var(--green);
}

.cxu--posts.style-3 .link {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  padding: 10px 20px;
  border: 1px solid #999;
}

.cxu--posts.style-3 .link:hover {
  color: var(--white);
  border-color: var(--green);
}

.cxu--posts.style-3 .blog__pagination ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 70px;
}

@media (max-width: 767px) {
  .cxu--posts.style-3 .blog__pagination ul {
    margin-top: 40px;
  }
}

.cxu--posts.style-4 {
  gap: 70px;
  display: -ms-grid;
  display: grid;
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .cxu--posts.style-4 {
    gap: 50px;
  }
}

.cxu--posts.style-4 .thumb {
  margin-bottom: 25px;
}

.cxu--posts.style-4 .thumb img {
  border-radius: 30px;
}

.cxu--posts.style-4 .meta {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.cxu--posts.style-4 .meta li span {
  color: #999999;
  padding: 5px 12px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 50px;
  font-family: var(--font-inter);
}

.cxu--posts.style-4 .meta li a {
  color: #999999;
  padding: 5px 12px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 50px;
  font-family: var(--font-inter);
}

.cxu--posts.style-4 .meta li a:hover {
  color: var(--white);
  border-color: var(--green);
  background-color: var(--green);
}

.cxu--posts.style-4 .title {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 25px;
}

@media (max-width: 1199px) {
  .cxu--posts.style-4 .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .cxu--posts.style-4 .title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .cxu--posts.style-4 .title {
    font-size: 24px;
  }
}

.cxu--posts.style-4 .cxu-btn-border {
  color: var(--green);
  border-color: var(--green);
  padding: 13px 24px;
}

.cxu--posts.style-4 .cxu-btn-border:hover {
  color: var(--white);
}

.cxu--posts.style-5 {
  gap: 10px;
  display: -ms-grid;
  display: grid;
}

.cxu--posts.style-5 .cxu-post {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 70px 10px auto;
  /* grid-template-columns: 70px auto; */
}

.cxu--posts.style-5 .thumb img {
  border-radius: 10px;
  width: 200px;
  object-fit: contain;
}

.cxu--posts.style-5 .meta {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.cxu--posts.style-5 .meta li a {
  color: #999999;
  padding: 2px 10px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 50px;
  font-family: var(--font-inter);
}

.cxu--posts.style-5 .title {
  font-size: 14px;
  font-weight: 500;
}

.comment__form .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  margin-bottom: 5px;
}

.dark .comment__form .title {
  color: var(--green);
}

.comment__form p {
  padding-bottom: 25px;
}

.comment__form .input {
  display: -ms-grid;
  display: grid;
  gap: 20px;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 767px) {
  .comment__form .input {
    gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.comment__form input,
.comment__form textarea {
  width: 100%;
  color: #999;
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
  margin-bottom: 15px;
  background-color: transparent;
  outline: none;
}

.dark .comment__form input,
.dark .comment__form textarea {
  border-color: var(--black-3);
}

.comment__form textarea {
  height: 120px;
  resize: none;
  margin-bottom: 20px;
}

.comment__form .submit {
  padding: 18px 50px;
  border: none;
  outline: none;
  text-transform: capitalize;
}

.faq__left {
  -webkit-padding-end: 30px;
  padding-inline-end: 30px;
}

.faq__left .sec-title-2 {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .faq__left .sec-title-2 {
    margin-bottom: 20px;
  }
}

.faq__left p {
  margin-bottom: 30px;
}

.faq__left img {
  float: right;
}

.dir-rtl .faq__left img {
  float: left;
}

@media (max-width: 991px) {
  .faq__left img {
    display: none;
  }
}

.faq__right .accordion-item {
  background-color: transparent;
}

.dark .faq__right .accordion-item {
  border-color: var(--black-3);
}

.faq__right .accordion-button {
  font-size: 20px;
  color: var(--primary);
  background-color: transparent;
  outline: none;
  gap: 15px;
}

.dir-rtl .faq__right .accordion-button {
  text-align: right;
}

.dark .faq__right .accordion-button {
  color: var(--green);
}

@media (max-width: 1199px) {
  .faq__right .accordion-button {
    font-size: 18px;
  }
}

.dark .faq__right .accordion-button::after {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.dir-rtl .faq__right .accordion-button::after {
  margin-left: unset;
  margin-right: auto;
}

.faq__right .accordion-button:not(.collapsed)::after {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
}

.dark .faq__right .accordion-button:not(.collapsed)::after {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.faq__right .accordion-button:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.switcher__area {
  position: fixed;
  right: 10px;
  top: 50%;
  padding: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9999;
  background-color: var(--green-4);
  border-radius: 15px;
}

.dir-rtl .switcher__area {
  right: unset;
  left: 10px;
}

.switcher__area .mode a {
  color: var(--white);
  font-size: 12px;
  text-transform: uppercase;
}

.switcher__area .mode a.active,
.switcher__area .mode a:hover {
  color: var(--green);
}

.switcher__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 5px;
}

.switcher__btn button {
  color: var(--white);
  font-size: 12px;
  text-transform: uppercase;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  border-radius: 15px;
  padding: 5px;
}

.switcher__btn button.active,
.switcher__btn button:hover {
  color: var(--green);
}

.contact__wrapper {
  gap: 130px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1.2fr 130px 1fr;
  grid-template-columns: 1.2fr 1fr;
  max-width: 1200px;
}

@media (max-width: 1199px) {
  .contact__wrapper {
    gap: 60px;
  }
}

@media (max-width: 991px) {
  .contact__wrapper {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.contact__left .sec-title-2 {
  margin-bottom: 15px;
}

.contact__left>p {
  font-weight: 500;
  max-width: 500px;
  margin-bottom: 35px;
}

.contact__left .box-wrapper {
  gap: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E7E7E7;
}

.dark .contact__left .box-wrapper {
  border-color: var(--black-3);
}

.contact__left .box-wrapper-2 {
  gap: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .contact__left .box-wrapper-2 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.contact__left .box-wrapper-2 .title {
  line-height: 2;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary);
}

.dark .contact__left .box-wrapper-2 .title {
  color: var(--green);
}

.contact__left .box-wrapper-2 p {
  font-weight: 500;
}

.contact__left .icon-box {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact__left .icon-box .icon {
  width: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--green);
  border-radius: 100%;
  color: var(--white);
  font-size: 20px;
}

.contact__left .icon-box .text p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.contact__left .icon-box .text a {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  display: inline-block;
  color: var(--primary);
  text-transform: capitalize;
}

.dark .contact__left .icon-box .text a {
  color: var(--green-5);
}

.contact__left .icon-box .text a:hover {
  color: var(--green);
}

.contact__form {
  padding: 40px;
  background-color: #FBFBFB;
}

.dark .contact__form {
  background-color: var(--black);
}

@media (max-width: 767px) {
  .contact__form {
    padding: 30px 20px;
  }
}

.contact__form .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--primary);
  margin-bottom: 20px;
}

.dark .contact__form .title {
  color: var(--green);
}

.contact__form input,
.contact__form textarea {
  width: 100%;
  color: #999;
  font-family: var(--font-inter);
  font-size: 16px;
  font-weight: 400;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
  margin-bottom: 15px;
  background-color: transparent;
  outline: none;
}

.dir-rtl .contact__form input,
.dir-rtl .contact__form textarea {
  direction: rtl;
}

.dark .contact__form input,
.dark .contact__form textarea {
  border-color: var(--black-3);
}

.contact__form textarea {
  height: 90px;
  resize: none;
}

.contact__form .submit {
  width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer__area-3 {
  background-color: var(--primary);
}

.footer__area-3.full {
  height: 40vh;
  padding-top: 70px;
}

@media (max-width: 1399px) {
  .footer__area-3.full {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .footer__area-3.full {
    height: 45vh;
  }
}

@media (max-width: 767px) {
  .footer__area-3.full {
    height: unset;
    padding-top: 60px;
  }
}

.footer__area-4 {
  background-color: var(--primary);
}

.footer__area-4 .footer__social {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 30px;
}

.footer__area-4 .footer__menu li a {
  color: var(--white);
}

.footer__area-4 .footer__menu li a:hover {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}

.footer__area-6 {
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 18px;
  background-color: var(--primary);
}

.footer__wrapper-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__wrapper-6 ul {
  gap: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer__wrapper-6 ul li {
  position: relative;
}

.footer__wrapper-6 ul li:last-child::after {
  display: none;
}

.footer__wrapper-6 ul li::after {
  position: absolute;
  content: "│";
  inset-inline-end: -20px;
  color: var(--white);
}

.footer__wrapper-6 ul li a {
  font-size: 16px;
  color: var(--white);
}

.footer__wrapper-6 ul li a:hover {
  color: var(--green);
}

.footer__wrapper-6 p {
  font-size: 16px;
  color: var(--white);
}

.footer__wrapper-6 p a {
  color: var(--white-3);
}

.footer__top {
  gap: 60px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 60px 1fr 60px 1.2fr 60px 1.5fr;
  grid-template-columns: 1fr 1fr 1.2fr 1.5fr;
}

@media (max-width: 1199px) {
  .footer__top {
    gap: 30px;
  }
}

@media (max-width: 991px) {
  .footer__top {
    gap: 20px;
    -ms-grid-columns: 0.8fr 20px 1fr 20px 1fr 20px 1.5fr;
    grid-template-columns: 0.8fr 1fr 1fr 1.5fr;
  }
}

@media (max-width: 767px) {
  .footer__top {
    gap: 30px;
    padding-bottom: 50px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.footer__top-3 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .footer__top-3 {
    padding-bottom: 60px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .footer__top-3 {
    padding-bottom: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.footer__top-4 {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 0.7fr 30px 1fr;
  grid-template-columns: 1fr 0.7fr 1fr;
  padding-bottom: 80px;
  border-bottom: 1px solid var(--white-3);
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .footer__top-4 {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .footer__top-4 {
    padding-bottom: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.footer__top-wrapper {
  background-color: var(--primary);
}

.footer__btm {
  gap: 30px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1.5fr;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
}

@media (max-width: 1199px) {
  .footer__btm {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .footer__btm {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .footer__btm {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.footer__btm-3 {
  gap: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #504090;
}

.footer__btm-3 p {
  font-weight: 500;
  color: var(--gray-2);
}

.footer__btm-4 {
  text-align: center;
  padding-bottom: 15px;
}

.footer__btm-4 p {
  color: var(--white);
}

.footer__btm-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #312660;
}

.footer__address-4 .address li a,
.footer__address-4 .address li a:not([href]) {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 10px;
  display: inline-block;
  font-family: var(--font-inter);
}

.footer__address-4 .address li a:hover,
.footer__address-4 .address li a:not([href]):hover {
  color: var(--white-3);
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}

.footer__logo {
  padding-top: 90px;
}

@media (max-width: 767px) {
  .footer__logo {
    padding-top: 60px;
  }
}

.footer__logo-4 img {
  margin-bottom: 30px;
}

.footer__logo-4 p {
  color: var(--white);
  -webkit-padding-end: 100px;
  padding-inline-end: 100px;
}

@media (max-width: 991px) {
  .footer__logo-4 p {
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}

.footer__menu li {
  padding-bottom: 8px;
}

.footer__menu li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.footer__menu li a:hover {
  color: var(--white);
}

.footer__widget {
  padding-top: 90px;
  padding-bottom: 90px;
  -webkit-border-end: 1px solid #4F408A;
  border-inline-end: 1px solid #4F408A;
}

@media (max-width: 991px) {
  .footer__widget {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .footer__widget {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }
}

.footer__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .footer__title {
    padding-bottom: 15px;
  }
}

.footer__title-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--white);
  margin-bottom: 10px;
  font-family: var(--font-inter);
}

.footer__newsletter {
  padding-top: 90px;
}

@media (max-width: 767px) {
  .footer__newsletter {
    padding-top: 0;
  }
}

.footer__newsletter .newsletter form {
  position: relative;
}

.footer__newsletter .newsletter input[type=email] {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--gray-2);
  font-family: var(--font-inter);
  background-color: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid #4F408A;
  padding: 8px 35px 8px 0;
  margin-bottom: 20px;
  outline: none;
}

.dir-rtl .footer__newsletter .newsletter input[type=email] {
  padding: 8px 0 8px 35px;
}

.footer__newsletter .newsletter input[type=email]::-webkit-input-placeholder {
  color: var(--gray-2);
  opacity: 1;
}

.footer__newsletter .newsletter input[type=email]::-moz-placeholder {
  color: var(--gray-2);
  opacity: 1;
}

.footer__newsletter .newsletter input[type=email]:-ms-input-placeholder {
  color: var(--gray-2);
  opacity: 1;
}

.footer__newsletter .newsletter input[type=email]::-ms-input-placeholder {
  color: var(--gray-2);
  opacity: 1;
}

.footer__newsletter .newsletter input[type=email]::placeholder {
  color: var(--gray-2);
  opacity: 1;
}

.footer__newsletter .newsletter .submit {
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  width: 30px;
  height: 30px;
  color: var(--primary);
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
}

.dir-rtl .footer__newsletter .newsletter .submit i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.footer__newsletter .newsletter label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--white-3);
  font-family: var(--font-inter);
}

.footer__contact .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  color: var(--white);
}

.footer__contact a,
.footer__contact a:not([href]) {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-2);
  font-family: var(--font-inter);
}

.footer__contact a:hover,
.footer__contact a:not([href]):hover {
  color: var(--white);
}

.footer__social {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .footer__social {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.footer__social li a {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: 1px solid var(--gray-2);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--gray-2);
}

.footer__social li a:hover {
  color: var(--gray);
  border-color: var(--secondary);
  background-color: var(--secondary);
}

@media (max-width: 991px) {
  .footer__social-3 {
    grid-column: 1/-1;
  }
}

@media (max-width: 991px) {
  .footer__social-wrapper {
    -ms-grid-row: 2;
    grid-row: 2;
    grid-column: 1/-1;
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media (max-width: 767px) {
  .footer__social-wrapper {
    -ms-grid-column-align: unset;
    justify-self: unset;
  }
}

@media (max-width: 991px) {
  .footer__social-wrapper-3 {
    -ms-grid-row: 2;
    grid-row: 2;
    grid-column: 1/-1;
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

.footer__mail-3 a {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--white-3);
}

@media (max-width: 1199px) {
  .footer__mail-3 a {
    font-size: 18px;
  }
}

.footer__mail-3 a:hover {
  color: var(--green);
}

.footer__phone-3 a {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--white-3);
}

@media (max-width: 1199px) {
  .footer__phone-3 a {
    font-size: 18px;
  }
}

.footer__phone-3 a:hover {
  color: var(--green);
}

.footer__social-3 ul {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}

@media (max-width: 991px) {
  .footer__social-3 ul {
    -webkit-columns: 5;
    -moz-columns: 5;
    columns: 5;
  }
}

@media (max-width: 767px) {
  .footer__social-3 ul {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
  }
}

.footer__social-3 li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--white-3);
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 5px;
}

.footer__social-3 li a:hover {
  color: var(--green);
}