/* ------------- site scroll up button css ------------ */
p {
  margin: 0;
  padding: 0;

}

#progress {
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 60px;
  width: 60px;
  display: none;
  z-index: 999 !important;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

#progress-value {
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 25px;
  color: #001a2e;
}

/* ----------------- site Loader ------------ */
.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.8s 1s ease;
  z-index: 666;
}

.loader {
  position: relative;
  display: block;
  z-index: 201;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  transition: all 1s 1s ease;
  border: 3px solid transparent;
  border-top-color: #0C5ADB;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loader:before {
  position: absolute;
  content: '';
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left-color: #730FD7;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader:after {
  position: absolute;
  content: '';
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-right-color: #FE6C04;
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  -o-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

/** Here comes the Magic **/
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-wrapper .loder-section {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100%;
  background: #111;
  z-index: 2;
}

.loader-wrapper .loder-section.left-section {
  left: 0;
  transition: 1s 1.4s ease;
}

.loader-wrapper .loder-section.right-section {
  right: 0;
  transition: 1s 1.4s ease;
}

/** When page loaded **/
.loaded .loder-section.left-section {
  left: -100%;
}

.loaded .loder-section.right-section {
  right: -100%;
}

.loaded .loader-wrapper {
  visibility: hidden;
}

.loaded .loader {
  top: -100%;
  opacity: 0;
}

#video-play-model-beno video {
  width: 100% !important;
}