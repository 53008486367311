/* all heading br display none */
.main-retail-indus-about-eff br {
    display: none;
}

.text-white {
    color: #fff !important;
}



/* all section padding top */
#about,
#provides,
#services,
#our-blog,
#overview,
.retail-bpo-counter,
#retaildgt-retail-success,
#faq-reatil-bpo,
#retailmis-managed-it-support {
    padding-top: 4rem !important;
}



.main-retail-indus-about-eff::after {
    margin-left: -422px;
}

.retail-main-indus-provides-head-bg::after {
    margin-left: -457px;
    width: 190px;
}

.retail-main-ind-capab-head-bg::after {
    margin-left: -317px;
}

.main-retail-indus-whats-new-eff::after {
    width: 107px;
    margin-left: -111px;
    margin-top: 10px;
}


/*=========== ecommerce industry all pages heading and sub heading css ===========*/
.indus-main-retail-section-heading {
    font-size: 45px;
    font-weight: 700;
    position: relative;
    color: #CD4409;
}

.indus-main-retail-section-heading {
    font-size: 40px !important;
}

.indus-main-retail-section-heading span {
    color: #14212b !important;
}

.retail-main-about-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.retail-main-about-sub-heading span {
    color: #CD4409;
    font-weight: 700;
}

.retail-main-about-sub-heading b ul {
    margin-top: 12px;
}

.retail-main-about-sub-heading b ul {
    text-align: left;
}

/* --------  ites Industry all banner css ---------- */
.retail-indus-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* background-blend-mode: overlay; */
    /* background-color: #bbcac29c; */
    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.retail-indus-banner-cloud {
    height: 470px !important;
    background-position: 0% 0%;
    padding: 5rem 0 2rem 0 !important;
}

.retail-indus-banner-bpo {
    padding: 4rem 0 2rem 0 !important;

}

.retail-indus-banner-hr-talent {
    height: 440px !important;
}

.retail-indus-main-banner-head {
    color: #14212B !important;
    margin-right: 19rem;
}

.retail-indus-main-banner-head-hr-talent {
    color: #fff !important;
    width: 60% !important;
    text-shadow: 0 0 10px black;
}

.retail-indus-main-banner-head-dgt {
    width: 75% !important;
}

.retail-indus-main-banner-head-dgt h3 {
    font-size: 45px !important;
}

.retail-indus-main-banner-head-dgt p {
    color: #fff !important;
}

#retail-indus-main-banner {
    background-color: unset !important;
}

.retail-indus-main-bet-content h3 {
    width: 100%;
}


.retail-indus-main-banner-content {
    width: 57%;
}

.retail-indus-main-banner-content-manage {
    width: 60% !important;
}

.retail-indus-main-banner-content-manage p {
    color: white !important;
}

.retail-dgt-head-outline,
.retail-cyber-head-outline,
.retail-cloud-head-outline {
    width: 81% !important;
}

.retail-cloud-head-outline {
    width: 44% !important;
}

.retail-dgt-head-outline-aps {
    width: 65% !important;
}

.retail-dgt-head-outline-aps p {
    color: #fff !important;
}

.retail-cyber-head-outline-cyber {
    width: 62% !important;
}

.retail-cyber-head-outline-cyber p {
    color: #fff !important;
}

.retail-cloud-head-outline-retail-hr {
    width: 96% !important;
}


.retail-indus-main-bet-content h3 {
    color: #14212b;
    font-size: 50px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.retail-indus-main-bet-content p {
    color: #14212b;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.indus-retail-banner-main-btn {
    margin: 1rem 0;
    text-align: end;
    width: 100%;
}

.indus-retail-banner-main-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 10px 25px;
    background: #CD4409;
    font-weight: 600;
    transition: all 0.5s;
    margin-left: 10px;
}

.bnr-nbtn-ind-retail {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-retail:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.indus-retail-banner-main-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}




/* ------------------ main page indexing ---------------- */
section.retail-main-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-ites-indus {
    position: fixed;
    top: 0;
}

.retail-main-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.retail-main-indus-index-nav ul li {
    display: inline-flex;
}

.retail-main-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.retail-main-indus-index-nav ul li a.active {
    background-color: #fff !important;
    color: #CD4409 !important;
}

.retail-main-indus-index-nav ul li:hover,
.retail-main-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.index-ites-indus-item.active {
    background-color: white;
    color: #CD4409;
}


/* --------------- about section ------------- */


#ites-indus-read-btn-about {
    border: none !important;
    color: #CD4409;
    font-size: 18px;
    font-weight: 600;
    background: unset !important;
}

.retail-ind-sub-img-about {
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.retail-main-indus-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.retail-main-indus-front-txt {
    position: relative;
    z-index: 1;
    width: 94.6%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: right;
    margin-bottom: 0.8rem;
}

.retail-main-indus-front-txt h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.retail-main-indus-front-txt h6::after {
    content: "";
    display: block;
    position: relative;
    width: 12.8rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.retail-main-indus-front-txt h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.retail-main-indus-front-txt p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.retail-main-indus-img-sub-heading::after {
    width: 11.7rem !important;
}

/* -------- support provides section --------  */
.retail-main-indus-provides-head-bg br {
    display: none !important;
}

.visible-provide {
    display: block !important;
}

.hidden-provide {
    display: none;
}

.service-provides-main-retail-indus {
    position: relative;
    margin-bottom: 30px;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide {
    position: relative;
    overflow: hidden;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide {
    position: relative;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide img {
    position: relative;
    width: 100%;
    height: 350px !important;
    object-fit: cover;
    display: block;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-caption-service-provide {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    color: #ffffff;
    display: block;
    font-size: 22px;
    padding: 20px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide {
    position: absolute;
    left: 0px;
    bottom: -353px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide h4 {
    position: relative;
    color: #ffffff;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide p {
    position: relative;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.6em;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide:hover .retail-main-indus-caption-service-provide {
    bottom: -60px;
}

.service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide:hover .retail-main-indus-overlay-box-service-provide {
    bottom: 0px;
}

.btn-div-travel-main {
    margin-top: 2rem;
}

.load-more-ecom-main-btn {
    background: #CD4409;
    border: none !important;
    border-radius: 33px;
    transition: all 0.3s;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 20px !important;
    text-decoration: none;
}

.load-more-ecom-main-btn:hover {
    background: #fff;
    color: #CD4409;
    outline: 2px solid !important;
}

/* ------------------our capabilities--------------- */
.retail-main-ind-capabilities-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}



.retail-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.retail-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.retail-ind-inner-area-customer-main:hover .retail-main-indus-serve-small-box {
    margin-left: 22rem;
}

.retail-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.retail-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.retail-main-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.retail-main-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.retail-ind-inner-area-customer-main .retail-main-ind-serve-circle-main .retail-main-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.retail-ind-inner-area-customer-main .retail-main-ind-serve-circle-main .retail-main-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}


/* ------  whats new in retail section  ------ */

.retail-main-whats-new-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#insurance-we-think-second {
    padding: 0 5% 0 5%;
}

.insurance-we-think-img {
    width: 100%;
    height: 19rem;
    object-fit: cover;
}

.triangle-down-insurance-think {
    border-left: 27px solid transparent;
    border-top: 42px solid #14212b;
    position: relative;
    top: 65%;
    right: 100%;
}

div#retail-ind-frid-first-whats-new-sec {
    margin-top: 1rem;
}

img.retail-ind-whats-new-grid-first-card-img {
    height: 16rem;
    object-fit: cover;
    border-radius: 0;
}

h6.retail-ind-whats-new-grid-first-card-category {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

h6.retail-ind-whats-new-grid-first-card-category::after {
    content: "";
    display: block;
    position: relative;
    width: 6rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

h5.retail-ind-whats-new-grid-first-card-title {
    color: #CD4409;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.retail-ind-whats-new-grid-first-card-link {
    text-decoration: none;
}

p.retail-ind-whats-new-grid-first-card-para {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.card.retail-ind-whats-new-grid-first-card {
    border: none;
}


/* =========================
=======================
========================== 

retail in BPO page start

=============
=============== */

/* ----------------------retail in BPO page counter section -----------------*/

.retailbpo-counter-content h3 {
    color: #CD4409;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.retailbpo-counter-content p {
    color: #262626;
    text-align: center;
    font-size: 18px;
    padding: 0 12px;
}


/* ----------------------retail in BPO page service section -----------------*/
.retail-bpo-advantage {
    background-image: url("../../../../public/assets/img/sape-down-bp.png");
    background-repeat: no-repeat;
}

.retail-bpo-service {
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 10px;
    height: 15rem;
    position: relative;
    margin-top: 1rem !important;
}

.retail-bpo-service-img-dep img {
    width: 100%;
    height: 13.7rem;
    object-fit: cover;
}

.retail-bpo-service-content-dep h4 {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    margin-top: 5px;
    font-weight: 600;
    line-height: normal;
}

.retail-bpo-service-content-dep p {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.retail-bpo-service:hover::before {
    position: absolute;
    content: "";
    width: 7rem;
    height: 8px;
    transition: 0.7s;
    left: -1px;
    top: -8px;
    background-color: #CD4409;
}

.retail-bpo-service:hover::after {
    position: absolute;
    content: "";
    width: 8px;
    transition: 0.7s;
    height: 7rem;
    left: -9px;
    top: -8px;
    background-color: #CD4409;
}

/* {-------------retail bpo page faq section -------------} */
#faq-reatil-bpo {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.retailbpo-faq-accordion-main {
    margin-top: 1rem;
}

.retailbpo-faq-accordion-main .accordion-body p {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.retail-bpo-accordion-quest,
.accordion-button {
    color: #14212B;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    background-color: #fff !important;
    line-height: 30px;
}

.retail-bpo-accordion-quest span {
    color: #CD4409;
    font-size: 32px;
    margin-right: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}


/*-------------------- retail in bpo page slip box contant us section---------------------------- */
#retail-bpo-flip-contact {
    margin-top: 4rem;
}

.retail-bpo-slip-box-question {
    opacity: 0.9;
    background: #CD4409;
}

.retail-bpo-slip-box-question ul li {
    list-style: none;
    display: inline-flex;
    padding: 10px 50px;
}

.retail-bpo-slip-box-question ul li p {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
}

.retail-bpo-slip-box-question ul li a {
    border-radius: 33px;
    background: #00973a;
    padding: 8px 25px;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    line-height: normal;
    transition: 0.5s;
    font-weight: 600;
}

.retail-bpo-slip-box-question ul li a:hover {
    background: #fff;
    color: #00973a;
}


/* =========================
=======================
========================== 

retail in digital transformation page start

=============
=============== */

/* {=======================Retail in digital page retail success stories section============== } */
.indus-main-retail-section-para {
    text-align: center;
    margin-top: 12px;
}

.retaildgt-success-content {
    background-color: #FEF2EA;
    border-radius: 100px;
    padding: 45px 50px;
    width: 65rem;
    margin: 2rem 0 0 54px;
    position: relative;
}

.retaildgt-success-content h4 {
    text-align: right;
    position: relative;
}


.retaildgt-success-content p {
    text-align: right;
    font-size: 17px;
}

.retaildgt-success-img {
    /* background-color: #fff; */
}

.retaildgt-success-img img {
    width: 140px;
    height: 140px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgba(0, 151, 58, 0.20);
    padding: 12px;
    background-color: #fff;
    margin-top: -17px;
    margin-left: 16px;
}



/* -=============== retail aps page how can we help section =================- */
#retailaps-how-we-help {
    margin-top: 4rem;
    opacity: 0.9;
    background: #FEF2EA;
}

.retail-aps-already-project {
    padding: 3rem 2rem;
}

.retail-aps-already-project h3 {
    color: #14212B;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2rem;
}

.retailaps-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 2rem;
}

.retailaps-bg-effect,
.retailaps-bg-effect,
.retailmis-bg-effect,
.retaildgt-bg-effect {
    background-image: url("../../../../public/assets/img/shape-12-1.png");
    background-repeat: no-repeat;
}


/* -=============== retail mis page retail managed it support section =================- */

.retailmis-managed-it-support-content p {
    color: #14212B;
    text-align: center;
    font-size: 17px;
    line-height: 33px;
    margin-top: 1rem;
}

/* --------------------  retail hr-talent ------------------------ */

.retail-healt-about-right-img {
    height: 30rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.retailmis-managed-it-support-content-hr-talent p {
    text-align: unset;
}

.retail-healt-about-inner-box {
    padding-right: 2rem;
}

.retail-healt-about-inner-box h3 {
    color: #CD4409;
    font-size: 30px;
    font-weight: 700;
}

.retail-healt-about-inner-box p {
    color: #111111;
    font-size: 16px;
}

@media only screen and (max-width: 480px) {

    /* all heading br display none */
    .main-retail-indus-about-eff br {
        display: block;
    }

    #retailaps-how-we-help {
        margin-top: 2rem !important;
    }

    .main-retail-indus-whats-new-eff br {
        display: block;
    }

    /* all headings bg effect */
    .main-retail-indus-about-eff::after {
        width: 107px !important;
        margin-left: -140px !important;
        margin-top: -43px !important;
    }

    .retail-main-ind-capab-head-bg::after {
        margin-left: -211px !important;
        margin-top: -5px !important;
    }

    .main-retail-indus-whats-new-eff::after {
        width: 67px;
        margin-left: -74px;
        margin-top: -7px;
    }

    .retail-main-indus-provides-head-bg::after {
        margin-left: -152px;
        width: 143px;
        margin-top: -40px;
    }

    /* --============== all travel pages headings and sub headings ===============- */
    .indus-main-retail-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .retail-main-about-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* all section padding top */
    #about,
    #provides,
    #services,
    #our-blog,
    #overview,
    .retail-bpo-counter,
    #faq-reatil-bpo,
    #retaildgt-retail-success,
    #retailmis-managed-it-support {
        padding-top: 2rem !important;
    }


    /* ---- all page banner css -------------- */
    .retail-indus-banner {
        margin-top: 0% !important;
        padding: 3rem 0 1rem 0 !important;
        height: unset !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .retail-indus-main-bet-content {
        width: 100% !important;
        text-align: center;
    }

    .retail-indus-main-bet-content h3 {

        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .retail-indus-main-bet-content p {
        font-size: 17px !important;
        line-height: 26px !important;
        padding: 10px 5px 0 5px !important;
        text-align: center;
    }

    .retailmis-managed-it-support-content p {
        font-size: 16px !important;
    }

    .indus-ites-banner-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    .retail-main-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .retail-main-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    .indus-retail-banner-main-btn a {
        display: block !important;
        margin: 1rem !important;
        padding: 10px 20px !important;
        text-align: center !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .retail-ind-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .travel-serv-index-nav ul li a {
        padding: 8px 12px !important;
    }

    .retail-main-indus-front-txt {
        float: unset !important;
        margin-left: 0rem;
        width: 93.2%;
    }

    .retail-main-indus-front-txt h4 {
        font-size: 24px !important;
    }

    .retail-main-indus-front-txt p {
        line-height: 20px !important;
    }

    /* --------------- main page providers secton -------------- */
    .service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide p {
        line-height: 22px !important;
    }

    .service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide h4 {
        font-size: 22px !important;
    }

    .service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-overlay-box-service-provide {
        padding: 20px 8px !important;
        bottom: -432px !important;
    }

    .service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide .retail-main-indus-caption-service-provide {
        font-size: 18px !important;
        padding: 15px 0px !important;
    }

    .service-provides-main-retail-indus .retail-main-indus-inner-box-service-provide .retail-main-indus-image-box-service-provide img {
        height: 100% !important;
    }

    .retail-main-indus-provides-head-bg br {
        display: block !important;
    }

    /* ---------------our capabilities----------- */
    .retail-main-ind-capabilities-sec {
        background-image: none !important;

    }

    /* -------------- service sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    .retail-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .retail-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .retail-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .retail-main-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }


    /* --------------- whats new in retail section  ------------------- */
    #insurance-we-think-first p {
        margin-top: 0 !important;
    }

    .insurance-we-think-img {
        width: 98% !important;
        object-fit: cover;
    }

    .triangle-down-insurance-think {
        right: 98% !important;
        top: 67% !important;
    }



    #insurance-we-think-grid-first {
        margin-bottom: 1rem;
    }

    h6.retail-ind-whats-new-grid-first-card-category::after {
        width: 7rem !important;
    }

    div#bpo-serv-banner-col-first {
        margin-top: -37% !important;
    }

    /* =========================
  =======================
  ========================== 
  
  retail in BPO page start
  
  =============
  =============== */

    /* ----------------------retail in BPO page service section -----------------*/
    .retail-bpo-service {
        height: unset !important;
    }

    /* {-------------retail bpo page faq section -------------} */
    .retailbpo-faq-accordion-main {
        margin-top: 1rem !important;
    }


    /* ------------retail-bpo-contactus (slip-box) section */
    .retail-bpo-slip-box-question ul {
        padding: 0 !important;
        margin: 0 !important;
    }

    .retail-bpo-slip-box-question,
    .latest-blog-section {
        margin-top: 2rem !important;
    }

    .retail-bpo-slip-box-question ul li {
        padding: 10px 0 !important;
    }

    .retail-bpo-slip-box-question ul li p {
        font-size: 20px !important;
    }


    /* ==============retail application services page start----------- */
    .retail-aps-already-project h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
    }

    .retailaps-section-sub-heading {
        color: #14212b !important;
        font-size: 18px;
        line-height: 22px;
    }


    /*======================= retail success stories section start================ */
    .retaildgt-success-content {
        background-color: #FEF2EA;
        border-radius: 25px;
        padding: 20px 0px;
        width: 100%;
        margin: 1rem 0 0 0;
        position: relative;
        height: 17rem;
    }

    .retaildgt-success-img img {
        width: 90px;
        height: 90px;
        padding: 5px;
        margin-top: -69px;
        margin-left: 16px;
    }

    .retaildgt-success-content p {
        text-align: center;
        font-size: 17px;
    }

    .retaildgt-success-content h4 {
        text-align: right;
        position: relative;
        margin-top: 13%;
        display: flex;
        justify-content: end;
        /* align-items: center; */
    }

    .retailmis-managed-it-support-content-hr-talent p {
        text-align: center !important;
    }

    .retail-healt-about-inner-box h3 {
        font-size: 22px !important;
        text-align: center !important;
    }

    .retail-healt-about-inner-box p {
        text-align: center !important;
    }

    .retail-healt-about-inner-box {
        padding-right: unset !important;
        margin-top: 1rem !important;
    }

    .retail-healt-about-right-img {
        height: 16rem !important;
    }

    .retail-indus-main-banner-head-hr-talent {
        width: 100% !important;
    }

}


@media (max-width: 767px) and (min-width: 481px) {

    /* all section padding top */
    #about,
    #provides,
    #services,
    #our-blog,
    #overview,
    #retaildgt-retail-success,
    #retailmis-managed-it-support,
    .retail-bpo-counter {
        padding-top: 2rem !important;
    }

    #retailaps-how-we-help {
        margin-top: 2rem !important;
    }

    .main-retail-indus-about-eff br {
        display: none;
    }

    .main-retail-indus-whats-new-eff br {
        display: block;
    }

    /* all heading background effect*/
    .main-retail-indus-about-eff::after {
        margin-left: -370px !important;
        margin-top: 3px !important;
    }

    .retail-main-indus-provides-head-bg::after {
        margin-left: -397px !important;
        margin-top: 4px;
    }

    .retail-main-ind-capab-head-bg::after {
        margin-left: -277px !important;
        margin-top: 4px;
    }

    .main-retail-indus-whats-new-eff::after {
        width: 92px;
        margin-left: -97px;
        margin-top: 1px;
    }


    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-retail-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .retail-main-about-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }


    /* ---------------indexing section--------- */
    .retail-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* ---- all page banner css -------------- */
    .retail-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: unset !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .retail-indus-main-bet-content {
        width: 100% !important;
    }

    .retail-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .retail-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    .retailmis-managed-it-support-content p {
        font-size: 16px !important;
    }

    .indus-ites-banner-btn {
        text-align: center !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .indus-main-retail-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .retail-main-about-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .retail-ind-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .retail-main-indus-front-txt {
        float: unset !important;
        width: 95% !important;
        padding-bottom: 13px;
    }

    .retail-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- our capabilities sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    #retail-ind-capabilities-main {
        width: 100% !important;
    }

    .retail-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .retail-ind-inner-area-customer-main p {
        margin-right: 15px !important;
        text-align: justify !important;
    }

    /* --------------- whats new in retail section css ------------------- */
    .insurance-we-think-img {
        margin: 3rem 0 0 0 !important;
    }

    .triangle-down-insurance-think {
        top: 65% !important;
    }

    p.retail-ind-whats-new-grid-first-card-para {
        line-height: 21px !important;
    }

    img.retail-ind-whats-new-grid-first-card-img {
        height: 14rem !important;
    }

    div#insurance-we-think-grid-first {
        margin-bottom: 1rem !important;
    }

    /* =========================
  =======================
  ========================== 
  
  retail in BPO page start
  
  =============
  =============== */

    /* ----------------------retail in BPO page service section -----------------*/
    .retail-bpo-service {
        height: unset !important;
    }


    /*---------------- retail in bpo page slip box section--------------- */
    .retail-bpo-slip-box-question ul {
        padding: 0 !important;
        margin: 0 !important;
    }

    .retail-bpo-slip-box-question,
    .latest-blog-section {
        margin-top: 2rem !important;
    }

    .retail-bpo-slip-box-question ul li {
        padding: 10px 0 !important;
    }

    .retail-bpo-slip-box-question ul li p {
        font-size: 20px !important;
    }


    /* =========retail in aplication services page============ */
    .retail-aps-already-project h3 {
        color: #14212B;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 2rem;
    }

    /* ==========retail success stories section start================== */
    .retaildgt-success-img img {
        padding: 5px;
        margin-top: -49px;
        margin-left: 26px;
        width: 120px;
        height: 120px;
    }

    .retaildgt-success-content h4 {
        padding: 0px 14px 0 0;
    }

    .retaildgt-success-content {
        background-color: #FEF2EA;
        border-radius: 35px;
        padding: 26px 10px;
        width: 100%;
        margin: 2rem 0 0 0;
        height: 16rem;
    }

    .retailmis-managed-it-support-content-hr-talent p {
        text-align: center !important;
    }

    .retail-healt-about-inner-box h3 {
        font-size: 22px !important;
        text-align: center !important;
    }

    .retail-healt-about-inner-box p {
        text-align: center !important;
    }

    .retail-healt-about-inner-box {
        padding-right: unset !important;
    }

    .retail-healt-about-right-img {
        height: 18rem !important;
    }

    .retail-indus-main-banner-head-hr-talent {
        width: 100% !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    .retail-bpo-counter,
    #about,
    #provides,
    #services,
    #overview,
    #retaildgt-retail-success,
    #retailmis-managed-it-support {
        padding-top: 3rem !important;
    }

    #retailaps-how-we-help {
        margin-top: 3rem !important;
    }

    /* all heading br */
    .main-retail-indus-whats-new-eff br {
        display: block;
    }

    /* all heading background effect */
    .main-retail-indus-about-eff::after {
        margin-top: 12px !important;
    }

    .retail-main-indus-provides-head-bg::after {
        margin-top: 13px !important;
    }

    .retail-main-ind-capab-head-bg::after {
        margin-left: -320px !important;
        margin-top: 10px !important;
    }

    .main-retail-indus-whats-new-eff::after {
        width: 106px;
        margin-left: -111px;
        margin-top: 7px;
    }


    /* ---- all page banner css -------------- */
    .retail-indus-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: unset !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .retail-indus-main-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .retail-indus-main-bet-content {
        width: 100% !important;
    }

    .retail-indus-main-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 25px 0 25px !important;
    }

    .indus-ites-banner-btn {
        text-align: center !important;
    }

    .indus-ites-banner-btn a {
        margin: 0 2rem !important;
    }

    /* --------------ites indexing section------------ */
    .retail-main-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* about ites section start */
    .retail-main-indus-front-txt {
        float: unset !important;
        width: 93%;
    }

    .retail-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- Our Capabilities ---------- */
    div#retail-ind-capabilities-main {
        width: 50%;
    }



    .retail-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }



    /* =========================
  =======================
  ========================== 
  
  retail in BPO page start
  
  =============
  =============== */

    /* ----------------------retail in BPO page service section -----------------*/
    .retail-bpo-service {
        height: unset !important;
    }

    .retail-bpo-service-img-dep,
    .retail-bpo-service-content-dep {
        width: 100% !important;
    }

    /*--------------------retail in bpo page slipbox section-----------------  */
    .retail-bpo-slip-box-question ul li {
        padding: 10px !important;
    }

    .retail-bpo-slip-box-question ul li a {
        padding: 8px 25px !important;
    }

    /* ===================]retail success stories section start================= */
    .retaildgt-success-content {
        border-radius: 113px;
    }

    /* =============retail in digital transformation page ================ */

    /* ============ retail success stories section-================= */
    .retaildgt-success-img img {
        width: 140px;
        height: 140px;
        margin-top: -134px;
        margin-left: 32rem;
    }

    .retaildgt-success-content {
        border-radius: 100px;
        padding: 20px 16px;
        width: 39rem !important;
        margin: 2rem 0 0 0;
    }
}



@media (max-width: 1200px) and (min-width: 992px) {

    /* all heading br  */
    .main-retail-indus-whats-new-eff br {
        display: block;
    }

    /*-------------all heading background effect  --------------*/




    /* ---- all page banner css -------------- */
    .retail-indus-banner {
        padding: 5rem 0 4rem 0 !important;
    }

    .indus-ites-banner-btn {
        margin: 1rem 0 !important;
        width: 100%;
    }

    .indus-ites-banner-btn a {

        margin: 0rem 1rem !important;
        text-align: center;
    }

    .retail-indus-main-bet-content {
        width: 100% !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .retail-main-about-sub-heading {
        line-height: 28px !important;
    }


    /* --------------- about section ------------- */
    .retail-main-indus-front-txt {
        float: unset !important;
        width: 94.4% !important;
    }

    .retail-main-indus-front-txt p {
        line-height: 19px !important;
    }

    /* -------------- service sectoin ---------- */
    .retail-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* =========================
  =======================
  ========================== 
  
  retail in BPO page start
  
  =============
  =============== */

    /* ----------------------retail in BPO page service section -----------------*/
    .retail-bpo-service {
        height: max-content !important;
    }

    /* -----------------retail in bpo page slip box section------------------ */
    .retail-bpo-slip-box-question ul li {
        padding: 10px !important;
    }

    .retail-bpo-slip-box-question ul li a {
        padding: 8px 25px !important;
    }


    /* =========================
  =======================
  ========================== 
  
  retail in digital transformation start
  
  =============
  =============== */

    /* --------------retail success stories start -------------------------- */
    .retaildgt-success-content {
        padding: 35px 50px;
        width: 48rem !important;
    }

    .retaildgt-success-img img {
        margin-top: -132px;
        margin-left: 39rem;
        width: 150px;
        height: 150px;
    }


}

@media (max-width: 1400px) and (min-width: 1201px) {
    /* all heading background effect */

    /* ---- all page banner css -------------- */

    .retail-indus-main-bet-content h3 {
        padding: 30px !important;
    }


    /* ites page about section  */
    .retail-main-indus-front-txt {
        width: 95% !important;
    }

    /* -------------- service sectoin ---------- */
    .retail-ind-inner-area-customer-main {
        padding: 15px 5px 15px 15px !important;
    }



    .retaildgt-success-content {
        background-color: #FEF2EA;
        border-radius: 100px;
        padding: 45px 50px;
        width: 65rem !important;
        margin: 2rem 0 0 0rem;
    }

    .retaildgt-success-img img {
        margin-top: -7.7rem;
        margin-left: 55rem;
        height: 150px;
        width: 150px;
    }
}






/* travel tourism  */
/* travel tourism  */
/* travel tourism  */
/* travel tourism  */
/* travel tourism  */
/* travel tourism  */
/* travel tourism  */



/* =========
============ 
travel and tourism main page start
========
=============== */




.travel-effortslessly-effect::after {
    margin-left: -318px !important;
}

.main-page-travel-about-eff::after {
    width: 159px !important;
    margin-left: -685px !important;
}

.provides-main-page-efft::after {
    width: 203px !important;
    margin-left: -458px !important;
    margin-top: 12px !important;
}

.effortslessly-effect::after {
    width: 199px !important;
    margin-left: -560px !important;
}

/* --------  travel and tourism all banner css ---------- */
.travel-ind-banner {
    /* margin-top: 10.5rem; */
    background-size: cover;
    background-position: center;
    /* padding: 7rem 0 2rem 0; */
    height: 450px;
    display: flex;
    align-items: center;
}

.travel-ind-banner-main {
    background-position: 0 0;
}

.travel-ind-banner-unique {
    background-position: 0 30% !important;
}

.travel-ind-main-banner-head {
    color: #000 !important;
    margin-right: 19rem;
    font-size: 40px !important;
}

#travel-ind-main-banner {
    background-color: unset !important;
}

.travel-ind-bet-content h3 {
    width: 100%;
}

.travel-advisory-banner-content {
    width: 72%;
}

.travel-advisory-banner-content-main {
    width: 65% !important;
}

.dge-travel-banner-content {
    width: 78%;
}

.bpo-travel-banner-content {
    width: 60%;
}

.travel-ind-bet-content h3 {
    color: #14212b;
    font-size: 45px;
    font-weight: 700;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.travel-ind-bet-content p {
    color: #14212b;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 35px 0 35px;
}

.travel-ind-banner-btn {
    margin: 3rem 0;
    text-align: end;
    width: 100%;
}

.travel-ind-banner-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 8px 20px;
    background: #CD4409;
    font-weight: 600;
    transition: 0.5s;
    margin-left: 10px;
}

.bnr-nbtn-ind-travel {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-travel:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

.travel-ind-banner-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

/* ------------------ main page indexing ---------------- */
section.travel-serv-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-travel {
    position: fixed;
    top: 0;
}

.travel-serv-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.travel-serv-index-nav ul li {
    display: inline-flex;
}

.travel-serv-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.travel-serv-index-nav ul li:hover,
.travel-serv-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.travel-serv-ind-item.active {
    background-color: white;
    color: #CD4409;
}

/*=========== industry travel all pages heading and sub heading css ===========*/
.travel-section-heading,
.travel-capabilities-section-heading {
    color: #CD4409 !important;
    font-size: 43px;
    font-weight: 700;
    position: relative;
}

.travel-section-heading span {
    color: #14212b !important;
}

.travel-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.hidden-travel {
    display: none;
}

.visible-travel {
    display: block !important;
}

#about,
#provides,
#services,
#our-blog,
#overview {
    padding-top: 4rem;
}

#bpo-travels-question-slip-box {
    margin-top: 4rem;
}

/* --------------- about section ------------- */
.travel-about-sec {
    padding-top: 4rem;
}

#travel-read-btn-about {
    border: none !important;
    color: #CD4409;
    font-size: 18px;
    font-weight: 600;
    background: unset !important;
}

.travel-sub-img-about {
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.travel-sub-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

/* -------- support provides section --------  */
.travel-provides-main-page-efft br {
    display: none !important;
}

.visible-provide {
    display: block !important;
}

.hidden-provide {
    display: none;
}

.service-provides-beno-travel {
    position: relative;
    margin-bottom: 30px;
}

.service-provides-beno-travel .travel-inner-box-service-provide {
    position: relative;
    overflow: hidden;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide {
    position: relative;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide img {
    position: relative;
    width: 100%;
    height: 350px !important;
    object-fit: cover;
    display: block;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-caption-service-provide {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    color: #ffffff;
    display: block;
    font-size: 22px;
    padding: 20px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide {
    position: absolute;
    left: 0px;
    bottom: -353px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide h4 {
    position: relative;
    color: #ffffff;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide p {
    position: relative;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.6em;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide:hover .travel-caption-service-provide {
    bottom: -60px;
}

.service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide:hover .travel-overlay-box-service-provide {
    bottom: 0px;
}

.btn-div-travel-main {
    margin-top: 2rem;
}

.load-more-travel-main-btn {
    background: #CD4409;
    border: none !important;
    border-radius: 33px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 20px !important;
    text-decoration: none;
}

.load-more-travel-main-btn:hover {
    background: #fff;
    color: #CD4409;
    outline: 2px solid !important;
}


/* ---==========  all pages serive area section ======-  */
.travel-ind-capabilities-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.travel-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.travel-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.travel-ind-inner-area-customer-main:hover .travel-indus-serve-small-box {
    margin-left: 22rem;
}

.travel-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 20px;
    font-weight: 600;
}

.travel-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.travel-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.travel-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.travel-ind-inner-area-customer-main .travel-ind-serve-circle-main .travel-ind-serve-cir-inr {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.travel-ind-inner-area-customer-main .travel-ind-serve-circle-main .travel-ind-serve-cir-inr span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}


/* ------------- tourism-establishing section --------- */


.tourism-establishing-section-heading {
    font-size: 45px;
    font-weight: 700;
    line-height: normal;
}

.tourism-establishing-section-heading span {
    border-bottom: 3px solid #CD4409;

}

.inner-travel-tourism-establishing li {
    list-style: none;
    margin: 20px;
    font-size: 22px;
}

.inner-travel-tourism-establishing li a {
    color: #979797;
    font-size: 25px;
    font-weight: 400;
    line-height: normal;
    transition: 0.5s ease-in;
    text-decoration: none;
}

.inner-travel-tourism-establishing li a:hover {
    color: #CD4409;
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
}

.inner-travel-tourism-establishing li svg {
    font-size: 20px;
    margin-right: 10px;
    color: #CD4409;
    transition: 0.5s ease-in;
    display: none;
}

.inner-travel-tourism-establishing li:hover svg {
    display: inline-block;
}

.inner-travel-tourism-establishing-para {
    color: #262626;
    margin: -19px 5px 0 3rem;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
}

.ind-travel-sub-triangle-down {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 0px solid transparent;
    border-top: 42px solid #14212b;
    display: flex;
}

.ind-travel-we-think-img {
    width: 100%;
    height: 19rem;
    object-fit: cover;
}

/* =========
============ 
travel and tourism in BPO page start
========
=============== */
/* all heading background  */
.bpo-travel-about-head-bg::after {
    margin-left: -32.5rem !important;
    margin-top: 9px !important;
}

.dge-travel-about-head-bg::after {
    margin-left: -43.5rem !important;
    margin-top: 9px !important;
}

.dge-travel-opening-head-bg::after {
    margin-left: -894px;
}

/*----------- bpo travel question box------------- */
.bpo-travels-slip-box-questoin-ask {
    opacity: 0.9;
    background: #CD4409;
}

.bpo-travels-slip-box-questoin-ask ul li {
    list-style: none;
    display: inline-flex;
    padding: 25px;
}

.bpo-travels-slip-box-questoin-ask ul li p {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
}

.bpo-travels-slip-box-questoin-ask ul li a {
    border-radius: 33px;
    background: #00973a;
    border: 2px solid #00973a;
    padding: 8px 25px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.5s;
}

.bpo-travels-slip-box-questoin-ask ul li a:hover {
    background: #fff;
    color: #00973a;
}

/* ---------bpo travel at its best section--------- */
.bpo-travel-best {
    background-color: #FEF2EA;
    padding-top: 45px;
    margin-top: 4rem;
}

.bpo-travel-best-inner h2,
.bpo-travel-best-under h2 {
    color: #14212B;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
}

.bpo-travel-best-inner p {
    color: #fff;
    background-color: #CD4409;
    border-radius: 70px 0px 0px 0px;
    padding: 30px 10px 30px 30px;
    font-size: 16px;
}

/* ---------bpo travel back-front----- */

.bpo-travel-back-front {
    margin-top: 4rem;
}


.bpo-travel-best-under p {
    color: #14212B;
    padding: 0px 10px 0px 30px;
    font-size: 16px;
}

/* =================
===================
Travel and tourism servies in digital experience 
==========================
==============================*/

.travel-dge-banner-head,
.travel-dge-banner-para {
    color: #fff !important;
}

/* / -----homepage industry trends section pages--------- / */
.shaping-ai-contactus a {
    color: #fff !important;
}

.shaping-ai-contactus a:hover {
    color: #00973a !important;
}

.shapingai-heading h3,
.shapingai-heading p {
    color: #fff !important;
}

.shapingai-heading {
    width: 53% !important;
}

.remove-effect-head::after {
    content: unset !important;
}

@media only screen and (max-width: 480px) {

    /* padding top all sections */
    #about,
    #provides,
    #services,
    #our-blog {
        padding-top: 2rem;
    }

    /* ----------------- heading effect ------------ */
    .main-page-travel-about-eff::after {
        width: 110px !important;
        margin-left: -227px !important;
        margin-top: -41px !important;
    }

    .travel-provides-main-page-efft::after {
        width: 140px !important;
        margin-left: -150px !important;
        margin-top: -42px !important;
    }

    .travel-effortslessly-effect::after {
        width: 131px !important;
        margin-left: -213px !important;
        margin-top: -7px !important;
        z-index: -1;

    }

    .bpo-travel-about-head-bg::after {
        display: none;
    }


    .travel-section-heading br {
        display: block !important;
    }

    /* ---- all page banner css -------------- */
    .travel-ind-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 1rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .travel-ind-bet-content {
        width: 100% !important;
    }

    .travel-ind-bet-content h3 {
        text-align: center;
        padding: 10px 5px !important;
        font-size: 40px !important;
    }

    .travel-ind-bet-content p {
        font-size: 18px !important;
        line-height: 26px !important;
        padding: 10px 5px 0 5px !important;
        text-align: center;
    }

    .travel-ind-banner-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    .travel-serv-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 20px !important;
    }

    .travel-serv-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /*------------ travel and tourism establishing section--------- */
    .travel-tourism-establishing {
        margin: 2rem 0 2.5rem 0 !important;
    }

    .tourism-establishing-section-heading {
        font-size: 29px !important;
    }

    .inner-travel-tourism-establishing-para {
        margin: -25px 1px 0 2rem !important;
        line-height: 28px !important;
    }

    .inner-travel-tourism-establishing {
        margin: 0 !important;
        padding: 0 !important;
    }

    .inner-travel-tourism-establishing li {
        margin: 20px 5px 20px 5px !important;
    }

    .inner-travel-tourism-establishing li a {
        font-size: 20px !important;
    }

    /* --============== all travel pages headings and sub headings ===============- */
    .travel-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .travel-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* -------------- service sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    .travel-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .travel-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .travel-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .travel-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .travel-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    .travel-serv-index-nav ul li a {
        padding: 8px 12px !important;
    }

    /* ---------------our capabilities---------- */
    .travel-ind-capabilities-sec {
        background-image: none !important;

    }

    /* --------------- main page providers secton -------------- */
    .service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide p {
        line-height: 22px !important;
    }

    .service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide h4 {
        font-size: 22px !important;
    }

    .service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-overlay-box-service-provide {
        padding: 20px 8px !important;
    }

    .service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide .travel-caption-service-provide {
        font-size: 18px !important;
        padding: 15px 0px !important;
    }

    .service-provides-beno-travel .travel-inner-box-service-provide .travel-image-box-service-provide img {
        height: 100% !important;
    }





    /* ==============
  =================
  bpo travel page start 
  ===================
  ===================
  */
    /* question box start  */
    .bpo-travels-slip-box-questoin-ask ul {
        padding: 0 !important;
        margin: 0 !important;
    }

    .bpo-travels-slip-box-questoin-ask,
    .latest-blog-section {
        margin-top: 2rem !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li {
        padding: 10px 0 !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li p {
        font-size: 20px !important;
    }

    /* business process at its best section start */

    .bpo-travel-best-inner h2,
    .bpo-travel-best-under h2 {
        font-size: 25px !important;
        text-align: center;
    }

    .bpo-travel-best {
        margin-top: 2rem !important;
    }

    .bpo-travel-back-front {
        margin-top: 2rem !important;
    }

    .dge-travel-opening-head-bg::after {
        display: none;
    }

}

@media (max-width: 767px) and (min-width: 481px) {

    /* ----------------- heading effect ------------ */
    .main-page-travel-about-eff::after {
        width: 152px !important;
        margin-left: -302px !important;
        margin-top: -41px !important;
    }

    .dge-travel-opening-head-bg::after {
        margin-left: -382px !important;
        margin-top: -44px !important;
    }

    .bpo-travel-about-head-bg::after {
        margin-left: -28rem !important;
        margin-top: 3px !important;
        width: 140px !important;
    }

    .travel-provides-main-page-efft::after {
        width: 173px !important;
        margin-left: -410px !important;
        margin-top: 6px !important;

    }

    .travel-effortslessly-effect::after {
        width: 173px !important;
        margin-left: -274px !important;
        margin-top: 3px !important;
    }


    /* ---------------indexing section--------- */
    .travel-serv-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 10px !important;
    }

    /* ---- all page banner css -------------- */
    .travel-ind-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .travel-ind-bet-content {
        width: 100% !important;
    }

    .travel-ind-bet-content h3 {
        text-align: center;
        padding: 20px 10px !important;
        font-size: 37px !important;
    }

    .travel-ind-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 10px 0 10px !important;
    }

    .travel-ind-banner-btn {
        text-align: center !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .travel-section-heading {
        font-size: 39px !important;
        text-align: center;
    }

    .travel-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        text-align: center !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* -------------- service sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 2rem 0 2rem 0 !important;
    }

    #travel-ind-business-areas-main {
        width: 100% !important;
    }

    .travel-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .travel-ind-inner-area-customer-main p {
        margin-right: 15px !important;
        text-align: justify !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .travel-sub-img-about {
        padding-top: 2rem !important;
        height: 100% !important;
    }

    /* ==============
  =================
  bpo travel page start 
  ===================
  ===================
  */
    /* question box start  */

    .bpo-travels-slip-box-questoin-ask,
    .latest-blog-section {
        margin-top: 2rem !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li {
        padding: 10px 0 !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li p {
        font-size: 20px !important;
    }

    /* business process at its best section start */

    .bpo-travel-best-inner h2,
    .bpo-travel-best-under h2 {
        font-size: 25px !important;
        text-align: center;
    }


}

@media (max-width: 991px) and (min-width: 768px) {

    /* ----------all heading background-------- */
    .main-page-travel-about-eff::after {
        width: 159px !important;
        margin-left: -685px !important;
    }

    .dge-travel-opening-head-bg::after {
        margin-left: -275px !important;
        margin-top: -46px !important;
    }

    .travel-effortslessly-effect::after {
        width: 173px !important;
        margin-left: -315px !important;
    }

    .bpo-travel-about-head-bg::after {
        width: 167px !important;
        margin-left: -522px !important;
        margin-top: 10px !important;
    }

    /* ---- all page banner css -------------- */
    .travel-ind-banner {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .travel-ind-bet-content h3 {
        text-align: center;
        padding: 20px 25px !important;
    }

    .travel-ind-bet-content {
        width: 100% !important;
    }

    .travel-ind-bet-content p {
        font-size: 18px !important;
        line-height: 32px !important;
        padding: 10px 25px 0 25px !important;
    }

    .travel-ind-banner-btn {
        text-align: center !important;
    }

    .travel-ind-banner-btn a {
        margin: 0 2rem !important;
    }

    /* --------------travel indexing section------------ */
    .travel-serv-index-nav ul li a {
        font-size: 18px !important;
        padding: 5px 30px !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .travel-section-sub-heading {
        line-height: 26px !important;
        font-size: 16px !important;
    }

    /* -------------- service sectoin ---------- */
    .travel-ind-effortslessly-sec {
        margin: 1rem 0 2rem 0 !important;
    }

    div#sin {
        width: 50%;
    }

    .travel-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li {
        padding: 10px !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li a {
        padding: 8px 25px !important;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {

    /* -----------all heading background----------- */
    .main-page-travel-about-eff::after {
        width: 168px !important;
        margin-left: -687px !important;
    }

    .travel-effortslessly-effect::after {
        margin-left: -320px !important;
    }

    .travel-provides-main-page-efft::after {
        margin-left: -458px !important;
    }

    .bpo-travel-about-head-bg::after {
        margin-left: -32.4rem !important;
        margin-top: 9px !important;
        width: 165px !important;
    }

    /* ---- all page banner css -------------- */
    .travel-ind-banner {
        padding: 5rem 0 4rem 0 !important;
    }

    .travel-ind-banner-btn {
        margin: 1rem 0 !important;
        width: 100%;
    }

    .travel-ind-banner-btn a {
        /* display: block !important; */
        margin: 0rem 1rem !important;
        text-align: center;
    }

    .travel-ind-bet-content {
        width: 100% !important;
    }

    /* --------------- all travel pages heading and sub heading ---------- */
    .travel-section-sub-heading {
        line-height: 28px !important;
    }

    /* -------------- service sectoin ---------- */
    .travel-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* --------------- about section ------------- */
    .travel-about-sec {
        padding-top: 2rem !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li {
        padding: 10px !important;
    }

    .bpo-travels-slip-box-questoin-ask ul li a {
        padding: 8px 25px !important;
    }
}

@media (max-width: 1400px) and (min-width: 1201px) {

    /* all heading background css */
    .main-page-travel-about-eff::after {
        width: 159px !important;
        margin-left: -684px !important;
    }

    .travel-effortslessly-effect::after {
        margin-left: -316px !important;
    }

    .travel-provides-main-page-efft::after {
        margin-left: -457px !important;
    }

    /* ---- all page banner css -------------- */

    .travel-ind-bet-content h3 {
        padding: 25px !important;
    }


    /* -------------- service sectoin ---------- */
    .travel-ind-inner-area-customer-main {
        padding: 15px 5px 15px 15px !important;
    }

}


.tech-effortslessly-effect::after {
    width: 171px !important;
    margin-left: -317px !important;
}

.tech-provides-main-page-efft::after {
    width: 183px !important;
    margin-left: -458px !important;
}

.industries-cater-head-effect::after {
    width: 183px !important;
    margin-left: -426px !important;
}

/* -================ all comman page css =========- */
/* --------  comman page banner css ---------- */
.tech-indus-banner-main {
    /* margin-top: 10.5rem; */
    /* padding: 10rem 0 4rem 0; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
}

.tech-indus-banner-hr {
    background-position: 0% 0% !important;
}

.tech-indus-content h3 {
    color: #ffffff;
    font-size: 45px;
    font-weight: 700;
    width: 72%;
    border-top: 3px solid #CD4409;
    border-bottom: 3px solid #CD4409;
    border-left: 3px solid #CD4409;
    padding: 35px;
}

.tech-indus-content-tech-main h3 {
    width: 45% !important;
}

.tech-indus-content-hr h3 {
    width: 61% !important;

}

.tech-indus-content-tech-bpo h3 {
    width: 50% !important;
}

.tech-indus-banner-btn {
    margin: 3rem 0;
    text-align: end;
}

.tech-indus-banner-btn a {
    text-decoration: none;
    color: #fff;
    border-radius: 33px;
    font-size: 20px;
    padding: 8px 20px;
    background: #CD4409;
    font-weight: 600;
    transition: 0.5s;
    margin-left: 10px;
}

.tech-indus-banner-btn a:hover {
    background: #fff;
    color: #CD4409;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-tech-indus {
    background: #fff !important;
    color: #CD4409 !important;
    box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-tech-indus:hover {
    background: #CD4409 !important;
    color: #fff !important;
}

/*=========== industry technology all pages heading and sub heading css ===========*/
.technology-section-heading {
    color: #CD4409 !important;
    font-size: 44px;
    font-weight: 700;
    position: relative;
}

.technology-section-heading span {
    color: #14212b !important;
}

.technology-section-sub-heading {
    color: #14212b !important;
    font-size: 17px;
    line-height: 30px;
}

.technology-section-sub-heading span {
    color: #CD4409;
    font-weight: 600;
}

.technology-section-sub-heading b {
    font-weight: 600 !important;
}

/* ------------------ main page indexing ---------------- */
section.tech-indus-page-index {
    background: #CD4409 !important;
    transition: top 0.9s ease;
    top: 90px;
    width: 100%;
    z-index: 9;
}

.sticky-techno {
    position: fixed;
    top: 0;
}

.tech-indus-index-nav ul {
    margin-bottom: 0 !important;
    text-align: center;
}

.tech-indus-index-nav ul li {
    display: inline-flex;
}

.tech-indus-index-nav ul li a {
    color: #fff;
    font-size: 20px;
    padding: 5px 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.tech-indus-index-nav ul li:hover,
.tech-indus-index-nav ul li a:hover {
    background-color: #ffffff;
    color: #CD4409;
}

.tech-indus-index-item.active {
    background-color: white;
    color: #CD4409;
}

/* --------------- about section ------------- */
.technology-about-sec {
    padding-top: 4rem;
}

.technology-sub-img-about {
    height: 20rem;
    width: 100%;
    object-fit: cover;
}

.technology-sub-triangle-down-right {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 42px solid #14212b;
    float: right;
}

.technology-sub-img-about-down-box {
    width: 95.3%;
    height: max-content;
    padding: 12px 18px 2px 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #c0c0c0;
    float: left;
    margin-bottom: 0.8rem;
}

.technology-sub-img-about-down-box h6 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.technology-sub-img-about-down-box h6::after {
    content: "";
    display: block;
    position: relative;
    width: 10.5rem;
    border: 1.5px solid #CD4409;
    margin-top: 2px;
}

.technology-sub-img-about-down-box h4 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.technology-sub-img-about-down-box p {
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

/* -------- support provides section --------  */

.service-provides-beno-technology {
    position: relative;
    margin-bottom: 30px;
}

.service-provides-beno-technology .inner-box-serv-prov-technology {
    position: relative;
    overflow: hidden;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology {
    position: relative;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology img {
    position: relative;
    width: 100%;
    height: 350px !important;
    object-fit: cover;
    display: block;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .caption-serv-prov-technology {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    color: #ffffff;
    display: block;
    font-size: 22px;
    padding: 20px 0px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology {
    position: absolute;
    left: 0px;
    bottom: -400px;
    padding: 40px 35px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology h4 {
    position: relative;
    color: #ffffff;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology p {
    position: relative;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 1.6em;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology:hover .caption-serv-prov-technology {
    bottom: -60px;
}

.service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology:hover .overlay-box-serv-prov-technology {
    bottom: 0px;
}

/* ---==========  all technology pages serive area section ======-  */
.technology-ind-effortslessly-sec {
    background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.technology-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px;
    height: max-content;
    border: 2px solid #ebebeb;
    overflow: hidden;
    margin: 10px 0px;
    transition: 0.3s;
}

.technology-ind-inner-area-customer-main:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    border: 2px solid #CD4409;
}

.technology-ind-inner-area-customer-main:hover .technology-indus-serve-small-box {
    margin-left: 22rem;
}

.technology-ind-inner-area-customer-main h5 {
    margin-right: 20px;
    color: #14212b;
    font-size: 25px;
    font-weight: 600;
}

.technology-ind-inner-area-customer-main p {
    color: #616161 !important;
    margin-right: 20px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.technology-indus-serve-small-box {
    float: right;
    width: 40px;
    height: 40px;
    background: #CD4409;
}

.technology-indus-serve-small-box svg {
    padding: 10px;
    color: #fff;
    font-size: 20px;
    rotate: 51deg;
}

.technology-ind-inner-area-customer-main .technology-ind-serve-circle-main li {
    color: #696969 !important;
    font-size: 17px;
    line-height: 28px;
    margin-right: 10px;
}

.technology-ind-inner-area-customer-main .technology-ind-serve-circle-main li span a {
    text-decoration: none;
    color: #CD4409;
    font-style: normal;
    font-weight: 700;
}

/* ---------- Industries cater section ----------- */
.industries-cater-main-sec {
    background-image: url('../../../../public/assets/img/sape-down-bp.png');
    background-position: bottom;
    position: relative;
    z-index: -1;
    background-repeat: no-repeat;
}

.technology-main-indus-cater {
    width: 31% !important;
    border: 1px solid #b3b3b3af;
    margin: 10px;
    padding: 15px;
    border-left: 5px solid #CD4409;
}

.technology-main-indus-cater h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.technology-main-indus-cater p {
    color: #696969;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#bpo-approach {
    margin-top: 4rem !important;
}

.technology-hr-bpo-approach-detai-sec {
    background-image: linear-gradient(to right, #FFFFFF, #FEF2EA);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.technology-hr-bpo-approach-descrp-main h4 {
    color: #CD4409;
    font-size: 30px;
    font-weight: 600;
}

.technology-hr-section-heading {
    font-size: 40px;
    font-weight: 600;
    color: #000000;
}

@media only screen and (max-width: 480px) {

    /* --------------- heading effect --------------------- */
    .tech-effortslessly-effect::after {
        width: 130px !important;
        margin-left: -211px !important;
        margin-top: -3px;
    }

    .tech-indus-content-tech-bpo h3 {
        width: 100% !important;
    }

    .tech-indus-content-hr h3 {
        width: 100% !important;

    }

    .technology-section-heading::after {
        content: unset !important;
    }

    /* ---- all page banner css -------------- */
    .tech-indus-banner-main {
        margin-top: 0rem !important;
        padding: 3rem 0 1rem 0 !important;
        height: 100% !important;
    }

    .tech-indus-content h3 {
        width: 100%;
    }

    .tech-indus-content h3 {
        text-align: center;
        padding: 0 5px !important;
        font-size: 35px !important;
        width: 100% !important;
    }

    .tech-indus-banner-btn a {
        display: block !important;
        margin: 1rem !important;
        text-align: center !important;
        padding: 10px 20px !important;
    }

    /* --============== all technology pages headings and sub headings ===============- */

    .technology-section-heading {
        font-size: 30px !important;
        text-align: center;
        position: relative;
    }

    .technology-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    /* ---------------- page indexing css --------------- */
    .tech-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 5px 15px !important;
    }

    .tech-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- about section ------------- */
    .technology-about-sec {
        padding-top: 2rem !important;
    }

    .technology-sub-img-about-down-box {
        width: 91.3% !important;
    }

    .technology-sub-img-about-down-box h4 {
        font-size: 18px !important;
    }

    .technology-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    /* --------------- main page providers secton -------------- */

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology p {
        line-height: 22px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology h4 {
        font-size: 22px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology {
        padding: 20px 8px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .caption-serv-prov-technology {
        font-size: 18px !important;
        padding: 15px 0px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology img {
        height: 100% !important;
    }

    /* -------------- service sectoin ---------- */

    .technology-ind-inner-area-customer-main h5 {
        font-size: 25px !important;
        margin-right: 10px !important;
        text-align: center;
    }

    .technology-ind-inner-area-customer-main p {
        margin-right: 10px !important;
        text-align: center;
    }

    .technology-ind-inner-area-customer-main {
        padding: 20px 0px 0px 20px !important;
    }

    .technology-indus-serve-small-box {
        position: unset !important;
        float: right !important;
    }

    /* ---------- Industries cater section ----------- */
    .technology-main-indus-cater {
        width: 94% !important;
        height: max-content !important;
        padding: 7px !important;
    }

    #bpo-approach {
        margin-top: 2rem !important;
    }

    .technology-hr-bpo-approach-descrp-main {
        margin-top: 1rem !important;
    }

    .technology-hr-section-heading {
        font-size: 22px !important;
    }
}

@media (max-width: 767px) and (min-width: 481px) {

    /* ----------------------- heading efffect ---------------- */

    .technology-section-heading::after {
        width: 144px !important;
        margin-left: -485px !important;
        margin-top: 3px !important;
    }

    .tech-provides-main-page-efft::after {
        width: 183px !important;
        margin-left: -408px !important;
        margin-top: 4px !important;
    }

    .tech-effortslessly-effect::after {
        width: 131px !important;
        margin-left: -280px !important;
        margin-top: 4px !important;
    }

    .industries-cater-head-effect::after {
        width: 165px !important;
        margin-left: -378px !important;
    }

    /* ---- all page banner css -------------- */
    .tech-indus-banner-main {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
    }

    .tech-indus-content h3 {
        width: 100% !important;
    }

    .tech-indus-content h3 {
        text-align: center;
        padding: 0 10px !important;
        font-size: 40px !important;
    }

    .tech-indus-banner-btn {
        text-align: center !important;
    }

    /* ---------------- page indexing ------------------ */
    .tech-indus-index-nav ul li a {
        font-size: 17px !important;
        padding: 8px 20px !important;
    }

    .tech-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- about section ------------- */
    .technology-about-sec {
        padding-top: 2rem !important;
    }

    .technology-sub-img-about {
        padding-top: 2rem !important;
        height: 300px !important;
    }

    .technology-sub-img-about-down-box {
        width: 94.5% !important;
    }

    /* --------------- all technology pages heading and sub heading ---------- */
    .technology-section-heading {
        font-size: 40px !important;
        text-align: center;
    }

    .technology-section-sub-heading {
        font-size: 16px !important;
        line-height: 26px !important;
        padding: 0 !important;
        margin: 0 !important;
    }


    /* -------------- service sectoin ---------- */
    #technology-ind-business-areas-main {
        width: 100% !important;
    }

    .technology-ind-inner-area-customer-main h5 {
        margin-right: 15px !important;
        font-size: 23px !important;
        text-align: center;
    }

    .technology-ind-inner-area-customer-main p {
        margin-right: 15px !important;
    }

    /* ---------- Industries cater section ----------- */
    .technology-main-indus-cater {
        width: 46% !important;

    }

    .technology-main-indus-cater h2 {
        font-size: 20px !important;
    }

    .technology-main-indus-cater p {
        font-size: 16px !important;
    }

    #bpo-approach {
        margin-top: 2rem !important;
    }

    .technology-hr-bpo-approach-descrp-main {
        margin-top: 1rem !important;
    }

    .technology-hr-section-heading {
        font-size: 22px !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {

    /* ----------------------- heading efffect ---------------- */

    .technology-section-heading::after {
        width: 157px !important;
        margin-left: -545px !important;
        margin-top: 10px !important;
    }

    .tech-provides-main-page-efft::after {
        width: 169px !important;
        margin-left: -457px !important;
        margin-top: 10px !important;
    }

    .industries-cater-head-effect::after {
        width: 183px !important;
        margin-left: -426px !important;
    }

    .tech-effortslessly-effect::after {
        width: 131px !important;
        margin-left: -280px !important;
        margin-top: 4px !important;
    }

    /* ---- all page banner css -------------- */
    .tech-indus-banner-main {
        margin-top: 0rem !important;
        padding: 3rem 0 2rem 0 !important;
        height: 100% !important;
    }

    .tech-indus-content h3 {
        width: 100% !important;
    }

    .tech-indus-content h3 {
        text-align: center;
        padding: 0 25px !important;
    }

    .tech-indus-banner-btn {
        text-align: center !important;
    }

    .tech-indus-banner-btn a {
        margin: 0 2rem !important;
    }

    /* ----------------- page indexing --------------- */
    .tech-indus-index-nav ul li a {
        font-size: 18px !important;
        padding: 8px 20px !important;
    }

    .tech-indus-index-nav ul {
        margin-bottom: 0 !important;
        padding: 0 !important;
    }

    /* --------------- all technology pages heading and sub heading ---------- */
    .technology-section-sub-heading {
        line-height: 26px !important;
        font-size: 16px !important;
    }

    /* --------------- about section ------------- */
    .technology-about-sec {
        padding-top: 2rem !important;
    }

    .technology-sub-img-about-down-box {
        width: 92% !important;
    }

    /* ------------ providers main section ----------- */
    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology p {
        font-size: 15px !important;
        line-height: 22px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology .overlay-box-serv-prov-technology {
        padding: 20px 15px !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology img {
        height: 100% !important;
    }

    .service-provides-beno-technology .inner-box-serv-prov-technology .image-box-serv-prov-technology:hover .caption-serv-prov-technology {
        bottom: -104px !important;
    }

    /* -------------- service sectoin ---------- */
    div#technology-ind-business-areas-main {
        width: 50%;
    }

    .technology-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

    /* ---------- Industries cater section ----------- */
    .technology-main-indus-cater h2 {
        font-size: 20px !important;
    }

    .technology-main-indus-cater p {
        font-size: 16px !important;
    }

    .technology-main-indus-cater {
        width: 30% !important;
    }

    #bpo-approach {
        margin-top: 3rem !important;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {

    /* ---- all page banner css -------------- */
    .tech-indus-banner-main {
        padding: 5rem 0 4rem 0 !important;
    }

    .tech-indus-banner-btn {
        margin: 1rem 0 !important;
    }

    /* --------------- all technology pages heading and sub heading ---------- */
    .technology-section-sub-heading {
        line-height: 28px !important;
    }

    /* --------------- about section ------------- */
    .technology-about-sec {
        padding-top: 2rem !important;
    }

    .technology-sub-img-about-down-box {
        width: 94.3% !important;
    }

    /* -------------- service sectoin ---------- */
    .technology-ind-inner-area-customer-main h5 {
        font-size: 23px !important;
    }

}

@media (max-width: 1400px) and (min-width: 1201px) {

    /* ---- all page banner css -------------- */
    .tech-indus-banner-main {
        padding: 7rem 0 2rem 0 !important;
    }

    /* -------------- service sectoin ---------- */
    .technology-ind-inner-area-customer-main {
        padding: 15px 5px 15px 15px !important;
    }

}

.retail-indus-main-bet-content-retail-bpo p {
    color: #fff !important;
    padding: 0 !important;
}