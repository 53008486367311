
@font-face {
  font-family: Proxima Nova Regular;
  src: url('/src/assets/font/ProximaNovaRegular.ttf');
}
@font-face {
  font-family: 'proxima-nova-regular';
  src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),
      url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),
      url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
  font-weight:400;
  font-stretch:normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: proxima-nova-regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px !important;
}
body,
html {
overflow-x: hidden;
}
a{
  text-decoration: none;
}
@media only screen and (max-width: 991px) {
#gt_float_wrapper{
  top: unset !important;
  right: unset !important;
  bottom: 5px !important;
}
}