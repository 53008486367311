#cloud-about,
#cloud-industry,
#cloud-services,
#cloud-approach,
#cloud-what-we-think,
#cloud-devops-benefits,
.migration-cloud-service-sec {
  padding-top: 5rem;
}


#cloud-indus-head-effect::after {
  width: 207px !important;
  margin-left: -359px !important;
}

#deveops-cloud-about-effect span::after,
#managed-cloud-about-effect span::after,
#app-datamig-cloud-about-effect span::after {
  content: unset !important;
}

#cloud-service-head-effect::after {
  margin-left: -269px !important;
}

/* -------- pages  heading and sub heading css --------- */
.cloud-head-main-title {
  color: #14212b !important;
  font-size: 45px;
  font-weight: 700;
}

.cloud-head-main-title span {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
}

.cloud-head-main-para {
  color: #14212b !important;
  font-size: 17px;
  line-height: 28px;
  margin: 10px 0 1rem 0;
}

/* ------------- banner css ------------- */
.cloud-banner-main {
  background-blend-mode: overlay;
  width: 100%;
  height: 450px;
  max-height: 450px;
  /* margin-top: 10.5rem; */
  background-color: #727272;
  background-size: cover;
  /* padding: 4rem 0; */
  transition: all 3s;
  display: flex;
  align-items: center;
}

.cloud-banner-main:hover {
  transform: scale(1.03);
}

.cloud-banner-heading {
  font-size: 60px;
  text-transform: none;
  font-weight: 800;
  color: #14212b;
  line-height: 1.1;
}

.cloud-banner-heading-app-data {
  font-size: 55px !important;
}

.cloud-banner-para {
  color: #14212b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
  margin-top: 1.5rem;
}

#play-button-cloud {
  display: flex;
  align-items: center;
}

div#play-button-cloud-banner {
  position: relative;
}

.cloud-inner-banner-bg-btn {
  margin-top: 3.5rem;
}



.cloud-inner-banner-bg-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-right: 26px;
}

.cloud-inner-banner-bg-btn-num {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.cloud-inner-banner-bg-btn-num:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.cloud-inner-banner-bg-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.cloud-it-bnr-btn-main {
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  margin-top: 50%;
  margin-left: 50% !important;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.cloud-it-bnr-btn-main:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  animation: pulse-border-heppens 1500ms ease-out infinite;
}

.cloud-it-bnr-btn-main:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #CD4409;
  border-radius: 50%;
  transition: all 200ms;
}

.cloud-it-bnr-btn-main span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.cloud-banner-know-how a {
  background: #CD4409;
  padding: 9px 30px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  border-radius: 25px;
  color: #fff;
}

.cloud-banner-know-how a svg {
  padding: 4px 5px 0 5px;
}

.cloud-banner-know-how a:hover {
  color: #CD4409;
  background-color: #fff;
  border: 2px solid #CD4409;
}

div#play-button-cloud-banner {
  position: relative;
}

/* --------- page index css ----------------- */
section.nag-page-index-cloud {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 81px;
  width: 100%;
  z-index: 99;
}

section.nag-page-index-cloud.sticky-cloud {
  position: fixed;
  top: 90px !important;
}

.nav-link-index-cloud.active {
  background-color: white;
  color: #CD4409;
}

.navbar-index-cloud ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-index-cloud ul li {
  display: inline-flex;
}

.navbar-index-cloud ul li:hover,
.navbar-index-cloud ul li a:hover {
  background-color: #ffffff;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-index-cloud ul li a {
  color: #fff;
  font-size: 18px;
  padding: 7px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

/* --------- about section  ---------------------- */
.cloud-about-btn-bln {
  margin-top: 2rem;
}

.cloud-about-btn-bln a {
  background: #CD4409;
  padding: 10px 25px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  border-radius: 33px;
  color: #fff;
}

.cloud-about-btn-bln a:hover {
  color: #CD4409;
  background-color: #fff;
  outline: auto;
}

#cloud-about-dev-img {
  margin-top: 1rem;
}

.cloud-about-introimg img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.cloud-about-triangle-down::after {
  width: 12% !important;
}

.cloud-about-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  display: flex;
}

.cloud-about-img-desc-txt {
  width: 95.5%;
  height: max-content;
  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  margin-top: -2.5rem;
  float: right;
}

.cloud-about-img-desc-txt h6 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.cloud-about-img-desc-txt h6::after {
  content: "";
  display: block;
  position: relative;
  width: 5.8rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.cloud-about-img-desc-txt h4 {
  color: #000;
  font-size: 21px;
  font-weight: 700;
}

.cloud-about-img-desc-txt p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* ------------ industries sction -------------- */
.cloud-indus-item-row {
  margin-top: 2rem;
}

.cloud-indus-item-box {
  margin-bottom: 2rem;
}

.cloud-indus-item-box.hidden-segments,
.hidden-wedo_box {
  display: none;
}

.cloud-indus-item-box.visible-segments,
.visible-wedo_box {
  display: block;
}

.cloud-indus-item-card {
  border-radius: 20px 20px 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  border: unset !important;
  height: 350px;
  text-align: center;
}

.cloud-indus-item-card figure {
  margin: 0.1em;
  width: 100%;
  height: 348px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
}

figure.cloud-indus-item-effect-steve img {
  opacity: 1;
  transition: transform 0.35s;
  transform: perspective(1000px) translate3d(0, 0, 0);
  width: 100% !important;
  height: 349px !important;
  object-fit: cover;
}

figure.cloud-indus-item-effect-steve h2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #212121;
  line-height: normal;
  text-transform: capitalize;
  word-spacing: -0.15em;
  background: #ffffff;
}

figure.cloud-indus-item-effect-steve p {
  padding: 0.5em;
  opacity: 0;
  font-size: 15px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #CD4409;
  line-height: 21px;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: left;
  padding: 10px 11px !important;
  margin: 0;
  height: 100% !important;
}

figure.cloud-indus-item-effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.cloud-indus-item-effect-steve:hover p {
  opacity: 1;
}

figure .cloud-indus-item-intro {
  position: absolute;
  bottom: -1px;
  width: 90%;
  height: 40%;
  margin-left: 5% !important;
}

.btn-div-manage-it-main {
  margin-top: 2rem;
}

.load-more-cloud-it-btn {
  border-radius: 33px;
  padding: 10px 30px !important;
  background: #CD4409;
  color: #fff;
  border: none !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.load-more-cloud-it-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ----------------- servcie section ------------- */
.cloud-service-crd-main {
  margin-bottom: 2rem;
  border: 2px solid #eedfd7;
  text-align: center;
  height: 550px;
}

.cloud-service-crd-main a {
  text-decoration: none !important;
}

.cloud-service-crd-img-main {
  overflow: hidden;
  margin: 5px;
}

.cloud-service-crd-img-main:hover img {
  transform: scale(1.1);
}

.cloud-service-crd-main:hover {
  border: 2px solid #CD4409;
}

.cloud-service-crd-main .card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.cloud-service-crd-main img {
  width: 100%;
  height: 220px;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  object-fit: cover;
  border-radius: 5px;
}

.cloud-service-crd-main h3 {
  color: #14212b;
  margin-top: 1rem;
  font-size: 20px;
  padding: 0 8px;
  font-weight: 600;
  line-height: normal;
  transition: 0.5s;

  &:hover {
    color: #CD4409;
  }
}

.cloud-service-crd-main p {
  color: #696969;
  text-align: center;
  padding: 0 8px !important;
  margin: 0 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cloud-service-small-box-down {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
  position: absolute;
  bottom: 0;
  right: 0;
}

.cloud-service-small-box-down svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

/* ---------------- approch section ------------- */
.cloud-approach-sec {
  background-image: url("../../../../public/assets/img/shape-12-1.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.cloud-main-approach-box {
  position: relative;
  margin: 10px;
  padding: 60px;
  width: 23.1%;
  border: 1px solid #d1d1d1;
  background: #fffbf9;
  align-items: center;
}

.cloud-approach-main-heading {
  color: #14212b;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-left: 4px solid #CD4409;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.cloud-approach-main-heading span {
  color: #CD4409;
}

#cloud-approach-row-main-content {
  margin: 2rem 0;
}

.cloud-main-approach-box h2 {
  color: #14212b;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.cloud-main-approach-box::before,
.cloud-main-approach-box::after {
  position: absolute;
  border-style: solid;
  border-color: #CD4409;
  border-width: 1px;
  height: 80px;
  width: 80px;
  content: "";
}

.cloud-main-approach-box::before {
  top: -6px;
  left: -6px;
  border-width: 5px 0 0 5px;
}

.cloud-main-approach-box::after {
  bottom: -6px;
  right: -6px;
  border-width: 0 5px 5px 0;
}

/* ---------- all sub pages Service Areas section  css ------------ */
.cloud-security-service-sec {
  margin-top: 3rem;
}

#cloud-service-area-cont {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px 10px 10px 10px;
  height: 15rem;
  position: relative;
  margin-top: 0.5rem;
}

#cloud-service-area-cont:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

#cloud-service-area-cont:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#cloud-service-area-cont-img img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

#cloud-service-area-cont-desc h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

/* ----------- what we think section -------------- */
.cloud-sub-under-img-right {
  width: 100%;
  padding: 11%;
  background-size: cover;
  height: 16rem;
}

.cloud-sub-under-learn {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.cloud-sub-triangle-down {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 0px solid transparent;
  border-top: 42px solid #14212b;
  float: left;
}

#cloud-service-area-cont-desc p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#cloud-stories-think {
  margin-top: 2rem;
}

.cloud-maximum-efficiency-first img {
  width: 100%;
  margin-bottom: 1rem;
  height: 12.5rem;
  object-fit: cover;
}

.cloud-maximum-efficiency-second h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.cloud-maximum-efficiency-second h6::after {
  position: absolute;
  content: "";
  margin-left: -6.2rem;
  margin-top: 1.2rem;
  width: 6.2rem;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.cloud-maximum-efficiency-second h5 {
  color: #000;
  font-size: 24px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cloud-maximum-efficiency-second p {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

/* -------- migration cloud section ------------- */
.migration-experts-cloud-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.migration-experts-cloud-content .migration-experts-cloud-content-first {
  border: 1px solid #D1D1D1;
  padding: 13px;
}

.migration-experts-cloud-content .migration-experts-cloud-content-first img {
  height: 100%;
  width: 100%;
}

.migration-experts-cloud-content h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #CD4409;
}

.migration-experts-cloud-content p {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
}


/* #cloud-stories-think {
  margin-top: 2rem;
} */

.cloud-maximum-efficiency-first img {
  width: 100%;
  margin-bottom: 1rem;
  height: 12.5rem;
  object-fit: cover;
}



.cloud-maximum-efficiency-first-img img {
  height: 320px !important;
  object-fit: cover;
}

.cloud-maximum-efficiency-second h6 {
  color: #000;
  font-size: 15px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.cloud-maximum-efficiency-second h6::after {
  position: absolute;
  content: "";
  margin-left: -6.2rem;
  margin-top: 1.2rem;
  width: 6.2rem;
  height: 0px;
  border-bottom: 2px solid #CD4409;
}

.cloud-maximum-efficiency-second h5 {
  color: #000;
  font-size: 24px;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cloud-maximum-efficiency-second h2 {
  color: #CD4409;
  font-weight: 600;
}

.cloud-maximum-efficiency-second p {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.cloud-head-main-title-span {
  margin-left: 10px;
}

.cloud-devops-main-content {
  width: 90%;
  margin-top: 1rem;
  margin-left: -.6rem;
}

.cloud-devops-main-content h4 {
  color: #CD4409;
  font-weight: 600;
  font-size: 30px;

}

.cloud-devops-main-content h4 img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 5px 10px;
}

.cloud-devops-main-content p {
  /* font-weight: 600; */
  font-size: 16px;
  margin-left: 2.3rem;

}



@media only screen and (max-width: 480px) {

  #cloud-about,
  #cloud-industry,
  #cloud-services,
  #cloud-approach,
  #cloud-what-we-think,
  #cloud-devops-benefits,
  .migration-cloud-service-sec {
    padding-top: 2rem !important;
  }

  .cloud-head-main-title span::after {
    content: unset !important;
  }

  #cloud-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
  }

  #cloud-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 4px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .cloud-head-main-title {
    font-size: 30px !important;
    position: relative;
  }

  .cloud-head-main-title span {
    font-size: 30px !important;
    display: inline;
    margin-left: 5px;
  }

  .cloud-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  /* ----------- banners css ------------ */
  .cloud-banner-main {
    margin-top: unset !important;
    height: unset !important;
    max-height: unset !important;
    padding: 3rem 0 !important;
  }

  .cloud-inner-banner-bg-btn a {
    display: block !important;
    margin: 1rem 2rem 0 2rem !important;
    text-align: center !important;
  }

  .cloud-inner-banner-bg-btn {
    margin-top: 2rem !important;
  }

  .cloud-banner-heading {
    font-size: 40px !important;
    text-align: center !important;
    position: relative;
  }

  /* -------play button-------- */
  .cloud-banner-para {
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    margin-top: 16px;
  }

  .cloud-it-bnr-btn-main {
    margin-top: 20% !important;
  }

  .cloud-know-how-btn {
    text-align: center;
  }

  /* --------- page index css ----------------- */
  .navbar-index-cloud ul li a {
    font-size: 16px !important;
    padding: 2px 10px 2px 10px !important;
  }

  ul.nav-index-cloud {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul.nav-index-cloud li {
    margin: 5px !important;
  }

  /* --------- about section  ---------------------- */
  .cloud-about-btn-bln {
    margin-top: unset !important;
    justify-content: center;
    display: flex;
  }

  #cloud-about-dev-img {
    margin-top: 2rem !important;
  }

  .cloud-about-img-desc-txt {
    width: 92% !important;
  }

  .cloud-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ------------ industries sction -------------- */
  .cloud-indus-item-box {
    margin-bottom: 1rem !important;
  }

  .cloud-indus-item-card {
    height: 300px !important;
  }

  figure.cloud-indus-item-effect-steve img {
    height: 100% !important;
  }

  figure.cloud-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 16px;
  }

  figure .cloud-indus-item-intro {
    height: 50%;
  }

  /* ----------------- servcie section ------------- */
  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem !important;
  }

  #cloud-service-area-cont {
    height: unset !important;
  }

  /* ---------------- approch section ------------- */
  .cloud-approach-main-heading {
    font-size: 30px !important;
    border-left: 2px solid #CD4409 !important;
    padding-left: 5px !important;
    margin-bottom: unset !important;
  }

  #cloud-approach-row-main-content {
    margin: 1rem 0 !important;
  }

  .cloud-main-approach-box {
    width: 94%;
  }

  /* ----------------- servcie section ------------- */
  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem !important;
  }

  .migration-experts-cloud-content-first {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .migration-experts-cloud-content-first img {
    height: 30% !important;
    width: 30% !important;

  }

  .migration-experts-cloud-content h5 {
    text-align: center;
  }

  .migration-experts-cloud-content p {
    text-align: center;
  }




  /* ----------- what we think section -------------- */
  .cloud-sub-under-img-right {
    margin-top: 1rem !important;
  }

  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-first-img img {
    height: 200px !important;
    object-fit: cover;
  }

  #cloud-stories-think {
    margin-top: 2rem !important;
  }

  .cloud-devops-main-content h4 {
    text-align: center;
    font-size: 21px;
  }

  .cloud-devops-main-content p {
    text-align: center;
  }

  .migration-experts-cloud-img img {
    height: 250px !important;
    object-fit: cover !important;
  }

  #app-datamig-cloud-about-effect {
    display: unset !important;
    text-align: center !important;
  }

  #deveops-cloud-about-effect {
    display: unset !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  #cloud-about,
  #cloud-industry,
  #cloud-services,
  #cloud-approach,
  #cloud-what-we-think,
  #cloud-devops-benefits,
  .migration-cloud-service-sec {
    padding-top: 2rem !important;
  }

  .cloud-head-main-title span::after {
    width: 119px !important;
    margin-left: -195px !important;
    margin-top: 4px !important;
    height: 29px !important;
  }

  #cloud-indus-head-effect::after {
    width: 140px !important;
    margin-left: -241px !important;
    margin-top: 4px !important;
    height: 30px !important;
  }

  #cloud-service-head-effect::after {
    margin-left: -185px !important;
    width: 125px !important;
    height: 30px !important;
    margin-top: 4px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .cloud-head-main-title {
    font-size: 30px !important;
    text-align: start !important;
    display: unset !important;
  }

  .cloud-head-main-title span {
    font-size: 30px !important;
    text-align: start !important;
  }

  .cloud-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
    text-align: start !important;
  }

  /* -----------  banners css ------------ */
  .cloud-banner-main {
    margin-top: 0px !important;
    height: 100% !important;
  }

  .cloud-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .cloud-banner-para {
    text-align: center;
  }

  div#play-button-cloud {
    margin: 2rem 0 0 0 !important;
  }

  #play-button-cloud-banner,
  .cloud-know-how-btn {
    width: 50% !important;
  }

  .cloud-it-bnr-btn-main {
    margin-top: 35% !important;
    margin-left: 50% !important;
  }

  .cloud-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-cloud-banner,
  .cloud-know-how-btn {
    width: 50% !important;
  }

  /* --------- page index css ----------------- */
  .nav-link-index-cloud {
    font-size: 27px;
    padding: 3px 13px 3px 13px;
  }

  .navbar-index-cloud ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .navbar-index-cloud ul {
    padding: 0 !important;
  }

  /* --------- about section  ---------------------- */
  .cloud-about-btn-bln {
    margin-top: unset !important;
    display: flex !important;
    justify-content: center !important;
  }

  .cloud-about-img-desc-txt {
    width: 95% !important;
  }

  #cloud-about-dev-img {
    margin-top: 2rem !important;
  }

  /* ------------ industries sction -------------- */
  .btn-div-manage-it-main {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  /* ----------------- servcie section ------------- */
  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem;
  }

  /* ------- apporch section ----------- */
  .cloud-approach-main-heading {
    font-size: 30px !important;
  }

  .cloud-main-approach-box {
    width: 45.8%;
  }

  .cloud-maximum-efficiency-first-img img {
    height: 303px !important;
  }

  /* ----------------- servcie section ------------- */
  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem;
  }

  #cloud-service-area-cont {
    height: unset !important;
  }


  .migration-experts-cloud-content-first {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem !important;
  }

  .migration-experts-cloud-content-first img {
    height: 7em !important;
    width: 8em !important;
    object-fit: contain;
  }

  .migration-experts-cloud-content h5 {
    text-align: center;
  }

  .migration-experts-cloud-content p {
    text-align: center;
  }

  /* ----------- what we think section -------------- */
  .cloud-sub-under-img-right {
    margin-top: 1rem !important;
  }

  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  #cloud-service-area-cont {
    height: unset !important;
  }

  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second p {
    text-align: start !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  #cloud-stories-think {
    margin-top: 2rem !important;
  }

  .cloud-devops-main-content h4 {
    text-align: center !important;
  }

  .migration-experts-cloud-img img {
    width: 100%;
    height: 270px;
    object-fit: cover;
  }

  #deveops-cloud-about-effect span {
    display: unset !important;
    margin-left: 5px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  #cloud-about,
  #cloud-industry,
  #cloud-services,
  #cloud-approach,
  #cloud-what-we-think,
  #cloud-devops-benefits,
  .migration-cloud-service-sec {
    padding-top: 2rem;
  }

  .cloud-head-main-title span::after {
    width: 143px !important;
    margin-left: -227px !important;
    margin-top: 6px !important;
    height: 32px !important;
  }

  #cloud-indus-head-effect::after {
    width: 165px !important;
    margin-left: -283px !important;
    margin-top: 7px !important;
    height: 30px !important;
  }

  #cloud-service-head-effect::after {
    margin-left: -212px !important;
    width: 138px !important;
    height: 30px !important;
    margin-top: 6px !important;
  }

  /* ---------- page heading and sub headings -------- */
  .cloud-head-main-title,
  .cloud-head-main-title span {
    font-size: 35px !important;
  }

  .cloud-head-main-para {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: unset !important;
  }

  /* ----------- banners css ------------ */
  .cloud-banner-main {
    margin-top: 0px !important;
    height: unset !important;
    max-height: unset;
  }

  .cloud-banner-para {
    text-align: center;
  }

  .cloud-inner-banner-bg-btn {
    margin-top: 2.5rem !important;
    text-align: center !important;
  }

  .cloud-banner-heading {
    text-align: center !important;
    font-size: 50px !important;
  }

  #play-button-cloud {
    margin-top: 2rem;
  }

  #play-button-cloud-banner,
  .cloud-know-how-btn {
    width: 50% !important;
  }

  .cloud-it-bnr-btn-main {
    margin-top: 25% !important;
    margin-left: 50% !important;
  }

  /* --------- page index css ----------------- */
  .navbar-index-cloud ul li a {
    font-size: 17px !important;
    padding: 8px 30px !important;
  }

  /* --------- about section ---------- */
  .cloud-about-img-desc-txt {
    width: 93% !important;
  }

  .cloud-about-img-desc-txt h4 {
    font-size: 20px !important;
  }

  /* ------------ industries sction -------------- */
  .cloud-indus-item-box {
    width: 50% !important;
  }

  figure.cloud-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  /* ----------------- servcie section ------------- */
  .cloud-services-div-main {
    width: 50% !important;
    display: unset !important;
  }

  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem;
  }

  /* -------------- apporch section -------------  */
  .cloud-main-approach-box {
    width: 46.2%;
  }

  /* ----------------- servcie section ------------- */
  .cyber-services-div-main {
    width: 50% !important;
    display: unset !important;
  }

  .cloud-service-crd-main {
    margin-bottom: 1rem !important;
  }

  .btn-div-cloud-it-main {
    margin-top: 0rem;
  }

  #cloud-service-area-cont-img {
    width: 100% !important;
  }

  .cloud-service-area-cont-desc {
    width: 100% !important;
  }

  #cloud-service-area-cont,
  #cloud-service-area-cont-desc {
    width: 100% !important;
  }

  #cloud-service-area-cont {
    height: unset !important;
  }

  #migration-experts-cloud-img-container {
    width: 100% !important;
  }

  .migration-experts-cloud-img img {
    width: 100% !important;
    height: 250px !important;
    padding: 0 35px;
    object-fit: cover;
  }


  .migration-cloud-data-intent {
    width: 100% !important;
  }

  .migration-experts-cloud-content .migration-experts-cloud-content-first {
    padding: 3px !important;
  }

  .migration-experts-cloud-content h5 {
    font-size: 18px;

  }

  .migration-experts-cloud-content p {
    font-size: 15px;
    margin-bottom: 25px;

  }

  /* ----------- what we think section -------------- */
  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h5 {
    font-size: 20px !important;
    margin-top: unset !important;
  }

  .cloud-maximum-efficiency-second h6 {
    margin-top: unset !important;
  }

  .cloud-devops-main-content h4 {
    text-align: center;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cloud-head-main-title span::after {
    margin-left: -290px !important;
    margin-top: 9px !important;
  }

  #cloud-indus-head-effect::after {
    margin-left: -320px !important;
    margin-top: 7px !important;
  }

  #cloud-service-head-effect::after {
    margin-left: -239px !important;
    width: 155px !important;
    margin-top: 5px !important;
  }

  /* ------------- pages heading and sub heading ------- */
  .cloud-head-main-title {
    font-size: 40px !important;
  }

  /* ----------- banners css ------------ */
  .cloud-banner-heading {
    font-size: 50px;
  }

  /* ------------ industries sction -------------- */
  figure.cloud-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }

  .cloud-main-approach-box {
    width: 22.8%;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {

  /* ------------ industries sction -------------- */
  figure.cloud-indus-item-effect-steve p {
    font-size: 14px !important;
    height: 100% !important;
    line-height: 18px !important;
  }
}