/* -================ all comman page css =========- */
/* --------  comman page banner css ---------- */
.bfsi-ind-banner {
  background-size: cover;
  background-position: center;
  height: 450px;
  display: flex;
  align-items: center;
}

.bfsi-ind-main-banner-head {
  color: #fff !important;
}

#bfsi-ind-main-banner {
  background-color: unset !important;
}

#bfsi-main-indus-content {
  width: 90% !important;
}

.bfsi-ind-bet-content h3 {
  color: #14212b;
  font-size: 50px;
  font-weight: 700;
  border-top: 3px solid #CD4409;
  border-bottom: 3px solid #CD4409;
  border-left: 3px solid #CD4409;
  padding: 35px;
}

.bfsi-ind-bet-content-cloud h3 {
  margin-left: 5rem !important;
  display: inline-block !important;
}

.bfsi-ind-bet-content-hr h3 {
  display: inline-block;
}

.bfsi-ind-bet-content p {
  color: #14212b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 0 35px 0 35px;
}

.bfsi-ind-bet-content-bfsi-hr p {
  color: #fff;

}

.bfsi-ind-banner-btn {
  margin: 3rem 0;
  text-align: end;
  padding: 6rem 0 0 0 !important;
}


.bfsi-ind-banner-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-left: 20px;
}

.bnr-nbtn-ind-bfsi {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.bnr-nbtn-ind-bfsi:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

.bfsi-ind-banner-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

/* ------------------ main page indexing ---------------- */
section.bfsi-serv-page-index {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 90px;
  width: 100%;
  z-index: 9;
}

.sticky-bfsi {
  position: fixed;
  top: 0;
}

.bfsi-serv-index-nav ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.bfsi-serv-index-nav ul li {
  display: inline-flex;
}

.bfsi-serv-index-nav ul li a {
  color: #fff;
  font-size: 20px;
  padding: 5px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.bfsi-serv-index-nav ul li:hover,
.bfsi-serv-index-nav ul li a:hover {
  background-color: #ffffff;
  color: #CD4409;
}

.bfsi-serv-ind-item.active {
  background-color: white;
  color: #CD4409;
}

/*=========== industry bfsi all pages heading and sub heading css ===========*/
.bfsi-section-heading {
  color: #CD4409 !important;
  font-size: 42px;
  font-weight: 700;
  position: relative;
}

.bfsi-section-heading-bpo {
  font-size: 40px !important;
}

.bfsi-section-heading span {
  color: #14212b !important;
}

.bfsi-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 30px;
}

.hidden-bfsi {
  display: none;
}

.visible-bfsi {
  display: block !important;
}

#about,
#provides,
#services,
#our-blog {
  padding-top: 4rem;
}

/* -------- support provides section --------  */
.visible-provide {
  display: block !important;
}

.hidden-provide {
  display: none;
}

.service-provides-beno-bfsi {
  position: relative;
  margin-bottom: 30px;
}

.service-provides-beno-bfsi .inner-box-service-provide {
  position: relative;
  overflow: hidden;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide {
  position: relative;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide img {
  position: relative;
  width: 100%;
  height: 350px !important;
  object-fit: cover;
  display: block;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .caption-service-provide {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  color: #ffffff;
  display: block;
  font-size: 22px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide {
  position: absolute;
  left: 0px;
  bottom: -400px;
  padding: 40px 35px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide p {
  position: relative;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
  line-height: 1.6em;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide:hover .caption-service-provide {
  bottom: -60px;
}

.service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide:hover .overlay-box-service-provide {
  bottom: 0px;
}

.btn-div-bfsi-main {
  margin-top: 2rem;
}

.load-more-bfsi-main-btn {
  background: #CD4409;
  border: none !important;
  border-radius: 33px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding: 8px 20px !important;
  text-decoration: none;
}

.load-more-bfsi-main-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ---==========  all pages serive area section ======-  */
.bfsi-ind-effortslessly-sec {
  background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bfsi-ind-inner-area-customer-main {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.bfsi-ind-inner-area-customer-main:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #CD4409;
}

.bfsi-ind-inner-area-customer-main:hover .bfsi-indus-serve-small-box {
  margin-left: 22rem;
}

.bfsi-ind-inner-area-customer-main h5 {
  margin-right: 20px;
  color: #14212b;
  font-size: 25px;
  font-weight: 600;
}

.bfsi-ind-inner-area-customer-main p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.bfsi-indus-serve-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.bfsi-indus-serve-small-box svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.bfsi-ind-inner-area-customer-main .bfsi-ind-serve-circle-main .bfsi-ind-serve-cir-inr {
  color: #696969 !important;
  font-size: 17px;
  line-height: 28px;
  margin-right: 10px;
}

.bfsi-ind-inner-area-customer-main .bfsi-ind-serve-circle-main .bfsi-ind-serve-cir-inr span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

/* --------------- about section ------------- */
.bfsi-about-sec {
  padding-top: 4rem;
}

#bfsi-read-btn-about {
  border: none !important;
  color: #CD4409;
  font-size: 18px;
  font-weight: 600;
  background: unset !important;
}

.bfsi-sub-img-about {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.bfsi-sub-triangle-down-right {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-top: 42px solid #14212b;
  float: right;
}

/* -------- servicing experienc section ----------------- */
.bfsi-servicing-experienc {
  margin-top: 4rem;
  padding: 4rem 0;
  opacity: 0.9;
  background: #fef2ea;
}

.bfsi-serve-exp-desc-right {
  margin: 0 2rem;
  border-radius: 0px 90px 0px 0px;
  background: #CD4409;
  padding: 30px 40px 30px 20px;
}

.bfsi-serve-exp-desc-right-unique p {
  color: #fff !important;
}

.bfsi-serve-exp-desc-right-unique>p {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 20px !important;

}

.challenges-left-arrow-unique p {
  margin: 0 !important;
}

.challenges-left-arrow-unique h3 {
  color: #fff !important;
  font-size: 24px !important;
  border-bottom: 2px solid #fff;
  display: inline-block;
  font-weight: 600;
}

.bfsi-serve-exp-div-left h3 {
  color: #14212b;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bfsi-serve-exp-desc-right p {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  line-height: 30px;
}

/* -------- bfsi-service-specification section ----------------- */
.bfsi-service-speci {
  padding: 4rem 2rem 2rem 2rem;
}

.bfsi-serv-speci-icondata {
  padding: 0 4rem 0 8rem;
}

.bfsi-serv-speci-icondata img {
  object-fit: cover;
  padding: 10px;
  width: 80px;
  height: 80.001px;
}

.bfsi-serv-speci-icondata p {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  padding: 10px 0 1rem 1rem;
  font-weight: bold;
  line-height: normal;
  border-top: 2px solid #CD4409;
}

.slip-box-questoin-ask {
  opacity: 0.9;
  background: #CD4409;
}

.slip-box-questoin-ask ul li {
  list-style: none;
  display: inline-flex;
  padding: 10px 50px;
}

.slip-box-questoin-ask ul li p {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
}

.slip-box-questoin-ask ul li a {
  border-radius: 33px;
  background: #00973a;
  padding: 8px 25px;
  color: #fff;
  font-size: 17px;
  font-style: normal;
  line-height: normal;
  transition: 0.5s;
}

.slip-box-questoin-ask ul li a:hover {
  background: #fff;
  color: #00973a;
}

.latest-blog-section {
  margin-top: 3rem !important;
}

/* -=========================== managed it support page css ================== */
#mangdit-bfsi-banner {
  padding-bottom: 6rem !important;
  background-color: unset !important;
}

.mangdit-bfsi-banner-head,
.mangdit-bfsi-banner-desc {
  color: #fff !important;
}

#mangdit-bfsi-banner-btn-inr {
  margin: 6rem 0 0 0 !important;
}

/* ------------- service secton ----------------- */
.bfsi-service-sec {
  padding-top: 4rem;
}

.service-bfsi-main {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding: 10px;
  height: 15rem;
  position: relative;
  margin-top: 1rem !important;
}

.service-bfsi-main-bfsi-hr {
  height: 17rem !important;
}

.service-bfsi-main-img-dep img {
  width: 100%;
  height: 13.7rem;
  object-fit: cover;
}

.service-bfsi-main-img-dep-bfsi-hr img {
  height: 15.7rem;

}

.service-bfsi-main-content-dep-bfsi-hr img {
  height: 15.7rem !important;
}

.service-bfsi-main-content-dep h4 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  margin-top: 17px;
  font-weight: 600;
  line-height: normal;
}

.service-bfsi-main-content-dep p {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.service-bfsi-main:hover::before {
  position: absolute;
  content: "";
  width: 7rem;
  height: 8px;
  transition: 0.7s;
  left: -1px;
  top: -8px;
  background-color: #CD4409;
}

.service-bfsi-main:hover::after {
  position: absolute;
  content: "";
  width: 8px;
  transition: 0.7s;
  height: 7rem;
  left: -9px;
  top: -8px;
  background-color: #CD4409;
}

#flip-box-connnect-bfsi {
  margin-top: 4rem;
}

/* -=========================== cyber security page css ================== */
.bfsi-cyber-sec {
  padding-top: 4rem;
}

.bfsi-section-security-title {
  color: #14212B;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* -=========================== cloud services page css ================== */
/* -------- cloud Highlights section ----------------- */
.bfsi-could-highlights-sec {
  padding-top: 4rem;
}

.bfsi-could-highlights-heading {
  color: #262626;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.bfsi-could-highlights-content {
  margin-top: 2rem;
  border-left: 4px solid #CD4409;
  padding: 2rem 0 2rem 3rem;
}

.bfsi-could-highlights-content ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

.bfsi-could-highlights-content ul li::before {
  content: "\2022";
  color: #CD4409;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 27px;
  margin-left: -1em;
}

.bfsi-could-highlights-content ul li {
  color: #262626;
  font-size: 17px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

/* -=========================== Hr Talent services page css ================== */
/* -------- EXCEPTIONAL SERVICES section  ----------------- */
.bfsi-exceptional-service-sec {
  padding-top: 4rem;
  background-image: url(../../../../public/assets/img/shape-12-1.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

.pre-head-bfsi-exceptional-service {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.head-bfsi-exceptional-service {
  color: #000;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  border-left: 4px solid #CD4409;
  padding-left: 2rem;
}

.head-bfsi-exceptional-service span {
  color: #CD4409;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
}

#bfsi-exceptional-service-imglef img {
  height: 350px;
  object-fit: cover;
  width: 100%;
  padding: 0 3rem;
}

#bfsi-exceptional-service-contrig p {
  color: #262626;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

#bfsi-exceptional-service-contrig p ul li {
  font-weight: 600 !important;
}

#bfsi-exceptional-service-contrig button {
  border-radius: 33px;
  background: #CD4409;
  padding: 8px 22px;
  font-size: 18px;
  font-weight: 500;
  transition: 0.5s;
  color: #fff;
  border: none;
}

#bfsi-exceptional-service-contrig button:hover {
  background-color: #fff;
  color: #CD4409;
  outline: auto;
}

.text-white {
  color: #FFF !important;
}

@media only screen and (max-width: 480px) {

  #mangdit-bfsi-banner-btn-inr {
    margin: 1rem 0 !important;
  }

  .bfsi-ind-bet-content-cloud h3 {
    margin-left: unset !important;
  }

  /* ----------------- heading effect ------------ */
  .bfsi-section-heading::after {
    width: 109px !important;
    margin-left: -151px !important;
    margin-top: -6px !important;
  }

  #bfsi-inr-page-head-eff::after {
    width: 117px !important;
    margin-left: -213px !important;
    margin-top: -40px !important;
  }

  .provides-main-page-efft::after,
  .effortslessly-effect::after {
    content: unset !important;
  }

  #flip-box-connnect-bfsi {
    margin-top: 2rem !important;
  }

  #magdit-bfsi-service-efft::after {
    width: 171px !important;
    margin-left: -255px !important;
    margin-top: -40px !important;
  }

  .bfsi-service-sec {
    padding-top: 2rem;
  }

  #magdit-bfsi-about-head::after {
    content: unset !important;
  }

  #security-bfsi-about-head::after {
    width: 125px !important;
    margin-left: -295px !important;
  }

  #cloud-bfsi-about-head::after {
    content: unset !important;
  }

  #talent-bfsi-about-head::after {
    width: 125px !important;
    margin-left: -257px !important;
  }

  #about,
  #provides,
  #services,
  #our-blog {
    padding-top: 2rem !important;
  }

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    margin-top: 0rem !important;
    padding: 3rem 0 1rem 0 !important;
    height: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  #bfsi-main-indus-content {
    width: 100% !important;
  }

  .bfsi-ind-bet-content h3 {
    text-align: center;
    padding: 0 5px !important;
    font-size: 40px !important;
  }

  #mangdit-bfsi-banner {
    padding-bottom: 0rem !important;
  }

  .bfsi-ind-bet-content p {
    font-size: 18px !important;
    line-height: 26px !important;
    padding: 10px 5px 0 5px !important;
  }

  .bfsi-ind-banner-btn a {
    display: block !important;
    margin: 1rem !important;
    text-align: center !important;
    padding: 10px 20px !important;
  }

  .bfsi-serv-index-nav ul li a {
    font-size: 17px !important;
    padding: 5px 20px !important;
  }

  .bfsi-serv-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- main page providers secton -------------- */
  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide p {
    line-height: 22px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide h4 {
    font-size: 22px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide {
    padding: 20px 8px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .caption-service-provide {
    font-size: 18px !important;
    padding: 15px 0px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide img {
    height: 100% !important;
  }

  /* --============== all bfsi pages headings and sub headings ===============- */

  .bfsi-section-heading {
    font-size: 30px !important;
    text-align: center;
    position: relative;
  }

  .bfsi-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* -------------- service sectoin ---------- */
  .bfsi-ind-effortslessly-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  .bfsi-ind-inner-area-customer-main h5 {
    font-size: 25px !important;
    margin-right: 10px !important;
    text-align: center;
  }

  .bfsi-ind-inner-area-customer-main p {
    margin-right: 10px !important;
    text-align: center;
  }

  .bfsi-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px !important;
  }

  .bfsi-indus-serve-small-box {
    position: unset !important;
    float: right !important;
  }

  /* --------------- about section ------------- */
  .bfsi-about-sec {
    padding-top: 2rem !important;
  }

  .bfsi-sub-img-about {
    padding-top: 2rem !important;
    height: 100% !important;
  }

  /* -------- servicing experienc section ----------------- */
  .bfsi-servicing-experienc {
    margin-top: 2rem !important;
    padding: 0rem 0 !important;
  }

  .bfsi-serve-exp-div-left h3 {
    font-size: 28px !important;
  }

  .bfsi-serve-exp-desc-right {
    margin: 1rem 0rem !important;
    padding: 20px 15px 15px 10px !important;
  }

  .bfsi-serve-exp-desc-right p {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  /* -------- bfsi-service-specification section ----------------- */
  .bfsi-service-speci {
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .bfsi-serv-speci-icondata {
    padding: 1rem 1rem 0 1rem !important;
  }

  .bfsi-service-speci {
    padding: 1rem 0rem 0rem 0rem;
  }

  .slip-box-questoin-ask ul {
    padding: 0 !important;
    margin: 0 !important;
  }

  .slip-box-questoin-ask,
  .latest-blog-section {
    margin-top: 2rem !important;
  }

  .slip-box-questoin-ask ul li {
    padding: 10px 0 !important;
  }

  .slip-box-questoin-ask ul li p {
    font-size: 20px !important;
  }

  /* --------- service section --------------- */
  .service-bfsi-main {
    height: unset !important;
  }

  /* ---------------- cyber page new sction -------------  */
  .bfsi-cyber-sec {
    padding-top: 2rem !important;
  }

  .bfsi-section-security-title {
    font-size: 26px !important;
  }

  /* ------------------- cloud service ------------------ */
  .bfsi-could-highlights-sec {
    padding-top: 2rem !important;
  }

  .bfsi-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  /* -------- hr talent new section ------------ */
  .bfsi-exceptional-service-sec {
    padding-top: 2rem !important;
  }

  .head-bfsi-exceptional-service,
  .head-bfsi-exceptional-service span {
    font-size: 30px !important;
    padding-left: 0.5rem !important;
  }

  #bfsi-exceptional-service-imglef img {
    height: 211px !important;
    padding: 0 0rem !important;
  }

  #bfsi-exceptional-service-contrig p {
    margin-top: 1rem !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .bfsi-ind-banner-btn {
    margin: unset !important;
    text-align: end;
    padding: 1rem 0 0 0 !important;
  }
}

@media (max-width: 767px) and (min-width: 481px) {

  /* ----------------- heading effect ------------ */
  #mangdit-bfsi-banner-btn-inr {
    margin: 1rem 0 !important;
  }

  .bfsi-ind-bet-content-cloud h3 {
    margin-left: unset !important;
  }

  .bfsi-section-heading::after {
    width: 145px !important;
    margin-left: -200px !important;
    margin-top: 4px !important;
  }

  #flip-box-connnect-bfsi {
    margin-top: 2rem !important;
  }

  #talent-bfsi-about-head::after {
    width: 123px !important;
    margin-left: -339px !important;
  }

  #bfsi-inr-page-head-eff::after {
    width: 157px !important;
    margin-left: -283px !important;
    margin-top: -43px !important;
  }

  .bfsi-service-sec {
    padding-top: 2rem;
  }

  .provides-main-page-efft::after {
    width: 183px !important;
    margin-left: -408px !important;
    margin-top: 4px !important;
  }

  .effortslessly-effect::after {
    content: unset !important;
  }

  #magdit-bfsi-about-head::after {
    width: 166px !important;
    margin-left: -492px !important;
    margin-top: 4px !important;
  }

  #magdit-bfsi-service-efft::after {
    width: 191px !important;
    margin-left: -339px !important;
    margin-top: -44px !important;
  }

  #security-bfsi-about-head::after {
    width: 125px !important;
    margin-left: -391px !important;
  }

  #cloud-bfsi-about-head::after {
    width: 124px !important;
    margin-left: -398px !important;
  }

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  #bfsi-main-indus-content {
    width: 100% !important;
  }

  #mangdit-bfsi-banner {
    padding-bottom: 1rem !important;
  }

  .bfsi-ind-bet-content h3 {
    text-align: center;
    padding: 0 10px !important;
    font-size: 40px !important;
  }

  .bfsi-ind-bet-content p {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 10px 10px 0 10px !important;
  }

  .bfsi-ind-banner-btn {
    text-align: center !important;
    padding: 0 !important;
  }

  /* -- page indexing ---- */
  .bfsi-serv-index-nav ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .bfsi-serv-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- all bfsi pages heading and sub heading ---------- */
  .bfsi-section-heading {
    font-size: 40px !important;
    text-align: center;
  }

  .bfsi-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    text-align: center !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .btn-div-bfsi-main {
    margin-top: 0rem !important;
  }

  /* -------------- service sectoin ---------- */
  #bfsi-ind-business-areas-main {
    width: 100% !important;
  }

  .bfsi-ind-inner-area-customer-main h5 {
    margin-right: 15px !important;
    font-size: 23px !important;
    text-align: center;
  }

  .bfsi-ind-inner-area-customer-main p {
    margin-right: 15px !important;
  }

  /* --------------- about section ------------- */
  .bfsi-about-sec {
    padding-top: 2rem !important;
  }

  .bfsi-sub-img-about {
    padding-top: 2rem !important;
    height: 100% !important;
  }

  /* -------- servicing experienc section ----------------- */
  .bfsi-servicing-experienc {
    margin-top: 2rem !important;
    padding: 0.5rem 10px !important;
  }

  .bfsi-serve-exp-desc-right {
    margin: 1rem 0rem !important;
  }

  /* -------- bfsi-service-specification section ----------------- */
  .bfsi-service-speci {
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .bfsi-serv-speci-icondata {
    padding: 0 3rem !important;
  }

  .bfsi-service-speci {
    padding: 1rem 0rem 0rem 0rem;
  }

  .slip-box-questoin-ask ul {
    padding: 0 !important;
    margin: 0 !important;
  }

  .slip-box-questoin-ask,
  .latest-blog-section {
    margin-top: 2rem !important;
  }

  .slip-box-questoin-ask ul li {
    padding: 10px 0 !important;
  }

  .slip-box-questoin-ask ul li p {
    font-size: 20px !important;
  }

  /* --------- service section --------------- */
  .service-bfsi-main {
    height: unset !important;
  }

  /* ---------------- cyber page new sction -------------  */
  .bfsi-cyber-sec {
    padding-top: 2rem !important;
  }

  .bfsi-section-security-title {
    font-size: 30px !important;
    text-align: center;
  }

  /* ------------------- cloud service ------------------ */
  .bfsi-could-highlights-sec {
    padding-top: 2rem !important;
  }

  .bfsi-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  /* --------- hr talent service ------------------------ */
  .bfsi-exceptional-service-sec {
    padding-top: 2rem !important;
  }

  .head-bfsi-exceptional-service,
  .head-bfsi-exceptional-service span {
    font-size: 35px;
  }

  #bfsi-exceptional-service-imglef img {
    padding: 0 0rem !important;
    height: 280px !important;
  }

  #bfsi-exceptional-service-contrig p {
    font-size: 16px !important;
    margin-top: 1rem !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  #mangdit-bfsi-banner-btn-inr {
    margin: 2rem 0 !important;
  }

  .bfsi-ind-bet-content-cloud h3 {
    margin-left: unset !important;
  }

  /* ----------- heading effect -------------- */
  #magdit-bfsi-service-efft::after {
    width: 191px !important;
    margin-left: -85px !important;
    margin-top: -42px !important;
  }

  #flip-box-connnect-bfsi {
    margin-top: 3rem !important;
  }

  .bfsi-service-sec {
    padding-top: 3rem;
  }

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  #mangdit-bfsi-banner {
    padding-bottom: 1rem !important;
  }

  #bfsi-main-indus-content {
    width: 100% !important;
  }

  .bfsi-ind-bet-content h3 {
    text-align: center;
    padding: 0 25px !important;
  }

  .bfsi-ind-bet-content p {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 10px 25px 0 25px !important;
  }

  .bfsi-ind-banner-btn {
    text-align: center !important;
  }

  .bfsi-ind-banner-btn a {
    margin: 0 2rem !important;
  }

  /* -- page indexing ---- */
  .bfsi-serv-index-nav ul li a {
    font-size: 18px !important;
    padding: 8px 20px !important;
  }

  .bfsi-serv-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- all bfsi pages heading and sub heading ---------- */
  .bfsi-section-sub-heading {
    line-height: 26px !important;
    font-size: 16px !important;
  }

  /* ------------ providers main section ----------- */
  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide p {
    font-size: 15px !important;
    line-height: 22px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide .overlay-box-service-provide {
    padding: 20px 15px !important;
  }

  .service-provides-beno-bfsi .inner-box-service-provide .image-box-service-provide img {
    height: 100% !important;
  }

  /* -------------- service sectoin ---------- */
  .btn-div-bfsi-main {
    margin-top: 0rem !important;
  }

  div#bfsi-ind-business-areas-main {
    width: 50%;
  }

  .bfsi-ind-inner-area-customer-main h5 {
    font-size: 23px !important;
  }

  /* --------------- about section ------------- */
  .bfsi-about-sec {
    padding-top: 3rem !important;
  }

  /* -------- servicing experienc section ----------------- */
  .bfsi-servicing-experienc {
    margin-top: 2rem !important;
    padding: 0.5rem 10px !important;
  }

  .bfsi-serve-exp-desc-right {
    margin: 1rem 0rem !important;
  }

  .bfsi-serve-exp-div-left h3 {
    font-size: 35px !important;
  }

  .bfsi-serve-exp-desc-right p {
    font-size: 16px !important;
  }

  .bfsi-serve-exp-desc-right {
    padding: 16px 25px 16px 12px !important;
  }

  /* -------- bfsi-service-specification section ----------------- */
  .bfsi-service-speci {
    padding: 3rem 0rem 0rem 0rem !important;
  }

  .bfsi-serv-speci-icondata {
    padding: 0 0rem 0 0rem !important;
  }

  .slip-box-questoin-ask ul li {
    padding: 10px !important;
  }

  .slip-box-questoin-ask ul li a {
    padding: 8px 25px !important;
  }

  /* --------- service section --------------- */
  .service-bfsi-main {
    height: unset !important;
  }

  .service-bfsi-main-img-dep,
  .service-bfsi-main-content-dep {
    width: 100% !important;
  }

  /* ---------------- cyber page new sction -------------  */
  .bfsi-cyber-sec {
    padding-top: 3rem !important;
  }

  /* ------------------- cloud service ------------------ */
  .bfsi-could-highlights-sec {
    padding-top: 3rem !important;
  }

  .bfsi-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  /* --------- hr talent service ------------------------ */
  .bfsi-exceptional-service-sec {
    padding-top: 3rem !important;
  }

  .head-bfsi-exceptional-service,
  .head-bfsi-exceptional-service span {
    font-size: 35px;
  }

  #bfsi-exceptional-service-imglef img {
    padding: 0 0rem !important;
  }

  #bfsi-exceptional-service-contrig p {
    font-size: 16px !important;
    margin-top: 1rem !important;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    padding: 5rem 0 4rem 0 !important;
  }

  .bfsi-ind-banner-btn {
    margin: 1rem 0 !important;
  }

  #mangdit-bfsi-banner-btn-inr {
    margin: 0rem 0 !important;
  }

  #bfsi-main-indus-content {
    width: 100% !important;
  }

  #bfsi-main-indus-btn {
    margin-top: 3rem !important;
  }

  #bfsi-main-indus-btn a {
    display: unset !important;
    margin: 0 1rem !important;
  }

  .bfsi-ind-banner-btn a {
    display: block !important;
    margin: 1rem 0 !important;
    text-align: center;
  }

  /* --------------- all bfsi pages heading and sub heading ---------- */
  .bfsi-section-sub-heading {
    line-height: 28px !important;
  }

  /* -------------- service sectoin ---------- */
  .bfsi-ind-inner-area-customer-main h5 {
    font-size: 23px !important;
  }

  /* --------------- about section ------------- */

  /* -------- servicing experienc section ----------------- */
  .bfsi-servicing-experienc {
    margin-top: 2rem !important;
    padding: 0.5rem 10px !important;
  }

  .bfsi-serve-exp-desc-right {
    margin: 1rem 0rem !important;
  }

  .bfsi-serve-exp-div-left h3 {
    font-size: 35px !important;
  }

  .bfsi-serve-exp-desc-right p {
    font-size: 16px !important;
  }

  .bfsi-serve-exp-desc-right {
    padding: 16px 25px 16px 12px !important;
  }

  /* -------- bfsi-service-specification section ----------------- */
  .bfsi-service-speci {
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .bfsi-serv-speci-icondata {
    padding: 0 0rem 0 0rem !important;
  }

  .slip-box-questoin-ask ul li {
    padding: 10px !important;
  }

  .slip-box-questoin-ask ul li a {
    padding: 8px 25px !important;
  }

  /* --------- service section --------------- */
  .service-bfsi-main {
    height: max-content !important;
  }

  /* ------------------- cloud service ------------------ */

  .bfsi-could-highlights-content {
    padding: 0rem 0 0rem 3rem !important;
  }

  /* --------- hr talent service ------------------------ */

}

@media (max-width: 1400px) and (min-width: 1201px) {

  /* ---- all page banner css -------------- */
  .bfsi-ind-banner {
    padding: 7rem 0 2rem 0 !important;
  }

  .bfsi-ind-bet-content h3 {
    padding: 25px !important;
  }

  .bfsi-ind-bet-content {
    width: 65% !important;
  }

  .bfsi-ind-banner-btn {
    margin: 4rem 0 !important;
  }

  /* -------------- service sectoin ---------- */
  .bfsi-ind-inner-area-customer-main {
    padding: 15px 0px 0px 15px !important;
  }

  /* --------------- about section ------------- */

  .bfsi-servicing-experienc {
    padding: 2rem 0 !important;
  }
}

.text-white p {
  color: #fff !important;
}






/* industry  banner */
/* industry  banner */
/* industry banner */
/* industry  banner */
/* industry  banner */
/* industry  banner */

.industy-banner {
  width: 100%;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 5s ease-out;
  display: flex;
  align-items: center;
  justify-content: space-around;


  &:hover {
    transform: scale(1.03);
  }
}

.industy-banner-content {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  height: 100%;
  padding-left: 4rem;

  >h1 {
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    border: 3px solid #CD4409;
    border-right: none;
    padding: 1rem 0 1rem 3rem;
  }

  >p {
    font-size: 18px;
  }
}

.industy-banner-button {
  display: flex;
  gap: 1rem;


  >a {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 25px;
    transition: all 0.6s ease-in-out;
    border: 2px solid #CD4409;


    &:first-of-type {
      background-color: #fff;
      color: #CD4409;

      &:hover {
        color: #fff;
        background-color: #CD4409;
      }
    }

    &:last-of-type {
      background-color: #CD4409;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #CD4409;
      }
    }
  }
}

.head-text-black {
  color: #14212b !important;
}

.para-text-black {
  color: #14212b !important;
}

@media screen and (max-width: 480px) {
  .industy-banner {
    height: 350px !important;
    margin-top: 0 !important;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .industy-banner-content {
    width: 100% !important;
    padding: 1rem !important;
    align-items: center;
    gap: 1rem !important;

    >h1 {
      font-size: 30px !important;
      text-align: center !important;
    }

    >p {
      font-size: 16px !important;
      text-align: center !important;
    }
  }

}


@media (min-width: 481px) and (max-width: 767px) {
  .industy-banner {
    height: 400px !important;
    margin-top: 0 !important;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .industy-banner-content {
    width: 100% !important;
    padding: 2rem !important;
    align-items: center;
    gap: 1.5rem !important;

    >h1 {
      font-size: 35px !important;
      text-align: center !important;
    }

    >p {
      font-size: 17px !important;
      text-align: center !important;
    }
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .industy-banner {
    margin-top: 0 !important;
    height: 450px !important;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .industy-banner-content {
    width: 100% !important;
    padding: 3rem !important;
    align-items: center;
    gap: 2rem !important;

    >h1 {
      font-size: 45px !important;
      text-align: center !important;
    }

    >p {
      font-size: 18px !important;
      text-align: center !important;
    }
  }

}

@media (min-width: 992px) and (max-width: 1024px) {
  .industy-banner {
    height: 80vh !important;
  }

  .industy-banner-content {
    width: 70% !important;
    padding: 3rem !important;
    gap: 2rem !important;

    >h1 {
      font-size: 45px !important;
    }

    >p {
      font-size: 18px !important;
    }
  }

  .industy-banner-button>a {
    padding: 10px 20px !important;
  }

}


@media (min-width: 1025px) and (max-width: 1200px) {
  .industy-banner {
    height: 85vh !important;
  }

  .industy-banner-content {
    width: 70% !important;
    padding: 3rem !important;
    gap: 2rem !important;

    >h1 {
      font-size: 55px !important;
    }

    >p {
      font-size: 20px !important;
    }
  }

}