/* -================ all comman page css =========- */
/* --------  comman page banner css ---------- */
.health-ind-banner-main {
  /* margin-top: 10.5rem; */
  /* padding: 10rem 0 4rem 0; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 450px;
  display: flex;
  align-items: center;
}

.health-ind-banner-main-unique {
  background-position: 0 10% !important;
}

.health-inner-page-banner {
  padding: 4rem 0 2rem 0 !important;
}

.health-indus-content {
  width: 72%;
}

.health-indus-content-main {
  width: 59% !important;
}

.health-indus-content h3 {
  color: #14212b;
  font-size: 45px;
  font-weight: 700;
  border-top: 3px solid #CD4409;
  border-bottom: 3px solid #CD4409;
  border-left: 3px solid #CD4409;
  padding: 25px;
}

.health-indus-content p {
  color: #14212b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 1rem 0;
}

.health-indus-banner-btn {
  margin: 3rem 0;
}

#health-inner-page-banner-btn {
  margin: 1rem 0 !important;
}

.health-indus-banner-btn a {
  text-decoration: none;
  color: #fff;
  border-radius: 33px;
  font-size: 20px;
  padding: 8px 20px;
  background: #CD4409;
  font-weight: 600;
  transition: 0.5s;
  margin-left: 10px;
}

.health-indus-banner-btn a:hover {
  background: #fff;
  color: #CD4409;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-health-indus {
  background: #fff !important;
  color: #CD4409 !important;
  box-shadow: 0px 0px 0px 2px #CD4409;
}

.nbtn-health-indus:hover {
  background: #CD4409 !important;
  color: #fff !important;
}

/*=========== industry bfsi all pages heading and sub heading css ===========*/
.health-section-heading {
  color: #CD4409 !important;
  font-size: 45px;
  font-weight: 700;
  position: relative;
}

.health-section-heading span {
  color: #14212b !important;
}

.health-section-sub-heading {
  color: #14212b !important;
  font-size: 17px;
  line-height: 30px;
}

.health-section-sub-heading ul {
  margin-top: 1rem;
}

.health-section-sub-heading ul li {
  font-weight: 600;
}

.health-about-hide-sub-heading {
  color: #14212b !important;
  font-size: 17px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
}

.health-section-sub-heading span {
  color: #CD4409;
  font-weight: 600;
}

.health-section-sub-heading b {
  font-weight: 600 !important;
}

/* ------------------ main page indexing ---------------- */
section.health-care-page-index {
  background: #CD4409 !important;
  transition: top 0.9s ease;
  top: 90px;
  width: 100%;
  z-index: 9;
}

.sticky-health {
  position: fixed;
  top: 0;
}

.health-care-index-nav ul {
  margin-bottom: 0 !important;
  text-align: center;
}

.health-care-index-nav ul li {
  display: inline-flex;
}

.health-care-index-nav ul li a {
  color: #fff;
  font-size: 20px;
  padding: 5px 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.health-care-index-nav ul li:hover,
.health-care-index-nav ul li a:hover {
  background-color: #ffffff;
  color: #CD4409;
}

.health-care-indus-item.active {
  background-color: white;
  color: #CD4409;
}

/* --------------- about section ------------- */
.health-about-sec {
  padding-top: 4rem;
}

#health-read-btn-about {
  border: none !important;
  color: #CD4409;
  font-size: 18px;
  font-weight: 600;
  background: unset !important;
}

.health-sub-img-about {
  height: 18rem;
  width: 100%;
  object-fit: cover;
}

.health-sub-triangle-down-right {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-top: 42px solid #14212b;
  float: right;
}

.health-sub-img-about-down-box {
  width: 95.3%;
  height: max-content;
  padding: 12px 18px 2px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #c0c0c0;
  float: left;
  margin-bottom: 0.8rem;
}

.health-sub-img-about-down-box h6 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.health-sub-img-about-down-box h6::after {
  content: "";
  display: block;
  position: relative;
  width: 8.7rem;
  border: 1.5px solid #CD4409;
  margin-top: 2px;
}

.health-sub-img-about-down-box h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.health-sub-img-about-down-box p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

/* -------- support provides section --------  */
.visible-provide-health {
  display: block !important;
}

.hidden-provide-health {
  display: none;
}

.service-provides-beno-health {
  position: relative;
  margin-bottom: 30px;
}

.service-provides-beno-health .inner-box-serv-prov-health {
  position: relative;
  overflow: hidden;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health {
  position: relative;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health img {
  position: relative;
  width: 100%;
  height: 350px !important;
  object-fit: cover;
  display: block;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .caption-serv-prov-health {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  color: #ffffff;
  display: block;
  font-size: 22px;
  padding: 20px 0px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health {
  position: absolute;
  left: 0px;
  bottom: -400px;
  padding: 40px 35px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health p {
  position: relative;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
  line-height: 1.6em;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health:hover .caption-serv-prov-health {
  bottom: -60px;
}

.service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health:hover .overlay-box-serv-prov-health {
  bottom: 0px;
}

.btn-div-health-main {
  margin-top: 2rem;
}

.load-more-health-main-btn {
  background: #CD4409;
  border: none !important;
  border-radius: 33px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  padding: 8px 20px !important;
  text-decoration: none;
}

.load-more-health-main-btn:hover {
  background: #fff;
  color: #CD4409;
  outline: 2px solid !important;
}

/* ---==========  all health pages serive area section ======-  */
.health-ind-effortslessly-sec {
  background-image: url("../../../../public/assets/img/BpoInd/back-effet.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.health-ind-inner-area-customer-main {
  padding: 20px 0px 0px 20px;
  height: max-content;
  border: 2px solid #ebebeb;
  overflow: hidden;
  margin: 10px 0px;
  transition: 0.3s;
}

.health-ind-inner-area-customer-main:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  border: 2px solid #CD4409;
}

.health-ind-inner-area-customer-main:hover .health-indus-serve-small-box {
  margin-left: 22rem;
}

.health-ind-inner-area-customer-main h5 {
  margin-right: 20px;
  color: #14212b;
  font-size: 25px;
  font-weight: 600;
}

.health-ind-inner-area-customer-main p {
  color: #616161 !important;
  margin-right: 20px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.health-indus-serve-small-box {
  float: right;
  width: 40px;
  height: 40px;
  background: #CD4409;
}

.health-indus-serve-small-box svg {
  padding: 10px;
  color: #fff;
  font-size: 20px;
  rotate: 51deg;
}

.health-ind-inner-area-customer-main .health-ind-serve-circle-main li {
  color: #696969 !important;
  font-size: 17px;
  line-height: 28px;
  margin-right: 10px;
}

.health-ind-inner-area-customer-main .health-ind-serve-circle-main li span a {
  text-decoration: none;
  color: #CD4409;
  font-style: normal;
  font-weight: 700;
}

/* ----------- Our Approach ----------- */
.health-bpo-approach-sec {
  background-image: url("../../../../public/assets/img/shape-12-1.png");
  background-repeat: no-repeat;
  background-position: center;
}

.health-bpo-approach-detai-sec {
  margin: 4rem 0 2rem 0;
  padding: 2rem 0;
  opacity: 0.9;
  background: #fef2ea;
}

.health-bpo-approach-descrp-main h4 {
  color: #CD4409;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* --------------- health automation --------------- */
.health-automation-appserv {
  margin-top: 3rem;
}

.health-automation-appserv img {
  height: 335px;
  width: 100%;
  object-fit: cover;
}

.health-automation-appserv p {
  color: #14212b;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 2rem;
}

.health-automation-appserv p span {
  color: #CD4409 !important;
}

.exceptional-serv-health-sec {
  margin: 4rem 0 3rem 0;
}

.exceptional-serv-health-sec h6 {
  color: #262626;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

#exceptional-serv-health-row {
  margin-top: 2rem;
}

#exceptional-serv-health-row img {
  width: 100%;
  height: 225px;
  padding: 0 2rem;
  object-fit: cover;
}

#exceptional-serv-health-head::after {
  content: unset !important;
}

#exceptional-serv-health-head {
  border-left: 4px solid #CD4409 !important;
  padding-left: 1rem !important;
}

/* -================= managed it support ================ */
.managed-health-inner-banner {
  background-blend-mode: overlay;
  background-color: #b9b9b9;
}

/* -------- specification section ----------------- */
.health-specification-managed-sec {
  opacity: 0.9;
  background: #fef2ea;
  margin-top: 3rem;
  padding: 2.5rem 0;
}

.health-specification-managed-imgdiv img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 50px;
  box-shadow: -8px -8px #CD4409;
}

.health-specification-managed-heading {
  color: #14212b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.health-managed-benefits-cover {
  margin: 3rem 0;
}

#health-benefits-cover-head::after {
  content: unset !important;
}

/* -=================== cyber security page ================== */
#health-specfic-cyber-main {
  color: #14212b;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* -=================== cloud page ================== */
.health-specification-cloud-sec {
  opacity: 0.9;
  background: #f3f3f3;
  padding: 2rem 0;
  margin: 3rem 0;
}

.health-cloud-main-specific {
  border-radius: 90px 0px 0px 0px;
  background: #001c32;
  padding: 2.5rem;
  margin-top: 1rem;
}

.health-specfic-cyber-main {
  text-align: right;
  margin-top: 1rem;
}

.health-cloud-main-specific-unique {
  border-radius: 0px 0px 90px 0px;

}

.health-cloud-main-specific p,
.health-talent-main-specific p {
  color: #FFF !important;
  margin: 0 !important;
}

/* ========================= hr talent page =================== */
.health-specification-talent-sec {
  opacity: 0.9;
  background: #FEF2EA;
  padding: 2rem 0;
  margin: 3rem 0;
}

.health-talent-main-specific {
  border-radius: 90px 0px 0px 0px;
  background: #CD4409;
  padding: 2.5rem;
  margin-top: 1rem;
}


.health-serve-exp-desc-right {
  margin: 0 2rem;
  background: #001c32;
  padding: 30px 40px 30px 20px;
}

.health-serve-exp-desc-right-unique p {
  color: #fff !important;
}

.health-serve-exp-desc-right-unique>p {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 20px !important;

}

.healt-serve-exp-desc-right p {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  line-height: 30px;
}


.health-left-li p {
  font-size: 16px;
  color: #979797;

}

.health-left-li>p {
  transition: all 0.3s ease-in;
  cursor: pointer;
  display: inline-block;
}

.health-left-li>p:hover {
  color: #CD4409;
}

@media only screen and (max-width: 480px) {

  /* --------------- heading effect --------------------- */
  .health-effortslessly-effect::after {
    width: 130px !important;
    margin-left: -211px !important;
    margin-top: -3px;
  }

  .health-section-heading::after {
    content: unset !important;
  }

  #bpo-health-care-about-head::after {
    content: '' !important;
    margin-left: -259px !important;
    margin-top: 5px;
    width: 124px;
    height: 30px;
  }

  /* ---- all page banner css -------------- */
  .health-ind-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 1rem 0 !important;
    height: 100% !important;
  }

  .health-indus-content h3 {
    width: 100%;
  }

  .health-indus-content h3 {
    text-align: center;
    padding: 0 5px !important;
    font-size: 35px !important;
  }

  .health-indus-content {
    width: 100% !important;
  }

  .health-indus-banner-btn a {
    display: block !important;
    margin: 1rem !important;
    text-align: center !important;
    padding: 10px 20px !important;
  }

  /* --============== all health pages headings and sub headings ===============- */

  .health-section-heading {
    font-size: 30px !important;
    text-align: center;
    position: relative;
  }

  .health-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /* ---------------- page indexing css --------------- */
  .health-care-index-nav ul li a {
    font-size: 17px !important;
    padding: 5px 20px !important;
  }

  .health-care-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- about section ------------- */
  .health-about-sec {
    padding-top: 2rem !important;
  }

  .health-sub-img-about-down-box {
    width: 91.3% !important;
  }

  .health-sub-img-about-down-box h4 {
    font-size: 18px !important;
  }

  .health-sub-img-about {
    padding-top: 2rem !important;
    height: 300px !important;
  }

  /* --------------- main page providers secton -------------- */

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health p {
    line-height: 22px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health h4 {
    font-size: 22px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health {
    padding: 20px 8px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .caption-serv-prov-health {
    font-size: 18px !important;
    padding: 15px 0px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health img {
    height: 100% !important;
  }

  /* -------------- service sectoin ---------- */
  .btn-div-health-main {
    margin-top: 0rem !important;
  }

  .health-ind-inner-area-customer-main h5 {
    font-size: 25px !important;
    margin-right: 10px !important;
    text-align: center;
  }

  .health-ind-inner-area-customer-main p {
    margin-right: 10px !important;
    text-align: center;
  }

  .health-ind-inner-area-customer-main {
    padding: 20px 0px 0px 20px !important;
  }

  .health-indus-serve-small-box {
    position: unset !important;
    float: right !important;
  }

  /* =============== bpo page =========== */
  .health-bpo-approach-detai-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  .health-bpo-approach-descrp-main {
    margin-top: 1rem !important;
  }

  /* =============== app service page =========== */
  .health-automation-appserv img {
    height: 250px !important;
  }

  .health-automation-appserv p {
    font-size: 30px !important;
    margin: 1rem 0.5rem !important;
    text-align: center !important;
  }

  .exceptional-serv-health-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  #exceptional-serv-health-head {
    border-left: 3px solid #f28242 !important;
    padding-left: 0.5rem !important;
    text-align: start !important;
  }

  #exceptional-serv-health-row {
    margin-top: 1rem !important;
  }

  #exceptional-serv-health-row img {
    padding: 0 0rem !important;
  }

  /* -================= managed it support page ===============- */
  .health-specification-managed-sec {
    margin-top: 2rem !important;
    padding: 2rem 0 !important;
  }

  .health-specification-managed-heading {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    margin-top: 1rem !important;
  }

  .health-managed-benefits-cover {
    margin: 2rem 0 !important;
  }

  /* -================ cyber security page ==================- */
  #health-specfic-cyber-main {
    font-size: 22px !important;
    margin-top: 1rem !important;
  }

  /* -================ cloud page =================- */
  .health-specification-cloud-sec,
  .health-specification-talent-sec {
    padding: 1rem 0 !important;
    margin: 2rem 0 0 0 !important;
  }

  .health-cloud-main-specific,
  .health-talent-main-specific {
    padding: 2.5rem 19px !important;
  }

  /* -=================== ht tanlent page ============= */

  #health-specfic-cyber-main {
    margin-top: 1rem !important;
    text-align: center;
  }
}

@media (max-width: 767px) and (min-width: 481px) {

  #about,
  #provides,
  #services,
  #our-blog {
    padding-top: 2rem !important;
  }

  /* ----------------------- heading efffect ---------------- */

  .health-section-heading::after {
    width: 144px !important;
    margin-left: -485px !important;
    margin-top: 3px !important;
  }

  .health-effortslessly-effect::after {
    width: 131px !important;
    margin-left: -280px !important;
    margin-top: 4px !important;
  }

  .provides-main-page-efft-health::after {
    width: 183px !important;
    margin-left: -408px !important;
    margin-top: 4px !important;
  }

  #bpo-health-care-about-head::after {
    margin-left: -342px !important;
  }

  #appserv-health-care-about-head::after {
    margin-left: -310px !important;
    margin-top: -42px !important;
    width: 179px !important;
  }

  #bpo-health-care-approach-head::after {
    content: unset !important;
  }

  #health-cyber-security-about-head::after {
    margin-left: -255px !important;
    margin-top: -46px !important;
    width: 162px !important;
  }

  #health-cloud-about-head::after {
    margin-left: -502px !important;
    width: 162px !important;
  }

  #health-talent-about-head::after {
    margin-left: -294px !important;
    margin-top: -43px !important;
  }

  #health-dgt-experience-about-head::after {
    margin-left: -288px !important;
    margin-top: -43px !important;
  }

  /* ---- all page banner css -------------- */
  .health-ind-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
  }

  .health-indus-content h3 {
    width: 100% !important;
  }

  .health-indus-content {
    width: 100% !important;
  }

  .health-indus-content h3 {
    text-align: center;
    padding: 0 10px !important;
    font-size: 40px !important;
  }

  .health-indus-banner-btn {
    text-align: center !important;
  }

  /* ---------------- page indexing ------------------ */
  .health-care-index-nav ul li a {
    font-size: 17px !important;
    padding: 8px 20px !important;
  }

  .health-care-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- about section ------------- */
  .health-about-sec {
    padding-top: 2rem !important;
  }

  .health-sub-img-about {
    padding-top: 2rem !important;
    height: 300px !important;
  }

  .health-sub-img-about-down-box {
    width: 94.5% !important;
  }

  /* --------------- all bfsi pages heading and sub heading ---------- */
  .health-section-heading {
    font-size: 40px !important;
    text-align: center;
  }

  .health-section-sub-heading {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .btn-div-health-main {
    margin-top: 0rem !important;
  }

  /* -------------- service sectoin ---------- */
  #health-ind-business-areas-main {
    width: 100% !important;
  }

  .health-ind-inner-area-customer-main h5 {
    margin-right: 15px !important;
    font-size: 23px !important;
    text-align: center;
  }

  .health-ind-inner-area-customer-main p {
    margin-right: 15px !important;
  }

  /* =============== bpo page =========== */
  .health-bpo-approach-detai-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  .health-bpo-approach-descrp-main {
    margin-top: 1rem !important;
  }

  /* =============== app service page =========== */
  .health-automation-appserv img {
    height: 270px !important;
  }

  .health-automation-appserv p {
    font-size: 35px !important;
    margin: 1rem 0.5rem !important;
    text-align: center !important;
  }

  .exceptional-serv-health-sec {
    margin: 2rem 0 2rem 0 !important;
  }

  #exceptional-serv-health-head {
    border-left: 3px solid #f28242 !important;
    padding-left: 1rem !important;
    text-align: start !important;
  }

  #exceptional-serv-health-row {
    margin-top: 1rem !important;
  }

  #exceptional-serv-health-row img {
    padding: 0 0rem !important;
  }

  /* -================= managed it support page ===============- */
  .health-specification-managed-sec {
    margin-top: 2rem !important;
    padding: 2rem 0 !important;
  }

  .health-specification-managed-heading {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: center !important;
    margin-top: 1rem !important;
  }

  .health-managed-benefits-cover {
    margin: 2rem 0 !important;
  }

  /* -================ cyber security page ==================- */
  #health-specfic-cyber-main {
    font-size: 25px !important;
    margin-top: 1rem !important;
  }

  /* -================ cloud page =================- */
  .health-specification-cloud-sec,
  .health-specification-talent-sec {
    padding: 1rem 0 !important;
    margin: 2rem 0 0 0 !important;
  }

  .health-cloud-main-specific,
  .health-talent-main-specific {
    padding: 2.5rem 25px !important;
  }

  /* -=================== ht tanlent page ============= */

  #health-specfic-cyber-main {
    margin-top: 0rem !important;
    text-align: center;
  }
}

@media (max-width: 991px) and (min-width: 768px) {

  #about,
  #provides,
  #services,
  #our-blog,
  #overview {
    padding-top: 2rem;
  }

  /* ----------------------- heading efffect ---------------- */

  .health-section-heading::after {
    width: 166px !important;
    margin-left: -549px !important;
    margin-top: 10px !important;
  }

  .provides-main-page-efft::after {
    width: 183px !important;
    margin-left: -408px !important;
    margin-top: 4px !important;
  }

  .provides-main-page-efft-health::after {
    width: 173px !important;
    margin-left: -459px !important;
    margin-top: 9px !important;
  }

  .health-effortslessly-effect::after {
    width: 144px !important;
    margin-left: -318px !important;
    margin-top: 8px !important;
  }

  /* ---- all page banner css -------------- */
  .health-ind-banner-main {
    margin-top: 0rem !important;
    padding: 3rem 0 2rem 0 !important;
    height: 100% !important;
  }

  .health-indus-content h3 {
    width: 100% !important;
  }

  .health-indus-content {
    width: 90% !important;
  }

  .health-indus-content h3 {
    padding: 0 25px !important;
  }

  .health-indus-banner-btn {
    text-align: center !important;
  }

  .health-indus-banner-btn a {
    margin: 0 2rem !important;
  }

  /* ----------------- page indexing --------------- */
  .health-care-index-nav ul li a {
    font-size: 18px !important;
    padding: 8px 20px !important;
  }

  .health-care-index-nav ul {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  /* --------------- all health pages heading and sub heading ---------- */
  .health-section-sub-heading {
    line-height: 26px !important;
    font-size: 16px !important;
  }

  /* --------------- about section ------------- */
  .health-about-sec {
    padding-top: 2rem !important;
  }

  .health-sub-img-about-down-box {
    width: 92% !important;
  }

  /* ------------ providers main section ----------- */
  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health p {
    font-size: 15px !important;
    line-height: 22px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health .overlay-box-serv-prov-health {
    padding: 20px 15px !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health img {
    height: 100% !important;
  }

  .service-provides-beno-health .inner-box-serv-prov-health .image-box-serv-prov-health:hover .caption-serv-prov-health {
    bottom: -104px !important;
  }

  .btn-div-health-main {
    margin-top: 0rem !important;
  }

  /* -------------- service sectoin ---------- */
  .btn-div-bfsi-main {
    margin-top: 0rem !important;
  }

  div#health-ind-business-areas-main {
    width: 50%;
  }

  .health-ind-inner-area-customer-main h5 {
    font-size: 23px !important;
  }

  /* =============== applications service page ============== */
  .health-automation-appserv img {
    height: 280px !important;
  }

  .health-automation-appserv p {
    font-size: 40px !important;
    margin: 0.5rem !important;
  }

  #exceptional-serv-health-row img {
    height: 260px !important;
    padding: 0 0rem !important;
  }

  /* -================= managed it support page ===============- */
  .health-specification-managed-sec {
    margin-top: 2rem !important;
    padding: 2rem 0 !important;
  }

  .health-specification-managed-heading {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .health-managed-benefits-cover {
    margin: 2rem 0 !important;
  }

  /* -================ cyber security page ==================- */
  #health-specfic-cyber-main {
    font-size: 24px !important;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {

  /* ---- all page banner css -------------- */
  .health-ind-banner-main {
    padding: 5rem 0 4rem 0 !important;
  }

  .health-indus-banner-btn {
    margin: 1rem 0 !important;
  }

  /* --------------- all helth pages heading and sub heading ---------- */
  .health-section-sub-heading {
    line-height: 28px !important;
  }

  /* --------------- about section ------------- */
  .health-about-sec {
    padding-top: 2rem !important;
  }

  .health-sub-img-about-down-box {
    width: 94.3% !important;
  }

  /* -------------- service sectoin ---------- */
  .health-ind-inner-area-customer-main h5 {
    font-size: 23px !important;
  }

  /* =============== applications service page ============== */
  .health-automation-appserv img {
    height: 300px !important;
  }

  .health-automation-appserv p {
    font-size: 50px !important;
    margin: 1rem !important;
  }

  #exceptional-serv-health-row img {
    height: 260px !important;
    padding: 0 0rem !important;
  }

}

@media (max-width: 1400px) and (min-width: 1201px) {

  /* ---- all page banner css -------------- */
  .health-ind-banner-main {
    padding: 7rem 0 2rem 0 !important;
  }

  /* -------------- service sectoin ---------- */
  .health-ind-inner-area-customer-main {
    padding: 15px 5px 15px 15px !important;
  }

}